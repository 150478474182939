import React, { useCallback, useMemo, useState } from "react";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { BreadcrumbForTable } from "presentation/designSystem/Breadcrumb/Breadcrumb";
import { ColumnType } from "presentation/designSystem/Table/Table";
import styled from "@emotion/styled";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import { TableRowSelection } from "antd/lib/table/interface";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { DateTimeFormat } from "lib/dateTime";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import { Description } from "@material-ui/icons";
import { CodeListResponse } from "../types";
import { getCodeList } from "../api";
import { useModal } from "@isfg/react-components/build/Modal/useModal";
import { CodeListDetailModal } from "../modals/CodeListDetailModal";

const COLUMNS: ColumnType<CodeListResponse>[] = [
  createColumn({
    dataIndex: "name",
    title: t(translationPath(lang.table.codeListTable.name))
  }),
  createDateTimeColumn(
    {
      dataIndex: "lastUpdate",
      title: t(translationPath(lang.table.codeListTable.lastUpdate)),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.Date
  )
];

export const CodeListTable = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [modal, modalApi] = useModal(CodeListDetailModal, {
    onOk: () => {}
  });

  const ACTIONS = useMemo(
    () => ({
      single: [
        {
          key: "showDetail",
          icon: <Description />,
          tooltip: t(translationPath(lang.general.showDetail)),
          action: (selected: CodeListResponse[]) => {
            return modalApi.open({
              codeListType: selected[0]?.type || ""
            });
          }
        }
      ]
    }),
    []
  );

  const getRemoteData: GetRemoteDataClb<CodeListResponse> = useCallback(
    ({ pagination, sorting }) => getCodeList(pagination, sorting),
    []
  );

  const onRowSelection: TableRowSelection<CodeListResponse> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  return (
    <MenuLayout>
      <>
        {modal}
        <BreadcrumbForTable
          title={t(translationPath(lang.breadcrumb.codeList))}
        />
        <TableStyled>
          <RemoteTable<CodeListResponse>
            name="administration/codeListTable"
            rowKey="name"
            actions={ACTIONS}
            columns={COLUMNS}
            getRemoteData={getRemoteData}
            rowSelection={onRowSelection}
          />
        </TableStyled>
      </>
    </MenuLayout>
  );
};

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
