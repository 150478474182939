import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentPropsType,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { PromoteConceptToDocumentContentDialog } from "./PromoteConceptToDocumentContentDialog";
import { GenericDocument } from "../../../../core/types";
import { useHasConceptConcernedSubjects } from "../../../hook/HasConceptConcernedSubjects/useHasConceptConcernedSubjects";
import { documentViewAction__Refresh } from "../../../../core/components/documentView/_actions";
import { callAsyncAction } from "../../../../core/action";
import { promoteConceptToDocumentAction } from "./_actions";
import { Modal } from "presentation/designSystem/Modal/Modal";
import { ModalSize } from "presentation/designSystem/Modal/Modal";
import { CloseOutlined } from "presentation/designSystem/Icon/Icon";
import { IconButton } from "presentation/designSystem/Button/IconButton";

export const promoteConceptToDocumentDialogContent = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      async ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        const formValues = channels?.contentTab?.state?.formValues;

        const hasConceptConcernedSubjects = await useHasConceptConcernedSubjects(
          (dialogProps.data as GenericDocument).id
        );

        if (!formValues) return;
        const onSuccess = () => {
          dispatch(documentViewAction__Refresh(true));
          onClose();
        };
        const saveChanges = () => {
          dispatch(
            callAsyncAction({
              action: promoteConceptToDocumentAction,
              onError,
              onSuccess,
              payload: {
                body: formValues,
                nodeId: (dialogProps.data as GenericDocument).id
              }
            })
          );
        };
        const onError = () => {
          buttonState.setIsPending(false);
        };

        buttonState.setIsPending(true);
        if (hasConceptConcernedSubjects) {
          saveChanges();
        } else {
          const config = {
            closeIcon: <IconButton icon={<CloseOutlined />} tooltip={null} />,
            closable: true,
            title: t(
              translationPath(lang.dialog.title.confirmationConcernedSubject)
            ),
            content: (
              <>
                <p>
                  {t(
                    translationPath(
                      lang.dialog.content.confirmationConcernedSubject
                    )
                  )}
                </p>
              </>
            ),
            okText: t(translationPath(lang.dialog.buttons.confirm)),
            cancelText: t(translationPath(lang.dialog.buttons.cancel)),

            onOk: () => {
              saveChanges();
            },
            onCancel: () => {
              buttonState.setIsPending(false);
            }
          };
          Modal.confirm(config);
        }
      }
    )
  ],
  content: PromoteConceptToDocumentContentDialog,
  title: (props: DialogContentPropsType) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.promoteConceptToDocument))}
      {...props}
    />
  ),
  type: DialogType.PromoteConceptToDocument,
  size: ModalSize.Small
};
