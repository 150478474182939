import { Form } from "../../../../designSystem/Form/Form";
import React from "react";
import { ChannelFormProps } from "./ChannelFormProps";
import { useChannelForm } from "./useChannelForm";

export function ChannelForm<Values>(props: ChannelFormProps<Values>) {
  const { channel, ...formProps } = props;
  const { setFormRef, onSubmit } = useChannelForm(props);

  return <Form {...formProps} onSubmit={onSubmit} innerRef={setFormRef} />;
}
