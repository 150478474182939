import { SearchSubjectLegalEntityRepositoryToken } from "domain/di/token/nameRegistry";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { SearchedSubjectLegalEntityRepository } from "../../../domain/service/nameRegistry/SearchedSubjectRepository";
import { LegalEntity } from "../../../domain/struct/nameRegistry/LegalEntity";

@DInjectable()
export class FindSearchedSubjectLegalEntity {
  constructor(
    @DInject(SearchSubjectLegalEntityRepositoryToken)
    protected searchedSubjectRepository: SearchedSubjectLegalEntityRepository
  ) {}

  findAllPaginated(
    config: PaginationConfig,
    params?: Record<string, string>
  ): Promise<PaginatedResult<LegalEntity>> {
    return this.searchedSubjectRepository.findAllPaginated(config, params);
  }
}
