import React from "react";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import { Button } from "presentation/designSystem/Button/Button";
import { Select } from "presentation/designSystem/Select/Select";
import styled from "@emotion/styled";
import { useQM } from "../../../../../share/hook/query/useQM";
import {
  getSearchEventTypes,
  getSearchGroups,
  getSearchNodeTypes,
  getSearchUsers,
  ImprintSearchParams,
  SearchEventTypesParams,
  SearchGroupsParams,
  SearchNodeTypesParams,
  SearchUsersParams
} from "../api";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { DateTimeFormat } from "presentation/govDesignSystem/lib/dateTime";
import moment from "moment";

const useFindGroups = () => {
  return useQM("findGroups", () => getSearchGroups(), {});
};

const GroupSelect = () => {
  const { data: groupData, isLoading: isGroupLoading } = useFindGroups();
  const groupOptions = (groupData || []).map((group: SearchGroupsParams) => {
    return {
      value: group.id,
      label: group.displayName
    };
  });

  return (
    <Item
      name="userGroupId"
      label={t(translationPath(lang.menu.items.organizationlUnit))}
    >
      <Select
        allowClear
        loading={isGroupLoading}
        options={groupOptions}
      ></Select>
    </Item>
  );
};

const useFindUsers = () => {
  return useQM("findUsers", () => getSearchUsers(), {});
};

const UserSelect = () => {
  const { data: userData, isLoading: isUserLoading } = useFindUsers();
  const userOptions = (userData || []).map((user: SearchUsersParams) => {
    return {
      value: user.id,
      label: user.displayName
    };
  });

  return (
    <Item
      name="userId"
      label={t(translationPath(lang.menu.items.authorOfChange))}
    >
      <Select allowClear loading={isUserLoading} options={userOptions} />
    </Item>
  );
};

const useFindNodeTypes = () => {
  return useQM("findNodeTypes", () => getSearchNodeTypes(), {});
};

const NodeTypesSelect = () => {
  const {
    data: nodeTypesData,
    isLoading: isNodeTypesLoading
  } = useFindNodeTypes();
  const nodeTypesOptions = (nodeTypesData || []).map(
    (nodeTypes: SearchNodeTypesParams) => {
      return {
        value: nodeTypes.code,
        label: nodeTypes.description
      };
    }
  );

  return (
    <Item
      name="nodeTypeCode"
      label={t(translationPath(lang.menu.items.objectType))}
    >
      <Select
        allowClear
        loading={isNodeTypesLoading}
        options={nodeTypesOptions}
      />
    </Item>
  );
};

const useFindEventTypes = () => {
  return useQM("findEventTypes", () => getSearchEventTypes(), {});
};

const EventTypesSelect = () => {
  const {
    data: eventTypesData,
    isLoading: isEventTypesLoading
  } = useFindEventTypes();
  const eventTypesOptions = (eventTypesData || []).map(
    (eventTypes: SearchEventTypesParams) => {
      return {
        value: eventTypes.code,
        label: eventTypes.description
      };
    }
  );

  return (
    <Item
      name="eventTypeCode"
      label={t(translationPath(lang.menu.items.eventType))}
    >
      <Select
        allowClear
        loading={isEventTypesLoading}
        options={eventTypesOptions}
      />
    </Item>
  );
};

interface DailyImprintSearchFormProps {
  setRemoteQueryParams(data: Record<string, string>): void;
}
export interface ImprintSearchFormValues
  extends Omit<ImprintSearchParams, "occuredAtMin" | "occuredAtMax"> {
  occuredAtMin?: moment.Moment;
  occuredAtMax?: moment.Moment;
}
export const DailyImprintSearchForm = ({
  setRemoteQueryParams
}: DailyImprintSearchFormProps) => {
  const [form] = useForm<ImprintSearchFormValues>();

  const onClickSearchHandler = () => {
    const { occuredAtMin, occuredAtMax, ...formValues } = form.getFieldsValue();

    setRemoteQueryParams({
      ...(formValues as Record<string, string>),
      ...(occuredAtMax && {
        occuredAtMax: occuredAtMax.toISOString()
      }),
      ...(occuredAtMin && {
        occuredAtMin: occuredAtMin.toISOString()
      })
    });
  };

  const onClickDeleteHandler = () => {
    form.setFieldsValue({
      occuredAtMin: undefined,
      occuredAtMax: undefined,
      userGroupId: "",
      userId: "",
      nodeTypeCode: "",
      eventTypeCode: "",
      description: ""
    });
  };

  const disabledDateMin = (current: moment.Moment) => {
    const { occuredAtMax } = form.getFieldsValue();
    return current > (occuredAtMax || moment());
  };

  const disabledDateMax = (current: moment.Moment) => {
    const { occuredAtMin } = form.getFieldsValue();
    return (occuredAtMin && current <= occuredAtMin) || current > moment();
  };

  return (
    <>
      <StyledTitle />
      <Form form={form}>
        <Row gutter={6}>
          <Col span={6}>
            <Item name="pid" label={t(translationPath(lang.general.pid))}>
              <Input placeholder={"Identifikátor"} />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="occuredAtMin"
              label={t(
                translationPath(lang.menu.items.dailyImprintSearchOccuredMin)
              )}
            >
              <DatePicker
                format={DateTimeFormat.FullDateTime}
                showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                style={{ width: "100%" }}
                disabledDate={disabledDateMin}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="occuredAtMax"
              label={t(
                translationPath(lang.menu.items.dailyImprintSearchOccuredMax)
              )}
            >
              <DatePicker
                disabledDate={disabledDateMax}
                format={DateTimeFormat.FullDateTime}
                showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <GroupSelect />
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={6}>
            <UserSelect />
          </Col>
          <Col span={6}>
            <EventTypesSelect />
          </Col>
          <Col span={6}>
            <NodeTypesSelect />
          </Col>
          <Col span={6}>
            <Item
              name="description"
              label={t(
                translationPath(lang.menu.items.dailyImprintSearchDescription)
              )}
            >
              <Input placeholder={"Popis změny"} />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col>
            <Button
              onClick={onClickSearchHandler}
              type="primary"
              data-test-id="search-daily-imprint"
            >
              {t(
                translationPath(lang.dialog.form.dailyImprintSearchForm.search)
              )}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={onClickDeleteHandler}
              data-test-id="delete-search-daily-imprint"
            >
              {t(
                translationPath(lang.dialog.form.dailyImprintSearchForm.delete)
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const StyledTitle = styled.div`
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
`;
