import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ImprintRepositoryToken } from "../../domain/di/token/administration";
import { PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import { ImprintRepository } from "../../domain/service/administration/ImprintRepository";

@DInjectable()
export class FindImprint {
  constructor(
    @DInject(ImprintRepositoryToken) protected repository: ImprintRepository
  ) {}

  findAll(pagination: PaginationConfig, sorting?: SortingConfig) {
    return this.repository.findAll(pagination, sorting);
  }
}
