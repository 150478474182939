import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { GroupRepositoryToken } from "../../domain/di/token/user";
import { GroupRepository } from "../../domain/service/user/GroupRepository";
import { UserId } from "../../domain/struct/user/User";

@DInjectable()
export class FindGroup {
  constructor(
    @DInject(GroupRepositoryToken) protected repository: GroupRepository
  ) {}

  getApplicableGroupsForUser() {
    return this.repository.getApplicableGroupsForUser();
  }

  getUserGroups(userId: UserId) {
    return this.repository.getUserGroups(userId);
  }
  getGroupsToHandover() {
    return this.repository.getGroupsToHandover();
  }
}
