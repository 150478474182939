import React from "react";
import { StyledFormControlFifth } from "presentation/core/components/dialog/Dialog.styles";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { sslPropsProxy } from "presentation/core/types";
import InputLabel from "@material-ui/core/InputLabel";
import { lang, t } from "../../../../translation/i18n";
import { Field } from "formik";
import { Select } from "formik-material-ui";
import { renderHiddenFileMarksOptions } from "../treeSelect/renderHiddenFileMarksOptions";
import { FileMarks } from "../treeSelect/FileMarks";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlWithError from "../../../../core/components/formControlWithError";
import { useFileMarksSearch } from "../treeSelect/hooks/useFileMarksSearch";
import { SslAnalogWithOwner } from "presentation/core/api/models";
import { FilePlanGroups } from "presentation/core/features/login/_types";
import { FormControlComponent } from "presentation/core/components/formControlWithError/_types";
import { FirstMenuItemWithSearch } from "../treeSelect/FirstMenuItemWithSearch";

export interface FileMarkFieldProps {
  isReadonly?: boolean;
  values: SslAnalogWithOwner;
  fileMarks: FilePlanGroups[];
  filteringFunction?: (fileMark: FilePlanGroups) => boolean;
  Component?: FormControlComponent;
}
export const FileMarkField = ({
  isReadonly,
  values,
  fileMarks,
  filteringFunction,
  Component = StyledFormControlFifth
}: FileMarkFieldProps) => {
  const {
    returnInputValue,
    searchedFileMarks,
    searchedFileMarksValue,
    validFileMarks
  } = useFileMarksSearch(
    fileMarks,
    (isReadonly
      ? (fileMark: FilePlanGroups) => !!fileMark
      : filteringFunction) || undefined
  );

  return (
    <FormControlWithError
      component={Component}
      name={lastPathMember(sslPropsProxy.fileMark).path}
    >
      <InputLabel htmlFor="fileMark">
        {t(translationPath(lang.general.fileMark))}
      </InputLabel>
      <Field
        component={Select}
        data-test-id="meta-input-fileMark"
        disabled={(!values.fileMark && !values.filePlan) || isReadonly}
        name={lastPathMember(sslPropsProxy.fileMark).path}
        inputProps={{
          id: "fileMark"
        }}
      >
        <FirstMenuItemWithSearch onSearch={returnInputValue} />
        {renderHiddenFileMarksOptions(searchedFileMarks(fileMarks))}

        {searchedFileMarks(validFileMarks).length ? (
          <FileMarks
            isSearch={!!searchedFileMarksValue}
            filePlanGroups={searchedFileMarks(validFileMarks)}
            level={0}
          />
        ) : (
          <MenuItem
            value={t(translationPath(lang.dialog.form.fileMarkNotFound))}
          >
            {t(translationPath(lang.dialog.form.fileMarkNotFound))}
          </MenuItem>
        )}
      </Field>
    </FormControlWithError>
  );
};
