import React from "react";
import { ModalWithTabs } from "presentation/designSystem/Modal/Modal";
import { Tabs } from "presentation/designSystem/Tabs/Tabs";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { lang, t } from "../../../translation/i18n";
import { translationPath } from "../../../share/utils/getPath";
import { ErasedDocumentForm } from "./form/ErasedDocumentForm";
import { DocumentHistoryTable } from "../../../share/tables/document/DocumentHistoryTable/DocumentHistoryTable";
import { Button } from "antd";

interface ShowDetailsOfErasedDocumentProps extends BaseModalProps {
  nodeId: string;
}

export const DetailsOfErasedDocumentModal = ({
  nodeId,
  onCancel
}: ShowDetailsOfErasedDocumentProps) => {
  const tabsAndForms = [
    {
      tab: t(translationPath(lang.table.retentionProposalTable.metadata)),
      form: <ErasedDocumentForm id={nodeId} />
    },
    {
      tab: t(translationPath(lang.table.retentionProposalTable.history)),
      form: <DocumentHistoryTable id={nodeId} />
    }
  ];
  return (
    <ModalWithTabs
      visible={true}
      title={t(translationPath(lang.dialog.title.documentDetail))}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t(translationPath(lang.modal.cancel))}
        </Button>
      ]}
    >
      <Tabs>
        {tabsAndForms.map((tabAndForm, index) => {
          return (
            <Tabs.TabPane tab={tabAndForm.tab} key={index}>
              <div>{tabAndForm.form}</div>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </ModalWithTabs>
  );
};
