import React, { useState } from "react";
import { TableCell } from "@material-ui/core";
import { ColumnProps } from "./Column";
import { IconButton } from "presentation/designSystem/Button/IconButton";
import { useHover } from "presentation/share/hook/hover/useHover";

export function IconButtonColumn<Row, ColumnValue>({
  columnValue,
  column,
  disabled,
  row
}: ColumnProps<Row, ColumnValue>) {
  const onClick = () => column.onClick?.(row);
  const tooltip = column.tooltip;
  const noBorder = column.noBorder;

  const [icon, setIcon] = useState(column.icon);
  const [iconButton] = useHover(
    IconButton,
    {
      onClick,
      disabled,
      tooltip,
      icon,
      noBorder,
      size: "large",
      ...(!columnValue && { style: { display: "none" } })
    },
    {
      onEnter: () => {
        column.hoverIcon && setIcon(column.hoverIcon);
      },
      onLeave: () => {
        column.hoverIcon && setIcon(column.icon);
      }
    }
  );

  return <TableCell>{iconButton}</TableCell>;
}
