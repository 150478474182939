import { Check, Description } from "@material-ui/icons";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import DocumentView from "presentation/core/components/documentView";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { EmailDocument, emailDocumentProxy } from "presentation/core/types";
import { SitePaths, SpisumNodeTypes } from "presentation/enums";
import React, { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { isEmptyString } from "presentation/share/utils/utils";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { EmailNotRegisteredDetailsModal } from "./modals/EmailNotRegisteredDetailsModal";
import { useModal } from "presentation/designSystem/Modal/useModal";
import Loading from "presentation/core/components/dataTable/components/Loading";
import { metaFormAction__Update } from "../../../../../../../core/components/MetaForm/_actions";
import { ActionType } from "typesafe-actions";
import { useCreateEmailRegistration } from "../unprocessed/hooks/useCreateEmailRegistration";

enum NotRegisteredReason {
  EM_VAL_01 = "EM_VAL_01"
}

const NotRegisteredTranslationMap = {
  [NotRegisteredReason.EM_VAL_01]: lang.general.notValid
};

const defaultColumn: DataColumn<EmailDocument> = {
  isDateTime: true,
  keys: [
    classPath(emailDocumentProxy.properties!.ssl!.digitalDeliveryDeliveryDate)
      .path
  ],
  label: t(translationPath(lang.general.delivery))
};

const IncomeDigitalDocumentEmailBoxNotRegistered = () => {
  const dispatch = useDispatch<
    Dispatch<ActionType<typeof metaFormAction__Update>>
  >();

  const emailAccounts = useSelector(
    (state: RootStateType) => state.emailReducer.emailAccounts
  );

  const columns: DataColumn<EmailDocument>[] = [
    {
      keys: [classPath(emailDocumentProxy.properties!.ssl!.pid).path],
      label: t(translationPath(lang.general.identifier))
    },
    {
      keys: [
        classPath(emailDocumentProxy.properties!.ssl!.digitalDeliverySubject)
          .path
      ],
      label: t(translationPath(lang.general.subject))
    },
    {
      getValue: (item) =>
        `${item.properties?.ssl?.emailSender}${
          !isEmptyString(item.properties?.ssl?.emailSenderName)
            ? ` (${item.properties?.ssl?.emailSenderName})`
            : ""
        }`,
      keys: [
        classPath(emailDocumentProxy.properties!.ssl!.emailSender).path,
        classPath(emailDocumentProxy.properties!.ssl!.emailSenderName).path
      ],
      label: t(translationPath(lang.general.sender))
    },
    defaultColumn,
    {
      keys: [
        classPath(
          emailDocumentProxy.properties!.ssl!.digitalDeliveryAttachmentsCount
        ).path
      ],
      label: t(translationPath(lang.general.attachmentsCount))
    },
    {
      getValue: (item) => {
        if (!item.properties?.ssl?.digitalDeliveryNotRegisteredReason) {
          return "";
        }

        const translation =
          NotRegisteredTranslationMap[
            item.properties?.ssl?.digitalDeliveryNotRegisteredReason
          ];

        return translation
          ? `${t(translationPath(translation))}`
          : item.properties?.ssl?.digitalDeliveryNotRegisteredReason;
      },
      keys: [
        classPath(
          emailDocumentProxy.properties!.ssl!.digitalDeliveryNotRegisteredReason
        ).path
      ],
      label: t(translationPath(lang.general.notRegisterReason))
    }
  ];

  if (emailAccounts?.length > 1) {
    columns.push({
      keys: [
        classPath(emailDocumentProxy.properties!.ssl!.emailRecipient).path
      ],
      label: t(translationPath(lang.general.recipient))
    });
  }

  const paths = useSelector(
    (state: RootStateType) => state.loginReducer.global.paths
  );

  const relativePath = getRelativePath(
    paths,
    null,
    SitePaths.Mailroom,
    SitePaths.MailBox,
    SitePaths.NotRegistered
  );

  const {
    modal: registerModal,
    register,
    isLoading: isRegisterLoading
  } = useCreateEmailRegistration();

  const [emailDetailsModal, emailDetailsModalApi] = useModal(
    EmailNotRegisteredDetailsModal,
    {}
  );

  const onDoubleClick: (row: EmailDocument) => void = (row) => {
    emailDetailsModalApi.open({
      data: row,
      register
    });
  };

  const controls: ControlsBarType<EmailDocument> = {
    single: {
      items: [
        {
          action: (selected: EmailDocument[]) => {
            emailDetailsModalApi.open({ data: selected[0], register });
          },
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: EmailDocument[]) => {
            register(selected[0].id);
            dispatch(
              metaFormAction__Update({
                formValues: selected[0]?.properties?.ssl
              })
            );
          },
          icon: <Check />,
          title: t(translationPath(lang.general.register))
        }
      ]
    }
  };

  return (
    <>
      {registerModal}
      {emailDetailsModal}
      {isRegisterLoading && <Loading />}
      <MenuLayout>
        <DocumentView
          children={{
            relativePath,
            where: `(nodeType='${SpisumNodeTypes.Email}')`
          }}
          columns={columns}
          controls={controls}
          customTitle={t(translationPath(lang.table.notRegisteredEmails))}
          defaultSortAsc={true}
          defaultSortColumn={defaultColumn}
          handleDoubleClick={onDoubleClick}
        />
      </MenuLayout>
    </>
  );
};

IncomeDigitalDocumentEmailBoxNotRegistered.displayName = "notRegistered";

export default withTranslation()(IncomeDigitalDocumentEmailBoxNotRegistered);
