import { LegalEntityRepositoryToken } from "domain/di/token/nameRegistry";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { LegalEntityRepository } from "../../domain/service/nameRegistry/LegalEntityRepository";
import {
  LegalEntity,
  LegalEntityId
} from "../../domain/struct/nameRegistry/LegalEntity";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";

@DInjectable()
export class FindLegalEntity {
  constructor(
    @DInject(LegalEntityRepositoryToken)
    protected repository: LegalEntityRepository
  ) {}

  findAllPaginated(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<LegalEntity>> {
    return this.repository.findAllPaginated(pagination, sorting);
  }

  findFullById(id: LegalEntityId) {
    return this.repository.findFullById(id);
  }

  findById(id: LegalEntityId) {
    return this.repository.findById(id);
  }
}
