import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { DocumentHistoryRepositoryToken } from "../../domain/di/token/document";
import { DocumentHistoryRepository } from "../../domain/service/document/DocumentHistoryRepository";
import { DocumentId } from "../../domain/struct/document/Document";
import { History } from "domain/struct/history/History";
import { SortingConfig } from "lib/contract/Sorting";

@DInjectable()
export class FindDocumentHistory {
  constructor(
    @DInject(DocumentHistoryRepositoryToken)
    protected readonly repository: DocumentHistoryRepository
  ) {}

  findPaginated(
    id: DocumentId,
    paginationConfig: PaginationConfig,
    sortingConfig?: SortingConfig
  ): Promise<PaginatedResult<History>> {
    return this.repository.findPaginated(id, paginationConfig, sortingConfig);
  }
}
