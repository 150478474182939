import React, { useCallback, useMemo } from "react";
import { ColumnType } from "presentation/designSystem/Table/Table";
import styled from "@emotion/styled";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { DateTimeFormat } from "lib/dateTime";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import { getImprintExport, getImprintSearch } from "../api";
import { ImprintSearchResponse } from "../api/mapper";
import { GetApp } from "@material-ui/icons";
import contentDisposition from "content-disposition";
import fileDownload from "js-file-download";

const COLUMNS: ColumnType<ImprintSearchResponse>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.pid)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "occuredAt",
      title: t(
        translationPath(lang.table.dailyImprintSearchTable.changedDateTime)
      ),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "logType",
    title: t(translationPath(lang.table.dailyImprintSearchTable.typeOfLog)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "eventType",
    title: t(translationPath(lang.table.dailyImprintSearchTable.typeOfChange)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "description",
    title: t(
      translationPath(lang.table.dailyImprintSearchTable.descriptionOfChange)
    ),
    defaultSortOrder: "ascend",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "nodeType",
    title: t(translationPath(lang.table.dailyImprintSearchTable.typeOfObject)),
    defaultSortOrder: "ascend",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "user",
    title: t(
      translationPath(lang.table.dailyImprintSearchTable.authorOfChange)
    ),
    defaultSortOrder: "ascend",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "userGroup",
    title: t(translationPath(lang.table.dailyImprintSearchTable.group)),
    defaultSortOrder: "ascend",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  })
];

interface DailyImprintSearchTableProps {
  remoteQueryParams?: Record<string, string>;
}

export const DailyImprintSearchTable = ({
  remoteQueryParams
}: DailyImprintSearchTableProps) => {
  const onExport = useCallback(() => {
    getImprintExport(remoteQueryParams).then(({ response, headers }) => {
      const disposition = contentDisposition.parse(
        headers?.get("content-disposition") || ""
      );

      fileDownload(response as Blob, disposition.parameters.filename);
    });
  }, [remoteQueryParams]);

  const ACTIONS = useMemo(
    () => ({
      default: [
        {
          key: "download",
          icon: <GetApp style={{ margin: "5px" }} />,
          tooltip: t(translationPath(lang.general.download)),
          action() {
            onExport();
          }
        }
      ]
    }),
    [onExport]
  );

  const getRemoteData: GetRemoteDataClb<ImprintSearchResponse> = useCallback(
    ({ pagination, sorting, params }) =>
      getImprintSearch(pagination, sorting, params),
    []
  );

  return (
    <>
      <TableStyled>
        <RemoteTable<ImprintSearchResponse>
          name="imprintSearch"
          rowKey="id"
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
          remoteQueryParams={remoteQueryParams}
        />
      </TableStyled>
    </>
  );
};

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
