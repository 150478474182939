import React from "react";
import styled from "@emotion/styled";
import moment from "moment";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { Select } from "presentation/designSystem/Select/Select";
import { useMutation } from "presentation/share/hook/query/useQM";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { ErrorType } from "presentation/types";
import { lockTypeFile } from "../api";
import { LockTypeFileParams, LockTypeForm } from "../types";

export interface LockTypeFileModalInterface extends BaseModalProps {
  nodeId: string;
  onOk?: BaseModalProps["onOk"];
  required?: boolean;
}

export enum SelectLockMethod {
  DateExpiration = "Uplynutí časového období",
  MaximumDocumentsReached = "Dosažení maximálního počtu dokumentů"
}

export const LockTypeFile = ({
  onCancel,
  nodeId,
  onOk
}: LockTypeFileModalInterface) => {
  const [form] = useForm<LockTypeForm>();
  const getNotificationForError = (error: ErrorType) => {
    return {
      message: error.code
        ? getErrorCodeTranslation(error.code)
        : t(translationPath(lang.dialog.notifications.typeFileNotClosed))
    };
  };
  const onConfirm = async () => {
    try {
      await form.validateFields();
      const { settleMethod } = form.getFieldsValue();
      mutate({ settleMethod, nodeId });
    } catch {}
  };

  const { isLoading, mutate } = useMutation(
    (params: LockTypeFileParams) => lockTypeFile(params),
    {
      onSuccess() {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(lang.table.openTypeFilesTable.successLockedFilePlan)
        )
      },
      onErrorNotification: getNotificationForError
    }
  );

  return (
    <Modal
      visible={true}
      title={t(translationPath(lang.table.openTypeFilesTable.closeTypeFile))}
      onCancel={onCancel}
      onOk={onConfirm}
      confirmLoading={isLoading}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <Form form={form} initialValues={{ createdAt: moment() }}>
        <StyledSelectItem
          name="settleMethod"
          label={t(
            translationPath(lang.table.openTypeFilesTable.methodOfSettlement)
          )}
          rules={[{ required: true }]}
          required={true}
        >
          <Select style={{ width: "100%" }}>
            {Object.keys(SelectLockMethod).map((key) => (
              <Select.Option key={key} value={key}>
                {SelectLockMethod[key]}
              </Select.Option>
            ))}
          </Select>
        </StyledSelectItem>
      </Form>
    </Modal>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;
