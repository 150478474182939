import React from "react";
import { Form, Item } from "presentation/designSystem/Form/v2/Form";
import { Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { emailValidation } from "../../../../../designSystem/Input/InputValidations";
import { styled } from "../../../../../styles";
import { FormInstance } from "antd";
import { lang, t } from "../../../../../translation/i18n";
import { translationPath } from "../../../../../share/utils/getPath";

interface AddEmailToWhitelistFormInterface {
  form: FormInstance;
  onChange: () => void;
}
export const CreateEmailToWhitelistForm = ({
  form,
  onChange
}: AddEmailToWhitelistFormInterface) => {
  return (
    <Form name="addEmailToWhitelistForm" form={form}>
      <StyledRow>
        <Item
          name="email"
          rules={[{ required: true }, emailValidation, { max: 50 }]}
          label={t(
            translationPath(lang.dialog.whitelistDialogs.detail.emailAddress)
          )}
        >
          <Input onChange={onChange} />
        </Item>
      </StyledRow>
      <Item
        name="comment"
        rules={[{ max: 100 }, { min: 4 }]}
        label={t(translationPath(lang.dialog.whitelistDialogs.detail.comment))}
      >
        <Input onChange={onChange} />
      </Item>
    </Form>
  );
};

const StyledRow = styled(Row)`
  //width: 150px;

  .ant-row {
    width: 50%;
  }
`;
