import {
  DInject,
  DInjectable
} from "../../presentation/core/features/dependencyInjection";
import { PerformDeletionToken } from "../../domain/di/token/recordRetention";
import { PerformDeletionRepository } from "../../domain/service/recordRetention/PerformDeletionRepository";

@DInjectable()
export class SavePerformDeletion {
  constructor(
    @DInject(PerformDeletionToken)
    protected repository: PerformDeletionRepository
  ) {}

  savePerformDeletion(id: string) {
    return this.repository.savePerformDeletion(id);
  }
}
