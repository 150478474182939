import {
  DInject,
  DInjectable
} from "../../presentation/core/features/dependencyInjection";
import { NationalityRepositoryToken } from "../../domain/di/token/codelist";
import { NationalityRepository } from "../../domain/service/codelist/NationalityRepository";

@DInjectable()
export class FindNationality {
  constructor(
    @DInject(NationalityRepositoryToken)
    protected repository: NationalityRepository
  ) {}

  findAll() {
    return this.repository.findAll();
  }
}
