import { ItemsOfMenu } from "presentation/core/components/menu/_types";
import React from "react";
import { Redirect } from "react-router-dom";
import { RootRouterType } from "presentation/router";
import { trimEnd } from "../../../lib/string";

export const findRedirect = (
  location: string,
  itemsOfMenu: ItemsOfMenu
): RootRouterType | null => {
  for (const item of itemsOfMenu) {
    if (
      item.url &&
      trimEnd(item.url.toLowerCase(), "/") === location &&
      item.redirectTo
    ) {
      return item.redirectTo;
    }
    const redirectInSubmenu =
      item.submenu &&
      item.submenu.length &&
      findRedirect(location, item.submenu);
    if (redirectInSubmenu) {
      return redirectInSubmenu;
    }
  }
  return null;
};

export const redirectFromMenu = (
  location: string,
  itemsOfMenu: ItemsOfMenu
) => {
  const redirectTo = findRedirect(
    trimEnd(location.toLowerCase(), "/"),
    itemsOfMenu
  );
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }
  return null;
};
