import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import clsx from "clsx";
import { Select, TextField } from "formik-material-ui";
import { EmailAccount } from "presentation/core/api/email/_types";
import {
  StyledFieldWide,
  StyledFormControlThird,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { BaseField } from "presentation/core/components/form/fields/BaseField";
import FormControlWithError from "presentation/core/components/formControlWithError";
import { RootStateType } from "presentation/reducers";
import { lang, t } from "presentation/translation/i18n";
import React from "react";
import { useSelector } from "react-redux";
import { lastPathMember, translationPath } from "../../../../../utils/getPath";
import { CreateShipmentFormValuesProxy } from "../../_types";

interface OwnProps {
  readonly: boolean;
  onSearchSubject?: () => void;
}

export const EmailFormFields = ({ readonly, onSearchSubject }: OwnProps) => {
  const classes = useStyles();
  const emailAccounts = useSelector(
    (state: RootStateType) => state.emailReducer.emailAccounts
  );

  return (
    <>
      <FormControlWithError
        className={clsx(classes.gapRightBig)}
        component={StyledFormControlThird}
        name={lastPathMember(CreateShipmentFormValuesProxy.sender).path}
      >
        <InputLabel
          required={true}
          htmlFor={lastPathMember(CreateShipmentFormValuesProxy.sender).path}
        >
          {t(translationPath(lang.general.sendFromEmail))}
        </InputLabel>
        <BaseField
          component={Select}
          data-test-id="create-shipment-email-sender"
          disabled={readonly}
          name={lastPathMember(CreateShipmentFormValuesProxy.sender).path}
          inputProps={{
            id: lastPathMember(CreateShipmentFormValuesProxy.sender).path
          }}
        >
          {emailAccounts?.map((account: EmailAccount, index: number) => {
            return (
              <MenuItem key={index} value={account.username}>
                {account.username} - {account.name}
              </MenuItem>
            );
          })}
        </BaseField>
      </FormControlWithError>
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <StyledFieldWide
          component={TextField}
          data-test-id="create-shipment-email"
          disabled={true}
          required={true}
          name={
            lastPathMember(CreateShipmentFormValuesProxy.recipientContactEmail)
              .path
          }
          type="text"
          label={t(translationPath(lang.general.email))}
          style={{ flex: 1 }}
        />
        <CreateIcon
          style={{
            margin: "9px",
            position: "absolute",
            right: 0,
            top: "1.25rem",
            cursor: "pointer"
          }}
          onClick={onSearchSubject}
        />
      </div>
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-recipientContactEmailBoxId"
        disabled={readonly}
        required={false}
        name={
          lastPathMember(
            CreateShipmentFormValuesProxy.recipientContactEmailBoxId
          ).path
        }
        type="text"
        style={{ display: "none" }}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-subject"
        disabled={readonly}
        required={true}
        name={lastPathMember(CreateShipmentFormValuesProxy.subject).path}
        type="text"
        label={t(translationPath(lang.general.subject))}
      />
    </>
  );
};
