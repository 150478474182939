import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { Lock } from "@material-ui/icons";
import { FileTypeStructure, LockSectionOfTypeFileParams } from "../../types";
import { useMutation } from "presentation/share/hook/query/useQM";
import { postCloseSectionOfTypeFile } from "../../api";
import { IconButton } from "presentation/designSystem/Button/IconButton";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { ErrorType } from "presentation/types";

enum SectionPartState {
  Open = "nevyrizen",
  Close = "uzavren"
}

interface UseCloseSectionOfTypeFileProps extends LockSectionOfTypeFileParams {
  section?: FileTypeStructure;
  onCloseSuccess?(): void;
}

export const useCloseSectionOfTypeFile = ({
  nodeId,
  sectionId,
  section,
  onCloseSuccess
}: UseCloseSectionOfTypeFileProps) => {
  const getNotificationForError = (error: ErrorType) => {
    return {
      message: error.code
        ? getErrorCodeTranslation(error.code)
        : t(
            translationPath(lang.dialog.notifications.documentForSealFormFailed)
          )
    };
  };

  const onConfirm = async () => {
    try {
      mutate({ nodeId, sectionId });
    } catch {}
  };

  const { isLoading, mutate } = useMutation(
    (params: LockSectionOfTypeFileParams) => postCloseSectionOfTypeFile(params),
    {
      onSuccess() {
        onCloseSuccess?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(
            lang.table.openTypeFilesTable.successCloseSectionOfFilePlan
          )
        )
      },
      onErrorNotification: getNotificationForError
    }
  );

  const isPartOpen = section?.parts.some(
    (part) => part.state === SectionPartState.Open
  );
  const isSectionClosed = section?.state === SectionPartState.Close;
  const disabled = !section || isPartOpen || isSectionClosed;

  const tooltip = isPartOpen
    ? t(translationPath(lang.tooltip.sectionContainsOpenPart))
    : isSectionClosed
    ? t(translationPath(lang.tooltip.sectionClosed))
    : t(translationPath(lang.tooltip.closeTypeFileAction));

  const button = (
    <IconButton
      data-test-id="close-section-of-typefile"
      icon={<Lock />}
      tooltip={tooltip}
      onClick={onConfirm}
      disabled={disabled}
      loading={isLoading}
    />
  );

  return {
    isLoading,
    button
  };
};
