import { TableAction } from "../../../designSystem/Table/Table";
import {
  FileTextFilled,
  PlusCircleOutlined,
  MinusCircleOutlined
} from "../../../designSystem/Icon/Icon";
import React from "react";
import { t } from "../../../translation/i18n";

type TableActionConfig<T> = Omit<TableAction<T>, "key" | "icon" | "tooltip"> & {
  key?: string; // make the key optional (actions will have default key set)
  tooltip?: TableAction<T>["tooltip"];
};

export function actionAdd<T>(action: TableActionConfig<T>): TableAction<T> {
  return {
    key: "add",
    icon: <PlusCircleOutlined />,
    tooltip: t("general:add"),
    ...action
  };
}

export function actionRemove<T>(action: TableActionConfig<T>): TableAction<T> {
  return {
    key: "remove",
    icon: <MinusCircleOutlined />,
    tooltip: t("general:remove"),
    ...action
  };
}

export function actionShowDetail<T>(
  action: TableActionConfig<T>
): TableAction<T> {
  return {
    key: "show-detail",
    icon: <FileTextFilled />,
    tooltip: t("general:showDetails"),
    ...action
  };
}
