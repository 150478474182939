import { useForm } from "@isfg/react-components/build/Form/Form";
import {
  BaseModalProps,
  useModal
} from "@isfg/react-components/build/Modal/useModal";
import { Tabs } from "@isfg/react-components/build/Tabs/Tabs";
import { Button } from "antd";
import { useComponentsV2TabContainer } from "presentation/core/components/dialog/tabs/components/hooks/useComponentsV2TabContainer";
import { RemoteTableApiContextProvider } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import { DocumentType, SpisumNodeTypes } from "presentation/enums";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommentsTabContainer from "../../../../../../../../core/components/dialog/tabs/comments";
import { DialogDataType } from "../../../../../../../../core/components/dialog/_types";
import { documentViewAction__Refresh } from "../../../../../../../../core/components/documentView/_actions";
import { RootStateType } from "../../../../../../../../reducers";
import { handoverDocument } from "../../../../../../../../share/components/dialog/documentHandoverDialog/_actions";
import { translationPath } from "../../../../../../../../share/utils/getPath";
import { lang, t } from "../../../../../../../../translation/i18n";
import { RegisterDataboxDigitalForm } from "../forms/RegisterDataboxDigitalForm";
import { RegisterDataboxDigitalFormFieldTypes } from "../forms/RegisterDataboxDigitalFormFieldTypes";
import { useUpdateDocument } from "../hooks/useUpdateDocument";
import { CreateDataboxRegistration } from "../types";
import UnsavedChangesModal from "./UnsavedChangesModal";
import { ModalWithPreview } from "../../../../../../../../designSystem/Modal/ModalWithPreview";
import { useInactiveComponentsV2TabContainer } from "../../../../../../../../core/components/dialog/tabs/InactivateComponents/hooks/useInactiveComponentsV2TabContainer";
import { useDocumentConcernedSubjectTable } from "presentation/share/tables/document/ConcernedSubjectTable/useDocumentConcernedSubjectTable";
import { SearchSubjectResult } from "presentation/modules/nameRegister/modal/SearchSubjectModal/SearchSubjectModal";

interface RegisterDataboxDigitalModalProps {
  onCancel?: BaseModalProps["onCancel"];
  onOk?: BaseModalProps["onOk"];
  document: CreateDataboxRegistration;
  documentType: DocumentType;
  nodeType: SpisumNodeTypes;
  nodeId?: string;
  documentId?: string;
  defaultActiveTab?: string;
}

export enum RegisterDataboxDigitalModalTabs {
  Metadata = "0",
  Components = "1",
  InActiveComponents = "2",
  ConcernedSubjects = "3",
  Comments = "4"
}

export const RegisterDataboxDigitalModal = (
  props: RegisterDataboxDigitalModalProps
) => {
  return (
    <RemoteTableApiContextProvider>
      <RegisterDataboxDigitalModalInner {...props} />
    </RemoteTableApiContextProvider>
  );
};

export const RegisterDataboxDigitalModalInner = ({
  onCancel,
  document,
  nodeId,
  nodeType,
  documentId,
  defaultActiveTab = RegisterDataboxDigitalModalTabs.Metadata
}: RegisterDataboxDigitalModalProps) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [form] = useForm<RegisterDataboxDigitalFormFieldTypes>();
  const formData = useSelector(
    (state: RootStateType) => state.metaFormReducer.actual.formValues
  );
  const dispatch = useDispatch();
  const changeActiveTab = (activeTabIndex: string) => {
    setActiveTab(activeTabIndex);
  };
  let [isDataBoxRegistered, setIsDataBoxRegistered] = useState<boolean>(false);

  const {
    updateAndRegister: updateAndRegisterDocument,
    updateAndRegisterLoading,
    update: updateDocument
  } = useUpdateDocument();

  const [unsavedChangesModal, unsavedChangesModalApi] = useModal(
    UnsavedChangesModal
  );

  const registerDataboxDocumentAndHandOver = async () => {
    if (isDataBoxRegistered) {
      await modalForHandover(nodeId!, form.getFieldsValue());
      return;
    }

    updateAndRegisterDocument(
      {
        id: nodeId!,
        input: form.getFieldsValue()
      },
      {
        onSuccess: () => {
          setIsDataBoxRegistered(true);
          modalForHandover(nodeId!, form.getFieldsValue());
        }
      }
    );
  };

  const modalForHandover = async (nodeId: string, formData: any) => {
    dispatch(
      handoverDocument({
        data: { id: nodeId, ...(formData as DialogDataType) },
        onClose: () => {},
        onSuccess() {
          onCancel?.();
        }
      })
    );
  };

  const closeDataboxModal = async () => {
    dispatch(documentViewAction__Refresh(true));
    onCancel?.();
  };

  const registerDataboxDocument = async () => {
    if (isDataBoxRegistered) {
      await closeDataboxModal();
    }
    updateAndRegisterDocument(
      {
        id: nodeId!,
        input: form.getFieldsValue()
      },
      {
        onSuccess: () => {
          setIsDataBoxRegistered(true);
          closeDataboxModal();
        }
      }
    );
  };

  const [showPreview, setShowPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState({
    id: "",
    name: "",
    nodeType: SpisumNodeTypes.Databox,
    entityId: "",
    fileIsEncrypted: false
  });
  const [showInactiveComponents, setShowInactiveComponents] = useState(false);

  const saveChanges = async () =>
    updateDocument(
      {
        id: nodeId!,
        input: form.getFieldsValue(true)
      },
      {
        onSuccess: () => {
          dispatch(documentViewAction__Refresh(true));
          onCancel?.();
        }
      }
    );
  const handleCancel = () => {
    form.isFieldsTouched()
      ? unsavedChangesModalApi.open({
          saveChanges,
          closeModal: onCancel
        })
      : onCancel?.();
  };

  const handleShowDeactivateComponent = () => {
    setShowInactiveComponents(true);
    setActiveTab("2");
  };

  const [
    componentsV2TabContainer,
    { fetchComponents }
  ] = useComponentsV2TabContainer({
    nodeId: nodeId!,

    isActive: activeTab === RegisterDataboxDigitalModalTabs.Components,
    dialogProps: {
      canUploadComponents: false,
      data: { nodeType: SpisumNodeTypes.Document }
    },
    documentId,
    showPreview,
    previewItem,
    setPreviewItem,
    setShowPreview,
    handleShowDeactivateComponent
  });
  const [
    inactiveComponentsV2TabContainer,
    { inActiveFetchComponent }
  ] = useInactiveComponentsV2TabContainer({
    nodeId: nodeId!,
    isActive: activeTab === RegisterDataboxDigitalModalTabs.InActiveComponents,
    dialogProps: {
      canUploadComponents: false,
      data: { nodeType: SpisumNodeTypes.Document }
    },
    documentId,
    showPreview,
    previewItem,
    setPreviewItem,
    setShowPreview
  });

  const [
    documentConcernedSubjectTable,
    { setSenderAsConcernedSubject, refreshDocConcernedSubjs }
  ] = useDocumentConcernedSubjectTable({
    documentId: nodeId!,
    readonly: false
  });

  const onSenderChange = (sender: SearchSubjectResult) => {
    setSenderAsConcernedSubject(sender.subject);
  };

  const tabsWithRefresh = {
    [RegisterDataboxDigitalModalTabs.Components]: fetchComponents,
    [RegisterDataboxDigitalModalTabs.ConcernedSubjects]: refreshDocConcernedSubjs,
    [RegisterDataboxDigitalModalTabs.InActiveComponents]: inActiveFetchComponent
  };

  return (
    <>
      {unsavedChangesModal}
      <ModalWithPreview
        visible={true}
        hasTabs
        onRefresh={tabsWithRefresh[activeTab]}
        title={t(translationPath(lang.dialog.title.digitalDocumentIncome))}
        onCancel={handleCancel}
        confirmLoading={false}
        handleShowPreviewChange={() => setShowPreview(false)}
        showPreview={showPreview}
        previewItem={previewItem}
        setPreviewItem={setPreviewItem}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t(translationPath(lang.modal.cancel))}
          </Button>,
          <Button
            key="registerAndHandover"
            type="primary"
            loading={false}
            onClick={registerDataboxDocumentAndHandOver}
          >
            {t(translationPath(lang.dialog.form.toRegisterAndRefer))}
          </Button>,
          <Button
            key="register"
            loading={updateAndRegisterLoading}
            onClick={registerDataboxDocument}
          >
            {t(translationPath(lang.dialog.form.toRegister))}
          </Button>
        ]}
      >
        <Tabs onChange={changeActiveTab} activeKey={activeTab}>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.subjectDialog.tabs.metadata))}
            key={RegisterDataboxDigitalModalTabs.Metadata}
          >
            <RegisterDataboxDigitalForm
              formData={document}
              form={form}
              onSenderChange={onSenderChange}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.components))}
            key={RegisterDataboxDigitalModalTabs.Components}
          >
            {componentsV2TabContainer}
          </Tabs.TabPane>
          {showInactiveComponents && (
            <Tabs.TabPane
              tab={t(translationPath(lang.dialog.tabs.InactiveComponents))}
              key={RegisterDataboxDigitalModalTabs.InActiveComponents}
            >
              {inactiveComponentsV2TabContainer}
            </Tabs.TabPane>
          )}
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.concernedSubjects))}
            key={RegisterDataboxDigitalModalTabs.ConcernedSubjects}
          >
            <div className="body-fullsize">{documentConcernedSubjectTable}</div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.notes))}
            key={RegisterDataboxDigitalModalTabs.Comments}
          >
            <CommentsTabContainer
              nodeId={nodeId!}
              isActive={activeTab === RegisterDataboxDigitalModalTabs.Comments}
              dialogProps={{ data: { nodeType } }}
            />
          </Tabs.TabPane>
        </Tabs>
      </ModalWithPreview>
    </>
  );
};
