import { DataBoxRepositoryToken } from "domain/di/token/nameRegistry";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { DataBox } from "../../../domain/struct/nameRegistry/DataBox";
import { DataBoxRepository } from "../../../domain/service/nameRegistry/isds/DataBoxRepository";
import { Contact } from "../../../domain/struct/nameRegistry/Contact";
import { ContactType } from "../../../domain/struct/nameRegistry/ContactType";

@DInjectable()
export class FindDataBox {
  constructor(
    @DInject(DataBoxRepositoryToken)
    protected repository: DataBoxRepository
  ) {}

  findOne(id: string, subjectId: string): Promise<DataBox> {
    if (!id) {
      throw Error("DataBox ID should be defined");
    }
    return this.repository.findOne(id, subjectId);
  }

  // async checkDataBox(contacts: Contact[]) {
  //   const contact = this.findDataBox(contacts);
  //   if (contact && contact.contact) {
  //     await this.findOne(String(contact.contact));
  //   }
  // }

  private findDataBox(contacts: Contact[]): Contact | undefined {
    const isDataBox = (contact: Contact) =>
      contact.contactType === ContactType.DataBoxId;
    return contacts.find(isDataBox);
  }
}
