import { callAsyncAction } from "presentation/core/action";
import { conceptUpdateActionType } from "presentation/core/api/concept/_actions";
import { documentUpdateActionType } from "presentation/core/api/document/_actions";
import { updateFileAction } from "presentation/core/api/file/_actions";
import {
  errorAction,
  successAction
} from "presentation/core/components/dialog/lib/actionsFactory";
import { triggerChannelsValidation } from "presentation/core/components/dialog/_methods";
import {
  ChannelsType,
  DialogContentType,
  DialogDataGenericData,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { SpisumNodeTypes } from "presentation/enums";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import {
  shipmentDetailBodyList,
  shipmentDetailSaveActionList
} from "../shipmentDetailDialog/mappers";
import DataModifiedDialog from "./DataModifiedDialog";
import { ModalSize } from "../../../../designSystem/Modal/Modal";

export const dataModifiedDialogContent: DialogContentType = {
  actions: () => [
    successAction(
      t(translationPath(lang.dialog.form.save)),
      ({ dialogProps, onClose, dispatch, buttonState, channels }) => {
        const dialogPropsTyped = dialogProps.data as DialogDataGenericData;
        buttonState.setIsPending(true);

        const onError = () => {
          buttonState.setIsPending(false);
        };

        const onSuccess = ({ refreshDocumentView = false } = {}) => () => {
          onClose();
          dialogProps.onSuccess?.();
          if (refreshDocumentView) {
            dispatch(documentViewAction__Refresh(true));
          }
        };

        if (dialogProps.parentDialogChannels) {
          const parentDialogChannels: ChannelsType =
            dialogProps.parentDialogChannels;
          const parentDialogChannelInstances = Object.values(
            parentDialogChannels
          );

          triggerChannelsValidation(parentDialogChannelInstances).then(() => {
            const allValid = parentDialogChannelInstances.every(
              (channel) => channel.isValid
            );

            if (!allValid) {
              onClose();
              return;
            }

            switch (dialogPropsTyped.nodeType) {
              case SpisumNodeTypes.Document: {
                dispatch(
                  callAsyncAction({
                    action: documentUpdateActionType,
                    onError,
                    onSuccess: onSuccess({ refreshDocumentView: true }),
                    payload: {
                      body: {
                        properties: dialogPropsTyped!.formValues
                      },
                      nodeId: dialogPropsTyped!.id
                    }
                  })
                );
                break;
              }
              case SpisumNodeTypes.Concept: {
                dispatch(
                  callAsyncAction({
                    action: conceptUpdateActionType,
                    onError,
                    onSuccess: onSuccess({ refreshDocumentView: true }),
                    payload: {
                      body: dialogPropsTyped!.formValues,
                      nodeId: dialogPropsTyped!.id
                    }
                  })
                );
                break;
              }
              case SpisumNodeTypes.File: {
                dispatch(
                  callAsyncAction({
                    action: updateFileAction,
                    onError,
                    onSuccess: onSuccess({ refreshDocumentView: true }),
                    payload: {
                      nodeId: dialogPropsTyped!.id,
                      properties: dialogPropsTyped!.formValues
                    }
                  })
                );
                break;
              }
              default: {
                const action =
                  shipmentDetailSaveActionList[dialogPropsTyped.nodeType!];
                const bodyMapper =
                  shipmentDetailBodyList[dialogPropsTyped.nodeType!];
                const componentIdList =
                  parentDialogChannels?.Komponenty?.state
                    ?.selectedComponentsIds;
                const body = bodyMapper(
                  dialogPropsTyped.formValues,
                  componentIdList
                );

                dispatch(
                  callAsyncAction({
                    action,
                    onError,
                    onSuccess: onSuccess(),
                    payload: {
                      body,
                      nodeId: dialogPropsTyped.id
                    }
                  })
                );
              }
            }
          });
        }
      }
    ),
    errorAction(
      t(translationPath(lang.dialog.form.dontSave)),
      ({ dialogProps, onClose, dispatch }) => {
        dialogProps.onSuccess?.();
        onClose?.();
        dispatch(documentViewAction__Refresh(true));
      }
    )
  ],
  content: DataModifiedDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.unsavedChanges))}
      {...props}
    />
  ),
  type: DialogType.DataModified,
  size: ModalSize.Small
};
