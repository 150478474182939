import { Check, Description } from "@material-ui/icons";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import DocumentView from "presentation/core/components/documentView";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { DataboxDocument, dataBoxDocumentProxy } from "presentation/core/types";
import { SitePaths, SpisumNodeTypes } from "presentation/enums";
import React, { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { ActionType } from "typesafe-actions";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { DataboxNotRegisteredDetailsModal } from "./modals/DataboxNotRegisteredDetailsModal";
import { useCreateDataboxRegistration } from "../unprocessed/hooks/useCreateDataboxRegistration";
import Loading from "presentation/core/components/dataTable/components/Loading";
import { metaFormAction__Update } from "presentation/core/components/MetaForm/_actions";

const defaultColumn: DataColumn<DataboxDocument> = {
  isDateTime: true,
  keys: [
    classPath(dataBoxDocumentProxy.properties!.ssl!.digitalDeliveryDeliveryDate)
      .path
  ],
  label: t(translationPath(lang.general.delivery))
};

const IncomeDigitalDocumentDataBoxNotRegistered = () => {
  const dispatch = useDispatch<
    Dispatch<ActionType<typeof metaFormAction__Update>>
  >();
  const databoxAccounts = useSelector(
    (state: RootStateType) => state.databoxReducer.databoxAccounts
  );

  const columns: DataColumn<DataboxDocument>[] = [
    {
      keys: [classPath(dataBoxDocumentProxy.properties!.ssl!.pid).path],
      label: t(translationPath(lang.general.identifier))
    },
    {
      keys: [
        classPath(dataBoxDocumentProxy.properties!.ssl!.digitalDeliverySubject)
          .path
      ],
      label: t(translationPath(lang.general.subject))
    },
    {
      keys: [
        classPath(dataBoxDocumentProxy.properties!.ssl!.databoxSenderName).path
      ],
      label: t(translationPath(lang.general.sender))
    },
    defaultColumn,
    {
      keys: [
        classPath(
          dataBoxDocumentProxy.properties!.ssl!.digitalDeliveryAttachmentsCount
        ).path
      ],
      label: t(translationPath(lang.general.attachmentsCount))
    },
    {
      keys: [
        classPath(
          dataBoxDocumentProxy.properties!.ssl!
            .digitalDeliveryNotRegisteredReason
        ).path
      ],
      label: t(translationPath(lang.general.notRegisterReason))
    }
  ];

  if (databoxAccounts?.length > 1) {
    columns.push({
      getValue: (x) => {
        return (
          databoxAccounts.find(
            (y) => y.username === x.properties?.ssl?.databoxRecipientUid
          )?.name || x.properties?.ssl?.databoxRecipientUid
        );
      },
      keys: [
        classPath(dataBoxDocumentProxy.properties!.ssl!.databoxRecipientUid)
          .path
      ],
      label: t(translationPath(lang.general.recipient))
    });
  }

  const paths = useSelector(
    (state: RootStateType) => state.loginReducer.global.paths
  );

  const relativePath = getRelativePath(
    paths,
    null,
    SitePaths.Mailroom,
    SitePaths.DataBox,
    SitePaths.NotRegistered
  );

  const controls: ControlsBarType<DataboxDocument> = {
    single: {
      items: [
        {
          action: (selected: DataboxDocument[]) => {
            databoxDetailsModalApi.open({
              data: selected[0],
              register
            });
          },
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: DataboxDocument[]) => {
            register(selected[0].id);
            dispatch(
              metaFormAction__Update({
                formValues: selected[0]?.properties?.ssl
              })
            );
          },
          icon: <Check />,
          title: t(translationPath(lang.general.register))
        }
      ]
    }
  };

  const {
    modal: registerModal,
    register,
    isLoading: isRegisterLoading
  } = useCreateDataboxRegistration();

  const [databoxDetailsModal, databoxDetailsModalApi] = useModal(
    DataboxNotRegisteredDetailsModal,
    {}
  );

  const onDoubleClick: (row: DataboxDocument) => void = (row) => {
    databoxDetailsModalApi.open({
      data: row,
      register
    });
  };

  return (
    <>
      {registerModal}
      {databoxDetailsModal}
      {isRegisterLoading && <Loading />}
      <MenuLayout>
        <DocumentView
          children={{
            relativePath,
            where: `(nodeType='${SpisumNodeTypes.Databox}')`
          }}
          columns={columns}
          controls={controls}
          customTitle={t(translationPath(lang.table.notRegisteredDataboxes))}
          defaultSortAsc={true}
          defaultSortColumn={defaultColumn}
          handleDoubleClick={onDoubleClick}
        />
      </MenuLayout>
    </>
  );
};

IncomeDigitalDocumentDataBoxNotRegistered.displayName = "notRegistered";

export default withTranslation()(IncomeDigitalDocumentDataBoxNotRegistered);
