import { Description, Mail } from "@material-ui/icons";
import { ControlsBarType } from "presentation/core/components/dataTable/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import {
  DialogTabContentPropsType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { isGenericDocument, ShipmentDocument } from "presentation/core/types";
import { ErrorCodeList, SpisumNodeTypes } from "presentation/enums";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { shipmentDetailDialogOpen } from "presentation/share/components/dialog/shipmentDetailDialog/_action";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import ShipmentTab from "./ShipmentTab";
import { nodeShipmentAction } from "./_actions";
import { Notification } from "presentation/designSystem/notification/Notification";
import MarkEmailRead from "assets/icons-tsx/MarkEmailRead";
import { getDownloadDelivery } from "./api";
import contentDisposition from "content-disposition";
import fileDownload from "js-file-download";
import { ErrorType } from "presentation/types";

type ShipmentTabContainerProps = DialogTabContentPropsType & {
  nodeId: string;
};

interface ShipmentTabContainerState {
  pageNumber: number;
  rowsPerPage: number;
}

const initialState: ShipmentTabContainerState = {
  pageNumber: 0,
  rowsPerPage: 50
};

const DATABOX_INTERNAL_STATE_NOT_DELIVERED = "Vytvorena";

const ShipmentTabContainer = ({
  channel,
  dialogProps,
  isActive,
  nodeId
}: ShipmentTabContainerProps) => {
  const [
    { pageNumber, rowsPerPage },
    setState
  ] = useState<ShipmentTabContainerState>(initialState);

  const { entries, totalItems, isLoading, error } = useSelector(
    (state: RootStateType) => {
      return {
        entries: state.shipmentTabReducer.list.entries?.map((e) => e.entry),
        error: state.shipmentTabReducer.error,
        isLoading: state.shipmentTabReducer.isLoading,
        totalItems: state.shipmentTabReducer.list.pagination?.totalItems || 0
      };
    }
  );

  const dispatch = useDispatch();
  const loadData = useCallback(
    () =>
      dispatch(
        nodeShipmentAction.request({
          nodeId,
          where: `(nodeType='${SpisumNodeTypes.Shipment} INCLUDESUBTYPES')`
        })
      ),
    [nodeId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  channel.refreshData = loadData;

  useEffect(() => {
    if (isActive && entries === undefined) {
      loadData();
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isActive) {
      loadData();
    }
  }, [pageNumber, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const onExport = useCallback((params) => {
    getDownloadDelivery(params)
      .then(({ response, headers }) => {
        const disposition = contentDisposition.parse(
          headers?.get("content-disposition") || ""
        );
        fileDownload(response as Blob, disposition.parameters.filename);
        Notification.success({
          message: t(
            translationPath(lang.dialog.notifications.dataBoxDelivered)
          )
        });
      })
      .catch((error: ErrorType) => {
        return Notification.error({
          message: t(
            translationPath(
              lang.dialog.notifications[
                error.code === ErrorCodeList.DataBoxNotFound
                  ? "dataBoxNotDelivered"
                  : "actionFailed"
              ]
            )
          )
        });
      });
  }, []);

  const handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void = (_, page) => {
    setState((state) => ({
      pageNumber: page,
      rowsPerPage: state.rowsPerPage
    }));
  };

  const handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event) => {
    setState(() => ({
      pageNumber: 0,
      rowsPerPage: parseInt(event.target.value, 10)
    }));
  };

  const handleShowMarkEmailButton = (selected: ShipmentDocument) =>
    selected.nodeType === SpisumNodeTypes.ShipmentDatabox;

  const controls: ControlsBarType<ShipmentDocument> = {
    default: {
      items: dialogProps.hideManageShipmentsIcon
        ? undefined
        : [
            {
              action: (selected: ShipmentDocument[]) => {
                dispatch(
                  dialogOpenAction({
                    dialogProps: {
                      data: {
                        id: nodeId,
                        ...(isGenericDocument(dialogProps.data)
                          ? { nodeType: dialogProps.data.nodeType }
                          : {})
                      },

                      onClose: () => loadData()
                    },
                    dialogType: DialogType.SendShipment
                  })
                );
              },
              icon: <Mail />,
              title: t(translationPath(lang.general.manageShipments))
            }
          ]
    },
    single: {
      items: [
        {
          action: (selected: ShipmentDocument[]) => {
            dispatch(
              shipmentDetailDialogOpen({
                documentId: nodeId,
                onClose: loadData,
                selected: selected[0],
                readonly:
                  selected[0].properties?.cm?.lockType === "READ_ONLY_LOCK"
              })
            );
          },

          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },

        {
          action(selected: ShipmentDocument[]) {
            onExport({
              nodeId: selected[0].id,
              dataBoxId: selected[0].properties?.ssl?.shipmentSender,
              dataBoxMessageId: selected[0].properties?.ssl?.itemId
            });
          },
          filter: handleShowMarkEmailButton,
          icon: <MarkEmailRead />,
          title: t(translationPath(lang.general.downloadDeliveryShipment)),
          validation: (selected: ShipmentDocument[]) =>
            selected[0]?.properties?.ssl?.internalState ===
            DATABOX_INTERNAL_STATE_NOT_DELIVERED
              ? t(
                  translationPath(lang.dialog.notifications.dataBoxNotDelivered)
                )
              : ""
        }
      ]
    }
  };

  if (error) {
    return <div />;
  }

  return (
    <ShipmentTab
      controls={controls}
      items={entries || []}
      totalItems={totalItems}
      pageNumber={pageNumber}
      refreshTable={loadData}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isLoading={isLoading}
    />
  );
};

export default ShipmentTabContainer;
