import Snackbar from "@material-ui/core/Snackbar";
import styled from "presentation/styles";

export const StyledSnackbarLayout = styled(Snackbar)(() => ({
  bottom: 0,
  cursor: "default",
  display: "block",
  marginBottom: 30,
  marginRight: 50,
  maxWidth: 400,
  position: "relative"
}));
