import manual from "assets/icons/manual.svg";
import support from "assets/icons/support.svg";
import youtube from "assets/icons/youtube.svg";
import gavel from "assets/icons/gavel.svg";
import styled from "presentation/styles";

const filter =
  "invert(58%) sepia(55%) saturate(2079%) hue-rotate(360deg) brightness(103%) contrast(105%)";
const iconBase = {
  "&:hover": {
    filter
  },
  backgroundSize: "cover",
  cursor: "pointer",
  height: "100px",
  margin: "10px 15px",
  width: "100px"
};

export const StyledIconBox = styled("div")<{}>(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  fontSize: "1.125rem",
  fontWeight: "bold",
  justifyContent: "center"
}));

export const StyledManual = styled("div")<{}>(() => ({
  background: `url(${manual}) center center no-repeat`,
  ...iconBase
}));

export const StyledSupport = styled("div")<{}>(() => ({
  background: `url(${support}) center center no-repeat`,
  ...iconBase
}));

export const StyledLicense = styled("div")<{}>(() => ({
  background: `url(${gavel}) center center no-repeat`,
  ...iconBase
}));

export const StyledYoutube = styled("div")<{}>(() => ({
  background: `url(${youtube}) center center no-repeat`,
  ...iconBase
}));

export const StyledTextBox = styled("div")<{}>(({ theme }) => ({
  "& > p": {
    color: theme.colors.black,
    margin: "0",
    textAlign: "center"
  },
  "& > p.colored": {
    color: theme.colors.orange,
    fontWeight: "bold"
  },
  color: theme.colors.white,
  fontSize: "2.75rem",
  fontWeight: "normal",
  zIndex: 2
}));

export const StyledDivider = styled("div")<{}>(({ theme }) => ({
  background: "rgba(0, 0, 0, 0.5)",
  height: "1px",
  margin: "50px 0 40px 0",
  width: "100%"
}));

export const StyledBox = styled("div")<{}>(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
}));

export const StyledWrapper = styled("div")<{}>(() => ({
  margin: "80px auto",
  maxWidth: "800px",
  padding: "0 20px"
}));
