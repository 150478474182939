import styled from "@emotion/styled";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import clsx from "clsx";
import { useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useCallback, useMemo, useState } from "react";
import { Address } from "../../../../domain/struct/nameRegistry/Address";
import { Contact } from "../../../../domain/struct/nameRegistry/Contact";
import { ContactType } from "../../../../domain/struct/nameRegistry/ContactType";
import { LegalEntity } from "../../../../domain/struct/nameRegistry/LegalEntity";
import { Subject } from "../../../../domain/struct/nameRegistry/Subject";
import { SubjectType } from "../../../../domain/struct/nameRegistry/SubjectType";
import { useModal } from "../../../designSystem/Modal/useModal";
import { DeliveryMode, SenderType } from "../../../enums";
import {
  SearchSubjectModal,
  SearchSubjectResult
} from "../../../modules/nameRegister/modal/SearchSubjectModal/SearchSubjectModal";
import { AddressFormatHelpers } from "../../../modules/nameRegister/service/AddressFormatHelpers";
import { ContactListHelpers } from "../../../modules/nameRegister/service/ContactListHelpers";
import { SslProperties, SslProperty } from "../../api/models";
import { getService } from "../../features/dependencyInjection";
import { sslPropsProxy } from "../../types";
import { StyledFieldWide, useStyles } from "../dialog/Dialog.styles";
import { BaseField } from "../form/fields/BaseField";
import { useIsOwnFromProperties } from "./useIsDocumentOwn";
import { RadioTypes } from "./_types";
import { Notification } from "../../../designSystem/notification/Notification";
import { useFindCountries } from "../../../modules/nameRegister/hooks/useFindCountries";
import { CountryType } from "../../../../domain/struct/codelist/CountryType";
import { callAsyncAction } from "presentation/core/action";
import { documentUpdateActionType } from "presentation/core/api/document/_actions";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { useMetaFormDocument } from "../dialog/hooks/useMetaFormDocument";
import { useDispatch } from "react-redux";
import { useRemoteTableApiContext } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import { stringOrDefault } from "lib/string";

const editableFields: SslProperty[] = [
  "senderCompanyEmployeeJob",
  "senderFullName",
  "senderCompanyEmployeeUnit"
];

interface RadioWrapperProps {
  readonly?: boolean;
  disabledFields?: string[];
  initialValues: SslProperties;
  isFileDialog?: boolean;
  mode?: DeliveryMode;
  hidden?: {
    individual?: boolean;
    legal?: boolean;
    own?: boolean;
  };
}

interface FormFieldsProps {
  values: SslProperties;
}

export const SenderForm = ({
  isFileDialog,
  readonly,
  disabledFields,
  initialValues,
  hidden,
  mode
}: RadioWrapperProps) => {
  const addressFormatHelpers = getService(AddressFormatHelpers);
  const contactListHelpers = getService(ContactListHelpers);

  const { documentId } = useMetaFormDocument();
  const dispatch = useDispatch();

  const { data: countryCodesAndNames } = useFindCountries({ retry: false });
  const classes = useStyles();
  const isOwn = useIsOwnFromProperties(initialValues);

  const [senderState, setSenderState] = useState<string | undefined>(
    isOwn ? SenderType.Own : initialValues?.senderType
  );

  const [initSenderType] = useState<string | undefined>(
    initialValues?.senderType
  );
  const {
    setFieldValue,
    setValues,
    values
  } = useFormikContext<SslProperties>();

  const { refreshData: refreshConcernedSubjects } = useRemoteTableApiContext();

  const onSearchSubjectModalOK = useCallback(
    (response?: SearchSubjectResult) => {
      if (!response) {
        return;
      }

      const { subject, addresses, contacts } = response;

      if (mode === DeliveryMode.Email || mode === DeliveryMode.Databox) {
        const contactMatch = contacts?.find(
          (contact) => contact.contact === initialValues.sender
        );
        if (!contactMatch) {
          Notification.error({
            message:
              mode === DeliveryMode.Email
                ? t(translationPath(lang.general.emailNotMatch))
                : t(translationPath(lang.general.dataBoxNotMatch))
          });
          return Promise.reject();
        }
      }

      setSenderState(subject.subjectType);

      const mappedSubject = mapSender(subject);
      const mappedAddresses = addresses
        ? mapAddresses(addressFormatHelpers, addresses, countryCodesAndNames)
        : {};
      const mappedContacts = contacts
        ? mapContacts(contactListHelpers, contacts)
        : {};
      const newValues = {
        ...values,
        ...mappedSubject,
        ...mappedAddresses,
        ...mappedContacts
      };

      setValues(newValues);

      const onSuccess = () => {
        refreshConcernedSubjects();
      };
      const onError = (error: any) => {
        Notification.error({
          message: getErrorCodeTranslation(error.code)
        });
      };

      dispatch(
        callAsyncAction({
          action: documentUpdateActionType,
          onSuccess,
          onError: onError,
          payload: {
            body: {
              properties: newValues
            },
            nodeId: documentId
          }
        })
      );

      return Promise.resolve();
    },
    [
      dispatch,
      addressFormatHelpers,
      contactListHelpers,
      countryCodesAndNames,
      documentId,
      initialValues.sender,
      mode,
      refreshConcernedSubjects,
      setValues,
      values
    ]
  );

  const [searchSubjectModal, searchSubjectModalApi] = useModal(
    SearchSubjectModal,
    {
      onOk: onSearchSubjectModalOK
    }
  );

  const onRadioFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFieldValue(lastPathMember(sslPropsProxy.senderType).path, value);
    setSenderState(value);
    editableFields.forEach((field) => {
      const fieldValue =
        initSenderType === value || disabledFields?.includes(field)
          ? initialValues[field]
          : "";
      setFieldValue(field, fieldValue);
    });
  };

  const openDialog = useCallback(() => searchSubjectModalApi.open({}), [
    searchSubjectModalApi
  ]);

  const isVisible = useMemo(() => determineVisibleFields(isOwn, hidden), [
    hidden,
    isOwn
  ]);

  return (
    <StyledDiv>
      {searchSubjectModal}
      <StyledBaseField
        className={clsx(
          classes.fullWidth,
          classes.mtGap,
          classes.flexDirectionRow
        )}
        component={RadioGroup}
        name="senderTypeRadio"
        value={senderState}
        onChange={onRadioFieldChange}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 style={{ marginRight: 30 }}>
            {t(translationPath(lang.general.sender))}
          </h3>
          {isVisible.individual && (
            <FormControlLabel
              control={<Radio />}
              disabled={true}
              label={t(translationPath(lang.general.senderIndividualPerson))}
              value={SubjectType.Person}
            />
          )}
          {isVisible.legal && (
            <FormControlLabel
              control={<Radio />}
              disabled={true}
              label={t(translationPath(lang.general.senderLegalPerson))}
              value={SubjectType.LegalEntity}
            />
          )}
          {isVisible.own && (
            <FormControlLabel
              control={<Radio />}
              disabled={true}
              label={t(translationPath(lang.general.senderOwn))}
              value={RadioTypes.own}
            />
          )}
        </div>
        {!isOwn && !isFileDialog && !initialValues.lockType && (
          <CreateIcon
            style={{
              margin: "9px",
              right: 0,
              top: ".75rem",
              cursor: "pointer"
            }}
            onClick={openDialog}
          />
        )}
      </StyledBaseField>
      {!values.senderId && <NoSenderSelectedFields values={values} />}
      {senderState === SubjectType.Person ? (
        <IndividualSenderForm values={values} />
      ) : senderState === SubjectType.LegalEntity ? (
        <LegalSenderForm values={values} isReadonly={!!readonly} />
      ) : null}
    </StyledDiv>
  );
};

const NoSenderSelectedFields = ({ values }: FormFieldsProps) => (
  <>
    {values.senderContactEmail && (
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-senderContactEmail"
        name={lastPathMember(sslPropsProxy.senderContactEmail).path}
        type="text"
        label="E-Mail"
        disabled
      />
    )}
    {values.senderContactDatabox && (
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-senderContactDatabox"
        name={lastPathMember(sslPropsProxy.senderContactDatabox).path}
        type="text"
        label="ID datové schránky"
        disabled
      />
    )}
  </>
);

const CommonFields = ({ values }: FormFieldsProps) => {
  return (
    <>
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-senderId"
        name={lastPathMember(sslPropsProxy.senderId).path}
        type="text"
        label=""
        disabled
        style={{ display: "none" }}
      />
      {values.senderContactEmail && (
        <StyledFieldWide
          component={TextField}
          data-test-id="meta-input-senderContactEmail"
          name={lastPathMember(sslPropsProxy.senderContactEmail).path}
          type="text"
          label="E-Mail"
          disabled
        />
      )}
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-senderContactEmailId"
        name={lastPathMember(sslPropsProxy.senderContactEmailId).path}
        type="text"
        label="E-Mail"
        disabled
        style={{ display: "none" }}
      />
      {values.senderContactDatabox && (
        <StyledFieldWide
          component={TextField}
          data-test-id="meta-input-senderContactDatabox"
          name={lastPathMember(sslPropsProxy.senderContactDatabox).path}
          type="text"
          label="ID datové schránky"
          disabled
        />
      )}
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-senderContactDataboxId"
        name={lastPathMember(sslPropsProxy.senderContactDataboxId).path}
        type="text"
        label="Databox"
        disabled
        style={{ display: "none" }}
      />
      {values.senderContactPhone && (
        <StyledFieldWide
          component={TextField}
          data-test-id="meta-input-org-senderContactPhone"
          name={lastPathMember(sslPropsProxy.senderContactPhone).path}
          type="text"
          label="Telefonní číslo"
          disabled
        />
      )}
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-senderContactPhoneId"
        name={lastPathMember(sslPropsProxy.senderContactPhoneId).path}
        type="text"
        label="Phone"
        disabled
        style={{ display: "none" }}
      />
    </>
  );
};

export const IndividualSenderForm = ({ values }: FormFieldsProps) => {
  return (
    <>
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-senderFullName"
        name={lastPathMember(sslPropsProxy.senderFullName).path}
        type="text"
        label={t(translationPath(lang.general.nameSurname))}
        disabled
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-senderAddress"
        name={lastPathMember(sslPropsProxy.senderAddress).path}
        type="text"
        label={t(translationPath(lang.general.address))}
        disabled
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-senderAddressId"
        name={lastPathMember(sslPropsProxy.senderAddressId).path}
        type="text"
        label=""
        disabled
        style={{ display: "none" }}
      />
      <CommonFields values={values} />
    </>
  );
};

export const LegalSenderForm = ({
  values,
  isReadonly
}: FormFieldsProps & { isReadonly: boolean }) => {
  return (
    <>
      <CommonFields values={values} />
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-org-name"
        name={lastPathMember(sslPropsProxy.senderCompanyName).path}
        type="text"
        label={t(translationPath(lang.general.orgName))}
        disabled
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-sender-address"
        name={lastPathMember(sslPropsProxy.senderAddressId).path}
        type="text"
        label={t(translationPath(lang.general.orgAddress))}
        disabled
        style={{ display: "none" }}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-sender-address"
        name={lastPathMember(sslPropsProxy.senderAddress).path}
        type="text"
        label={t(translationPath(lang.general.orgAddress))}
        disabled
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-sender-sender-org-unit"
        name={lastPathMember(sslPropsProxy.senderCompanyEmployeeUnit).path}
        type="text"
        label={t(translationPath(lang.general.orgUnit))}
        disabled={isReadonly}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-sender-name"
        name={lastPathMember(sslPropsProxy.senderFullName).path}
        type="text"
        label={t(translationPath(lang.general.nameSurname))}
        disabled={isReadonly}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-sender-job"
        name={lastPathMember(sslPropsProxy.senderCompanyEmployeeJob).path}
        type="text"
        label={t(translationPath(lang.general.senderJob))}
        disabled={isReadonly}
      />
    </>
  );
};

const allSenderTypeFieldsVisible = {
  individual: true,
  legal: true,
  own: true
};

const determineVisibleFields = (
  isOwn: boolean,
  hidden: RadioWrapperProps["hidden"]
) =>
  isOwn
    ? allSenderTypeFieldsVisible
    : {
        individual: !hidden?.individual,
        legal: !hidden?.legal,
        own: !hidden?.own
      };

const mapSender = (subject: Subject): SslProperties => ({
  senderFullName: subject.fullName,
  senderCompanyName: (subject as LegalEntity).companyFullName,
  senderId: subject.id
});

const mapContacts = (
  contactListHelpers: ContactListHelpers,
  contactItems: Contact[]
): SslProperties => {
  if (!(contactItems && contactItems.length)) {
    return {};
  }

  const emailContact = contactListHelpers.findContactByType(
    contactItems,
    ContactType.EmailAddress
  );
  const dataBoxContact = contactListHelpers.findContactByType(
    contactItems,
    ContactType.DataBoxId
  );
  const phoneContact = contactListHelpers.findContactByType(
    contactItems,
    ContactType.PhoneNumber
  );

  return {
    senderContactEmail: emailContact?.contact,
    senderContactEmailId: stringOrDefault(emailContact?.id),
    senderContactDatabox: dataBoxContact?.contact,
    senderContactDataboxId: stringOrDefault(dataBoxContact?.id),
    senderContactPhone: phoneContact?.contact,
    senderContactPhoneId: stringOrDefault(phoneContact?.id)
  };
};

const mapAddresses = (
  addressFormatHelpers: AddressFormatHelpers,
  addressItems: Address[],
  countryCodesAndNames: CountryType[] | undefined
): SslProperties => {
  if (!(addressItems && addressItems.length)) {
    return {};
  }

  const [address] = addressItems;

  return {
    senderAddressId: String(address.id!),
    senderAddress: addressFormatHelpers.formatAddress(
      address,
      countryCodesAndNames
    )
  };
};

const StyledBaseField = styled(BaseField)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    margin-bottom: 0;
  }
`;
const StyledDiv = styled.div`
  flex-direction: column;
  width: 100%;
`;
