import React, { useState } from "react";
import {
  BaseModalProps,
  useModal
} from "@isfg/react-components/build/Modal/useModal";
import { Button } from "presentation/designSystem/Button/Button";
import { Tabs } from "presentation/designSystem/Tabs/Tabs";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { AnalogDocument, UpdateAnalogFormFieldTypes } from "../types/types";
import { AnalogDetailAntForm } from "../form/AnalogDetailAntForm";
import { useForm } from "@isfg/react-components/build/Form/Form";
import { useDispatch, useSelector } from "react-redux";
import { RemoteTableApiContextProvider } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import CommentsTabContainer from "presentation/core/components/dialog/tabs/comments";
import { SpisumNodeTypes } from "presentation/enums";
import { RootStateType } from "presentation/reducers";
import { useUpdateDocument } from "../hooks/useUpdateDocument";
import UnsavedChangesModal from "../../digitalDocument/dataBox/unprocessed/modals/UnsavedChangesModal";
import { handoverDocument } from "presentation/share/components/dialog/documentHandoverDialog/_actions";
import { DialogDataType } from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import { ModalWithRefresh } from "presentation/designSystem/Modal/ModalWithRefresh";
import { SearchSubjectResult } from "presentation/modules/nameRegister/modal/SearchSubjectModal/SearchSubjectModal";
import { useDocumentConcernedSubjectTable } from "presentation/share/tables/document/ConcernedSubjectTable/useDocumentConcernedSubjectTable";

interface CreateAnalogModalProps {
  onCancel?: BaseModalProps["onCancel"];
  onOk?: BaseModalProps["onOk"];
  document: AnalogDocument;
}

enum TabKeys {
  Metadata = "0",
  ConcernedSubjects = "1",
  Comments = "2"
}

export const AnalogDetailAntModal = (props: CreateAnalogModalProps) => {
  return (
    <RemoteTableApiContextProvider>
      <AnalogDetailAntModalInner {...props} />
    </RemoteTableApiContextProvider>
  );
};

export const AnalogDetailAntModalInner = ({
  onCancel,
  document
}: CreateAnalogModalProps) => {
  const [activeTab, setActiveTab] = useState("0");
  const [form] = useForm<UpdateAnalogFormFieldTypes>();
  let [isAnalogRegistered, setIsAnalogRegistered] = useState<boolean>(false);

  const formData = useSelector(
    (state: RootStateType) => state.metaFormReducer.actual.formValues
  );

  const dispatch = useDispatch();

  const changeActiveTab = (activeTabIndex: string) => {
    setActiveTab(activeTabIndex);
  };

  const {
    updateAndRegister: updateAndRegisterDocument,
    updateAndRegisterLoading,
    update: updateDocument
  } = useUpdateDocument();

  const [unsavedChangesModal, unsavedChangesModalApi] = useModal(
    UnsavedChangesModal
  );

  const registerAnalogDocumentAndHandOver = async () => {
    try {
      if (isAnalogRegistered) {
        await modalForHandover(document.nodeId!, form.getFieldsValue());
        return;
      }
      await form.validateFields();
      updateAndRegisterDocument(
        {
          id: document.nodeId!,
          input: form.getFieldsValue()
        },
        {
          onSuccess: () => {
            setIsAnalogRegistered(true);
            modalForHandover(document.nodeId!, form.getFieldsValue());
          }
        }
      );
    } catch {}
  };

  const modalForHandover = async (nodeId: string, formData: any) => {
    dispatch(
      handoverDocument({
        data: { id: nodeId, ...(formData as DialogDataType) },
        onClose: () => {},
        onSuccess() {
          onCancel?.();
        }
      })
    );
  };

  const closeAnalogModal = async () => {
    dispatch(documentViewAction__Refresh(true));
    onCancel?.();
  };

  const registerAnalogDocument = async () => {
    if (isAnalogRegistered) {
      await closeAnalogModal();
      return;
    }
    await form.validateFields();
    updateAndRegisterDocument(
      {
        id: document.nodeId!,
        input: form.getFieldsValue()
      },
      {
        onSuccess: () => {
          setIsAnalogRegistered(true);
          closeAnalogModal();
        }
      }
    );
  };

  const saveChanges = async () =>
    updateDocument(
      {
        id: document.nodeId!,
        input: form.getFieldsValue(true)
      },
      {
        onSuccess: () => {
          dispatch(documentViewAction__Refresh(true));
          onCancel?.();
        }
      }
    );

  const handleCancel = () => {
    form.isFieldsTouched()
      ? unsavedChangesModalApi.open({
          saveChanges,
          closeModal: onCancel
        })
      : onCancel?.();
  };

  const [
    documentConcernedSubjectTable,
    { setSenderAsConcernedSubject, refreshDocConcernedSubjs }
  ] = useDocumentConcernedSubjectTable({
    documentId: document.nodeId!,
    readonly: false
  });

  const onSenderChange = (sender: SearchSubjectResult) => {
    setSenderAsConcernedSubject(sender.subject);
  };

  const tabsWithRefresh = {
    [TabKeys.ConcernedSubjects]: refreshDocConcernedSubjs
  };

  return (
    <>
      {unsavedChangesModal}
      <ModalWithRefresh
        visible={true}
        hasTabs
        title={t(translationPath(lang.dialog.title.analogDocumentIncome))}
        onCancel={handleCancel}
        onRefresh={tabsWithRefresh[activeTab]}
        confirmLoading={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t(translationPath(lang.modal.cancel))}
          </Button>,
          <Button
            key="registerAndHandover"
            type="primary"
            loading={false}
            onClick={registerAnalogDocumentAndHandOver}
          >
            {t(translationPath(lang.dialog.form.toRegisterAndRefer))}
          </Button>,
          <Button
            key="register"
            loading={updateAndRegisterLoading}
            onClick={registerAnalogDocument}
          >
            {t(translationPath(lang.dialog.form.toRegister))}
          </Button>
        ]}
      >
        <Tabs onChange={changeActiveTab}>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.subjectDialog.tabs.metadata))}
            key={TabKeys.Metadata}
          >
            <AnalogDetailAntForm
              formData={document}
              form={form}
              onSenderChange={onSenderChange}
            />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.concernedSubjects))}
            key={TabKeys.ConcernedSubjects}
          >
            <div className="body-fullsize">{documentConcernedSubjectTable}</div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.notes))}
            key={TabKeys.Comments}
          >
            <CommentsTabContainer
              nodeId={document.nodeId!}
              isActive={activeTab === TabKeys.Comments}
              dialogProps={{ data: { nodeType: SpisumNodeTypes.Document } }}
            />
          </Tabs.TabPane>
        </Tabs>
      </ModalWithRefresh>
    </>
  );
};
