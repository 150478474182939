import { Check, Close, Description, Refresh } from "@material-ui/icons";
import { callAsyncAction } from "presentation/core/action";
import { AnimatedRefreshRotateStyled } from "presentation/core/components/dataTable/DataTable.styles";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import DocumentView from "presentation/core/components/documentView";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { Notification } from "presentation/designSystem/notification/Notification";
import { DataboxDocument, dataBoxDocumentProxy } from "presentation/core/types";
import { SitePaths, SpisumNodeTypes } from "presentation/enums";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { RefreshStatusPayload } from "presentation/modules/mailroom/features/income/types";
import {
  documentDataBoxRefreshAction,
  documentDataBoxRefreshStatusAction,
  documentRefreshAction
} from "presentation/modules/mailroom/features/income/_actions";
import { useCreateDataboxRegistration } from "./hooks/useCreateDataboxRegistration";
import { metaFormAction__Update } from "presentation/core/components/MetaForm/_actions";
import Loading from "presentation/core/components/dataTable/components/Loading";
import { DataboxDetailsModal } from "./modals/DataboxDetailsModal";
import { useModal } from "presentation/designSystem/Modal/useModal";

const defaultColumn: DataColumn<DataboxDocument> = {
  isDateTime: true,
  keys: [
    classPath(dataBoxDocumentProxy.properties!.ssl!.digitalDeliveryDeliveryDate)
      .path
  ],
  label: t(translationPath(lang.general.delivery))
};

const IncomeDigitalDocumentDataBoxUnprocessed = () => {
  const dispatch = useDispatch();
  const [refreshPending, setRefreshPending] = useState<boolean>(false);

  const selectedRows = useSelector(
    (state: RootStateType) => state.documentViewReducer.selected
  );
  const databoxAccounts = useSelector(
    (state: RootStateType) => state.databoxReducer.databoxAccounts
  );

  const columns: DataColumn<DataboxDocument>[] = [
    {
      keys: [classPath(dataBoxDocumentProxy.properties!.ssl!.pid).path],
      label: t(translationPath(lang.general.identifier))
    },
    {
      keys: [
        classPath(dataBoxDocumentProxy.properties!.ssl!.digitalDeliverySubject)
          .path
      ],
      label: t(translationPath(lang.general.subject))
    },
    {
      keys: [
        classPath(dataBoxDocumentProxy.properties!.ssl!.databoxSenderName).path
      ],
      label: t(translationPath(lang.general.sender))
    },
    defaultColumn,
    {
      keys: [
        classPath(
          dataBoxDocumentProxy.properties!.ssl!.digitalDeliveryAttachmentsCount
        ).path
      ],
      label: t(translationPath(lang.general.attachmentsCount))
    }
  ];

  if (databoxAccounts?.length > 1) {
    columns.push({
      getValue: (x) => {
        return (
          databoxAccounts.find(
            (y) => y.username === x.properties?.ssl?.databoxRecipientUid
          )?.name || x.properties?.ssl?.databoxRecipientUid
        );
      },
      keys: [
        classPath(dataBoxDocumentProxy.properties!.ssl!.databoxRecipientUid)
          .path
      ],
      label: t(translationPath(lang.general.recipient))
    });
  }

  const onSuccess = (payload: RefreshStatusPayload) => {
    setRefreshPending(false);
    Notification.success({
      message: t(translationPath(lang.dialog.notifications.refreshSucceeded), {
        count: payload.newMessageCount
      })
    });

    dispatch(documentViewAction__Refresh(true));
  };

  const onError = () => {
    setRefreshPending(false);
  };

  const triggerFetchDataboxMessages = () => {
    if (refreshPending) {
      return;
    }

    setRefreshPending(true);

    dispatch(
      callAsyncAction({
        action: documentRefreshAction,
        onError,
        onSuccess,
        onSuccessNotification: null,
        payload: {
          refreshAction: documentDataBoxRefreshAction,
          statusAction: documentDataBoxRefreshStatusAction
        }
      })
    );
  };

  const {
    modal: registerModal,
    register,
    isLoading: isRegisterLoading
  } = useCreateDataboxRegistration();

  const [databoxDetailsModal, databoxDetailsModalApi] = useModal(
    DataboxDetailsModal,
    {}
  );

  const onDoubleClick: (row: DataboxDocument) => void = (row) => {
    databoxDetailsModalApi.open({
      data: row,
      register
    });
  };

  const controls: ControlsBarType<DataboxDocument> = {
    default: {
      items: [
        {
          action: triggerFetchDataboxMessages,
          icon: () =>
            refreshPending ? <AnimatedRefreshRotateStyled /> : <Refresh />,
          title: t(translationPath(lang.general.refreshMessages))
        }
      ]
    },
    single: {
      items: [
        {
          action: (selected: DataboxDocument[]) => {
            databoxDetailsModalApi.open({
              data: selected[0],
              register
            });
          },
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: DataboxDocument[]) => {
            register(selected[0].id);
            dispatch(
              metaFormAction__Update({
                formValues: selected[0]?.properties?.ssl
              })
            );
          },
          icon: <Check />,
          title: t(translationPath(lang.general.register))
        },
        {
          action: (selected: DataboxDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.DontRegisterDocument
              })
            );
          },
          icon: <Close />,
          title: t(translationPath(lang.general.notRegister))
        }
      ]
    }
  };

  const paths = useSelector(
    (state: RootStateType) => state.loginReducer.global.paths
  );

  const relativePath = getRelativePath(
    paths,
    null,
    SitePaths.Mailroom,
    SitePaths.DataBox,
    SitePaths.Unprocessed
  );

  return (
    <>
      {registerModal}
      {databoxDetailsModal}
      {isRegisterLoading && <Loading />}
      <MenuLayout>
        <DocumentView
          children={{
            includeSource: true,
            relativePath,
            where: `(nodeType='${SpisumNodeTypes.Databox}')`
          }}
          columns={columns}
          controls={controls}
          customTitle={t(translationPath(lang.table.unprocessedDataboxes))}
          defaultSortAsc={true}
          defaultSortColumn={defaultColumn}
          handleDoubleClick={onDoubleClick}
          refreshPending={refreshPending}
        />
      </MenuLayout>
    </>
  );
};

IncomeDigitalDocumentDataBoxUnprocessed.displayName = "unprocessed";

export default withTranslation()(IncomeDigitalDocumentDataBoxUnprocessed);
