import { callAsyncAction } from "presentation/core/action";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import { ComponentsShipmentTab } from "presentation/core/components/dialog/tabs/shipmentComponents";
import {
  DialogContentPropsType,
  DialogContentType,
  DialogDataGenericData,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import { SpisumNodeTypes } from "presentation/enums";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React from "react";
import { createDocumentDialog } from "../baseDocumentDialog/documentDialogFactory";
import {
  shipmentDetailBodyList,
  shipmentDetailSaveActionList
} from "./mappers";
import MetaDataFormTab from "./MetadataFormTab";
import { ShipmentDocument } from "../../../../core/types";
import { HistoryTab } from "../../../../core/components/dialog/tabs/history";

const componentList = {
  [SpisumNodeTypes.ShipmentDatabox]: true,
  [SpisumNodeTypes.ShipmentEmail]: true,
  [SpisumNodeTypes.ShipmentPost]: false,
  [SpisumNodeTypes.ShipmentPersonally]: false,
  [SpisumNodeTypes.ShipmentPublish]: true
};
const componentFilter = ({ dialogProps }: DialogContentPropsType) => {
  return componentList[(dialogProps.data as DialogDataGenericData).nodeType!];
};

export const openShipmentDetailDialogContent: DialogContentType = createDocumentDialog(
  {
    actions: () => [
      secondaryAction(
        t(translationPath(lang.dialog.form.save)),
        ({ dispatch, channels, onClose, buttonState, dialogProps }) => {
          const formValues = channels?.Metadata?.state?.formValues;

          const preventAction = channels?.Komponenty?.state?.preventAction;
          if (!formValues || preventAction) {
            return;
          }

          const { nodeType, properties } = dialogProps.data as ShipmentDocument;

          if (
            !shipmentDetailSaveActionList.hasOwnProperty(nodeType!) ||
            !shipmentDetailBodyList.hasOwnProperty(nodeType!)
          ) {
            return;
          }

          // When we open the shipment dialog and click the save button, the "channels?.Komponenty?.state?.selectedComponentsIds"
          // would be empty, because we did not click on the Components tab and the components were not saved into its channel state.
          // In that case return original components, coming from the shipment component data
          const componentIdList =
            channels?.Komponenty?.state?.selectedComponentsIds ||
            properties?.ssl?.components;

          const action = shipmentDetailSaveActionList[nodeType!];
          const bodyMapper = shipmentDetailBodyList[nodeType!];
          const body = bodyMapper(formValues, componentIdList);
          const onSuccess = () => {
            // set all tabs to saved to close modal
            for (const key in channels) {
              if (!channels.hasOwnProperty(key)) continue;
              channels[key].setIsSaved(true);
            }
            onClose();
          };

          const onError = () => {
            buttonState.setIsPending(false);
            channels?.Metadata?.setIsSaved(false);
          };

          buttonState.setIsPending(true);

          dispatch(
            callAsyncAction({
              action,
              onError,
              onSuccess,
              payload: {
                body,
                nodeId: channels?.Metadata?.state?.id as string
              }
            })
          );
        }
      )
    ],
    tabs: [
      {
        content: MetaDataFormTab,
        label: t(translationPath(lang.dialog.tabs.metadata))
      },
      {
        content: ComponentsShipmentTab,
        filter: componentFilter,
        label: t(translationPath(lang.dialog.tabs.components))
      },
      {
        content: HistoryTab,
        label: t(translationPath(lang.dialog.tabs.history))
      }
    ],
    title: (props) => (
      <NamedTitle
        text={t(translationPath(lang.dialog.title.shipmentDetail))}
        {...props}
      />
    ),
    type: DialogType.OpenShipmentDetail
  }
);
