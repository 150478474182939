import { Notification } from "@isfg/react-components/build/notification/Notification";
import { useDispatch } from "react-redux";
import { useDocumentVisibility } from "ahooks";
import React, { useCallback, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { Modal, ModalSize } from "../../../../../../designSystem/Modal/Modal";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getComponentsSignStatus } from "../../api";
import Timeout = NodeJS.Timeout;

export interface ForSignatureCancelProps extends BaseModalProps {
  nodeId: string;
  componentId: string;
}
const PERIOD = 3000;
const successStatuses = ["OK", "BatchResultDone"];

export const SendComponentsForSignatureModal = ({
  nodeId,
  componentId,
  onOk,
  onCancel
}: ForSignatureCancelProps) => {
  const intervalRef = useRef<Timeout | undefined>(undefined);
  const isPageVisible = useDocumentVisibility();
  const dispatch = useDispatch();

  const clearRequestInterval = useCallback(() => {
    if (!intervalRef.current) {
      return;
    }

    clearInterval(intervalRef.current);
  }, []);

  const handleSigningFinish = useCallback(
    (allSigned: boolean) => {
      clearRequestInterval();
      allSigned
        ? Notification.success({
            message: t(
              translationPath(lang.dialog.notifications.actionSucceeded)
            )
          })
        : Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });

      onCancel?.();
    },
    [clearRequestInterval, onCancel]
  );

  const fetchFilesStatuses = useCallback(async () => {
    if (!componentId) {
      return;
    }

    try {
      const componentsSignStatuses = await getComponentsSignStatus(componentId);

      if (!componentsSignStatuses || !componentsSignStatuses.length) {
        return;
      }

      const allSigned = successStatuses.includes(
        componentsSignStatuses[0].status
      );
      handleSigningFinish(allSigned);
    } catch (e) {
      handleSigningFinish(false);
    }
  }, [componentId, handleSigningFinish]);

  useEffect(() => {
    if (!isPageVisible) {
      clearRequestInterval();
      return;
    }

    intervalRef.current = setInterval(fetchFilesStatuses, PERIOD);

    return clearRequestInterval;
  }, [clearRequestInterval, fetchFilesStatuses, isPageVisible]);

  return (
    <Modal
      visible={true}
      title={t(translationPath(lang.dialog.componentForSignModal.title))}
      onCancel={onCancel}
      size={ModalSize.Small}
      cancelText={t(translationPath(lang.dialog.componentForSignModal.cancel))}
      okButtonProps={{ style: { display: "none" } }}
    >
      <StyledDiv>
        <p>{t(translationPath(lang.dialog.content.signingInProgress))}</p>
        <p>{t(translationPath(lang.dialog.content.signingInProgressHelp))}</p>
        <p>
          {t(translationPath(lang.dialog.content.signingInProgressHelpText))}
        </p>
      </StyledDiv>
      <LinearProgress />
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
`;
