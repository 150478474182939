import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { CancelProposalRepositoryToken } from "../../domain/di/token/recordRetention";
import { CancelProposalRepository } from "../../domain/service/recordRetention/CancelProposalRepository";

@DInjectable()
export class CancelProposal {
  constructor(
    @DInject(CancelProposalRepositoryToken)
    protected repository: CancelProposalRepository
  ) {}
  cancelProposal(nodeId?: string, reason?: string) {
    return this.repository.delete(nodeId, reason);
  }
  validateProposal(nodeId?: string) {
    return this.repository.validateRetentionProposal(nodeId);
  }
}
