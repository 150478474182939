import React, { useCallback, useEffect, useState } from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { Lock } from "@material-ui/icons";
import { Parts } from "../../types";
import { IconButton } from "presentation/designSystem/Button/IconButton";
import { useModal } from "presentation/designSystem/Modal/useModal";
import {
  LockPartTypeFile,
  LockPartTypeFileModalInterface
} from "../../modals/LockPartTypeFile";
import { useGetTypeFilePartValidate } from "../../hooks/useGetTypeFilePartValidate";

enum PartState {
  Open = "nevyrizen",
  Close = "uzavren"
}

interface UseClosePartOfTypeFileProps extends LockPartTypeFileModalInterface {
  part?: Parts;
  onCloseSuccess?(): void;
}

export const useClosePartOfTypeFile = ({
  nodeId,
  partId,
  part,
  onCloseSuccess
}: UseClosePartOfTypeFileProps) => {
  const { isValidatePartLoading, validatePart } = useGetTypeFilePartValidate();

  const validateClosePartButton = useCallback(() => {
    if (nodeId && partId) {
      validatePart(
        { nodeId, partId },
        {
          onSuccess: () => setIsPartValid(true),
          onError: () => setIsPartValid(false)
        }
      );
    } else {
      setIsPartValid(true);
    }
  }, [validatePart, nodeId, partId]);

  const [
    lockPartTypeFileModal,
    lockPartTypeFileModalApi
  ] = useModal<LockPartTypeFileModalInterface>(LockPartTypeFile, {
    onOk: onCloseSuccess
  });

  const handleRemovePart = useCallback(() => {
    lockPartTypeFileModalApi.open({ nodeId, partId: partId });
  }, [nodeId, partId, lockPartTypeFileModalApi]);

  const [isPartValid, setIsPartValid] = useState(true);

  const isPartClosed = part?.state === PartState.Close;
  const disabled = !part || isPartClosed || !isPartValid;

  const tooltip = isPartClosed
    ? t(translationPath(lang.tooltip.partClosed))
    : isPartValid
    ? t(translationPath(lang.tooltip.closeTypePartAction))
    : t(translationPath(lang.tooltip.partClosedDisabled));

  const closePartButton = (
    <IconButton
      data-test-id="close-part"
      icon={<Lock />}
      tooltip={tooltip}
      onClick={handleRemovePart}
      disabled={disabled}
      loading={isValidatePartLoading}
    />
  );

  useEffect(() => {
    validateClosePartButton();
  }, [validateClosePartButton]);

  return {
    lockPartTypeFileModal,
    closePartButton,
    validateClosePartButton
  };
};
