import { GenericDocument } from "presentation/core/types";
import { SpisumNodeTypes } from "presentation/enums";
import { openDocumentWithSaveButtonsAction } from "presentation/core/api/document/_actions";
import { openFileDetailsAction } from "presentation/core/components/dialog/tabs/tableOfContents/_actions";
import { dialogOpenConceptDetails } from "../../../components/dialog/conceptDetailsDialog/_actions";

const nodeTypeToActionMap = {
  [SpisumNodeTypes.Document]: openDocumentWithSaveButtonsAction,
  [SpisumNodeTypes.File]: openFileDetailsAction,
  [SpisumNodeTypes.Concept]: dialogOpenConceptDetails
};

export const getDetailActionForDocument = (document: GenericDocument) =>
  nodeTypeToActionMap[document.nodeType];

export const getDetailActionForDocumentNewDataModel = (
  nodeType: SpisumNodeTypes | string
) => nodeTypeToActionMap[nodeType];
