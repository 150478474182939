import styled from "@emotion/styled";

export const StyledUnsupportedFileWrapper = styled("div")<{}>(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  width: "100%",
  padding: "2rem"
}));
