import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { AddressRepositoryToken } from "../../../domain/di/token/nameRegistry";
import { PaginationConfig } from "../../../lib/contract/Pagination";
import { AddressRepository } from "../../../domain/service/nameRegistry/AddressRepository";

@DInjectable()
export class FindAddress {
  constructor(
    @DInject(AddressRepositoryToken) protected repository: AddressRepository
  ) {}

  findBySubjectId(subjectId: string, pagination: PaginationConfig) {
    return this.repository.findBySubjectId(subjectId, pagination);
  }
}
