import { Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import {
  StyledFieldWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { FormState } from "presentation/core/components/reactiveFormik/_types";
import { shipmentDocumentProxy } from "presentation/core/types";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t, WithTranslation } from "presentation/translation/i18n";
import React from "react";
import { withTranslation } from "react-i18next";
import { CreateShipmentFormValuesProxy } from "../../../createShipmentDialog/_types";
import { MetaFormProps } from "../../../_types";
import { ShipmentFormValues } from "../../_types";
import { BaseBottomForm, BaseUpperForm } from "../baseForm/ShipmentBaseForm";
import { validate } from "./_validations";

const Component = ({
  initialValues,
  formRef,
  readonly
}: MetaFormProps<ShipmentFormValues> & WithTranslation) => {
  const dialogClasses = useStyles();
  const handle = (): void => void 0;

  // const [searchResult, setSearchResult] = useState<SearchSubjectResult>();
  // const [modal, modalApi] = useModal(SearchSubjectModal, {
  //   onOk: (result?: SearchSubjectResult) => {
  //     setSearchResult(result);
  //   }
  // });

  // const onSearchSubject = () => {
  //   modalApi.open({});
  // };

  return (
    <>
      {/* {modal} */}
      <Formik<ShipmentFormValues>
        initialValues={initialValues}
        validate={validate}
        innerRef={formRef || handle}
        onSubmit={handle}
      >
        {({ setFieldValue }: FormState<ShipmentFormValues>) => {
          return (
            <Form className={dialogClasses.form}>
              {/* <MuiFormikShipmentHelper
                sendMode={SendModeValues.Email}
                searchSubjectResult={searchResult}
                setFieldValue={setFieldValue}
              /> */}
              <BaseUpperForm
                initialValues={initialValues}
                toDispatchDatePosition={"bottom"}
                onlyThreeColumns={true}
                readonly={readonly}
              />
              <BaseBottomForm />
              <div
                style={{ display: "flex", position: "relative", width: "100%" }}
              >
                <StyledFieldWide
                  component={TextField}
                  data-test-id="detail-shipment-email-recipient"
                  disabled={true}
                  name={
                    lastPathMember(
                      shipmentDocumentProxy.properties?.ssl
                        ?.recipientContactEmail
                    ).path
                  }
                  type="text"
                  label={t(translationPath(lang.general.emailAddressee))}
                />
                {/* <CreateIcon
                  style={{
                    margin: "9px",
                    position: "absolute",
                    right: 0,
                    top: "1.25rem",
                    cursor: "pointer"
                  }}
                  onClick={onSearchSubject}
                /> */}
              </div>
              <StyledFieldWide
                component={TextField}
                data-test-id="create-shipment-post-recipientContactEmailBoxId"
                disabled={readonly}
                required={false}
                name={
                  lastPathMember(
                    CreateShipmentFormValuesProxy.recipientContactEmailBoxId
                  ).path
                }
                type="text"
                style={{ display: "none" }}
              />
              <StyledFieldWide
                component={TextField}
                data-test-id="detail-shipment-email-sender"
                disabled={true}
                name={
                  lastPathMember(
                    shipmentDocumentProxy.properties?.ssl?.shipmentSender
                  ).path
                }
                type="text"
                label={t(translationPath(lang.general.emailOfTheSender))}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export const ShipmentEmailForm = withTranslation()(React.memo(Component));
