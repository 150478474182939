import { AnalogDocument } from "../../income/analog/types/types";
import { CreateDataboxRegistration } from "../../income/digitalDocument/dataBox/unprocessed/types";
import { TechnicalDataCarrierDocument } from "../../income/digitalDocument/technicalDataCarries/types/types";
import { CreateEmailRegistration } from "../../income/digitalDocument/emailBox/unprocessed/types";
import { DocumentGetUnfinishedResponse, ExcludeNull } from "../types";

export const mapNulltoUndefined = <T>(data: T) =>
  Object.keys(data).reduce((acc, key) => {
    if (data[key] === null) {
      acc[key] = undefined; // map null as undefined
    } else if (typeof data[key] === "object") {
      acc[key] = mapNulltoUndefined(data[key]); // map nested objects
    } else {
      acc[key] = data[key]; // otherwise map old value
    }
    return acc;
  }, {} as ExcludeNull<T>);

export const mapApiToDataboxDocument = (
  response: DocumentGetUnfinishedResponse
): CreateDataboxRegistration => {
  const data = mapNulltoUndefined(response);
  return {
    ...data,
    attachmentCount: data.attachmentsCount,
    attachmentType: data.attachmentsType
  };
};

export const mapApiToEmailDocument = (
  response: DocumentGetUnfinishedResponse
): CreateEmailRegistration => {
  const data = mapNulltoUndefined(response);
  return {
    ...data,
    attachmentCount: data.attachmentsCount,
    attachmentType: data.attachmentsType
  };
};

export const mapApiToAnalogDocument = (
  response: DocumentGetUnfinishedResponse
): AnalogDocument => mapNulltoUndefined(response);

export const mapApiToTechnDataCarrierDocument = (
  response: DocumentGetUnfinishedResponse
): TechnicalDataCarrierDocument => mapNulltoUndefined(response);
