import { PersonRepository } from "domain/service/nameRegistry/PersonRepository";
import { PersonId } from "domain/struct/nameRegistry/Person";
import { PersonRepositoryToken } from "domain/di/token/nameRegistry";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../../lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";

@DInjectable()
export class FindPerson {
  constructor(
    @DInject(PersonRepositoryToken) protected personRepository: PersonRepository
  ) {}

  findAllPaginated(config: PaginationConfig, sorting?: SortingConfig) {
    return this.personRepository.findAllPaginated(config, sorting);
  }

  findFullById(id: PersonId) {
    return this.personRepository.findFullById(id);
  }

  findById(id: PersonId) {
    return this.personRepository.findById(id);
  }
}
