import { SslAnalog } from "presentation/core/api/models";
import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import MetaFormContainer from "presentation/core/components/MetaForm";
import { GenericDocument } from "presentation/core/types";
import { DocumentType, SenderType } from "presentation/enums";
import React from "react";
import { AnalogDeliveredMetadataForm } from "./AnalogDeliveredMetadataForm";
import { AnalogOwnMetadataForm } from "./AnalogOwnMetadataForm";

const MetaDataFormTab = ({
  channel,
  dialogProps,
  onClose
}: DialogContentPropsType) => {
  const sslProps = (dialogProps.data as GenericDocument)?.properties?.ssl;
  const form = sslProps?.form;
  const senderType = sslProps?.senderType;
  const defaultForm = AnalogDeliveredMetadataForm;

  const forms = {
    [DocumentType.Analog]: {
      [SenderType.Own]: AnalogOwnMetadataForm
    }
  };
  const selectedForm =
    (form && senderType && forms[form]?.[senderType]) || defaultForm;

  return (
    <MetaFormContainer<SslAnalog>
      channel={channel}
      dialogProps={dialogProps}
      MetaForm={selectedForm}
      onClose={onClose}
    />
  );
};

export default MetaDataFormTab;
