import React, { useCallback, useMemo, useState } from "react";
import { ColumnType } from "../../../../designSystem/Table/Table";
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined
} from "../../../../designSystem/Icon/Icon";
import { createColumn } from "../../../../designSystem/Table/column/column";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { AddressModal } from "../../modal/AdressModal/AddressModal";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../designSystem/Table/RemoteTable";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "../../../../share/utils/getPath";
import { StreetRow } from "./StreetRow";
import { CountryRow } from "./CountryRow";
import { AddressTypeRow } from "./AddressTypeRow";
import { AddressDeleteModal } from "../../modal/AdressModal/AddressDeleteModal";
import { useSubjectModalContext } from "../../contexts/SubjectModalContextProvider";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { TableRowSelection } from "antd/lib/table/interface";

const COLUMNS: ColumnType<Address>[] = [
  createColumn({
    dataIndex: "street",
    title: t(translationPath(lang.table.addressTable.street)),
    render: (text, row) => {
      const {
        street,
        houseNumber,
        orientationHouseNumber,
        orientationHouseNumberAddendum
      } = row || {};

      return (
        <StreetRow
          street={street}
          houseNumber={houseNumber}
          orientationHouseNumberAddendum={orientationHouseNumberAddendum}
          orientationHouseNumber={orientationHouseNumber}
        />
      );
    }
  }),
  createColumn({
    dataIndex: "city",
    title: t(translationPath(lang.table.addressTable.city))
  }),
  createColumn({
    dataIndex: "postalCode",
    title: t(translationPath(lang.table.addressTable.postalCode))
  }),
  createColumn({
    dataIndex: "country",
    title: t(translationPath(lang.table.addressTable.country)),
    defaultSortOrder: "ascend",
    render: (text) => {
      return <CountryRow country={text} />;
    }
  }),
  createColumn({
    dataIndex: "addressType",
    title: t(translationPath(lang.table.addressTable.addressType)),
    render: (text) => {
      return <AddressTypeRow addressType={text} />;
    }
  })
];

export const AddressesTable: React.FC = () => {
  const { persistence } = useSubjectModalContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [addressModal, addressModalApi] = useModal(AddressModal, {
    onClose: () => {
      setSelectedRowKeys([]);
    }
  });

  const [addressDeleteModal, addressDeleteModalApi] = useModal(
    AddressDeleteModal
  );

  const ACTIONS = useMemo(
    () => ({
      default: [
        {
          key: "add-address",
          icon: <PlusCircleOutlined />,
          tooltip: "Přidat adresu",
          action() {
            return addressModalApi.open({});
          }
        }
      ],
      single: [
        {
          key: "delete-address",
          icon: <MinusCircleOutlined />,
          tooltip: "Odebrat adresu",
          action(selected: Address[]) {
            return addressDeleteModalApi.open({
              address: selected[0],
              onCancel() {
                addressDeleteModalApi.close();
              }
            });
          }
        },
        {
          key: "edit-address",
          icon: <EditOutlined />,
          tooltip: "Upravit adresu",
          action(selected: Address[]) {
            return addressModalApi.open({
              address: selected[0]
            });
          }
        }
      ]
    }),
    [addressDeleteModalApi, addressModalApi]
  );

  const getRemoteData: GetRemoteDataClb<Address> = useCallback(
    ({ pagination }) =>
      persistence.getAddresses({ page: 1, itemsPerPage: 1000 }),
    [persistence]
  );

  const onRowSelection: TableRowSelection<Address> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  return (
    <>
      {addressModal}
      {addressDeleteModal}
      <RemoteTable<Address>
        rowKey="id"
        name={`nameRegistry/addressTable/${
          persistence.getSubjectData()?.subjectType
        }/${persistence.getSubjectData()?.id}`}
        actions={ACTIONS}
        columns={COLUMNS}
        getRemoteData={getRemoteData}
        rowSelection={onRowSelection}
      />
    </>
  );
};
