import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Description, Mail } from "@material-ui/icons";
import MarkEmailRead from "assets/icons-tsx/MarkEmailRead";
import { ControlsBarType } from "presentation/core/components/dataTable/_types";
import { isGenericDocument, ShipmentDocument } from "presentation/core/types";
import { shipmentDetailDialogOpen } from "presentation/share/components/dialog/shipmentDetailDialog/_action";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { dialogOpenAction } from "../../../_actions";
import { DialogType } from "../../../_types";
import ShipmentTab from "../ShipmentTab";
import { useGetShipments } from "./useGetShipments";
import { ErrorCodeList, SpisumNodeTypes } from "presentation/enums";
import { getDownloadDelivery } from "../api";
import contentDisposition from "content-disposition";
import fileDownload from "js-file-download";
import { Notification } from "presentation/designSystem/notification/Notification";
import { ErrorType } from "presentation/types";

interface UseShipmentTabState {
  pageNumber: number;
  rowsPerPage: number;
}

const initialState: UseShipmentTabState = {
  pageNumber: 0,
  rowsPerPage: 50
};

export interface UseShipmentTabContextProviderProps {
  nodeId: string;
  isActive: boolean;
  hideManageShipmentsIcon?: boolean;
}

export type UseShipmentTabContainerResult = [
  JSX.Element,
  {
    fetchShipments(): void;
  }
];

export const useShipmentTab = ({
  nodeId,
  isActive,
  hideManageShipmentsIcon
}: UseShipmentTabContextProviderProps): UseShipmentTabContainerResult => {
  const dispatch = useDispatch();
  const [state, setState] = useState<UseShipmentTabState>(initialState);

  const { data, refetch, isFetching } = useGetShipments(
    nodeId,
    {
      page: state.pageNumber ? state.pageNumber : 1,
      itemsPerPage: state.rowsPerPage ? state.rowsPerPage : 10
    },
    isActive
  );

  const onExport = useCallback((params) => {
    getDownloadDelivery(params)
      .then(({ response, headers }) => {
        const disposition = contentDisposition.parse(
          headers?.get("content-disposition") || ""
        );
        fileDownload(response as Blob, disposition.parameters.filename);
        Notification.success({
          message: t(
            translationPath(lang.dialog.notifications.dataBoxDelivered)
          )
        });
      })
      .catch((error: ErrorType) => {
        return Notification.error({
          message: t(
            translationPath(
              lang.dialog.notifications[
                error.code === ErrorCodeList.DataBoxNotFound
                  ? "dataBoxNotDelivered"
                  : "actionFailed"
              ]
            )
          )
        });
      });
  }, []);

  const handleShowMarkEmailButton = (selected: ShipmentDocument) =>
    selected.nodeType === SpisumNodeTypes.ShipmentDatabox;

  const controls: ControlsBarType<ShipmentDocument> = {
    default: {
      items: hideManageShipmentsIcon
        ? undefined
        : [
            {
              action: (selected: ShipmentDocument[]) => {
                dispatch(
                  dialogOpenAction({
                    dialogProps: {
                      data: {
                        id: nodeId,
                        ...(isGenericDocument(data)
                          ? { nodeType: data.nodeType }
                          : {})
                      },

                      onClose: () => refetch()
                    },
                    dialogType: DialogType.SendShipment
                  })
                );
              },
              icon: <Mail />,
              title: t(translationPath(lang.general.manageShipments))
            }
          ]
    },
    single: {
      items: [
        {
          action: (selected: ShipmentDocument[]) => {
            dispatch(
              shipmentDetailDialogOpen({
                documentId: nodeId,
                onClose: () => refetch,
                selected: selected[0],
                readonly:
                  selected[0].properties?.cm?.lockType === "READ_ONLY_LOCK"
              })
            );
          },

          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },

        {
          action(selected: ShipmentDocument[]) {
            onExport({
              nodeId: selected[0].id,
              dataBoxId: selected[0].properties?.ssl?.shipmentSender,
              dataBoxMessageId: selected[0].properties?.ssl?.itemId
            });
          },
          filter: handleShowMarkEmailButton,
          icon: <MarkEmailRead />,
          title: t(translationPath(lang.general.downloadDeliveryShipment))
        }
      ]
    }
  };

  const handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void = (_, page) => {
    setState((state) => ({
      pageNumber: page,
      rowsPerPage: state.rowsPerPage
    }));
  };

  const handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event) => {
    setState(() => ({
      pageNumber: 0,
      rowsPerPage: parseInt(event.target.value, 10)
    }));
  };

  const shipmentTabEl = (
    <ShipmentTab
      controls={controls}
      {...state}
      items={data?.items || []}
      totalItems={data?.items.length || 0}
      refreshTable={refetch}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isLoading={isFetching}
    />
  );

  return [
    shipmentTabEl,
    {
      fetchShipments: refetch
    }
  ];
};
