import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ComponentUpload } from "./ComponentUpload";
import {
  PaperClipOutlined,
  PlusCircleOutlined
} from "../../../../../designSystem/Icon/Icon";
import { Row } from "presentation/designSystem/Grid/Grid";

type OwnProps = {
  handleUploadComponent: (files: globalThis.File[]) => void;
  file?: File[];
  allowedExtensions?: string[];
};

export const ComponentUploadedFilename = React.forwardRef(
  ({ handleUploadComponent, file, allowedExtensions }: OwnProps, ref) => {
    return (
      <>
        <Row align="middle">
          {t(translationPath(lang.general.uploadComponents))}
          <ComponentUpload
            handleUploadComponent={handleUploadComponent}
            title={t(translationPath(lang.general.uploadComponents))}
            icon={<PlusCircleOutlined style={{ margin: "5px" }} />}
            allowedExtensions={allowedExtensions}
          />
        </Row>
        <Row align="bottom" justify="space-between">
          {file ? (
            <Row
              justify="space-between"
              align="middle"
              style={{ marginTop: "10px" }}
            >
              <PaperClipOutlined
                style={{
                  fontSize: "17px",
                  marginTop: "auto",
                  marginBottom: "auto"
                }}
              />
              <input
                type="text"
                value={file[0].name}
                style={{
                  border: "none",
                  width: "30rem",
                  margin: "auto 10px",
                  outline: "none",
                  cursor: "default"
                }}
                readOnly
              />
            </Row>
          ) : (
            <input type="text" style={{ display: "none" }} />
          )}
        </Row>
      </>
    );
  }
);
