import {
  Check,
  Close,
  Description,
  NotInterested,
  Refresh
} from "@material-ui/icons";
import { callAsyncAction } from "presentation/core/action";
import { AnimatedRefreshRotateStyled } from "presentation/core/components/dataTable/DataTable.styles";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import DocumentView from "presentation/core/components/documentView";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { EmailDocument, emailDocumentProxy } from "presentation/core/types";
import { SitePaths, SpisumNodeTypes } from "presentation/enums";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { isEmptyString } from "presentation/share/utils/utils";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { RefreshStatusPayload } from "../../../types";
import {
  documentEmailRefreshAction,
  documentEmailRefreshStatusAction,
  documentRefreshAction
} from "../../../_actions";
import { Notification } from "presentation/designSystem/notification/Notification";
// New govDesign notification :
// import { notificationAction } from "presentation/core/components/notifications/_actions";
// import { NotificationSeverity } from "presentation/core/components/notifications/_types";
import Loading from "presentation/core/components/dataTable/components/Loading";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { EmailDetailsModal } from "./modals/EmailDetailsModal";
import { metaFormAction__Update } from "presentation/core/components/MetaForm/_actions";
import { useCreateEmailRegistration } from "./hooks/useCreateEmailRegistration";

const defaultColumn: DataColumn<EmailDocument> = {
  isDateTime: true,
  keys: [
    classPath(emailDocumentProxy.properties!.ssl!.digitalDeliveryDeliveryDate)
      .path
  ],
  label: t(translationPath(lang.general.delivery))
};

const IncomeDigitalDocumentEmailBoxUnprocessed = () => {
  const dispatch = useDispatch();
  const [refreshPending, setRefreshPending] = useState<boolean>(false);

  const emailAccounts = useSelector(
    (state: RootStateType) => state.emailReducer.emailAccounts
  );

  const columns: DataColumn<EmailDocument>[] = [
    {
      keys: [classPath(emailDocumentProxy.properties!.ssl!.pid).path],
      label: t(translationPath(lang.general.identifier))
    },
    {
      keys: [
        classPath(emailDocumentProxy.properties!.ssl!.digitalDeliverySubject)
          .path
      ],
      label: t(translationPath(lang.general.subject))
    },
    {
      getValue: (item) =>
        `${item.properties?.ssl?.emailSender}${
          !isEmptyString(item.properties?.ssl?.emailSenderName)
            ? ` (${item.properties?.ssl?.emailSenderName})`
            : ""
        }`,
      keys: [
        classPath(emailDocumentProxy.properties!.ssl!.emailSender).path,
        classPath(emailDocumentProxy.properties!.ssl!.emailSenderName).path
      ],
      label: t(translationPath(lang.general.sender))
    },
    defaultColumn,
    {
      keys: [
        classPath(
          emailDocumentProxy.properties!.ssl!.digitalDeliveryAttachmentsCount
        ).path
      ],
      label: t(translationPath(lang.general.attachmentsCount))
    }
  ];

  if (emailAccounts?.length > 1) {
    columns.push({
      keys: [
        classPath(emailDocumentProxy.properties!.ssl!.emailRecipient).path
      ],
      label: t(translationPath(lang.general.recipient))
    });
  }

  const onSuccess = (payload: RefreshStatusPayload) => {
    setRefreshPending(false);

    Notification.success({
      message: t(translationPath(lang.dialog.notifications.refreshSucceeded), {
        count: payload.newMessageCount
      })
    });

    // New govDesign notification :
    // dispatch(
    //   notificationAction({
    //     message: t(
    //       translationPath(lang.dialog.notifications.refreshSucceeded),
    //       { count: payload.newMessageCount }
    //     ),
    //     severity: NotificationSeverity.Success
    //   })
    // );

    dispatch(documentViewAction__Refresh(true));
  };

  const onError = () => {
    setRefreshPending(false);
  };

  const triggerFetchEmails = () => {
    if (refreshPending) {
      return;
    }

    setRefreshPending(true);

    dispatch(
      callAsyncAction({
        action: documentRefreshAction,
        onError,
        onSuccess,
        onSuccessNotification: null,
        payload: {
          refreshAction: documentEmailRefreshAction,
          statusAction: documentEmailRefreshStatusAction
        }
      })
    );
  };

  const {
    modal: registerModal,
    register,
    isLoading: isRegisterLoading
  } = useCreateEmailRegistration();

  const [emailDetailsModal, emailDetailsModalApi] = useModal(
    EmailDetailsModal,
    {}
  );

  const onDoubleClick: (row: EmailDocument) => void = (row) => {
    emailDetailsModalApi.open({
      data: row,
      register
    });
  };

  const controls: ControlsBarType<EmailDocument> = {
    default: {
      items: [
        {
          action: triggerFetchEmails,
          icon: () =>
            refreshPending ? <AnimatedRefreshRotateStyled /> : <Refresh />,
          title: t(translationPath(lang.general.refreshEmails))
        }
      ]
    },
    single: {
      items: [
        {
          action: (selected: EmailDocument[]) => {
            emailDetailsModalApi.open({
              data: selected[0],
              register
            });
          },
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: EmailDocument[]) => {
            register(selected[0].id);
            dispatch(
              metaFormAction__Update({
                formValues: selected[0]?.properties?.ssl
              })
            );
          },
          icon: <Check />,
          title: t(translationPath(lang.general.register))
        },
        {
          action: (selected: EmailDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.DontRegisterDocument
              })
            );
          },
          icon: <Close />,
          title: t(translationPath(lang.general.notRegister))
        },
        {
          action: (selected: EmailDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.IncompleteDocument
              })
            );
          },
          icon: <NotInterested />,
          title: t(translationPath(lang.general.notValid))
        }
      ]
    }
  };

  const paths = useSelector(
    (state: RootStateType) => state.loginReducer.global.paths
  );

  const relativePath = getRelativePath(
    paths,
    null,
    SitePaths.Mailroom,
    SitePaths.MailBox,
    SitePaths.Unprocessed
  );

  return (
    <>
      {registerModal}
      {emailDetailsModal}
      {isRegisterLoading && <Loading />}
      <MenuLayout>
        <DocumentView
          children={{
            includeSource: true,
            relativePath,
            where: `(nodeType='${SpisumNodeTypes.Email}')`
          }}
          columns={columns}
          controls={controls}
          customTitle={t(translationPath(lang.table.unprocessedEmails))}
          defaultSortAsc={true}
          defaultSortColumn={defaultColumn}
          refreshPending={refreshPending}
          handleDoubleClick={onDoubleClick}
        />
      </MenuLayout>
    </>
  );
};

IncomeDigitalDocumentEmailBoxUnprocessed.displayName = "unprocessed";

export default withTranslation()(IncomeDigitalDocumentEmailBoxUnprocessed);
