import React, { useMemo } from "react";
import { TableActionButton } from "./TableActionButton";
import { TableAction } from "./contract";

export interface TableActionsButtonsProps<Row> {
  actions: TableAction<Row>[];
  selectedRows: Row[];
  dataSource: Row[];
  onActionFinished?: (
    action: TableAction<Row>,
    dataWasMutated: boolean
  ) => void;
}

export function TableActionsButtons<Row>({
  actions,
  selectedRows,
  dataSource,
  onActionFinished
}: TableActionsButtonsProps<Row>) {
  const filteredActions = useMemo(
    () => filterActions(actions, selectedRows, dataSource),
    [actions, selectedRows, dataSource]
  );

  return (
    <div>
      {filteredActions.map((action) => (
        <TableActionButton
          key={action.key}
          action={action}
          selectedRows={selectedRows}
          dataSource={dataSource}
          onActionFinished={onActionFinished}
        />
      ))}
    </div>
  );
}

function filterActions<Row>(
  actions: TableAction<Row>[],
  selected: Row[],
  items: Row[]
) {
  return actions.filter((action) => {
    if (action.isDisabled) {
      return false;
    }

    if (!action.canBeDisplayed) {
      return true;
    }

    return allItemsAreApplicableForAction(action, selected, items);
  });
}

function allItemsAreApplicableForAction<Row>(
  action: TableAction<Row>,
  selected: Row[],
  items: Row[]
) {
  const notApplicableItem = selected.find((item) =>
    action.canBeDisplayed ? !action.canBeDisplayed(item, items) : false
  );

  return !notApplicableItem;
}
