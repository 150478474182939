import React from "react";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Input } from "presentation/designSystem/Input/Input";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { translationPath } from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { useMutation } from "presentation/share/hook/query/useQM";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { createNewValue, CreateNewValueProps } from "../api";
import { ErrorType } from "presentation/types";

export interface CreateNewValueParams
  extends Omit<CreateNewValueProps, "codeList"> {}
export interface CreateNewValueInterface extends BaseModalProps {
  nodeId?: string;
  onOk?: BaseModalProps["onOk"];
  required?: boolean;
  codeListType: string;
}

export const CreateNewValue = ({
  onCancel,
  onOk,
  codeListType
}: CreateNewValueInterface) => {
  const getNotificationForError = (error: ErrorType) => {
    return {
      message: error.messages?.[0]
        ? error.messages?.[0]
        : t(translationPath(lang.dialog.notifications.actionFailed))
    };
  };
  const [form] = useForm<CreateNewValueParams>();

  const onConfirm = async () => {
    try {
      await form.validateFields();
      const { value } = form.getFieldsValue();
      mutate({ value, codeList: codeListType });
    } catch {}
  };

  const { isLoading, mutate } = useMutation(
    (params: CreateNewValueProps) => createNewValue(params),
    {
      onSuccess() {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(translationPath(lang.menu.items.successCreatedValue))
      },
      onErrorNotification: getNotificationForError
    }
  );

  return (
    <Modal
      visible={true}
      title={t(translationPath(lang.dialog.title.createNewValue))}
      onCancel={onCancel}
      onOk={onConfirm}
      confirmLoading={isLoading}
      className={""}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <Form form={form}>
        <Item
          name="value"
          label={t(translationPath(lang.menu.items.value))}
          rules={[{ required: true }, { max: 255 }, { min: 4 }]}
          required={true}
        >
          <Input style={{ width: "100%" }} />
        </Item>
      </Form>
    </Modal>
  );
};
