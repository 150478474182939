import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { lang, t } from "presentation/translation/i18n";
import React, { useState } from "react";
import { Contact } from "../../../../../domain/struct/nameRegistry/Contact";
import { ContactType } from "../../../../../domain/struct/nameRegistry/ContactType";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import { useForm } from "../../../../designSystem/Form/v2/Form";
import { Modal, ModalSize } from "../../../../designSystem/Modal/Modal";
import { Notification } from "../../../../designSystem/notification/Notification";
import { useMutation } from "../../../../share/hook/query/useQM";
import { getErrorCodeTranslation } from "../../../../share/utils/errorCodeTranslation";
import { translationPath } from "../../../../share/utils/getPath";
import { useSubjectModalContext } from "../../contexts/SubjectModalContextProvider";
import { ContactsForm } from "../../form/ContactsForm/ContactsForm";
import { useFindDataBox } from "../../hooks/useFindDataBox";
import DataBoxModal from "./DataBoxModal";
import {
  Address,
  DESCRIPTIVE_HOUSE_NUMBER_TYPE,
  EVIDENCE_HOUSE_NUMBER_TYPE
} from "../../../../../domain/struct/nameRegistry/Address";
import { Query, useQueryClient } from "react-query";
import { LegalEntity } from "../../../../../domain/struct/nameRegistry/LegalEntity";

export interface CreateContactModalProps extends BaseModalProps {
  contact?: Contact;
  type: string;
}

const TITLE_MAP = {
  create: t(translationPath(lang.dialog.contactsDialog.create)),
  edit: t(translationPath(lang.dialog.contactsDialog.edit))
};

const DEFAULT_CITIZENSHIP = "CZE";

function getHouseNumberType(address: Address) {
  const { houseNumberType } = address || {};
  if (!houseNumberType) {
    return DESCRIPTIVE_HOUSE_NUMBER_TYPE;
  }
  return address.orientationHouseNumber
    ? DESCRIPTIVE_HOUSE_NUMBER_TYPE
    : EVIDENCE_HOUSE_NUMBER_TYPE;
}

export const ContactModal = ({
  onCancel,
  onOk,
  contact,
  type
}: CreateContactModalProps) => {
  const [contactForm] = useForm<Contact>();
  const [databoxForm] = useForm<Address>();

  const [dataBoxId, setDataBoxId] = useState("");
  const [checkedAddress, setCheckedAddress] = useState(false);
  const [checkedMetadata, setCheckedMetadata] = useState(false);
  const { persistence } = useSubjectModalContext();

  const queryClient = useQueryClient();

  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });

  const { isLoading: isLoadingAdd, mutate: addContact } = useMutation(
    (contact: Contact) => persistence.addContact(contact),
    {
      onSuccess() {
        onOk?.();
      },
      onErrorNotification: getNotificationForError,
      onSuccessNotification: {
        message: t(translationPath(lang.dialog.notifications.contactWasAdded))
      }
    }
  );

  const { isLoading: isLoadingUpdate, mutate: updateContact } = useMutation(
    (contact: Contact) => persistence.updateContact(contact),
    {
      onSuccess() {
        onOk?.();
      },
      onErrorNotification: getNotificationForError,
      onSuccessNotification: {
        message: t(translationPath(lang.dialog.notifications.contactWasEdited))
      }
    }
  );

  const { data: dataBoxData, isFetching: isDataBoxFetching } = useFindDataBox(
    dataBoxId,
    persistence.getSubjectData()?.id || "",
    {
      retry: false,
      enabled: !!dataBoxId,
      onError(error) {
        // This hack was introduced in https://isfg.atlassian.net/browse/SV1-3034 as
        // a temporary solution
        // HACK START
        const errorWithCode = error as Error & { code: string };
        if (errorWithCode?.code === "DBX_NOT_FOUND") {
          contact
            ? updateContact({
                ...contact,
                ...contactForm.getFieldsValue()
              })
            : addContact(contactForm.getFieldsValue());
          return;
        }
        // HACK END
        Notification.error({
          message: t(
            translationPath(lang.dialog.notifications.dataBoxIdNotFound)
          )
        });
      }
    }
  );

  const { mutate: addAddress } = useMutation(
    (address: Address) => persistence.addAddress(address),
    {
      onSuccess() {
        onOk?.();
      },
      onErrorNotification: getNotificationForError,
      onSuccessNotification: {
        message: t(translationPath(lang.dialog.notifications.addressWasEdited))
      }
    }
  );

  const handleOk = async () => {
    await contactForm.validateFields();

    const {
      contactType,
      contact: currentContact
    } = contactForm.getFieldsValue();

    const { items } = await persistence.getContacts({
      page: 1,
      itemsPerPage: 10
    });

    const isContactDuplicate =
      items.filter(
        (contact) => contact.contact === contactForm.getFieldsValue().contact
      ).length > 0;

    if (isContactDuplicate) {
      return Notification.error({
        message: t(
          translationPath(lang.dialog.notifications.contactAlreadyExists)
        )
      });
    }

    if (contactType === ContactType.DataBoxId) {
      const isDataboxFODuplicate =
        type === SubjectType.Person &&
        items.filter((contact) => contact.contactType === ContactType.DataBoxId)
          .length > 0;

      if (isDataboxFODuplicate && !contact) {
        return Notification.error({
          message: t(
            translationPath(
              lang.dialog.notifications.contactTypeDataboxAlreadyExists
            )
          )
        });
      } else {
        return setDataBoxId(currentContact);
      }
    }

    try {
      if (contact) {
        await updateContact({
          ...contact,
          ...contactForm.getFieldsValue()
        });
      } else {
        await addContact(contactForm.getFieldsValue());
      }
    } catch {}
  };

  const onConfirmDataBox = async () => {
    try {
      await contactForm.validateFields();

      contact
        ? await updateContact({
            ...contact,
            ...contactForm.getFieldsValue()
          })
        : await addContact(contactForm.getFieldsValue());

      const { address, ...rest } = dataBoxData || {};
      if (checkedMetadata) {
        if (dataBoxData?.subjectType === SubjectType.Person) {
          const firstName = rest?.subjectName?.split(" ")?.[0];
          const lastName = rest?.subjectName?.split(" ")?.[1];
          persistence.setSubjectData({
            firstName,
            surname: lastName,
            subjectType: SubjectType.Person,
            citizenship: DEFAULT_CITIZENSHIP
          });
        } else {
          persistence.setSubjectData({
            companyFullName: rest?.subjectName,
            identificationNumber: persistence.getSubjectData()?.id
              ? (persistence.getSubjectData() as LegalEntity)
                  .identificationNumber
              : rest?.identificationNumber,
            subjectType: SubjectType.LegalEntity,
            citizenship: DEFAULT_CITIZENSHIP
          });
        }
      }

      if (checkedAddress) {
        if (dataBoxData?.address) {
          await addAddress({
            ...dataBoxData.address,
            addressType: databoxForm.getFieldsValue().addressType,
            country: databoxForm.getFieldsValue().country,
            houseNumberType: getHouseNumberType(databoxForm.getFieldsValue())
          });
        }
      }
      // to refresh the table
      await queryClient.removeQueries({
        predicate: (query: Query) => {
          const key = query.queryKey[0] as string;
          return key.startsWith("remoteTable/nameRegistry/addressTable");
        }
      });
    } catch {}
  };

  const onCancelDataBox = () => {
    setDataBoxId("");
  };

  return (
    <Modal
      visible={true}
      title={contact ? TITLE_MAP.edit : TITLE_MAP.create}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={
        (!contact ? isLoadingAdd : isLoadingUpdate) || isDataBoxFetching
      }
      size={ModalSize.Small}
      centered={true}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      {dataBoxData && (
        <DataBoxModal
          dataBoxData={dataBoxData}
          isDataBoxFetching={isDataBoxFetching}
          onConfirmDataBox={onConfirmDataBox}
          onCancel={onCancelDataBox}
          typeOfSubject={type}
          checkedAddress={checkedAddress}
          setCheckedAddress={setCheckedAddress}
          checkedMetadata={checkedMetadata}
          setCheckedMetadata={setCheckedMetadata}
          form={databoxForm}
        />
      )}
      <ContactsForm form={contactForm} contact={contact} />
    </Modal>
  );
};
