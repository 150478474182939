import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../lib/contract/Pagination";
import { RetentionProtocolsOrProposalsRepositoryTokenHistory } from "../../domain/di/token/recordRetention";
import { RetentionProtocolsOrProposalsHistoryRepository } from "../../domain/service/recordRetention/RetentionProtocolsOrProposalsHistoryRepository";
import { SortingConfig } from "lib/contract/Sorting";

@DInjectable()
export class FindRetentionProtocolsOrProposalsHistory {
  constructor(
    @DInject(RetentionProtocolsOrProposalsRepositoryTokenHistory)
    protected retentionProtocolOrProposalHistoryRepository: RetentionProtocolsOrProposalsHistoryRepository
  ) {}

  findAllPaginated(
    nodeId: string,
    config: PaginationConfig,
    sorting?: SortingConfig
  ) {
    return this.retentionProtocolOrProposalHistoryRepository.findAllPaginated(
      nodeId,
      config,
      sorting
    );
  }
}
