import React, { useCallback, useMemo, useState } from "react";
import { ColumnType } from "presentation/designSystem/Table/Table";
import { lang, t } from "../../../../../translation/i18n";
import { translationPath } from "../../../../../share/utils/getPath";
import { useFindStoredFiles } from "../hooks/useFindStoredFiles";
import { useDispatch } from "react-redux";
import { SpisumNodeTypes } from "../../../../../enums";
import { dialogOpenAction } from "../../../../../core/components/dialog/_actions";
import { DialogType } from "../../../../../core/components/dialog/_types";
import { FilePlanColumnValue } from "../../../../../share/components/table/column/document/FilePlan/FilePlanColumnValue";
import { FileMarkColumnValue } from "../../../../../share/components/table/column/document/FilePlan/FileMarkColumnValue";
import { StoredFile } from "../../../../../../domain/struct/repository/StoredFile";
import { AllInbox, AssignmentInd, SwapHoriz } from "@material-ui/icons";
import { openFileDetailsAction } from "../../../../../core/components/dialog/tabs/tableOfContents/_actions";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import { actionShowDetail } from "presentation/share/components/table/actionFactory";
import { createSenderNameColumn } from "../../../factory/table/columnFactory";
import { TableRowSelection } from "antd/lib/table/interface";
import { BreadcrumbForTable } from "../../../../../designSystem/Breadcrumb/Breadcrumb";
import styled from "@emotion/styled";
import { DateTimeFormat } from "lib/dateTime";

const COLUMNS: ColumnType<StoredFile>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier))
  }),
  createColumn({
    dataIndex: "fileIdentifier",
    title: t(translationPath(lang.general.fileIdentifier))
  }),
  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.general.subject))
  }),
  createSenderNameColumn(),
  createColumn({
    dataIndex: "filePlan",
    title: t(translationPath(lang.general.filePlan)),
    render: (text, row) => {
      const { filePlanId } = row;
      if (!filePlanId) {
        return null;
      }
      return <FilePlanColumnValue filePlanId={filePlanId} />;
    }
  }),
  createColumn({
    dataIndex: "fileMark",
    title: t(translationPath(lang.general.fileMark)),
    render: (text, row) => {
      const { fileMark } = row;
      if (!fileMark) {
        return null;
      }
      return <FileMarkColumnValue fileMarkId={fileMark} />;
    }
  }),
  createColumn({
    dataIndex: "retentionMode",
    title: t(translationPath(lang.general.retentionMode))
  }),
  createColumn({
    dataIndex: "location",
    title: t(translationPath(lang.general.storageLocation))
  }),
  createDateTimeColumn(
    {
      dataIndex: "shreddingYear",
      title: t(translationPath(lang.general.shreddingYear)),
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.Year
  )
];

export const StoredFilesTable = React.memo(() => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const dispatch = useDispatch();
  const findStoredFiles = useFindStoredFiles();

  const ACTIONS = useMemo(
    () => ({
      single: [
        actionShowDetail({
          action(selected: StoredFile[]) {
            dispatch(
              openFileDetailsAction({
                data: {
                  id: selected[0].id,
                  nodeType: SpisumNodeTypes.File
                },
                hideManageShipmentsIcon: true,
                initiator: SpisumNodeTypes.File,
                isReadonly: true
              })
            );
          }
        }),
        {
          key: "change-file-mark",
          icon: <SwapHoriz />,
          tooltip: t(translationPath(lang.general.changeFileMark)),
          action(selected: StoredFile[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].id,
                    nodeType: SpisumNodeTypes.File,
                    properties: {
                      ssl: {
                        filePlan: selected[0].filePlanId,
                        fileMark: selected[0].fileMark
                      }
                    }
                  },
                  onClose: () => {
                    setSelectedRowKeys([]);
                  }
                },
                dialogType: DialogType.ChangeFileMark
              })
            );
          }
        },
        {
          key: "change-location",
          icon: <AllInbox />,
          tooltip: t(translationPath(lang.general.editStorageLocation)),
          action(selected: StoredFile[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].id,
                    nodeType: SpisumNodeTypes.File
                  }
                },
                dialogType: DialogType.ChangeLocation
              })
            );
          }
        },
        {
          key: "borrow",
          icon: <AssignmentInd />,
          tooltip: t(translationPath(lang.general.borrow)),
          action(selected: StoredFile[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].id,
                    nodeType: SpisumNodeTypes.File
                  }
                },
                dialogType: DialogType.Borrow
              })
            );
          }
        }
      ]
    }),
    [dispatch]
  );

  const getRemoteData: GetRemoteDataClb<StoredFile> = useCallback(
    ({ pagination, sorting }) => findStoredFiles(pagination, sorting),
    [findStoredFiles]
  );

  const onRowSelection: TableRowSelection<StoredFile> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  return (
    <>
      <BreadcrumbForTable title={"storedFiles"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<StoredFile>
          name="repository/storedFiles"
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
          rowSelection={onRowSelection}
        />
      </TableStyled>
    </>
  );
});
const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
