import React, { ChangeEvent, useState } from "react";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { lang, t } from "../../../translation/i18n";
import { translationPath } from "../../../share/utils/getPath";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Input } from "presentation/designSystem/Input/Input";
import { styled } from "../../../styles";
import { usePerformShreddingProcedure } from "../features/recordRetentionProcess/retentionProposal/hooks/usePerformShreddingProcedure";
import { useMutation } from "../../../share/hook/query/useMutation";
import { ErrorType } from "../../../types";
import { Notification } from "presentation/designSystem/notification/Notification";
import { getErrorCodeTranslation } from "../../../share/utils/errorCodeTranslation";

interface PerformShreddingProcedureModalProps extends BaseModalProps {
  selectedNodeId: string;
}

const CHARACTERS_MIN = 4;
const CHARACTERS_MAX = 30;

const validationRules = [
  { required: true },
  { min: CHARACTERS_MIN },
  { max: CHARACTERS_MAX }
];

export const PerformShreddingProcedureModal = ({
  onCancel,
  onOk,
  selectedNodeId
}: PerformShreddingProcedureModalProps) => {
  const [form] = useForm();
  const [inputValue, setInputValue] = useState("");

  const performShreddingProcedure = usePerformShreddingProcedure();

  const { mutate: performShreddingProcedureMutation } = useMutation(
    async () => {
      await form.validateFields();

      await performShreddingProcedure.savePerformShreddingProcedure({
        id: selectedNodeId!,
        reason: form.getFieldsValue().reasonOfShreddingOrTransfer
      });
    },
    {
      onError(error: ErrorType) {
        if (error.message === "Failed to fetch") {
          Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });
          return;
        }
        if (error?.messages) {
          error.messages.map((error: string) =>
            Notification.error({
              message: getErrorCodeTranslation(error)
            })
          );
        } else {
          if (error.message) {
            return Notification.error({
              message: error.message
            });
          }
          Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });
        }
      },
      onSuccess() {
        Notification.success({
          message: t(translationPath(lang.dialog.notifications.actionSucceeded))
        });
        onOk?.();
      },
      onErrorNotification: null,
      onSuccessNotification: null
    }
  );

  const savePerformShreddingProcedureMutation = async () => {
    await performShreddingProcedureMutation(undefined);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  return (
    <Modal
      visible={true}
      title={t(translationPath(lang.general.performShreddingProcedure))}
      onCancel={onCancel}
      onOk={savePerformShreddingProcedureMutation}
      size={ModalSize.Small}
      okText={t(translationPath(lang.dialog.buttons.confirm))}
      cancelText={t(translationPath(lang.dialog.buttons.cancel))}
      okButtonProps={{
        disabled:
          inputValue.length < CHARACTERS_MIN ||
          inputValue.length > CHARACTERS_MAX
      }}
    >
      <StyledForm
        name="performShreddingProcedureForm"
        form={form}
        initialValues={{
          reasonOfShreddingOrTransfer: ""
        }}
      >
        <Item
          name="reasonOfShreddingOrTransfer"
          label={t(
            translationPath(
              lang.dialog.performShreddingProcedureModal
                .reasonOfShreddingOrTransfer
            )
          )}
          rules={validationRules}
        >
          <Input onChange={onChange} />
        </Item>
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled(Form)`
  margin-top: 1rem;
`;
