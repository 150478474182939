import {
  DInject,
  DInjectable
} from "../../presentation/core/features/dependencyInjection";
import { PerformShreddingProcedureToken } from "../../domain/di/token/recordRetention";
import { PerformShreddingProcedureRepository } from "../../domain/service/recordRetention/PerformShreddingProcedureRepository";
import { PerformShreddingProcedure } from "../../domain/struct/recordRetention/PerformShreddingProcedure";

@DInjectable()
export class SavePerformShreddingProcedure {
  constructor(
    @DInject(PerformShreddingProcedureToken)
    protected repository: PerformShreddingProcedureRepository
  ) {}

  savePerformShreddingProcedure(payload: PerformShreddingProcedure) {
    return this.repository.savePerformShreddingProcedure(payload);
  }
}
