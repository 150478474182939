import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import { CheckboxWithLabel, Select, TextField } from "formik-material-ui";
import { DataboxAccount } from "presentation/core/api/databox/_types";
import {
  StyledField,
  StyledFieldFifth,
  StyledFieldThird,
  StyledFieldWide,
  StyledFormControlThird,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import FormControlWithError from "presentation/core/components/formControlWithError";
import { RootStateType } from "presentation/reducers";
import { lang, t } from "presentation/translation/i18n";
import React from "react";
import { useSelector } from "react-redux";
import { BaseField } from "../../../../../../core/components/form/fields/BaseField";
import { lastPathMember, translationPath } from "../../../../../utils/getPath";
import { CreateShipmentFormValuesProxy } from "../../_types";

interface OwnProps {
  readonly: boolean;
  onSearchSubject?: () => void;
}

export const DataboxFormFields = ({ readonly, onSearchSubject }: OwnProps) => {
  const dialogClasses = useStyles();
  const databoxAccounts = useSelector(
    (state: RootStateType) => state.databoxReducer.databoxAccounts
  );

  return (
    <>
      <div style={{ display: "flex", position: "relative", flexBasis: "33%" }}>
        <StyledFieldThird
          component={TextField}
          data-test-id="create-shipment-databox-recipient"
          disabled={true}
          required={true}
          name={
            lastPathMember(
              CreateShipmentFormValuesProxy.recipientContactDatabox
            ).path
          }
          type="text"
          label={t(translationPath(lang.general.recipientDataboxId))}
          style={{ flex: 1 }}
        />
        <CreateIcon
          style={{
            margin: "9px",
            position: "absolute",
            right: 0,
            top: ".75rem",
            cursor: "pointer"
          }}
          onClick={onSearchSubject}
        />
      </div>
      <FormControlWithError
        name={lastPathMember(CreateShipmentFormValuesProxy.sender).path}
        component={StyledFormControlThird}
      >
        <InputLabel
          required={true}
          htmlFor={lastPathMember(CreateShipmentFormValuesProxy.sender).path}
        >
          {t(translationPath(lang.general.sendFromDatabox))}
        </InputLabel>
        <BaseField
          component={Select}
          data-test-id="create-shipment-databox-sender"
          disabled={readonly}
          name={lastPathMember(CreateShipmentFormValuesProxy.sender).path}
          inputProps={{
            id: lastPathMember(CreateShipmentFormValuesProxy.sender).path
          }}
        >
          {databoxAccounts?.map((account: DataboxAccount, index: number) => {
            return (
              <MenuItem key={index} value={account.id}>
                {account.id} - {account.name}
              </MenuItem>
            );
          })}
        </BaseField>
      </FormControlWithError>
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-recipientContactDataboxId"
        disabled={readonly}
        required={false}
        name={
          lastPathMember(
            CreateShipmentFormValuesProxy.recipientContactDataboxId
          ).path
        }
        type="text"
        style={{ display: "none" }}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-databox-subject"
        disabled={readonly}
        required={true}
        name={lastPathMember(CreateShipmentFormValuesProxy.subject).path}
        type="text"
        label={t(translationPath(lang.general.subject))}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-databox-toHands"
        disabled={readonly}
        name={lastPathMember(CreateShipmentFormValuesProxy.toHands).path}
        type="text"
        label={t(translationPath(lang.general.toHands))}
      />
      <h2 className={dialogClasses.fullWidth}>
        {t(translationPath(lang.general.empowerment))}
      </h2>
      <StyledFieldFifth
        component={TextField}
        data-test-id="create-shipment-databox-legalTitleLaw"
        disabled={readonly}
        name={lastPathMember(CreateShipmentFormValuesProxy.legalTitleLaw).path}
        type="number"
        label={t(translationPath(lang.general.lawNumber))}
      />
      <StyledFieldFifth
        component={TextField}
        data-test-id="create-shipment-databox-legalTitleYear"
        disabled={readonly}
        name={lastPathMember(CreateShipmentFormValuesProxy.legalTitleYear).path}
        type="number"
        label={t(translationPath(lang.general.year))}
      />
      <StyledFieldFifth
        component={TextField}
        data-test-id="create-shipment-databox-legalTitleYear"
        disabled={readonly}
        name={lastPathMember(CreateShipmentFormValuesProxy.legalTitleSect).path}
        type="text"
        label="§"
      />
      <StyledFieldFifth
        component={TextField}
        data-test-id="create-shipment-databox-legalTitlePar"
        disabled={readonly}
        name={lastPathMember(CreateShipmentFormValuesProxy.legalTitlePar).path}
        type="number"
        label={t(translationPath(lang.general.paragraph))}
      />
      <StyledFieldFifth
        component={TextField}
        data-test-id="create-shipment-databox-legalTitlePoint"
        disabled={readonly}
        name={
          lastPathMember(CreateShipmentFormValuesProxy.legalTitlePoint).path
        }
        type="text"
        label={t(translationPath(lang.general.letter))}
      />
      <StyledField
        component={CheckboxWithLabel}
        data-test-id="create-shipment-databox-personalDelivery"
        disabled={readonly}
        name={
          lastPathMember(CreateShipmentFormValuesProxy.allowSubstDelivery).path
        }
        type="checkbox"
        Label={{
          className: dialogClasses.styledCheckboxFull,
          label: t(translationPath(lang.general.forbidFictionDelivery))
        }}
      />
      <StyledField
        component={CheckboxWithLabel}
        data-test-id="create-shipment-databox-personalDelivery"
        disabled={readonly}
        name={
          lastPathMember(CreateShipmentFormValuesProxy.personalDelivery).path
        }
        type="checkbox"
        Label={{
          className: dialogClasses.styledCheckbox,
          label: t(translationPath(lang.general.toOwnHands))
        }}
      />
    </>
  );
};
