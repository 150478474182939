import React, { useMemo, useState } from "react";
import { useGetTypeFileStructure } from "../../hooks/useGetTypeFileStructure";
import styled from "@emotion/styled";
import { Form, Item, useForm } from "@isfg/react-components/build/Form/Form";
import { t } from "../../../../../../translation/i18n";
import { translationPath } from "../../../../../../share/utils/getPath";
import lang from "../../../../../../translation/lang";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { TypeFilesContentTable } from "./TypeFilesContentTable";
import { useCloseSectionOfTypeFile } from "./useCloseSectionOfTypeFile";
import { useClosePartOfTypeFile } from "./useClosePartOfTypeFile";
import Loading from "presentation/core/components/dataTable/components/Loading";
import { RemoteTableApi } from "presentation/govDesignSystem/Table/RemoteTable";
import moment from "moment";
import { Select } from "presentation/designSystem/Select/Select";
export interface TypeFilesContentTabProps {
  nodeId: string;
  isOpenContent?: boolean;
  refreshOpenTypeFiles?: RemoteTableApi["refetch"];
}
export interface ContentTypeFileParams {
  sectionId: string;
  partId: string;
}

export const TypeFilesContentTab: React.FC<TypeFilesContentTabProps> = ({
  nodeId,
  isOpenContent,
  refreshOpenTypeFiles
}) => {
  const { data, refetch } = useGetTypeFileStructure(nodeId);
  const [sectionId, setSectionId] = useState<string>("");
  const [partId, setPartId] = useState<string>("");
  const [form] = useForm<ContentTypeFileParams>();

  const section = useMemo(
    () => data?.find((item) => item.nodeId === sectionId),
    [data, sectionId]
  );
  const part = useMemo(
    () => section?.parts.find((item) => item.nodeId === partId),
    [section, partId]
  );

  const handleSectionChange = (sectionId: string) => {
    form.setFieldsValue({ partId: "" });

    setSectionId(sectionId);
    setPartId("");
  };

  const handlePartChange = (partId: string) => {
    setPartId(partId);
  };

  const onCloseSuccess = () => {
    refetch();
    refreshOpenTypeFiles?.();
  };

  const {
    isLoading: lockSectionOfTypeFileLoading,
    button: lockSectionOfTypeFileButton
  } = useCloseSectionOfTypeFile({
    nodeId,
    sectionId,
    section,
    onCloseSuccess
  });

  const {
    lockPartTypeFileModal,
    closePartButton,
    validateClosePartButton
  } = useClosePartOfTypeFile({
    nodeId,
    partId,
    part,
    onCloseSuccess: refetch
  });

  return (
    <>
      {lockPartTypeFileModal}
      {lockSectionOfTypeFileLoading ? <Loading /> : null}
      <Form form={form}>
        <Row gutter={16}>
          <Col span={22}>
            <StyledSelectItem
              name="sectionId"
              label={t(translationPath(lang.table.openTypeFilesTable.section))}
              rules={[{ required: true }]}
              required={true}
            >
              <Select style={{ width: "100%" }} onChange={handleSectionChange}>
                {data &&
                  data.map((item) => (
                    <Select.Option key={item.nodeId} value={item.nodeId}>
                      {item.subject}
                    </Select.Option>
                  ))}
              </Select>
            </StyledSelectItem>
          </Col>
          {isOpenContent && (
            <Col span={2} style={{ paddingTop: "30px" }}>
              {lockSectionOfTypeFileButton}
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={22}>
            <StyledSelectItem
              name="partId"
              label={t(translationPath(lang.table.openTypeFilesTable.part))}
              rules={[{ required: true }]}
              required={true}
            >
              <Select
                style={{ width: "100%" }}
                onChange={handlePartChange}
                disabled={!section || section.parts.length === 0}
              >
                {section &&
                  section.parts.map((item) => (
                    <Select.Option key={item.nodeId} value={item.nodeId}>
                      {item.subject +
                        " - " +
                        moment(item.createdAt).format("DD.MM.YYYY")}
                    </Select.Option>
                  ))}
              </Select>
            </StyledSelectItem>
          </Col>
          {isOpenContent && (
            <Col
              span={2}
              style={{
                paddingTop: "30px",
                cursor: "pointer",
                color: Boolean(!partId) ? "#00000040" : "black"
              }}
            >
              {closePartButton}
            </Col>
          )}
        </Row>
      </Form>
      <div>
        <TypeFilesContentTable
          nodeId={nodeId}
          sectionId={sectionId}
          partId={partId}
          isOpenContent={isOpenContent || false}
          validateClosePartButton={validateClosePartButton}
        />
      </div>
    </>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;
