import React, { useCallback, useMemo, useState } from "react";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { BreadcrumbForTable } from "presentation/designSystem/Breadcrumb/Breadcrumb";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import { TableRowSelection } from "antd/lib/table/interface";
import {
  ColumnType,
  TableActions
} from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import { DateTimeFormat } from "lib/dateTime";
import styled from "@emotion/styled";
import { Description } from "@material-ui/icons";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { getClosedTypeFiles } from "../api";
import { FilePlanColumnValue } from "presentation/share/components/table/column/document/FilePlan/FilePlanColumnValue";
import { FileMarkColumnValue } from "presentation/share/components/table/column/document/FilePlan/FileMarkColumnValue";
import { useGetTypeFileClose } from "../hooks/useGetTypeFileClose";
import { ClosedTypeFilesResponse } from "../types";

const COLUMNS: ColumnType<ClosedTypeFilesResponse>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.table.closedTypeFilesTable.pid)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.table.closedTypeFilesTable.subject)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "createdAt",
      title: t(translationPath(lang.table.closedTypeFilesTable.createdAt)),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createDateTimeColumn(
    {
      dataIndex: "closureDate",
      title: t(translationPath(lang.table.closedTypeFilesTable.closureDate)),
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "filePlanId",
    title: t(translationPath(lang.table.closedTypeFilesTable.filePlanId)),
    render: (text, row) => {
      const { filePlanId } = row;
      if (!filePlanId) {
        return null;
      }
      return <FilePlanColumnValue filePlanId={filePlanId} />;
    }
  }),

  createColumn({
    dataIndex: "fileMark",
    title: t(translationPath(lang.table.closedTypeFilesTable.fileMark)),
    render: (text, row) => {
      const { fileMark } = row;
      if (!fileMark) {
        return null;
      }
      return <FileMarkColumnValue fileMarkId={fileMark} />;
    }
  })
];
interface ClosedTypeFilesTableProps {
  remoteQueryParams?: Record<string, string>;
}

export const ClosedTypeFilesTable = ({
  remoteQueryParams
}: ClosedTypeFilesTableProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");

  const {
    modal: closeTypeFileDetailModal,
    open: openCloseTypeFileDetailModal,
    data: openTypeFileDetailData
  } = useGetTypeFileClose(selectedId);

  const ACTIONS: TableActions<ClosedTypeFilesResponse> = useMemo(
    () => ({
      single: [
        {
          key: "detail-closed-type-file",
          action: (selected: ClosedTypeFilesResponse[]) => {
            if (selected[0]?.nodeId === selectedId) {
              openCloseTypeFileDetailModal({
                document: openTypeFileDetailData!
              });
            } else {
              setSelectedId(selected[0]?.nodeId ?? "");
            }
          },
          icon: <Description />,
          tooltip: t(
            translationPath(lang.table.closedTypeFilesTable.typeFileDetail)
          )
        }
      ]
    }),
    [openTypeFileDetailData, openCloseTypeFileDetailModal, selectedId]
  );

  const onRowSelection: TableRowSelection<ClosedTypeFilesResponse> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  const getRemoteData: GetRemoteDataClb<ClosedTypeFilesResponse> = useCallback(
    ({ pagination, sorting }) => getClosedTypeFiles(pagination, sorting),
    []
  );

  return (
    <MenuLayout>
      <>
        {closeTypeFileDetailModal}
        <BreadcrumbForTable
          title={t(translationPath(lang.breadcrumb.closedTypeFiles))}
        />
        <TableStyled>
          <RemoteTable<ClosedTypeFilesResponse>
            name="evidence/ClosedTypeFiles"
            rowKey="pid"
            actions={ACTIONS}
            columns={COLUMNS}
            getRemoteData={getRemoteData}
            remoteQueryParams={remoteQueryParams}
            rowSelection={onRowSelection}
          />
        </TableStyled>
      </>
    </MenuLayout>
  );
};

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
