import React, { useState } from "react";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { useMutation } from "../../../hook/query/useMutation";
import {
  convertAnalogToDigital,
  ConvertAnalogToDigitalContent
} from "../../../../core/api/document/actions";
import { useDispatch } from "react-redux";
import { documentViewAction__Refresh } from "../../../../core/components/documentView/_actions";
import { GenericDocument } from "../../../../core/types";
import {
  DocumentState,
  DocumentType,
  SafetyElementAnalog
} from "../../../../enums";
import { downloadDocument } from "../../../utils/downloadDocument";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Row } from "presentation/designSystem/Grid/Grid";
import styled from "@emotion/styled";
import { Select } from "presentation/designSystem/Select/Select";
import { InputNumber } from "presentation/designSystem/Input/Input";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { ComponentUploadedFilename } from "../../../../core/components/dialog/tabs/components/ComponentUploadedFilename";
import { positiveNumbers } from "presentation/designSystem/Input/InputValidations";
export interface ConvertAnalogToDigitalModalProps extends BaseModalProps {
  nodeId: string;
}

export const isDocumentAvailableForAnalogToDigitalConversion = (
  document: GenericDocument
): boolean => {
  const { form, state = "" } = document?.properties?.ssl || {};
  return (
    form === DocumentType.Analog &&
    [DocumentState.NotSettled.toString()].includes(state)
  );
};

export const ConvertAnalogToDigitalModal = ({
  nodeId,
  onOk,
  onCancel
}: ConvertAnalogToDigitalModalProps) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });
  const [file, setFile] = useState<File[]>();
  const { isLoading, mutate: convertDocument } = useMutation(
    (params: ConvertAnalogToDigitalContent) => convertAnalogToDigital(params),
    {
      onSuccess: ({ responseHeaders, response }) => {
        if (responseHeaders && response) {
          downloadDocument(response, responseHeaders, "application/pdf");
        }
        onOk?.();
        dispatch(documentViewAction__Refresh(true));
      },
      onErrorNotification: getNotificationForError || {
        message: t(
          translationPath(lang.dialog.notifications.documentForSealFormFailed)
        )
      }
    }
  );

  const handleOnOk = async () => {
    try {
      await form.validateFields();

      const {
        pageCount,
        safetyElements,
        uploadedComponent
      } = form.getFieldsValue();
      convertDocument({
        nodeId,
        pageCount,
        safetyElements,
        file: uploadedComponent
      });
    } catch {}
  };

  const handleUploadComponent = (files: File[]) => {
    setFile([...files]);
    form.setFieldsValue({ uploadedComponent: files[0] });
  };

  return (
    <Modal
      visible={true}
      title={t(translationPath(lang.dialog.convertions.analogToDigital.title))}
      onCancel={onCancel}
      onOk={handleOnOk}
      className={""}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      confirmLoading={isLoading}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <Form form={form}>
        <Row align="bottom" justify="space-between">
          <Item
            name="pageCount"
            label={t(translationPath(lang.general.listCount))}
            required={true}
            style={{ width: "15rem" }}
            rules={[{ required: true }, positiveNumbers]}
          >
            <InputNumber
              type="number"
              min={1}
              max={99999}
              data-test-id="convertAnalogToDigital"
              style={{ width: "15rem" }}
            />
          </Item>

          <StyledSelectItem
            name="safetyElements"
            label={t(translationPath(lang.general.safertyElements))}
          >
            <Select mode="multiple" style={{ width: "15rem" }}>
              {Object.keys(SafetyElementAnalog).map((key) => (
                <Select.Option key={key} value={key}>
                  {SafetyElementAnalog[key]}
                </Select.Option>
              ))}
            </Select>
          </StyledSelectItem>
        </Row>
        <Item
          name="uploadedComponent"
          rules={[
            {
              required: true,
              message: t(
                translationPath(lang.dialog.notifications.addComponent)
              )
            }
          ]}
        >
          <ComponentUploadedFilename
            handleUploadComponent={handleUploadComponent}
            file={file}
            allowedExtensions={["pdf"]}
          />
        </Item>
      </Form>
    </Modal>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;
