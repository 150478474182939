import React from "react";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { DocumentForSealForm } from "../_types";
import DocumentsForSealForm from "../forms/DocumentsForSealForm";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { useForm } from "presentation/designSystem/Form/v2/Form";
import { useMutation } from "presentation/share/hook/query/useQM";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { postDocumentForSeal } from "../api";
import { documentViewAction__Refresh } from "../../../../../../core/components/documentView/_actions";
import { useDispatch } from "react-redux";

export interface RetentionProposalCancelProps extends BaseModalProps {
  nodeId?: string;
}
export const DocumentsForSealModal = ({
  nodeId,
  onOk,
  onCancel
}: RetentionProposalCancelProps) => {
  const dispatch = useDispatch();
  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });

  const [documentForSealForm] = useForm<DocumentForSealForm>();

  const { isLoading, mutate: documentForSeal } = useMutation(
    (nodeId: string) =>
      postDocumentForSeal(nodeId, documentForSealForm.getFieldsValue()),
    {
      onSuccess() {
        dispatch(documentViewAction__Refresh(true));
        onOk?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(
            lang.dialog.notifications.documentForSealFormSucceeded
          )
        )
      },
      onErrorNotification: getNotificationForError || {
        message: t(
          translationPath(lang.dialog.notifications.documentForSealFormFailed)
        )
      }
    }
  );

  const handleOk = async () => {
    try {
      await documentForSealForm.validateFields();
      documentForSeal(nodeId!);
    } catch {}
  };

  return (
    <Modal
      visible={true}
      title={t(translationPath(lang.dialog.forSeal.title))}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={isLoading}
      className={""}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <DocumentsForSealForm form={documentForSealForm}></DocumentsForSealForm>
    </Modal>
  );
};
