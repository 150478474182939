import { Check, Delete } from "@material-ui/icons";
import { openDocumentDetailsAction } from "presentation/core/api/document/_actions";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import DocumentView from "presentation/core/components/documentView";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { GenericDocument, genericDocumentProxy } from "presentation/core/types";
import { DocumentType, SitePaths, SpisumNodeTypes } from "presentation/enums";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { documentRegisterAction } from "../income/_actions";
import { DisplaySenderNameByType } from "../../../../share/components/table/column/DisplaySenderNameByType";
import { useGetUnfinishedDocument } from "./hooks/useGetUnfinishedDocument";

const defaultColumn: DataColumn<GenericDocument> = {
  isDateTime: true,
  keys: [classPath(genericDocumentProxy.properties!.ssl!.deliveryDate).path],
  label: t(translationPath(lang.general.deliveryDate))
};

export const columns: DataColumn<GenericDocument>[] = [
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.pid).path],
    label: t(translationPath(lang.general.identifier))
  },
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.subject).path],
    label: t(translationPath(lang.general.subject))
  },
  {
    getValue: (row) => <DisplaySenderNameByType ssl={row?.properties?.ssl} />,
    keys: [
      classPath(genericDocumentProxy.properties!.ssl!.senderCompanyName).path,
      classPath(genericDocumentProxy.properties!.ssl!.senderFullName).path
    ],
    label: t(translationPath(lang.general.sender))
  },
  defaultColumn,
  {
    getValue: (x) =>
      x.properties?.ssl?.form === DocumentType.Analog
        ? x.properties!.ssl!.attachmentsCount
        : x.properties!.ssl!.associationCount,
    keys: [
      classPath(genericDocumentProxy.properties!.ssl!.attachmentsCount).path,
      classPath(genericDocumentProxy.properties!.ssl!.associationCount).path
    ],
    label: t(translationPath(lang.general.attachmentsCount))
  }
];

const Unfinished = () => {
  const relativePath = useSelector((state: RootStateType) =>
    getRelativePath(
      state.loginReducer.global.paths,
      null,
      SitePaths.Mailroom,
      SitePaths.Unfinished
    )
  );
  const dispatch = useDispatch();

  const onDoubleClick = (row: GenericDocument) => {
    register({
      id: row.id,
      documentType: row.properties?.ssl?.documentType!
    });
  };

  const { modal, register } = useGetUnfinishedDocument();

  const controls: ControlsBarType<GenericDocument> = {
    single: {
      items: [
        {
          action: (selected: GenericDocument[]) => {
            register({
              id: selected[0].id,
              documentType: selected[0].properties?.ssl?.documentType!
            });
          },
          icon: <Check />,
          title: t(translationPath(lang.general.register))
        }
      ],
      more: [
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.Cancel
              })
            );
          },
          icon: <Delete />,
          title: t(translationPath(lang.general.cancel))
        }
      ]
    }
  };

  return (
    <>
      {modal}
      <MenuLayout>
        <DocumentView
          children={{
            relativePath,
            where: `(nodeType='${SpisumNodeTypes.Document}')`
          }}
          columns={columns}
          controls={controls}
          customTitle={t(translationPath(lang.menu.items.unfinished))}
          defaultSortAsc={true}
          defaultSortColumn={defaultColumn}
          handleDoubleClick={onDoubleClick}
        />
      </MenuLayout>
    </>
  );
};

export default withTranslation()(Unfinished);
