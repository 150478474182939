import { theme as defaultTheme } from "@isfg/react-components/build/theme/default";
import { merge } from "../../lib/object";

const colorPalette = {
  black: "#000",
  orange: "#ff9600",
  white: "#fff",
  greyLight: "#fafafa",
  error: "#f44336",
  gray: "#848788",
  lightGray: "#d7d7d7",
  primary: "#141E1E",
  warning: "#ff9824",
  whiteOpacity: "rgba(0,0,0,0.87)"
};

const themeColors = {
  primary: colorPalette.orange,
  secondary: colorPalette.black,
  text: {
    secondary: colorPalette.white
  },
  background: {
    light: colorPalette.greyLight
  }
};

export const theme = merge(defaultTheme, {
  color: {
    ...themeColors
  },
  modal: {
    tabs: {
      nav: {
        bgColor: themeColors.background.light
      }
    }
  }
});
