import {
  Input,
  RemoveCircleOutlineOutlined,
  Save,
  Visibility
} from "@material-ui/icons";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { File } from "presentation/core/entities";
import { ReadonlyControlsType } from "presentation/core/components/dialog/tabs/components/_types";
import { ComponentUpload } from "presentation/core/components/dialog/tabs/components/ComponentUpload";
import { ControlsTypeV2 } from "./_types";

export const controls: ControlsTypeV2 = ({
  handleCanConvertToOutputFormat,
  handleCanCreateComponent,
  handleCanDeleteComponent,
  handleCanShowPreview,
  handleCanUploadNewVersion,
  handleConvertToOutputFormat,
  handleDeleteComponent,
  handleDownloadComponent,
  handleShowPreview,
  handleSwapComponentContent,
  handleUploadComponent
}) => {
  return {
    default: {
      items: [
        {
          filter: () => handleCanCreateComponent(),
          icon: () => (
            <ComponentUpload
              handleUploadComponent={handleUploadComponent}
              title={t(translationPath(lang.general.add))}
            />
          )
        }
      ]
    },
    multi: {
      items: [
        ...(handleDeleteComponent
          ? [
              {
                action: handleDeleteComponent,
                filter: (file: File) => handleCanDeleteComponent(file),
                icon: <RemoveCircleOutlineOutlined />,
                title: t(translationPath(lang.general.remove))
              }
            ]
          : []),

        {
          action: handleDownloadComponent,
          icon: <Save data-test-id="tab-download" />,
          title: t(translationPath(lang.general.download))
        }
      ]
    },
    single: {
      items: [
        ...(handleDeleteComponent
          ? [
              {
                action: handleDeleteComponent,
                filter: (file: File) => handleCanDeleteComponent(file),
                icon: <RemoveCircleOutlineOutlined />,
                title: t(translationPath(lang.general.remove))
              }
            ]
          : []),
        {
          action: handleDownloadComponent,
          icon: <Save data-test-id="tab-download" />,
          title: t(translationPath(lang.general.download))
        },
        {
          action: handleShowPreview,
          filter: handleCanShowPreview,
          icon: <Visibility data-test-id="tab-show-preview" />,
          title: t(translationPath(lang.general.showPreview))
        },
        /* V2
        {
          action: handleRenameComponent,
          filter: (file) => handleCanRenameComponent(),
          icon: <Edit />,
          title: t(translationPath(lang.general.rename))
        },
        */
        {
          action: handleConvertToOutputFormat,
          filter: handleCanConvertToOutputFormat,
          icon: <Input data-test-id="tab-convert" />,
          title: t(translationPath(lang.general.convertToOutputFormat)),
          validation: (source) => {
            return source[0]?.isEncrypted
              ? t(translationPath(lang._validations.componentEncrypted))
              : null;
          }
        }
      ]
    }
  };
};

export const readOnlyControls: ReadonlyControlsType = ({
  handleCanShowPreview,
  handleShowPreview,
  handleDownloadComponent
}) => ({
  multi: {
    items: [
      {
        action: handleDownloadComponent,
        icon: <Save data-test-id="tab-download" />,
        title: t(translationPath(lang.general.download))
      }
    ]
  },
  single: {
    items: [
      {
        action: handleDownloadComponent,
        icon: <Save data-test-id="tab-download" />,
        title: t(translationPath(lang.general.download))
      },
      {
        action: handleShowPreview,
        filter: handleCanShowPreview,
        icon: <Visibility data-test-id="tab-show-preview" />,
        title: t(translationPath(lang.general.showPreview))
      }
    ]
  }
});
