import {
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikProps,
  useFormikContext
} from "formik";
import React, { useEffect } from "react";

export type FormState<Values> = FormikProps<Values>;
export type FormInstance<T> = FormState<T> | null;
export type FormHelpers<T> = FormikHelpers<T>;
export type SetFormInstanceClb<T> = (form: FormInstance<T>) => void;
export type OnFormDataChanged<T> = (formState: FormState<T>) => void;
export type OnSubmit<T> = (
  values: T,
  helpers: FormHelpers<T>
) => void | Promise<any>;
export interface FormProps<T> extends FormikConfig<T> {}

export function Form_<Values>(props: FormProps<Values>) {
  return <Formik {...props} />;
}

// This is helper component which triggers validation of formik
// on every values change.
// It is necessary when working with the setFieldValue function.
// Because of the current formik architecture the validations after using the setFieldValue
// are using the old form values. So it can mark a field as invalid even when it has valid value.
//
// @see https://github.com/formium/formik/issues/2083
export const Validator = () => {
  const { values, validateForm } = useFormikContext();

  useEffect(() => {
    validateForm(values);
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export const Form = React.memo(Form_) as typeof Form_;
