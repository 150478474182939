import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { History } from "../../domain/struct/history/History";
import { ConceptHistoryRepositoryToken } from "domain/di/token/concept";
import { ConceptHistoryRepository } from "domain/service/concept/ConceptHistoryRepository";
import { ConceptId } from "domain/struct/concept/Concept";
import { SortingConfig } from "lib/contract/Sorting";

@DInjectable()
export class FindConceptHistory {
  constructor(
    @DInject(ConceptHistoryRepositoryToken)
    protected readonly repository: ConceptHistoryRepository
  ) {}
  findPaginated(
    id: ConceptId,
    paginationConfig: PaginationConfig,
    sortingConfig?: SortingConfig
  ): Promise<PaginatedResult<History>> {
    return this.repository.findPaginated(id, paginationConfig, sortingConfig);
  }
}
