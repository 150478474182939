import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../lib/contract/Pagination";
import { RetentionProtocolsOrProposalsRepositoryTokenContent } from "../../domain/di/token/recordRetention";
import { RetentionProtocolsOrProposalsContentRepository } from "../../domain/service/recordRetention/RetentionProtocolsOrProposalsContentRepository";

@DInjectable()
export class FindRetentionProtocolsOrProposalsContent {
  constructor(
    @DInject(RetentionProtocolsOrProposalsRepositoryTokenContent)
    protected retentionProtocolOrProposalContentRepository: RetentionProtocolsOrProposalsContentRepository
  ) {}

  findAllPaginated(nodeId: string, config: PaginationConfig) {
    return this.retentionProtocolOrProposalContentRepository.findAllPaginated(
      nodeId,
      config
    );
  }
}
