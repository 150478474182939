import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { AddressRepositoryToken } from "../../../domain/di/token/nameRegistry";
import { AddressRepository } from "../../../domain/service/nameRegistry/AddressRepository";
import { Address } from "../../../domain/struct/nameRegistry/Address";
import { applyAddressRules } from "../../../domain/service/nameRegistry/address/addressDomainRules";

@DInjectable()
export class SaveAddress {
  constructor(
    @DInject(AddressRepositoryToken) protected repository: AddressRepository
  ) {}

  create(subjectId: string, address: Address) {
    const addressToUpdate = applyAddressRules(address);
    return this.repository.create(subjectId, addressToUpdate);
  }
  update(subjectId: string, address: Address) {
    const addressToUpdate = applyAddressRules(address);
    return this.repository.update(subjectId, addressToUpdate);
  }
  delete(subjectId: string, address: Address) {
    return this.repository.delete(subjectId, address);
  }
}
