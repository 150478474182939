import React from "react";
import { FormInstance } from "presentation/designSystem/Form/v2/Form";
import moment from "moment";
import {
  SenderFormFieldsValues,
  SenderFormNewProps
} from "presentation/share/components/form/SenderFormAnt";
import { EvidenceDocumentDetails } from "../_types";
import { DocumentType, SenderType } from "presentation/enums";
import { DigitalDocumentDetailsForm } from "./DigitalDocumentDetailsForm";
import { DigitalOwnDocumentDetailsForm } from "./DigitalOwnDocumentDetailsForm";
import { AnalogDocumentDetailsForm } from "./AnalogDocumentDetailsForm";
import { AnalogOwnDocumentDetailsForm } from "./AnalogOwnDocumentDetailsForm";

interface DocumentDetailsFormProps {
  formData: EvidenceDocumentDetails;
  form: FormInstance<DocumentDetailsFormValues>;
  onSenderChange: SenderFormNewProps["onSenderChange"];
  isReadonly: SenderFormNewProps["isReadonly"];
}

export interface DocumentDetailsFormValues
  extends Omit<EvidenceDocumentDetails, "deliveryDate" | "settleToDate">,
    SenderFormFieldsValues {
  deliveryDate?: moment.Moment;
  settleToDate?: moment.Moment;
}

const FORM = {
  [DocumentType.Analog]: {
    [SenderType.Individual]: AnalogDocumentDetailsForm,
    [SenderType.Legal]: AnalogDocumentDetailsForm,
    [SenderType.Own]: AnalogOwnDocumentDetailsForm
  },

  [DocumentType.Digital]: {
    [SenderType.Individual]: DigitalDocumentDetailsForm,
    [SenderType.Legal]: DigitalDocumentDetailsForm,
    [SenderType.Own]: DigitalOwnDocumentDetailsForm
  },
  [DocumentType.AD]: {
    [SenderType.Individual]: DigitalDocumentDetailsForm,
    [SenderType.Legal]: DigitalDocumentDetailsForm,
    [SenderType.Own]: DigitalOwnDocumentDetailsForm
  }
};

export const DocumentDetailsForm = ({
  formData,
  ...props
}: DocumentDetailsFormProps) => {
  const form = formData.form ?? DocumentType.Digital;
  const senderType = formData.sender?.type ?? SenderType.Individual;

  const Form =
    senderType && form ? FORM[form][senderType] : DigitalDocumentDetailsForm;

  return <Form {...props} formData={formData} />;
};
