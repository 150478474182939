import { green } from "@material-ui/core/colors";
import {
  CreateNewFolder,
  Delete,
  Description,
  Gesture,
  Mail,
  NextWeek,
  Send,
  Whatshot
} from "@material-ui/icons";
import { ControlsBarType } from "presentation/core/components/dataTable/_types";
import { FileMetaType } from "presentation/core/entities";
import { GenericDocument, genericDocumentProxy } from "presentation/core/types";
import { DocumentType, SenderType } from "presentation/enums";
import React from "react";
import {
  classPath,
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { isEmptyString } from "presentation/share/utils/utils";
import { validateItems } from "presentation/share/utils/validation";
import { lang, t } from "presentation/translation/i18n";
import * as yup from "yup";
import { ControlsType } from "./_types";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import { isDocumentAvailableForDigitalToAnalogConversion } from "../../../../../share/components/modals/conversions/ConvertDigitalToAnalogModal";
import { FileSyncOutlined } from "@ant-design/icons";
import { isDocumentAvailableForAnalogToDigitalConversion } from "../../../../../share/components/modals/conversions/ConvertAnalogToDigitalModal";
import { getActiveComponentsForMainActions } from "presentation/core/components/dialog/tabs/components/api";
import { SortDirection } from "../../../../../govDesignSystem/lib/contract/Sorting";

export const getControls: ControlsType = async ({
  handleOpenDescription,
  handleCreateNewDocumentFile,
  handleHandoverDocument,
  handleForSignatureDocument,
  handleForSealDocument,
  handleSendShipment,
  handleOpenSettleDocumentDialog,
  handleOpenLostDestroyedDocumentDialog,
  handleOpenCancelDialog,
  handleConvertDigitalToAnalog,
  handleConvertAnalogToDigital,
  userId
}): Promise<ControlsBarType<GenericDocument>> => {
  return {
    /*
   multi: {
      items: [
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Add,
                items: selected,
                nodeType: SpisumNodeTypes.Document
              })
            );
          },
          filter: (x) => !x.isFavorite,
          icon: <Star />,
          title: t(translationPath(lang.general.bookmark))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Remove,
                items: selected,
                nodeType: SpisumNodeTypes.Document
              })
            );
          },
          filter: (x) => !!x.isFavorite,
          icon: <StarBorderOutlined />,
          title: t(translationPath(lang.general.bookmarkRemove))
        }
      ]
    },
    */
    single: {
      items: [
        {
          action: handleOpenDescription,
          icon: <Description data-test-id="node-show-details" />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: handleCreateNewDocumentFile,
          icon: <CreateNewFolder data-test-id="node-paste-to-file" />,
          title: t(translationPath(lang.general.pasteToFile))
        },
        {
          action: handleHandoverDocument,
          icon: <Send data-test-id="node-hand-over" />,
          title: t(translationPath(lang.general.handOVer))
        },
        /*
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Add,
                items: selected,
                nodeType: SpisumNodeTypes.Document
              })
            );
          },
          filter: (x) => !x.isFavorite,
          icon: <Star />,
          title: t(translationPath(lang.general.bookmark))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Remove,
                items: selected,
                nodeType: SpisumNodeTypes.Document
              })
            );
          },
          filter: (x) => !!x.isFavorite,
          icon: <StarBorderOutlined />,
          title: t(translationPath(lang.general.bookmarkRemove))
        },
        */
        {
          action: handleForSignatureDocument,
          filter: (document) =>
            (document?.properties?.ssl?.form === DocumentType.Digital ||
              document?.properties?.ssl?.form === DocumentType.AD ||
              document?.properties?.ssl?.form === DocumentType.DA) &&
            document?.properties?.ssl?.senderType === SenderType.Own,
          icon: <Gesture />,
          title: t(translationPath(lang.general.toSign)),
          validation: async (items: GenericDocument[]) => {
            let isValid = true;
            const checkTheComponent = await getActiveComponentsForMainActions(
              items[0]?.id,
              {
                page: 1,
                itemsPerPage: 100
              },
              [
                {
                  property: "name",
                  direction: SortDirection.Ascending
                }
              ]
            ).then((result) => {
              if (result.items.length > 0) {
                isValid = result.items.every(
                  (x) => x.isInOutputFormat !== "no"
                );
                const settle = result.items.filter(
                  (item) => item.type === FileMetaType.main && item.isReadable
                );

                if (settle.length > 0) {
                  return false; //t(translationPath(lang._validations.componentsForDatabox));
                }
              }
              return true;
            });

            const validationMessages = [];

            if (!isValid) {
              validationMessages.push(
                t(translationPath(lang._validations.componentsNotInOutputMode))
              );
            }

            if (checkTheComponent) {
              validationMessages.push(
                t(translationPath(lang._validations.componentsForOwnSign))
              );
            }

            return validationMessages.join("\n");
          }
        },
        {
          action: handleForSealDocument,
          filter: (document) =>
            (document?.properties?.ssl?.form === DocumentType.Digital ||
              document?.properties?.ssl?.form === DocumentType.AD ||
              document?.properties?.ssl?.form === DocumentType.DA) &&
            document?.properties?.ssl?.senderType === SenderType.Own,
          icon: <BrightnessHighIcon />,
          title: t(translationPath(lang.general.forSeal)),
          validation: async (items: GenericDocument[]) => {
            let isValid = true;
            const checkTheComponent = await getActiveComponentsForMainActions(
              items[0]?.id,
              {
                page: 1,
                itemsPerPage: 100
              },
              [
                {
                  property: "name",
                  direction: SortDirection.Ascending
                }
              ]
            ).then((result) => {
              if (result.items.length > 0) {
                isValid = result.items.every(
                  (x) => x.isInOutputFormat !== "no"
                );
                const settle = result.items.filter(
                  (item) => item.type === FileMetaType.main && item.isReadable
                );

                if (settle.length > 0) {
                  return false; //t(translationPath(lang._validations.componentsForDatabox));
                }
              }
              return true;
            });

            const validationMessages = [];

            if (!isValid) {
              validationMessages.push(
                t(translationPath(lang._validations.componentsNotInOutputMode))
              );
            }

            if (checkTheComponent) {
              validationMessages.push(
                t(translationPath(lang._validations.componentsForOwnSeal))
              );
            }

            return validationMessages.join("\n");
          }
        },
        /* V2
        {
          action: () => {},
          icon: <HowToReg />,
          title: t(translationPath(lang.general.toApprove))
        },
        */
        {
          action: handleSendShipment,
          icon: <Mail data-test-id="node-manage-shipments" />,
          title: t(translationPath(lang.general.manageShipments))
        },
        {
          action: handleConvertDigitalToAnalog,
          filter: (document: GenericDocument) => {
            return isDocumentAvailableForDigitalToAnalogConversion(document);
          },
          validation: (document: GenericDocument[]) => {
            if (document[0]?.properties?.ssl?.fileIsEncrypted) {
              return t(
                translationPath(lang._validations.documentHasEncryptedComponent)
              );
            }
            if (!document[0]?.properties?.ssl?.fileMark) {
              return t(
                translationPath(
                  lang.dialog.convertions.digitalToAnalog.missingFilePlan
                )
              );
            }
            return null;
          },
          icon: (
            <FileSyncOutlined
              style={{ fontSize: "17px" }}
              data-test-id="digital-to-analog"
            />
          ),
          title: t(translationPath(lang.general.convertDA))
        },
        {
          action: handleConvertAnalogToDigital,
          filter: (selected: GenericDocument) => {
            return isDocumentAvailableForAnalogToDigitalConversion(selected);
          },
          validation: (selected: GenericDocument[]) => {
            if (!selected[0]?.properties?.ssl?.fileMark) {
              return t(
                translationPath(
                  lang.dialog.convertions.analogToDigital.missingFilePlan
                )
              );
            }
            return null;
          },
          icon: (
            <FileSyncOutlined
              style={{ fontSize: "17px" }}
              data-test-id="analog-to-digital"
            />
          ),
          title: t(translationPath(lang.general.convertAD))
        },
        {
          action: handleOpenSettleDocumentDialog,
          icon: <NextWeek style={{ color: green[500] }} />,
          title: t(translationPath(lang.general.settle)),
          validation: async (items: GenericDocument[]) => {
            if (items[0]?.properties?.ssl?.fileIsEncrypted) {
              return t(
                translationPath(lang._validations.documentHasEncryptedComponent)
              );
            }
            if (items[0]?.properties?.ssl?.senderType === SenderType.Own) {
              const isComponent = await getActiveComponentsForMainActions(
                items[0]?.id,
                {
                  page: 1,
                  itemsPerPage: 100
                },
                [
                  {
                    property: "name",
                    direction: SortDirection.Ascending
                  }
                ]
              ).then((result) => result.items.length > 0);
              if (!isComponent) {
                return t(translationPath(lang._validations.requiredComponent));
              }
            }

            if (
              items[0]?.properties?.ssl?.documentType ===
                DocumentType.Databox ||
              items[0]?.properties?.ssl?.documentType ===
                DocumentType.TechnicalDataCarries
            ) {
              const checkTheComponent = await getActiveComponentsForMainActions(
                items[0]?.id,
                {
                  page: 1,
                  itemsPerPage: 100
                },
                [
                  {
                    property: "name",
                    direction: SortDirection.Ascending
                  }
                ]
              ).then((result) => {
                if (result.items.length > 0) {
                  const settle = result.items.filter(
                    (item) => item.type === FileMetaType.main && item.isReadable
                  );
                  if (settle.length > 0) {
                    return false; //t(translationPath(lang._validations.componentsForDatabox));
                  }
                  if (
                    items[0]?.properties?.ssl?.documentType ===
                    DocumentType.TechnicalDataCarries
                  ) {
                    return true;
                  }
                } else if (
                  result.items.length <= 0 &&
                  items[0]?.properties?.ssl?.documentType ===
                    DocumentType.TechnicalDataCarries
                ) {
                  return false;
                }
                return true;
              });
              if (checkTheComponent) {
                if (
                  items[0]?.properties?.ssl?.documentType ===
                  DocumentType.Databox
                ) {
                  return t(
                    translationPath(lang._validations.componentsForDatabox)
                  );
                } else {
                  return t(
                    translationPath(
                      lang._validations.componentsForTechnicalDataCarrier
                    )
                  );
                }
              } else {
                return validateItems(items, {
                  [classPath(genericDocumentProxy.properties!.ssl!.pid)
                    .path]: yup
                    .string()
                    .required(
                      t(translationPath(lang._validations.requiredPid))
                    ),
                  [classPath(genericDocumentProxy.properties!.ssl!.ssid)
                    .path]: yup
                    .string()
                    .required(
                      t(translationPath(lang._validations.requiredSsid))
                    ),
                  [classPath(genericDocumentProxy.properties!.ssl!.subject)
                    .path]: yup
                    .string()
                    .required(
                      t(translationPath(lang._validations.requiredSubject))
                    ),
                  [classPath(
                    genericDocumentProxy.properties!.ssl!.senderFullName
                  ).path]: yup
                    .string()
                    .test(
                      "oneOfRequired",
                      t(translationPath(lang._validations.requiredSender)),
                      function () {
                        return (
                          !isEmptyString(
                            this.parent[
                              lastPathMember(
                                genericDocumentProxy.properties!.ssl!
                                  .senderCompanyName
                              ).path
                            ]
                          ) ||
                          !isEmptyString(
                            this.parent[
                              lastPathMember(
                                genericDocumentProxy.properties!.ssl!
                                  .senderFullName
                              ).path
                            ]
                          ) ||
                          !isEmptyString(
                            this.parent[
                              lastPathMember(
                                genericDocumentProxy.properties!.ssl!.senderType
                              ).path
                            ]
                          )
                        );
                      }
                    ),
                  ...(items.filter(
                    (document) =>
                      document?.properties?.ssl?.form === DocumentType.Analog
                  ).length === items.length && {
                    [classPath(genericDocumentProxy.properties!.ssl!.listCount)
                      .path]: yup
                      .string()
                      .required(
                        t(translationPath(lang._validations.requiredListCount))
                      ),

                    [classPath(
                      genericDocumentProxy.properties!.ssl!.listCountAttachments
                    ).path]: yup
                      .string()
                      .required(
                        t(
                          translationPath(
                            lang._validations.listCountAttachments
                          )
                        )
                      ),

                    [classPath(
                      genericDocumentProxy.properties!.ssl!.attachmentsCount
                    ).path]: yup
                      .string()
                      .required(
                        t(
                          translationPath(
                            lang._validations.requiredAttachmentsCount
                          )
                        )
                      )
                  }),
                  ...(items.filter(
                    (document) =>
                      document?.properties?.ssl?.form === DocumentType.Digital
                  ).length === items.length && {
                    [classPath(
                      genericDocumentProxy.properties!.ssl!.attachmentsCount
                    ).path]: yup
                      .string()
                      .required(
                        t(
                          translationPath(
                            lang._validations.requiredAttachmentsCount
                          )
                        )
                      )
                  }),
                  ...(items.filter(
                    (document) =>
                      document?.properties?.ssl?.form === DocumentType.DA
                  ).length === items.length && {
                    [classPath(
                      genericDocumentProxy.properties!.ssl!.attachmentsCount
                    ).path]: yup
                      .string()
                      .required(
                        t(
                          translationPath(
                            lang._validations.requiredAttachmentsCount
                          )
                        )
                      )
                  }),
                  ...(items.filter(
                    (document) =>
                      document?.properties?.ssl?.form === DocumentType.AD
                  ).length === items.length && {
                    [classPath(
                      genericDocumentProxy.properties!.ssl!.attachmentsCount
                    ).path]: yup
                      .string()
                      .required(
                        t(
                          translationPath(
                            lang._validations.requiredAttachmentsCount
                          )
                        )
                      )
                  }),

                  [classPath(genericDocumentProxy.properties!.cm!.owner!.id)
                    .path]: yup
                    .string()
                    .required(
                      t(translationPath(lang._validations.requiredOwner))
                    ),
                  [classPath(genericDocumentProxy.properties!.ssl!.fileMark)
                    .path]: yup
                    .string()
                    .required(
                      t(translationPath(lang._validations.requiredFileMark))
                    ),
                  [classPath(genericDocumentProxy.properties!.ssl!.filePlan)
                    .path]: yup
                    .string()
                    .required(
                      t(translationPath(lang._validations.requiredFilePlan))
                    ),
                  [classPath(
                    genericDocumentProxy.properties!.ssl!.retentionMode
                  ).path]: yup
                    .string()
                    .required(
                      t(
                        translationPath(lang._validations.requiredRetentionMode)
                      )
                    ),
                  [classPath(genericDocumentProxy.properties!.ssl!.form)
                    .path]: yup
                    .string()
                    .required(
                      t(translationPath(lang._validations.requiredFormDocument))
                    )
                });
              }
            } else {
              return validateItems(items, {
                [classPath(genericDocumentProxy.properties!.ssl!.pid)
                  .path]: yup
                  .string()
                  .required(t(translationPath(lang._validations.requiredPid))),
                [classPath(genericDocumentProxy.properties!.ssl!.ssid)
                  .path]: yup
                  .string()
                  .required(t(translationPath(lang._validations.requiredSsid))),
                [classPath(genericDocumentProxy.properties!.ssl!.subject)
                  .path]: yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredSubject))
                  ),
                [classPath(genericDocumentProxy.properties!.ssl!.senderFullName)
                  .path]: yup
                  .string()
                  .test(
                    "oneOfRequired",
                    t(translationPath(lang._validations.requiredSender)),
                    function () {
                      return (
                        !isEmptyString(
                          this.parent[
                            lastPathMember(
                              genericDocumentProxy.properties!.ssl!
                                .senderCompanyName
                            ).path
                          ]
                        ) ||
                        !isEmptyString(
                          this.parent[
                            lastPathMember(
                              genericDocumentProxy.properties!.ssl!
                                .senderFullName
                            ).path
                          ]
                        ) ||
                        !isEmptyString(
                          this.parent[
                            lastPathMember(
                              genericDocumentProxy.properties!.ssl!.senderType
                            ).path
                          ]
                        )
                      );
                    }
                  ),
                ...(items.filter(
                  (document) =>
                    document?.properties?.ssl?.form === DocumentType.Analog
                ).length === items.length && {
                  [classPath(genericDocumentProxy.properties!.ssl!.listCount)
                    .path]: yup
                    .string()
                    .required(
                      t(translationPath(lang._validations.requiredListCount))
                    ),

                  [classPath(
                    genericDocumentProxy.properties!.ssl!.listCountAttachments
                  ).path]: yup
                    .string()
                    .required(
                      t(translationPath(lang._validations.listCountAttachments))
                    ),

                  [classPath(
                    genericDocumentProxy.properties!.ssl!.attachmentsCount
                  ).path]: yup
                    .string()
                    .required(
                      t(
                        translationPath(
                          lang._validations.requiredAttachmentsCount
                        )
                      )
                    )
                }),
                ...(items.filter(
                  (document) =>
                    document?.properties?.ssl?.form === DocumentType.Digital
                ).length === items.length && {
                  [classPath(
                    genericDocumentProxy.properties!.ssl!.attachmentsCount
                  ).path]: yup
                    .string()
                    .required(
                      t(
                        translationPath(
                          lang._validations.requiredAttachmentsCount
                        )
                      )
                    )
                }),
                ...(items.filter(
                  (document) =>
                    document?.properties?.ssl?.form === DocumentType.DA
                ).length === items.length && {
                  [classPath(
                    genericDocumentProxy.properties!.ssl!.attachmentsCount
                  ).path]: yup
                    .string()
                    .required(
                      t(
                        translationPath(
                          lang._validations.requiredAttachmentsCount
                        )
                      )
                    )
                }),
                ...(items.filter(
                  (document) =>
                    document?.properties?.ssl?.form === DocumentType.AD
                ).length === items.length && {
                  [classPath(
                    genericDocumentProxy.properties!.ssl!.attachmentsCount
                  ).path]: yup
                    .string()
                    .required(
                      t(
                        translationPath(
                          lang._validations.requiredAttachmentsCount
                        )
                      )
                    )
                }),

                [classPath(genericDocumentProxy.properties!.cm!.owner!.id)
                  .path]: yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredOwner))
                  ),
                [classPath(genericDocumentProxy.properties!.ssl!.fileMark)
                  .path]: yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredFileMark))
                  ),
                [classPath(genericDocumentProxy.properties!.ssl!.filePlan)
                  .path]: yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredFilePlan))
                  ),
                [classPath(genericDocumentProxy.properties!.ssl!.retentionMode)
                  .path]: yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredRetentionMode))
                  ),
                [classPath(genericDocumentProxy.properties!.ssl!.form)
                  .path]: yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredFormDocument))
                  )
              });
            }
          }
        }
      ],
      more: [
        /* V2
        {
          action: () => {},
          icon: <Print />,
          title: t(translationPath(lang.general.print))
        },
        */
        {
          action: handleOpenLostDestroyedDocumentDialog,
          filter: (document) =>
            document.properties?.ssl?.form === DocumentType.Analog ||
            document?.properties?.ssl?.form === DocumentType.AD,
          icon: <Whatshot data-test-id="node-damaged" />,
          title: t(translationPath(lang.general.damaged))
        },
        {
          action: handleOpenCancelDialog,
          filter: (document) =>
            document?.properties?.ssl?.senderType === SenderType.Own,
          icon: <Delete data-test-id="node-cancel" />,
          title: t(translationPath(lang.general.cancel))
        }
      ]
    }
  };
};
