import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ResetPasswordService } from "../../domain/service/authentication/ResetPasswordService";
import { PasswordRepositoryToken } from "../../domain/di/token/authentication";

@DInjectable()
export class RequestPasswordReset {
  constructor(
    @DInject(PasswordRepositoryToken) protected repository: ResetPasswordService
  ) {}
  requestPasswordReset(id: string) {
    return this.repository.requestPasswordReset(id);
  }
  validatePasswordReset(token: string) {
    return this.repository.validatePasswordReset(token);
  }
  changePasswordReset(token: string, password: string) {
    return this.repository.changePassword(token, password);
  }
}
