import { useSyncFormWithChannel } from "../../../../core/components/dialog/hooks/useSyncFormWithChannel";
import { useCallback, useMemo } from "react";
import { FormInstance } from "../../../../designSystem/Form/Form";
import { ChannelFormProps } from "./ChannelFormProps";

const onSubmitDummy = () => {};

export const useChannelForm = <Values>({
  channel,
  innerRef,
  onSubmit
}: ChannelFormProps<Values>) => {
  const setFormForChannelSync = useSyncFormWithChannel<Values>(channel);

  const setFormRef = useCallback(
    (form: FormInstance<Values>) => {
      setFormForChannelSync(form);

      if (!innerRef) {
        return;
      }

      if (typeof innerRef === "function") {
        innerRef?.(form);
      }
    },
    [innerRef] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onSubmitClb = useMemo(() => onSubmit || onSubmitDummy, [onSubmit]);

  return {
    setFormRef,
    onSubmit: onSubmitClb
  };
};
