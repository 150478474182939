import React from "react";
import lang from "presentation/translation/lang";
import { t } from "presentation/translation/i18n";
import { ModalSize, Modal } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { translationPath } from "presentation/share/utils/getPath";
import { Input } from "presentation/designSystem/Input/Input";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useMutation } from "presentation/share/hook/query/useQM";
import { PasswordProps, postDecryptedComponent } from "../api";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { ErrorType } from "presentation/types";
import { ErrorCodeList } from "presentation/enums";
import { Notification } from "presentation/designSystem/notification/Notification";
import { styled } from "presentation/styles";

export interface EncryptedComponentsPasswordModalInterface
  extends BaseModalProps,
    Pick<PasswordProps, "componentType" | "nodeId" | "componentId"> {
  onOk?: BaseModalProps["onOk"];
  onCancel?: BaseModalProps["onCancel"];
}

export type PasswordParams = Omit<
  PasswordProps,
  "componentType" | "nodeId" | "componentId"
>;

export const EncryptedComponentsPasswordModal = ({
  onOk,
  onCancel,
  componentType,
  nodeId,
  componentId
}: EncryptedComponentsPasswordModalInterface) => {
  const [form] = useForm<PasswordParams>();

  const validationPasswordRules = [
    {
      required: true,
      message: t(translationPath(lang._validations.inputPassword))
    }
  ];

  const onConfirm = async () => {
    try {
      await form.validateFields();
      const { password } = form.getFieldsValue();
      mutate({ password });
    } catch {}
  };

  const { isLoading, mutate } = useMutation(
    (params: PasswordParams) =>
      postDecryptedComponent({ ...params, componentType, nodeId, componentId }),
    {
      onSuccess() {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(translationPath(lang.menu.items.decrypted))
      },
      onError(error: ErrorType) {
        if (error.code === ErrorCodeList.IncorrectPassword) {
          form.setFields([
            {
              name: "password",
              errors: [t(translationPath(lang._validations.incorrectPassword))]
            }
          ]);
        } else {
          return Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });
        }
      },
      onErrorNotification: null
    }
  );

  return (
    <ModalStyled
      visible
      title={t(translationPath(lang.dialog.title.enterPassword))}
      onOk={onConfirm}
      onCancel={onCancel}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
      confirmLoading={isLoading}
    >
      <Form form={form}>
        <Item name="password" rules={validationPasswordRules}>
          <Input.Password
            placeholder={t(
              translationPath(lang.dialog.title.enterPasswordInput)
            )}
            iconRender={(visible) =>
              visible ? <Visibility /> : <VisibilityOff />
            }
          />
        </Item>
      </Form>
    </ModalStyled>
  );
};

const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding-top: 48px;
  }
`;
