import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { SortingConfig } from "lib/contract/Sorting";
import { PaginationConfig } from "lib/contract/Pagination";
import { RetentionForShreddingRepositoryToken } from "../../domain/di/token/recordRetention";
import { RetentionForShreddingRepository } from "../../domain/service/recordRetention/RetentionForShreddingRepository";

@DInjectable()
export class FindRetentionForShredding {
  constructor(
    @DInject(RetentionForShreddingRepositoryToken)
    protected repository: RetentionForShreddingRepository
  ) {}

  findAllPaginated(config: PaginationConfig, sorting?: SortingConfig) {
    return this.repository.getRetentionForShredding(config, sorting);
  }
}
