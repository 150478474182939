import React from "react";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { useMutation } from "../../../hook/query/useMutation";
import { convertDigitalToAnalog } from "../../../../core/api/document/actions";
import { useDispatch } from "react-redux";
import { documentViewAction__Refresh } from "../../../../core/components/documentView/_actions";
import { GenericDocument } from "../../../../core/types";
import { DocumentState, DocumentType } from "../../../../enums";
import { downloadDocument } from "../../../utils/downloadDocument";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";

export interface ConvertDigitalToAnalogModalProps extends BaseModalProps {
  nodeId: string;
}

export const isDocumentAvailableForDigitalToAnalogConversion = (
  document: GenericDocument
): boolean => {
  const { form, state = "" } = document?.properties?.ssl || {};
  return (
    (form === DocumentType.Digital &&
      [
        DocumentState.Settled.toString(),
        DocumentState.NotSettled.toString(),
        DocumentState.Closed.toString()
      ].includes(state)) ||
    (form === DocumentType.DA &&
      [
        DocumentState.Settled.toString(),
        DocumentState.NotSettled.toString()
      ].includes(state))
  );
};

export const ConvertDigitalToAnalogModal = ({
  nodeId,
  onOk,
  onCancel
}: ConvertDigitalToAnalogModalProps) => {
  const dispatch = useDispatch();
  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });
  const { isLoading, mutate: convertDocument } = useMutation(
    (nodeId: string) => convertDigitalToAnalog(nodeId),
    {
      onSuccess: ({ responseHeaders, response }) => {
        if (responseHeaders && response) {
          downloadDocument(response, responseHeaders, "application/pdf");
        }
        onOk?.();
        dispatch(documentViewAction__Refresh(true));
      },
      onErrorNotification: getNotificationForError || {
        message: t(
          translationPath(lang.dialog.notifications.documentForSealFormFailed)
        )
      }
    }
  );

  const handleOnOk = () => {
    convertDocument(nodeId);
  };

  return (
    <Modal
      visible={true}
      title={t(translationPath(lang.dialog.convertions.digitalToAnalog.title))}
      onCancel={onCancel}
      onOk={handleOnOk}
      className={""}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      confirmLoading={isLoading}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      {t(translationPath(lang.dialog.convertions.digitalToAnalog.confirmation))}
    </Modal>
  );
};
