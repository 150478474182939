import React, { useState } from "react";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { lang, t } from "../../../../../translation/i18n";
import { translationPath } from "../../../../../share/utils/getPath";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { CreateEmailToWhitelistForm } from "../form/CreateEmailToWhitelistForm";
import { useMutation } from "../../../../../share/hook/query/useMutation";
import { ErrorType } from "../../../../../types";
import { Notification } from "presentation/designSystem/notification/Notification";
import { getErrorCodeTranslation } from "../../../../../share/utils/errorCodeTranslation";
import { useForm } from "presentation/designSystem/Form/v2/Form";
import { createNewWhiteListEmail } from "../api";

export interface CreateEmailToWhitelistModalInterface {
  onCancel?: BaseModalProps["onCancel"];
  onOk?: BaseModalProps["onOk"];
}

export const CreateEmailToWhitelistModal = ({
  onCancel
}: CreateEmailToWhitelistModalInterface) => {
  const [errors, setErrors] = useState<any>([]);
  const [form] = useForm();

  const onChange = async () => {
    try {
      await form.validateFields();
      setErrors([]);
    } catch (error: any) {
      setErrors(error.errorFields);
    }
  };

  const { mutate: createNewWhiteListEmailMutation } = useMutation(
    async () => {
      await createNewWhiteListEmail({
        email: form.getFieldsValue().email,
        comment: form.getFieldsValue().comment
      });
    },
    {
      onError(error: ErrorType) {
        if (error.message === "Failed to fetch") {
          Notification.error({
            message: t(
              translationPath(
                lang.dialog.notifications.emailWasNotAddedToWhiteList
              )
            )
          });
          return;
        }
        if (error.code) {
          return Notification.error({
            message: getErrorCodeTranslation(error.code)
          });
        }
        if (error?.messages) {
          error.messages.map((error: string) =>
            Notification.error({
              message: getErrorCodeTranslation(error)
            })
          );
        } else {
          if (error.message) {
            return Notification.error({
              message: error.message
            });
          }
          Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });
        }
      },
      onSuccess() {
        Notification.success({
          message: t(
            translationPath(lang.dialog.notifications.emailWasAddedToWhiteList)
          )
        });
        onCancel?.();
      },
      onErrorNotification: null,
      onSuccessNotification: null
    }
  );

  const onOk = () => {
    createNewWhiteListEmailMutation(null);
  };

  return (
    <Modal
      visible={true}
      title={t(translationPath(lang.dialog.whitelistDialogs.create.title))}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{
        disabled:
          errors?.filter((error: any) => error?.errors?.length)?.length > 0
      }}
      size={ModalSize.Small}
      centered={true}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <CreateEmailToWhitelistForm form={form} onChange={onChange} />
    </Modal>
  );
};
