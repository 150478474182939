import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { FindConversionAll } from "../../../../useCase/administration/FindConversionAll";

export const useFindConversionAll = () => {
  const findConversionAll = getService(FindConversionAll);

  return useCallback(
    (config: PaginationConfig, sorting) =>
      findConversionAll.findAll(config, sorting),
    [findConversionAll]
  );
};
