import { format, isValid, parseISO } from "date-fns";
import cs from "date-fns/locale/cs";
import { CmProperties, SslFile } from "../../core/api/models";

export const formatDate = (
  date?: string,
  timeFormat: string = "dd.MM.yyyy HH:mm:ss"
) => {
  if (date && isValid(parseISO(date))) {
    if (timeFormat) {
      return format(parseISO(date), timeFormat, { locale: cs });
    } else {
      return format(parseISO(date), "dd.MM.yyyy", { locale: cs });
    }
  }
  return date;
};

export const convertResponse = <T extends {}>(response: T) => {
  if (typeof response !== "object") return response;
  const convertedResponse: T = {} as T;
  // tslint:disable-next-line: forin
  for (const key in response) {
    if (response[key] && response[key] instanceof Array) {
      response[key] = (convertResponsearray(
        (response[key] as unknown) as []
      ) as unknown) as T[Extract<keyof T, string>];
    } else if (response[key] && typeof response[key] === "object") {
      response[key] = convertResponse(response[key]);
    }
    if (key.indexOf(":") !== -1) {
      const prefix = key.substr(0, key.indexOf(":"));
      if (!convertedResponse[prefix]) convertedResponse[prefix] = {};
      convertedResponse[prefix][key.substr(key.lastIndexOf(":") + 1)] =
        response[key];
    } else {
      convertedResponse[key] = response[key];
    }
  }
  return convertedResponse;
};

export const convertProps = <T extends {}>(body: T, prefix = "ssl"): object => {
  return Object.keys(body).reduce((acc, key) => {
    acc[`${prefix}:${key}`] = body[key];
    return acc;
  }, {});
};

export const omitFormiddenProps = <T extends SslFile & CmProperties>(
  payload: T
) => {
  const {
    pid,
    form,
    documentType,
    deliveryTime,
    emailDeliveryTime,
    owner,
    createdAt,
    fileIsEncrypted,
    ...rest
  } = payload;

  return rest;
};

const convertResponsearray = <T>(array: T[]) => {
  const convertedResponse: T[] = [];
  array.forEach((x) => convertedResponse.push(convertResponse(x)));
  return convertedResponse;
};

export const getPropertySortName = (property: string) => {
  const key = "properties.";
  const index = property.lastIndexOf(key);
  if (index !== -1) {
    return transformProperty(property.substr(index + key.length));
  }
  return property;
};

const transformProperty = (property: string) => {
  const index = property.indexOf(".");
  if (index !== -1) {
    return `${property.substr(0, index)}:${property.substr(
      property.lastIndexOf(".") + 1
    )}`;
  }
  return property;
};
