import clsx from "clsx";
import {
  StyledFieldThird,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import { shipmentDocumentProxy } from "presentation/core/types";
import { Form } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { DispatchPostShipmentFormValues } from "./_types";
import { ChannelForm } from "../ChannelForm/ChannelForm";

const DispatchPostShipmentDialog = ({ channel }: DialogContentPropsType) => {
  const classes = useStyles();

  return (
    <ChannelForm<DispatchPostShipmentFormValues>
      initialValues={{
        postItemId: "",
        postItemNumber: ""
      }}
      channel={channel}
    >
      <Form className={classes.form} style={{ width: 500 }}>
        <StyledFieldThird
          className={clsx(classes.gapRight, classes.mlGap)}
          component={TextField}
          name={
            lastPathMember(shipmentDocumentProxy.properties?.ssl?.postItemId)
              .path
          }
          required={false}
          type="text"
          label={t(translationPath(lang.general.postItemId))}
        />
        <StyledFieldThird
          className={classes.mrGap}
          component={TextField}
          name={
            lastPathMember(
              shipmentDocumentProxy.properties?.ssl?.postItemNumber
            ).path
          }
          required={false}
          type="text"
          label={t(translationPath(lang.general.postItemNumber))}
        />
      </Form>
    </ChannelForm>
  );
};

export default DispatchPostShipmentDialog;
