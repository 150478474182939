import { SupervisorAccount } from "@material-ui/icons";
import { MenuItemType } from "presentation/core/components/menu/_types";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React from "react";
import Certificates from "./features/certificates";
import DailyImprint from "./features/dailyImprint";
import FilePlans from "./features/filePlan/FilePlans";
import { NameRegisterConfigurationPage } from "./features/nameRegister/NameRegisterConfigurationPage";
import OrganizationUnits from "./features/organizationUnits";
import Users from "./features/users";
import { AdministrationRoutes } from "./routes";
import ConversionAll from "./features/conversionAll/ConversionAll";
import { DispatchWhiteList } from "./features/dispatchWhiteList/DispatchWhiteList";
import DailyImprintSearch from "./features/dailyImprint/DailyImprintSearch";
import { CodeListTable } from "./features/codeList/table/CodeListTable";

export const administrationMenuItems: MenuItemType[] = [
  {
    __testId: "menu-main-administration",
    icon: <SupervisorAccount />,
    submenu: [
      {
        __testId: "menu-main-administration-organization-unit",
        content: <OrganizationUnits />,
        text: t(translationPath(lang.menu.items.organizationUnits)),
        url: AdministrationRoutes.ADMINISTRATION_ORGANIZATION_UNIT
      },
      {
        __testId: "menu-main-administration-name-register-configuration",
        content: <NameRegisterConfigurationPage />,
        text: t(translationPath(lang.menu.items.nameRegister)),
        url: AdministrationRoutes.ADMINISTRATION_NAME_REGISTER_CONFIGURATION
      },
      {
        __testId: "menu-main-administration-organization-users",
        content: <Users />,
        text: t(translationPath(lang.menu.items.users)),
        url: AdministrationRoutes.ADMINISTRATION_USERS
      },
      {
        __testId: "menu-main-administration-code-list",
        content: <CodeListTable />,
        text: t(translationPath(lang.menu.items.codeList)),
        url: AdministrationRoutes.ADMINISTRATION_CODELIST
      },

      {
        __testId: "menu-main-administration-file-plans",
        content: <FilePlans />,
        text: t(translationPath(lang.menu.items.filePlans)),
        url: AdministrationRoutes.ADMINISTRATION_FILE_PLANS
      },
      {
        __testId: "menu-main-administration-conversion",
        content: <ConversionAll />,
        text: t(translationPath(lang.menu.items.conversion)),
        url: AdministrationRoutes.ADMINISTRATION_CONVERSION
      },

      {
        __testId: "menu-main-administration-transaction-protocol",
        submenu: [
          {
            __testId: "menu-main-administration-daily-imprints-overview",
            content: <DailyImprint />,
            text: t(translationPath(lang.menu.items.dailyImprint)),
            url:
              AdministrationRoutes.ADMINISTRATION_TRANSACTION_PROTOCOL_DAILY_IMPRINT
          },
          {
            __testId: "menu-main-administration-daily-imprints-search",
            content: <DailyImprintSearch />,
            text: t(translationPath(lang.general.search)),
            url:
              AdministrationRoutes.ADMINISTRATION_TRANSACTION_PROTOCOL_DAILY_IMPRINT_SEARCH
          }
        ],
        text: t(translationPath(lang.menu.items.transactionProtocol))
      },
      {
        __testId: "menu-main-administration-email-whitelist",
        content: <DispatchWhiteList />,
        text: t(translationPath(lang.menu.items.dispatchWhiteList)),
        url: AdministrationRoutes.ADMINISTRATION_DISPATCH_WHITELIST
      }
    ],
    text: t(translationPath(lang.menu.items.administration))
  }
];
