import React from "react";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "./FileUploadProgress.styles";
// Style for new govDesign notification
// import { useStyles } from "presentation/govDesignSystem/Notification/Notificaton.styles";

type OwnProps = {
  fileName: string;
  progress: number;
};

export const FileUploadProgress = ({ fileName, progress }: OwnProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <CircularProgress color="secondary" variant="static" value={progress} />
      </div>
      {/* New govDesign notification */}
      {/* <CircularProgress
        className={styles.color}
        variant="static"
        value={progress}
      /> */}
      <div className={styles.wrapper}>{fileName}</div>
    </div>
  );
};
