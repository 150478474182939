import { ColumnType } from "presentation/designSystem/Table/Table";
import React, { useCallback, useMemo, useState } from "react";
import { Person } from "domain/struct/nameRegistry/Person";
import { useFindPersons } from "presentation/modules/nameRegister/hooks/useFindPersons";
import {
  FileTextFilled,
  PlusCircleOutlined
} from "../../../../designSystem/Icon/Icon";
import {
  createColumn,
  createDateTimeColumn
} from "../../../../designSystem/Table/column/column";
import { SubjectModal } from "../../modal/SubjectModal/SubjectModal";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../designSystem/Table/RemoteTable";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "../../../../share/utils/getPath";
import { useModal } from "../../../../designSystem/Modal/useModal";
import { DateTimeFormat } from "../../../../../lib/dateTime";
import { BreadcrumbForTable } from "presentation/designSystem/Breadcrumb/Breadcrumb";
import styled from "@emotion/styled";

const COLUMNS: ColumnType<Person>[] = [
  createColumn({
    dataIndex: "identifier",
    title: t(translationPath(lang.table.personTable.identifier)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "fullName",
    title: t(translationPath(lang.table.personTable.subjectName)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "firstName",
    title: t(translationPath(lang.table.personTable.firstName)),

    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "surname",
    title: t(translationPath(lang.table.personTable.lastName)),
    defaultSortOrder: "ascend",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "processedAt",
      title: t(translationPath(lang.table.personTable.creationDate)),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.DateTimeNoSeconds
  )
];

export const PersonTable = () => {
  const [subjectModal, subjectModalApi] = useModal(SubjectModal, {
    onClose: () => {
      setSelectedRowKeys([]);
    }
  });

  const findPersons = useFindPersons();

  const ACTIONS = useMemo(
    () => ({
      default: [
        {
          key: "create-subject",
          icon: <PlusCircleOutlined />,
          tooltip: "Vytvořit subjekt",
          action() {
            return subjectModalApi.open({
              type: SubjectType.Person
            });
          }
        }
      ],
      single: [
        {
          key: "show-details",
          icon: <FileTextFilled />,
          tooltip: "Zobrazit detaily",
          action(selected: Person[]) {
            return subjectModalApi.open({
              type: SubjectType.Person,
              person: selected[0]
            });
          }
        }
      ]
    }),
    [subjectModalApi]
  );

  const getRemoteData: GetRemoteDataClb<Person> = useCallback(
    ({ pagination, sorting }) => findPersons(pagination, sorting),
    [findPersons]
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const onRowSelection = {
    onChange: (selectedRowKeys: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  return (
    <>
      {subjectModal}
      <BreadcrumbForTable title={"persons"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<Person>
          name="nameRegistry/persons"
          rowKey="id"
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
          rowSelection={onRowSelection}
        />
      </TableStyled>
    </>
  );
};
const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
