import {
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import styled from "presentation/styles";
import {
  responsiveFieldFifth,
  responsiveFieldHalf,
  responsiveFieldQuarter
} from "../dialog/Dialog.styles";

export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)<{}>(
  () => responsiveFieldQuarter
);

export const StyledKeyboardDatePickerFifth = styled(KeyboardDatePicker)<{}>(
  () => responsiveFieldFifth
);
export const StyledKeyboardDatePickerHalf = styled(KeyboardDatePicker)<{}>(
  () => responsiveFieldHalf
);

export const StyledKeyboardDateTimePickerFifth = styled(
  KeyboardDateTimePicker
)<{}>(() => responsiveFieldFifth);
