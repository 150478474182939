import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { RootActionsType } from "presentation/actions";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import { MenuItemType } from "presentation/core/components/menu/_types";
import React, { Dispatch } from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import IncomeDigitalDocumentDataBoxNotRegistered from "./features/income/digitalDocument/dataBox/notRegistered";
import IncomeDigitalDocumentDataBoxUnprocessed from "./features/income/digitalDocument/dataBox/unprocessed";
import IncomeDigitalDocumentEmailBoxNotRegistered from "./features/income/digitalDocument/emailBox/notRegistered";
import IncomeDigitalDocumentEmailBoxUnprocessed from "./features/income/digitalDocument/emailBox/unprocessed";
import NotPassed from "./features/notPassed";
import Unfinished from "./features/unfinished";
import WaitingForTakeover from "./features/waitingForTakeover";
import { MailroomRoutes } from "./routes";
import { AnalogDetailLayout } from "./features/income/analog/AnalogDetailLayout";
import { TechnicalDataCarrier } from "./features/income/digitalDocument/technicalDataCarries/TechnicalDataCarrier";

export const mailRoomMenuItems: MenuItemType[] = [
  {
    __testId: "menu-main-mailroom",
    icon: <SaveAltIcon />,
    submenu: [
      {
        __testId: "menu-main-mailroom-income",
        redirectTo: MailroomRoutes.MAILROOM_INCOME_DIGITALDOCUMENT_EMAILBOX,
        submenu: [
          {
            __testId: "menu-main-mailroom-income-analogdocument",
            content: <AnalogDetailLayout />,
            text: t(translationPath(lang.menu.items.analogDocument)),
            url: MailroomRoutes.MAILROOM_INCOME_ANALOG
          },
          {
            __testId: "menu-main-mailroom-income-digitaldocument",
            submenu: [
              {
                __testId: "menu-main-mailroom-income-digitaldocument-emailbox",
                redirectTo:
                  MailroomRoutes.MAILROOM_INCOME_DIGITALDOCUMENT_EMAILBOX_UNPROCESSED,
                submenu: [
                  {
                    __testId:
                      "menu-main-mailroom-income-digitaldocument-emailbox-unprocessed",
                    content: <IncomeDigitalDocumentEmailBoxUnprocessed />,
                    text: t(translationPath(lang.menu.items.unprocessed)),
                    url:
                      MailroomRoutes.MAILROOM_INCOME_DIGITALDOCUMENT_EMAILBOX_UNPROCESSED
                  },
                  {
                    __testId:
                      "menu-main-mailroom-income-digitaldocument-emailbox-notregistered",
                    content: <IncomeDigitalDocumentEmailBoxNotRegistered />,
                    text: t(translationPath(lang.menu.items.notRegistered)),
                    url:
                      MailroomRoutes.MAILROOM_INCOME_DIGITALDOCUMENT_EMAILBOX_NOTREGISTERED
                  }
                ],
                text: t(translationPath(lang.menu.items.emailBox)),
                url: MailroomRoutes.MAILROOM_INCOME_DIGITALDOCUMENT_EMAILBOX
              },
              {
                __testId: "menu-main-mailroom-income-digitaldocument-databox",
                redirectTo:
                  MailroomRoutes.MAILROOM_INCOME_DIGITALDOCUMENT_DATABOX_UNPROCESSED,
                submenu: [
                  {
                    __testId:
                      "menu-main-mailroom-income-digitaldocument-databox-unprocessed",
                    content: <IncomeDigitalDocumentDataBoxUnprocessed />,
                    text: t(translationPath(lang.menu.items.unprocessed)),
                    url:
                      MailroomRoutes.MAILROOM_INCOME_DIGITALDOCUMENT_DATABOX_UNPROCESSED
                  },
                  {
                    __testId:
                      "menu-main-mailroom-income-digitaldocument-databox-notregistered",
                    content: <IncomeDigitalDocumentDataBoxNotRegistered />,
                    text: t(translationPath(lang.menu.items.notRegistered)),
                    url:
                      MailroomRoutes.MAILROOM_INCOME_DIGITALDOCUMENT_DATABOX_NOTREGISTERED
                  }
                ],
                text: t(translationPath(lang.menu.items.dataBox)),
                url: MailroomRoutes.MAILROOM_INCOME_DIGITALDOCUMENT_DATABOX
              },
              {
                __testId:
                  "menu-main-mailroom-income-digitaldocument-databox-technicaldataCarries",
                content: <TechnicalDataCarrier />,
                text: t(translationPath(lang.menu.items.technicalDataCarries)),
                url:
                  MailroomRoutes.MAILROOM_INCOME_DIGITALDOCUMENT_DATABOX_TECHDATACARRIE
              }
            ],
            text: t(translationPath(lang.menu.items.digitalDocument))
          }
        ],
        text: t(translationPath(lang.menu.items.income)),
        url: MailroomRoutes.MAILROOM_INCOME
      },
      {
        __testId: "menu-main-mailroom-unfinished",
        content: <Unfinished />,
        text: t(translationPath(lang.menu.items.unfinished)),
        url: MailroomRoutes.MAILROOM_UNFINISHED
      },
      {
        __testId: "menu-main-mailroom-notpassed",
        content: <NotPassed />,
        text: t(translationPath(lang.menu.items.notPassed)),
        url: MailroomRoutes.MAILROOM_NOTPASSED
      },
      {
        __testId: "menu-main-mailroom-waitingfortakeover",
        content: <WaitingForTakeover />,
        text: t(translationPath(lang.menu.items.waitingForTakeover)),
        url: MailroomRoutes.MAILROOM_WAITINGFORTAKEOVER
      }
    ],
    text: t(translationPath(lang.menu.items.mailroom))
  }
];
