import React from "react";
import {
  Form,
  FormInstance,
  Item
} from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { DateTimeFormat } from "lib/dateTime";
import moment from "moment";
import { Select } from "antd";
import { DocumentState, FormMode } from "presentation/enums";
import { camelCase } from "lodash";
import { DetailFormFieldTypes, DetailTypeFileDocument } from "../types";

interface TypeFileDetailFormProps {
  formData: DetailTypeFileDocument;
  form: FormInstance<DetailFormFieldTypes>;
}

export const TypeFileDetailsForm = ({
  form,
  formData
}: TypeFileDetailFormProps) => {
  const createdAt = moment(formData.createdAt!);
  const closureDate = moment(formData.closureDate!);
  const formInitialValues: DetailFormFieldTypes = {
    ...formData,
    createdAt: moment(createdAt),
    closureDate: moment(closureDate)
  };

  const selectedForms = Object.entries(FormMode).filter(([key, value]) =>
    [FormMode.Analog, FormMode.Digital, FormMode.Hybrid].includes(value)
  );

  const selectedState = Object.entries(DocumentState).filter(([key, value]) =>
    [
      DocumentState.Open,
      DocumentState.Closed,
      DocumentState.NotSettled
    ].includes(value)
  );
  return (
    <>
      <Form initialValues={formInitialValues} form={form}>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              name="pid"
              label={t(translationPath(lang.documentMetaForm.pid))}
            >
              <Input disabled />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="form"
              label={t(translationPath(lang.documentMetaForm.form))}
            >
              <Select placeholder="" disabled>
                {selectedForms.map(([key, value]) => {
                  return (
                    <Select.Option key={key} value={value}>
                      {t(translationPath(lang.general[camelCase(key)]))}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="createdAt"
              label={t(translationPath(lang.documentMetaForm.createdAt))}
            >
              <DatePicker
                disabled
                format={DateTimeFormat.FullDateTime}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="state"
              label={t(translationPath(lang.documentMetaForm.state))}
            >
              <Select placeholder="" disabled>
                {selectedState.map(([key, value]) => {
                  return (
                    <Select.Option key={key} value={value}>
                      {t(
                        translationPath(
                          lang.enums.documentState[camelCase(key)]
                        )
                      )}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Item
              name="subject"
              label={t(translationPath(lang.documentMetaForm.subjectName))}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
