import {
  Form,
  FormInstance,
  Item
} from "../../../../designSystem/Form/v2/Form";
import React, { useCallback, useState } from "react";
import { Row } from "../../../../designSystem/Grid/Grid";
import styled from "@emotion/styled";
import { Contact } from "../../../../../domain/struct/nameRegistry/Contact";
import { Input } from "../../../../designSystem/Input/Input";
import {
  emailValidation,
  phoneNumberValidation
} from "../../../../designSystem/Input/InputValidations";
import { ContactsSelect } from "./Select/ContactsSelect";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "../../../../share/utils/getPath";

interface ContactsFormProps {
  form: FormInstance<Contact>;
  contact?: Contact;
}

const validationRules = {
  PHONE: [{ required: true }, { max: 20 }, phoneNumberValidation],
  DATABOX: [{ required: true }, { len: 7 }],
  EMAIL: [{ required: true }, emailValidation, { max: 50 }]
};

export const ContactsForm = ({ form, contact }: ContactsFormProps) => {
  const [contactType, setContactType] = useState(contact?.contactType || "");
  const onContactTypeChange = useCallback(
    (value: any) => {
      form.resetFields(["contact"]);
      setContactType(value);
    },
    [form]
  );

  const title = {
    PHONE: t(translationPath(lang.dialog.form.contactForm.phoneNumber)),
    DATABOX: t(translationPath(lang.dialog.form.contactForm.dataBoxID)),
    EMAIL: t(translationPath(lang.dialog.form.contactForm.emailAddress))
  };

  return (
    <Form name="contactForm" form={form} initialValues={contact}>
      <StyledFieldsWrapper>
        <Row>
          <ContactsSelect disabled={!!contact} onChange={onContactTypeChange} />
          {contactType && (
            <Item
              name="contact"
              rules={validationRules[contactType]}
              label={title[contactType]}
            >
              <Input />
            </Item>
          )}
        </Row>
      </StyledFieldsWrapper>
    </Form>
  );
};

const StyledFieldsWrapper = styled.div`
  display: flex;
  .ant-row:nth-of-type(1) {
    padding-right: 2rem;
  }
`;
