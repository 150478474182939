import { AddCircleOutlineOutlined } from "@material-ui/icons";
import React, { useCallback } from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { useStyles } from "./Component.styles";
import { getAcceptStringForExtensions } from "./helpers/fileInputHelpers";
import { IconButton } from "presentation/designSystem/Button/IconButton";

type OwnProps = {
  handleUploadComponent: (files: globalThis.File[]) => void;
  multipleFilesAllowed?: boolean;
  icon?: React.ReactNode;
  title?: string;
  allowedExtensions?: string[];
};

const defaultIcon = <AddCircleOutlineOutlined />;

export const ComponentUploadAnt = React.forwardRef(
  (
    {
      allowedExtensions = [],
      handleUploadComponent,
      multipleFilesAllowed = true,
      icon = defaultIcon,
      title = t(translationPath(lang.general.uploadAttachments))
    }: OwnProps,
    ref
  ) => {
    const fileInputRef = React.createRef<HTMLInputElement>();
    const classes = useStyles();

    const handleFiles = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        handleUploadComponent(Array.from(event.target.files || []));

        event.target.value = "";
      },
      [handleUploadComponent]
    );

    const handleOnAddClick = useCallback(() => fileInputRef.current?.click(), [
      fileInputRef
    ]);

    return (
      <>
        <input
          type="file"
          className={classes.hidden}
          onChange={handleFiles}
          multiple={multipleFilesAllowed}
          accept={
            allowedExtensions
              ? getAcceptStringForExtensions(allowedExtensions)
              : "all"
          }
          ref={fileInputRef}
          style={{ display: "none" }}
          data-test-id="digital-document-component-upload"
        />
        <label>
          <IconButton
            icon={icon}
            tooltip={title}
            onClick={handleOnAddClick}
            data-test-id="component-upload"
          />
        </label>
      </>
    );
  }
);
