import "@abraham/reflection";
import "presentation/boot/wdyr";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider
} from "@material-ui/core/styles";
import { ConnectedRouter } from "connected-react-router";
import { ErrorBoundary } from "presentation/core/components/errorBoundary";
import { GlobalStyles } from "presentation/core/styles/global";
import "normalize.css";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Router from "presentation/router";
import { getStore } from "presentation/store";
import { muiTheme, theme } from "presentation/styles";
import { ThemeProvider } from "@emotion/react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import "presentation/translation/i18n";
import { boot } from "./presentation/boot";
import { GlobalError } from "./presentation/core/components/errorBoundary/errorTypes/GlobalError";

import moment from "moment";
import "moment/locale/cs";
import MomentUtils from "@date-io/moment";

import { ReactQueryProvider } from "presentation/core/providers/ReactQueryProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import { ISFGComponentsConfigProvider } from "./presentation/core/providers/ISFGComponentsConfigProvider";
import { AuthorizationProvider } from "./presentation/core/providers/AuthorizationProvider";

const LOCALE = "cs";

moment.locale(LOCALE);

const rootEl = document.getElementById("root") as HTMLElement;
const render = (Component: React.ComponentType) => {
  const appStore = getStore();

  return ReactDOM.render(
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={LOCALE}
    >
      <Provider store={appStore.store}>
        <PersistGate loading={null} persistor={appStore.persistor}>
          <StylesProvider injectFirst={true}>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={theme}>
                <AuthorizationProvider>
                  <ReactQueryProvider>
                    <ISFGComponentsConfigProvider>
                      <ConnectedRouter history={appStore.history}>
                        <GlobalStyles />
                        <ErrorBoundary Component={GlobalError}>
                          <Component />
                        </ErrorBoundary>
                      </ConnectedRouter>
                    </ISFGComponentsConfigProvider>
                    <ReactQueryDevtools />
                  </ReactQueryProvider>
                </AuthorizationProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </PersistGate>
      </Provider>
    </MuiPickersUtilsProvider>,
    rootEl
  );
};
boot();
render(Router);

// tslint:disable-next-line
if ((module as any).hot) {
  // tslint:disable-next-line
  // (module as any).hot.accept("./presentation/router", () => {
  //   const NextApp = require("./presentation/router").default;
  //   render(NextApp);
  // });
  (module as any).hot.accept(() => {
    const NextApp = require("./presentation/router").default;
    render(NextApp);
  });
}
