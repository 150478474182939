import { Global } from "@emotion/react";
import React from "react";

export const GlobalStyles = () => {
  return (
    <Global
      styles={{
        ".ant-select-dropdown": {
          zIndex: 1300
        },
        ".MuiFormControl-root.MuiTextField-root": {
          paddingBottom: "1.5em"
        },
        ".MuiFormHelperText-root.Mui-error": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        },
        ".MuiLinearProgress-barColorPrimary": {
          backgroundColor: "#ff9600"
        },

        ".ant-modal-content": {
          width: "100% !important",
          "& .ant-modal-close-x .ant-btn-icon-only": {
            verticalAlign: "0.3px"
          }
        },
        ".dailyImprint > div > .ant-row": {
          height: "50px"
        },
        ".ant-modal-confirm ": {
          top: "30%",
          "& .ant-modal-body": {
            padding: "32px 32px 12px"
          },
          "& .ant-modal-confirm-btns": {
            marginTop: "40px !important",
            marginRight: "-15px"
          }
        },

        ".ant-modal-confirm-body": {
          margin: " -32px",
          borderBottom: "1px solid #f0f0f0",
          "& span svg": {
            display: "none !important"
          },
          "& .ant-modal-confirm-title": {
            padding: "16px 24px",
            borderRadius: "2px 2px 0 0",
            color: "#fff",
            fontSize: "20px",
            lineHeight: "22px",
            background: "#000",
            height: "50px"
          },
          "& .ant-modal-confirm-content": {
            margin: "22px !important"
          }
        }
      }}
    />
  );
};
