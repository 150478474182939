import React, { useCallback, useEffect, useMemo } from "react";
import { useLogic } from "./useLogic";
import { DocumentId } from "../../../../../domain/struct/document/Document";
import {
  ShipmentComponent,
  ShipmentComponentId
} from "../../../../../domain/struct/shipment/ShipmentComponent";
import { RemoteTable } from "presentation/designSystem/Table/RemoteTable";
import { ColumnType } from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createFileSizeColumn
} from "presentation/designSystem/Table/column/column";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "../../../../share/utils/getPath";
import { ComponentValidationResult } from "../../../../core/components/dialog/tabs/shipmentComponents/methods";
import { TableSummary } from "presentation/designSystem/Table/TableSummary";
import { Text } from "presentation/designSystem/Typography/Typography";
import { FileSize } from "../../../../share/components/file/FileSize/FileSize";
import { IconButton } from "presentation/designSystem/Button/IconButton";
import { EyeOutlined } from "presentation/designSystem/Icon/Icon";
import { ShipmentId } from "../../../../../domain/struct/shipment/Shipment";

export interface ShipmentComponentTableProps {
  documentId: DocumentId;
  shipmentId?: ShipmentId;
  shipmentType: string;
  shipmentComponentsIds?: ShipmentComponentId[];
  readonly?: boolean;
  onSelectionChanged: (
    ids: ShipmentComponentId[],
    validationResult: ComponentValidationResult
  ) => void;
  onPreviewClicked: (component: ShipmentComponent) => void;
}

const PAGINATION_CONFIG = { defaultPageSize: 1000000, hideOnSinglePage: true };
export const ShipmentComponentTable: React.FC<ShipmentComponentTableProps> = React.memo(
  (props: ShipmentComponentTableProps) => {
    const { onSelectionChanged, readonly, onPreviewClicked } = props;
    const {
      getComponents,
      rowSelection,
      validationResult,
      selectedComponentIds
    } = useLogic(props);

    useEffect(() => {
      onSelectionChanged(
        selectedComponentIds as ShipmentComponentId[],
        validationResult
      );
    }, [selectedComponentIds, onSelectionChanged, validationResult]);

    const summary = useCallback(
      () => <ShipmentComponentsValidation {...validationResult} />,
      [validationResult]
    );

    const isRowDisabled = useCallback(
      ({ isSelectable }: ShipmentComponent) => readonly || !isSelectable,
      [readonly]
    );

    const COLUMNS: ColumnType<ShipmentComponent>[] = useMemo(
      () => [
        createColumn({
          dataIndex: "fileName",
          title: t(translationPath(lang.general.name))
        }),
        createFileSizeColumn({
          dataIndex: "size",
          title: t(translationPath(lang.general.size))
        }),
        createColumn({
          render(value, component) {
            return (
              <IconButton
                icon={<EyeOutlined />}
                tooltip={t(translationPath(lang.modal.showPreview))}
                onClick={() => {
                  onPreviewClicked(component);
                }}
              />
            );
          }
        })
      ],
      [onPreviewClicked]
    );

    return (
      <RemoteTable<ShipmentComponent>
        name={`shipmentComponents/${props.documentId}/${props.shipmentId}`}
        columns={COLUMNS}
        getRemoteData={getComponents}
        rowSelection={rowSelection}
        isRowDisabled={isRowDisabled}
        summary={summary}
        pagination={PAGINATION_CONFIG}
      />
    );
  }
);

const ShipmentComponentsValidation = ({
  isTooBig,
  isWrongCountOfFiles,
  selectedCount,
  sizeInBytes
}: ComponentValidationResult) => {
  return (
    <TableSummary.Row>
      <TableSummary.Cell index={0} />
      <TableSummary.Cell index={1}>
        <Text strong>
          {t(translationPath(lang.general.attachmentsCount))}
          {": "}
          <Text type={isWrongCountOfFiles ? "danger" : undefined}>
            {selectedCount}
          </Text>
        </Text>
      </TableSummary.Cell>
      <TableSummary.Cell index={2}>
        <Text type={isTooBig ? "danger" : undefined} strong>
          <FileSize size={sizeInBytes} />
        </Text>
      </TableSummary.Cell>
    </TableSummary.Row>
  );
};
