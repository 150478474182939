import { NameRegisterConfigurationRepository } from "domain/service/nameRegistry/NameRegisterConfigurationRepository";
import { NameRegisterConfiguration } from "domain/struct/nameRegistry/NameRegisterConfiguration";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { NameRegisterConfigurationApiMapper } from "./NameRegisterConfigurationApiMapper";

const URL: ApiUrls = { Update: "/api/admin/v1/name-register/configuration" };

@DInjectable()
export class NameRegisterConfigurationApiRepository
  implements NameRegisterConfigurationRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  update(nameRegisterConfiguration: NameRegisterConfiguration): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.Update, HttpMethod.Post, {
      bodyJSON: NameRegisterConfigurationApiMapper.mapToApi(
        nameRegisterConfiguration
      )
    });
  }
}
