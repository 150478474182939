export enum AlfrescoNames {
  OWNER = "OWNER"
}

export enum Associations {
  Documents = "ssl:documents",
  Components = "ssl:components",
  Databox = "ssl:digitalDeliveryAttachments",
  Email = "ssl:digitalDeliveryAttachments",
  ShipmentsCreated = "ssl:shipmentsCreated",
  ShipmentsReturned = "ssl:shipmentsReturned"
}

export enum DocumentType {
  Analog = "analog",
  Databox = "databox",
  Digital = "digital",
  Email = "email",
  Hybrid = "hybrid",
  TechnicalDataCarries = "technicalDataCarries",
  Concept = "concept",
  DA = "konverzeDA",
  AD = "konverzeAD"
}

export enum ErrorCodeList {
  SettleOutputReadableType = "V_SETTLE_OUTPUTFORMAT_READABLE_TYPE",
  SettleOutputFormat = "V_SETTLE_OUTPUTFORMAT",
  SettleReadableType = "V_SETTLE_READABLE_TYPE",
  SettleInvalidFilePlan = "V_SETTLE_INVALID_FILEPLAN",
  SettleInvalidFileMark = "V_SETTLE_INVALID_FILEMARK",
  CloseInvalidFilePlan = "V_CLOSE_INVALID_FILEPLAN",
  CloseInvalidFileMark = "V_CLOSE_INVALID_FILEMARK",
  UserDoesNotExist = "A_USER_DOES_NOT_EXIST",
  IncorrectPassword = "INCORRECT_PASSWORD",
  PasswordDuplicity = "PASSWORD_DUPLICITY",
  PasswordComplexity = "PASSWORD_COMPLEXITY",
  PasswordLength = "PASSWORD_LENGTH",
  AntivirusHarmfulContent = "ANTIVIRUS_HARMFUL_CONTENT",
  AntivirusNotWorking = "ANTIVIRUS_NOT_WORKING",
  DataBoxDoesNotMatch = "NR_SENDER_DATABOX_DO_NOT_MATCH",
  DataBoxIsMissing = "Property ssl:senderContactDataboxId is mandatory for this type of document",
  DataBoxNotDelivered = "The DataBoxMessageId field is required.",
  DataBoxNotFound = "DBX_NOT_FOUND",
  EmailAddressDoesNotMatch = "NR_SENDER_EMAIL_DO_NOT_MATCH",
  EmailAddressIsMissing = "Property ssl:senderContactEmailId is mandatory for this type of document",
  AddressTypeAlreadyExists = "Number of addresses not allowed",
  ContactTypeAlreadyExists = "NR_CONTACT_ALREADY_EXISTS",
  NameMustNotBeEmpty = "'Name' must not be empty.",
  RelationConcernedSubjectExists = "NR_RELATIONS_CONCERNED_SUBJECT_EXISTS",
  RelationSenderExists = "NR_RELATIONS_SENDER_EXISTS",
  RelationRecipientExists = "NR_RELATIONS_RECIPIENT_ALREADY_EXISTS",
  SurnameMustNotBeEmpty = "'Surname' must not be empty.",
  SubjectMustHaveAtLeastOneContact = "Subject must have at least one contact",
  SubjectMustHaveAtLeastOneAddress = "Subject must have at least one address",
  AtLeastOneAddressMustBeTypeOfCOMPANY_HEADQUARTERS = "At least one address must be type of COMPANY_HEADQUARTERS",
  CompanyHeadquartersCantBeDeleted = "NR_COMPANY_HEADQUARTERS_CANT_BE_DELETED",
  CompanyNameMustNotBeEmpty = "'Company Name' must not be empty.",
  VATMustBeANumber = "Must be a number",
  DocumentMustHaveFormOfAnalog = "Document must have form of analog.",
  RelationsConcernedSubjectExist = "NR_RELATIONS_CONCERNED_SUBJECT_EXISTS",
  SubjectHasToHaveAddress = "NR_SUBJECT_HAS_TO_HAVE_ADDRESS",
  ShreddingMissingLocation = "SHREDDING_MISSING_LOCATION",
  ShreddingBorrowed = "SHREDDING_BORROWED",
  ShreddingDiscarded = "SHREDDING_DISCARDED",
  ShreddingProposalCancelDecisionDA = "SHREDDING_PROPOSAL_CANCEL_DECISION_DA",
  ShreddingProposalCancelSIP = "SHREDDING_PROPOSAL_CANCEL_SIP",
  ShreddingProposalRemoveDecisionDA = "SHREDDING_PROPOSAL_REMOVE_DECISION_DA",
  ShreddingProposalRemoveSIP = "SHREDDING_PROPOSAL_REMOVE_SIP",
  WrongImportStructure = "IMPORT_STRUCTURE",
  NumberOfContactsNotAllowed = "Number of contacts not allowed",
  LegalSubjectExists = "NR_LEGAL_SUBJECT_EXISTS",
  SettleMandatoryFields = "SETTLE_MANDATORY_FIELDS",
  IsNotPDF = "IS_NOT_PDF_FILE",
  EmailIsDuplicity = "WHITELIST_EMAIL_DUPLICITY",
  PdfFileIsEncrypted = "PDF_FILE_IS_ENCRYPTED",
  PageCountGreaterZero = "'Page Count' must be greater than or equal to '1'.",
  MissingComponent = "Document must have at least one component"
}

export enum SitePaths {
  Administration = "Administration",
  Archive = "Archive",
  Archived = "Archived",
  Cancelled = "Cancelled",
  Closed = "Closed",
  Components = "Components",
  Concepts = "Concepts",
  DataBox = "DataBox",
  Unprocessed = "Unprocessed",
  Dispatch = "Dispatch",
  Dispatched = "Dispatched",
  Documents = "Documents",
  Evidence = "Evidence",
  Files = "Files",
  ForProcessing = "ForProcessing",
  ForSignature = "ForSignature",
  Lent = "Lent",
  LostDestroyed = "LostDestroyed",
  MailBox = "MailBox",
  Mailroom = "Mailroom",
  NotPassed = "NotPassed",
  NotRegistered = "NotRegistered",
  Open = "Open",
  Processed = "Processed",
  ReadyForShredding = "ReadyForShredding",
  Rented = "Rented",
  Repository = "Repository",
  Returned = "Returned",
  SignatureBook = "SignatureBook",
  Shredded = "Shredded",
  ShreddingPlan = "ShreddingPlan",
  ShreddingProposal = "ShreddingProposal",
  Stored = "Stored",
  ToDispatch = "ToDispatch",
  ToAprove = "ToAprove",
  ToHandover = "ToHandover",
  ToTakeOver = "ToTakeOver",
  Unfinished = "Unfinished",
  WaitingForSignature = "WaitingForSignature",
  WaitingForTakeOver = "WaitingForTakeOver"
}

export enum SubmitToRepositoryDialog {
  Documents = "Documents",
  Files = "Files"
}

export enum SpisumGroups {
  Admin = "GROUP_ESSL_Admin",
  Dispatch = "GROUP_Dispatch_1",
  Dispatch0 = "GROUP_Dispatch",
  Mailroom = "GROUP_Mailroom",
  Main = "GROUP_Spisum",
  SpisumNodes = "GROUP_AllNodes",
  Repository = "GROUP_Repository",
  Roles = "GROUP_Roles",
  NameRegister = "GROUP_NameRegister"
}

export enum SpisumNames {
  Borrower = "ssl:borrower",
  BorrowGroup = "ssl:borrowGroup",
  CancelDate = "ssl:cancelDate",
  DispatchedDate = "ssl:dispatchedDate",
  ForSignatureGroup = "ssl:forSignatureGroup",
  Group = "ssl:group",
  IsClosed = "ssl:isClosed",
  IsInFile = "ssl:isInFile",
  IsLostDestroyed = "ssl:isLostDestroyed",
  IsFavorite = "ssl:isFavorite",
  NextGroup = "ssl:nextGroup",
  NextOwner = "ssl:nextOwner",
  NextOwnerDecline = "ssl:nextOwnerDecline",
  RetentionProposal = "ssl:retentionProposal",
  ReturnedForReworkDate = "ssl:returnedForReworkDate",
  SendMode = "ssl:sendMode",
  ShreddingYear = "ssl:shreddingYear"
}

export enum SpisumNamesWithoutPrefix {
  IsFavorite = "isFavorite"
}

export enum ShipmentPersonally {
  Delivered = "Dorucena"
}
export enum ShipmentPost {
  ToDispatch = "kVypraveni",
  Dispatched = "Vypravena"
}

export enum SpisumNodeTypes {
  Concept = "ssl:concept",
  Databox = "ssl:databox",
  Document = "ssl:document",
  DocumentArchived = "ssl:documentArchived",
  DocumentDestroyed = "ssl:documentDestroyed",
  Email = "ssl:email",
  File = "ssl:file",
  FileArchived = "ssl:fileArchived",
  FileDestroyed = "ssl:fileDestroyed",
  Shipment = "ssl:shipment",
  ShipmentDatabox = "ssl:shipmentDatabox",
  ShipmentEmail = "ssl:shipmentEmail",
  ShipmentPersonally = "ssl:shipmentPersonally",
  ShipmentPost = "ssl:shipmentPost",
  ShipmentPublish = "ssl:shipmentPublish",
  TakeConcept = "ssl:takeConcept",
  TakeDocumentForProcessing = "ssl:takeDocumentForProcessing",
  TakeDocumentProcessed = "ssl:takeDocumentProcessed",
  TakeFileClosed = "ssl:takeFileClosed",
  TakeFileOpen = "ssl:takeFileOpen"
}

export enum SpisumValues {
  Group = "-group-"
}

export enum ComponentKeepForm {
  OriginalInOutputFormat = "original_inOutputFormat"
}

export enum SenderType {
  Individual = "FO",
  Legal = "PO",
  Own = "own"
}

export enum SendModeValues {
  Email = "email",
  Databox = "databox",
  Post = "post",
  Publish = "publish",
  Personally = "personally"
}

export enum DeliveryMode {
  Currier = "currier",
  Databox = "databox",
  Email = "email",
  Personally = "personally",
  Post = "post"
}

export enum FormMode {
  Analog = "analog",
  Digital = "digital",
  Hybrid = "hybrid"
}

export enum StateMode {
  OPEN = "open",
  CLOSED = "closed"
}

export enum SettleMethod {
  Document = "dokumentem",
  DocumentNote = "zaznamemNaDokumentu",
  Forward = "postoupenim",
  TakeIntoAccount = "vzetimNaVedomi",
  Other = "jinyZpusob"
}

export enum CodeList {
  customSettleMethod = "rmc_CustomSettleMethod"
}

export enum DocumentState {
  NotSettled = "nevyrizen",
  Open = "otevrit",
  Settled = "vyrizen",
  Closed = "uzavren",
  ReferedToRepository = "predanDoSpisovny",
  ReferedToArchive = "predanDoArchivu",
  Cancelled = "stornovan",
  Shredded = "skartovan"
}

export enum PostType {
  cashOnDelivery = "Dobirka",
  deliveryReport = "Dodejka",
  fee = "Doplatne",
  restrictedDelivery = "DoVlastnichRukou",
  restrictedDeliveryExplicitly = "DoVlastnichRukouVyhradne",
  fragile = "Krehke",
  dontSend = "Nedosilat",
  dontPrologPeriod = "NeprodluzovatLhutu",
  dontPrologCollectionPeriod = "NeprodluzovatOdberniLhutu",
  dontStore = "Neukladat",
  unstorable = "Neskladne",
  unstorableII = "NeskladneII",
  dontPutInPostbox = "NevkladatDoSchranky",
  dontReturn = "Nevracet",
  dontReturnPutInPostbox = "NevracetVlozitDoSchranky",
  replyMails = "OdpovedniZasilky",
  subscriptionBasic = "OpakovaneDodaniBezne",
  subscriptionSpecial = "OpakovaneDodaniZvlastni",
  firstClass = "Pilne",
  prolongCollectionPeriod1month = "ProdlouzeniOdberniLhuty1Mesic",
  payout = "TerminovaVyplata",
  price = "UdanaCena",
  reduceCollectionPeriod3days = "ZkraceniOdberniLhuty3Dny",
  reduceCollectionPeriod10days = "ZkraceniOdberniLhuty10Dni",
  reduceCollectionPeriod1month = "ZkraceniOdberniLhuty1Mesic",
  other = "Jina"
}

export enum PostItemType {
  empty = "",
  insuredParcel = "CennyBalik",
  insuredLetter = "CennePsani",
  internationalInsuredParcel = "CennyBalikDoZahranici",
  internationalInsuredLetter = "CennePsaniDoZahranici",
  registeredMail = "DoporucenaZasilka",
  internationalRegisteredMail = "DoporucenaZasilkaDoZahranici",
  registeredMailStandard = "DoporucenaZasilkaStandard",
  registeredMailForTheBlind = "DoporucenaSlepeckaZasilka",
  internationalRegisteredMailForTheBlind = "DoporucenaSlepeckaZasilkaDoZahranici",
  businessParcel = "ObchodniBalik",
  internationalBusinessParcel = "ObchodniBalikDoZahranici",
  businessLetter = "ObchodniPsani",
  internationalBusinessLetter = "ObchodniPsaniDoZahranici",
  ordinaryParcel = "ObycejnyBalik",
  ordinaryLetter = "ObycejnePsani",
  ordinaryLetterStandard = "ObycejnePsaniStandard",
  ordinaryMailForTheBlind = "ObycejnaSlepeckaZasilka",
  internationalOrdinaryMailForTheBlind = "ObycejnaSlepeckaZasilkaDoZahranici",
  internationalOrdinaryMail = "ObycejnaZasilkaDoZahranici",
  internationalParcelStandard = "StandartniBalikDoZahranici",
  other = "Jina"
}

export enum DateTimeFormats {
  FullDateTime = "DD.MM.YYYY HH:mm:ss",
  Date = "DD.MM.YYYY",
  HoursMinutes = "HH:mm",
  HoursMinutesSeconds = "HH:mm:ss",
  Year = "YYYY"
}

export enum PostTypeLangMap {
  Dobirka = "cashOnDelivery",
  Dodejka = "deliveryReport",
  Doplatne = "fee",
  DoVlastnichRukou = "restrictedDelivery",
  DoVlastnichRukouVyhradne = "restrictedDeliveryExplicitly",
  Krehke = "fragile",
  Nedosilat = "dontSend",
  NeprodluzovatLhutu = "dontPrologPeriod",
  NeprodluzovatOdberniLhutu = "dontPrologCollectionPeriod",
  Neukladat = "dontStore",
  Neskladne = "unstorable",
  NeskladneII = "unstorableII",
  NevkladatDoSchranky = "dontPutInPostbox",
  Nevracet = "dontReturn",
  NevracetVlozitDoSchranky = "dontReturnPutInPostbox",
  OdpovedniZasilky = "replyMails",
  OpakovaneDodaniBezne = "subscriptionBasic",
  OpakovaneDodaniZvlastni = "subscriptionSpecial",
  Pilne = "firstClass",
  ProdlouzeniOdberniLhuty1Mesic = "prolongCollectionPeriod1month",
  TerminovaVyplata = "payout",
  UdanaCena = "price",
  ZkraceniOdberniLhuty3Dny = "reduceCollectionPeriod3days",
  ZkraceniOdberniLhuty10Dni = "reduceCollectionPeriod10days",
  ZkraceniOdberniLhuty1Mesic = "reduceCollectionPeriod1month",
  Jina = "other"
}

export enum SafetyElementAnalog {
  Embossing = "Embossing",
  OptickyVariabilniPrvek = "Optický variabilní prvek",
  PlastickyText = "Plastický text",
  ReliefniRazba = "Reliéfní ražba",
  ReliefniTisk = "Reliéfní tisk",
  SuchaPecet = "Suchá pečeť",
  Vodoznak = "Vodoznak"
}
