import { Description, RestorePage } from "@material-ui/icons";
import { openDocumentWithSaveButtonsAction } from "presentation/core/api/document/_actions";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import { openFileDetailsAction } from "presentation/core/components/dialog/tabs/tableOfContents/_actions";
import DocumentView from "presentation/core/components/documentView";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { SessionType } from "presentation/core/features/login/_types";
import { GenericDocument, genericDocumentProxy } from "presentation/core/types";
import { DocumentType, SitePaths, SpisumNodeTypes } from "presentation/enums";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { dialogOpenConceptDetails } from "presentation/share/components/dialog/conceptDetailsDialog/_actions";
import { openFoundDialog } from "presentation/share/components/dialog/foundDialog/_actions";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { isUserInLeadership } from "presentation/share/utils/user";
import { lang, t, withTranslation } from "presentation/translation/i18n";

const defaultColumn: DataColumn<GenericDocument> = {
  getValue: (x) =>
    x.nodeType === SpisumNodeTypes.File
      ? x?.properties?.ssl?.createdDate
      : x?.properties?.ssl?.senderType === "own"
      ? x?.createdAt
      : x?.properties?.ssl?.deliveryDate,
  isDateTime: true,
  keys: [
    classPath(genericDocumentProxy.properties!.ssl!.deliveryDate).path,
    classPath(genericDocumentProxy.createdAt).path,
    classPath(genericDocumentProxy.properties!.ssl!.createdDate).path
  ],
  label: t(translationPath(lang.general.dateOfEvidence))
};

const getColumns = (session: SessionType): DataColumn<GenericDocument>[] => {
  const columns: DataColumn<GenericDocument>[] = [
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.pid).path],
      label: t(translationPath(lang.general.identifier))
    },
    {
      getValue: (item) =>
        item.nodeType === SpisumNodeTypes.File
          ? item.properties?.ssl?.fileIdentifier
          : item.properties?.ssl?.ssid,
      keys: [
        classPath(genericDocumentProxy.properties!.ssl!.ssid).path,
        classPath(genericDocumentProxy.properties!.ssl!.fileIdentifier).path
      ],
      label: `${t(translationPath(lang.general.referenceNumber))}/${t(
        translationPath(lang.general.fileIdentifier)
      )}`
    },
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.subject).path],
      label: t(translationPath(lang.general.subject))
    },
    defaultColumn,
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.lostReason).path],
      label: t(translationPath(lang.general.lostReason))
    },
    {
      isDateTime: true,
      keys: [classPath(genericDocumentProxy.properties!.ssl!.lostDate).path],
      label: t(translationPath(lang.general.lostDate))
    }
  ];

  if (isUserInLeadership(session)) {
    columns.push({
      keys: [
        classPath(genericDocumentProxy.properties!.cm!.owner?.displayName).path
      ],
      label: t(translationPath(lang.general.owner))
    });
  }

  return columns;
};

const LostDestroyedOverviews = () => {
  const dispatch = useDispatch();
  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );
  const relativePath = useSelector((state: RootStateType) =>
    getRelativePath(
      state.loginReducer.global.paths,
      state.loginReducer.session.activeGroup,
      SitePaths.Evidence,
      SitePaths.LostDestroyed
    )
  );

  const dispatchOpenDialog: (row: GenericDocument) => void = (row) => {
    if (row.nodeType === SpisumNodeTypes.Document) {
      dispatch(
        openDocumentWithSaveButtonsAction({
          canUploadComponents: false,
          data: row,
          hideManageShipmentsIcon: true,
          isReadonly: true
        })
      );
    } else if (row.nodeType === SpisumNodeTypes.File) {
      dispatch(
        openFileDetailsAction({
          data: row,
          hideManageShipmentsIcon: true,
          isReadonly: true
        })
      );
    } else if (row.nodeType === SpisumNodeTypes.Concept) {
      dispatch(
        dialogOpenConceptDetails({
          data: row,
          hideManageShipmentsIcon: true,
          isReadonly: true
        })
      );
    }
  };

  const controls: ControlsBarType<GenericDocument> = {
    multi: {
      items: [
        {
          action: (selected: GenericDocument[]) => {
            dispatch(openFoundDialog(selected));
          },
          icon: <RestorePage />,
          title: t(translationPath(lang.general.find)),
          filter: (x) => x.properties?.ssl?.form !== DocumentType.DA
        }
      ]
    },
    single: {
      items: [
        {
          action: (selected: GenericDocument[]) =>
            dispatchOpenDialog(selected[0]),
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(openFoundDialog(selected));
          },
          icon: <RestorePage />,
          title: t(translationPath(lang.general.find)),
          filter: (document) =>
            document.properties?.ssl?.form !== DocumentType.DA
        }
      ]
    }
  };

  return (
    <MenuLayout>
      <DocumentView
        children={{
          relativePath
        }}
        columns={getColumns(session)}
        controls={controls}
        customTitle={t(translationPath(lang.table.lostDestroyed))}
        defaultSortAsc={true}
        defaultSortColumn={defaultColumn}
        handleDoubleClick={dispatchOpenDialog}
      />
    </MenuLayout>
  );
};

export default withTranslation()(LostDestroyedOverviews);
