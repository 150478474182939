import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { SortingConfig } from "lib/contract/Sorting";
import { PaginationConfig } from "lib/contract/Pagination";
import { RetentionProposalsRepositoryToken } from "../../domain/di/token/recordRetention";
import { RetentionProposalsRepository } from "../../domain/service/recordRetention/RetentionProposalsRepository";

@DInjectable()
export class FindRetentionProposals {
  constructor(
    @DInject(RetentionProposalsRepositoryToken)
    protected repository: RetentionProposalsRepository
  ) {}

  findAllPaginated(config: PaginationConfig, sorting?: SortingConfig) {
    return this.repository.getRetentionProposals(config, sorting);
  }

  findOne(nodeId: string) {
    return this.repository.findRetentionProposalById(nodeId);
  }
  uploadDecisionFromDA(nodeId: string, file: File) {
    return this.repository.uploadDecisionFromDA(nodeId, file);
  }
}
