import styled from "@emotion/styled";
import { Form, FormInstance } from "presentation/designSystem/Form/v2/Form";
import React from "react";
import { ForSealFieldSelects } from "./forSealFields";
import { DocumentForSealForm } from "../_types";

export interface DocumentsForSealFormProps {
  form: FormInstance<DocumentForSealForm>;
}
const DocumentsForSealForm = ({ form }: DocumentsForSealFormProps) => {
  return (
    <StyledDiv>
      <Form
        name="documentForSealFormForm"
        form={form}
        style={{ width: "100%" }}
      >
        <ForSealFieldSelects form={form}></ForSealFieldSelects>
      </Form>
    </StyledDiv>
  );
};

export default DocumentsForSealForm;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
`;
