import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { LogoutRepositoryToken } from "../../domain/di/token/authentication";
import { LogoutRepository } from "../../domain/service/authentication/LogoutRepository";

@DInjectable()
export class Logout {
  constructor(
    @DInject(LogoutRepositoryToken) protected repository: LogoutRepository
  ) {}
  logout() {
    return this.repository.logout();
  }
}
