import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";

import { Select } from "../../../../../../designSystem/Select/Select";
import {
  FormInstance,
  Item
} from "../../../../../../designSystem/Form/v2/Form";
import { useFindGroupsForSeal } from "../hooks/useGroupsForSeal";
import { ForSealForm } from "./ForSealFormEnums";
import { ForSealUsersFieldSelects } from "./forSealUsersField";
import { DocumentForSealForm } from "../_types";

const validationGroupUserTypeRules = [{ required: true }];
export interface DocumentsForSealFormProps {
  form: FormInstance<DocumentForSealForm>;
}
export const ForSealFieldSelects = ({ form }: DocumentsForSealFormProps) => {
  const { data } = useFindGroupsForSeal();

  const [groupId, setGroupId] = useState("");

  const onGroupTypeChange = useCallback(
    (value: any) => {
      form.resetFields(["user"]);
      setGroupId(value);
    },
    [form]
  );
  const options = useMemo(
    () =>
      data
        ? data.map(({ id, displayName }) => ({
            label: displayName,
            value: id
          }))
        : [],
    [data]
  );

  return (
    <StyledDiv>
      <StyledSelectItem
        name="group"
        label={ForSealForm.groups}
        rules={validationGroupUserTypeRules}
      >
        <Select
          onChange={onGroupTypeChange}
          style={{ width: "15rem" }}
          options={options}
        />
      </StyledSelectItem>

      <ForSealUsersFieldSelects groupId={groupId}></ForSealUsersFieldSelects>
    </StyledDiv>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;
const StyledDiv = styled("div")`
  display: flex;
  justify-content: flex-start;
`;
