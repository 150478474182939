import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { SortingConfig } from "lib/contract/Sorting";
import { PaginationConfig } from "lib/contract/Pagination";
import { RetentionProtocolsRepositoryToken } from "../../domain/di/token/recordRetention";
import { RetentionProtocolsRepository } from "../../domain/service/recordRetention/RetentionProtocolsRepository";

@DInjectable()
export class FindRetentionProtocols {
  constructor(
    @DInject(RetentionProtocolsRepositoryToken)
    protected repository: RetentionProtocolsRepository
  ) {}

  findAllPaginated(config: PaginationConfig, sorting?: SortingConfig) {
    return this.repository.getRetentionProtocols(config, sorting);
  }

  findOneRetentionProtocol(nodeId: string) {
    return this.repository.findOneRetentionProtocol(nodeId);
  }
  uploadAcceptanceFromDA(nodeId: string, file: File) {
    return this.repository.uploadAcceptanceFromDA(nodeId, file);
  }
}
