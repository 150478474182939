import React, { useState } from "react";
import { useForm } from "@isfg/react-components/build/Form/Form";
import { BaseModalProps } from "@isfg/react-components/build/Modal/useModal";
import { Tabs } from "@isfg/react-components/build/Tabs/Tabs";
import { Button } from "presentation/designSystem/Button/Button";
import { SslDatabox } from "presentation/core/api/models";
import { useComponentsV2TabContainer } from "presentation/core/components/dialog/tabs/components/hooks/useComponentsV2TabContainer";
import { DataboxDocument } from "presentation/core/types";
import { RemoteTableApiContextProvider } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { DataboxDetailsForm } from "../../unprocessed/forms/DataboxDetailsForm";
import { UseCreateDataboxRegistrationReturn } from "../../unprocessed/hooks/useCreateDataboxRegistration";
import { SpisumNodeTypes } from "../../../../../../../../enums";
import { ModalWithPreview } from "presentation/designSystem/Modal/ModalWithPreview";

interface DataboxNotRegisteredDetailsModalProps {
  onCancel?: BaseModalProps["onCancel"];
  onOk?: BaseModalProps["onOk"];
  data: DataboxDocument;
  register: UseCreateDataboxRegistrationReturn["register"];
}

enum DataboxNotRegisteredDetailsModalTabs {
  Metadata = "0",
  Components = "1"
}

export const DataboxNotRegisteredDetailsModal = (
  props: DataboxNotRegisteredDetailsModalProps
) => {
  return (
    <RemoteTableApiContextProvider>
      <DataboxNotRegisteredDetailsModalInner {...props} />
    </RemoteTableApiContextProvider>
  );
};

export const DataboxNotRegisteredDetailsModalInner = ({
  onCancel,
  data,
  register
}: DataboxNotRegisteredDetailsModalProps) => {
  const [activeTab, setActiveTab] = useState("0");
  const [form] = useForm<SslDatabox>();
  const formData = data.properties!.ssl!;

  const changeActiveTab = (activeTabIndex: string) => {
    setActiveTab(activeTabIndex);
  };

  const [showPreview, setShowPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState({
    id: "",
    name: "",
    nodeType: SpisumNodeTypes.Databox,
    entityId: "",
    fileIsEncrypted: false
  });

  const onRegister = () => {
    register(data.id);
    onCancel?.();
  };

  const [
    componentsV2TabContainer,
    { fetchComponents }
  ] = useComponentsV2TabContainer({
    nodeId: data.id,
    isActive: activeTab === DataboxNotRegisteredDetailsModalTabs.Components,
    dialogProps: {
      canUploadComponents: false,
      data
    },
    isReadonly: true,
    showPreview,
    previewItem,
    setPreviewItem,
    setShowPreview
  });

  const tabsWithRefresh = {
    [DataboxNotRegisteredDetailsModalTabs.Components]: fetchComponents
  };

  return (
    <ModalWithPreview
      visible={true}
      hasTabs
      onRefresh={tabsWithRefresh[activeTab]}
      title={t(translationPath(lang.dialog.title.databoxDetails))}
      onCancel={onCancel}
      confirmLoading={false}
      handleShowPreviewChange={() => setShowPreview(false)}
      showPreview={showPreview}
      previewItem={previewItem}
      setPreviewItem={setPreviewItem}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t(translationPath(lang.modal.cancel))}
        </Button>,
        <Button key="register" type="primary" onClick={onRegister}>
          {t(translationPath(lang.dialog.form.register))}
        </Button>
      ]}
    >
      <Tabs onChange={changeActiveTab}>
        <Tabs.TabPane
          tab={t(translationPath(lang.dialog.subjectDialog.tabs.metadata))}
          key={DataboxNotRegisteredDetailsModalTabs.Metadata}
        >
          <DataboxDetailsForm formData={formData} form={form} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t(translationPath(lang.dialog.tabs.components))}
          key={DataboxNotRegisteredDetailsModalTabs.Components}
        >
          {componentsV2TabContainer}
        </Tabs.TabPane>
      </Tabs>
    </ModalWithPreview>
  );
};
