import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "../../../../designSystem/Modal/Modal";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import { Form, Item, useForm } from "../../../../designSystem/Form/v2/Form";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { SubjectModal } from "../SubjectModal/SubjectModal";
import { useMuiVisibility } from "./useMuiVisibility";
import { SubjectTypeInput } from "../../form/SubjectForm/Input/SubjectTypeInput";
import { getService } from "../../../../core/features/dependencyInjection";
import { FindAddress } from "../../../../../useCase/nameRegistry/address/FindAddress";
import { FindContact } from "../../../../../useCase/nameRegistry/contact/FindContact";
import { SubjectContactDetailsModal } from "../SubjectContactDetails/SubjectContactDetailsModal";
import { Contact } from "../../../../../domain/struct/nameRegistry/Contact";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { SearchSubjectLegalEntityTable } from "../../tables/AddressesTable/SearchSubjectTables/SearchSubjectLegalEntityTable";
import { SearchSubjectPersonTable } from "../../tables/AddressesTable/SearchSubjectTables/SearchSubjectPersonTable";
import { useSearchSubject } from "./hooks/useSearchSubject";
import { useModal } from "../../../../designSystem/Modal/useModal";
import { Subject } from "../../../../../domain/struct/nameRegistry/Subject";
import {
  actionAdd,
  actionShowDetail
} from "../../../../share/components/table/actionFactory";
import { TableActions } from "../../../../designSystem/Table/Table";
import { Input } from "../../../../designSystem/Input/Input";
import { styled } from "../../../../styles";
import { DeliveryMode } from "presentation/enums";
import { TableRowSelection } from "antd/lib/table/interface";
import { LegalEntity } from "domain/struct/nameRegistry/LegalEntity";

type OnCancelClb = () => void;

export interface SearchSubjectResult {
  subject: Subject;
  addresses?: Address[];
  contacts?: Contact[];
}

export type SubjectModalProps = {
  deliveryMode?: string;
  onCancel?: OnCancelClb;
  onOk?: (result?: SearchSubjectResult) => void;
  // returns only selected subject, not addresses and contacts
  returnOnlySubject?: boolean;
};

const { Search } = Input;

export const SearchSubjectModal = (props: SubjectModalProps) => {
  const {
    selectedSubjectType,
    selectedSubject,
    setSelectedSubject,
    setSelectedRowKeys,
    selectedRowKeys,
    remoteQueryParams,
    onSearch,
    onSelectChange
  } = useSearchSubject();
  const [subjectContactDetailsModal, subjectContactDetailsModalApi] = useModal(
    SubjectContactDetailsModal,
    {
      onOk(result) {
        if (!selectedSubject) {
          return;
        }

        props.onOk?.({
          subject: selectedSubject,
          ...result
        });
      }
    }
  );
  const [form] = useForm();
  const [isSubjectCreated, setIsSubjectCreated] = useState(false);

  const [subjectModal, subjectModalApi] = useModal(SubjectModal, {
    onOk(result) {
      if (result) {
        onSelectChange(result.subjectType);
        onSearch(
          isSubjectCreated
            ? result.subjectType === SubjectType.LegalEntity
              ? (result as LegalEntity).companyFullName!
              : result.fullName!
            : ""
        );
        form.setFieldsValue({
          subjectType: result.subjectType,
          search: ""
        });
      }
    }
  });

  useMuiVisibility();

  const onOk = useCallback(async () => {
    if (!selectedSubject) {
      props?.onCancel?.();
      return;
    }

    if (props.returnOnlySubject) {
      props.onOk?.({
        subject: selectedSubject
      });
      return;
    }

    const serviceAddresses = getService(FindAddress);
    const serviceContacts = getService(FindContact);

    const responseAddresses = await serviceAddresses.findBySubjectId(
      "" + selectedSubject?.id,
      { itemsPerPage: 100, page: 1 }
    );

    const responseContacts = await serviceContacts.findBySubjectId(
      "" + selectedSubject?.id,
      { itemsPerPage: 100, page: 1 }
    );

    //TODO: in the future there are going to be more contacts so the condition has to be changed
    const { items: contactItems } = responseContacts || {};
    const { items: addressItems } = responseAddresses || {};

    const emails = contactItems.filter(
      (email) => email.contactType === "EMAIL"
    );
    const databoxes = contactItems.filter(
      (databox) => databox.contactType === "DATABOX"
    );
    const phones = contactItems.filter(
      (phone) => phone.contactType === "PHONE"
    );
    if (
      (props.deliveryMode &&
        props.deliveryMode === DeliveryMode.Email &&
        emails.length < 1) ||
      (props.deliveryMode === DeliveryMode.Databox && databoxes.length < 1)
    ) {
      props?.onOk?.({
        subject: selectedSubject,
        addresses: addressItems,
        contacts: contactItems
      });
      return;
    }
    if (
      addressItems.length > 1 ||
      emails.length > 1 ||
      databoxes.length > 1 ||
      phones.length > 1
    ) {
      subjectContactDetailsModalApi.open({
        subjectType: selectedSubjectType,
        addresses: addressItems,
        contacts: contactItems
      });
      return;
    } else {
      props?.onOk?.({
        subject: selectedSubject,
        addresses: addressItems,
        contacts: contactItems
      });
    }
  }, [
    props,
    selectedSubject,
    selectedSubjectType,
    subjectContactDetailsModalApi
  ]);

  const onRowSelection: TableRowSelection<Subject> = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSubject(selectedRows[0]);
    },
    selectedRowKeys
  };

  useEffect(() => {
    setSelectedRowKeys(selectedSubject ? [selectedSubject?.id!] : undefined);
  }, [selectedSubject, setSelectedRowKeys]);

  const ACTIONS: TableActions<Subject> = useMemo(
    () => ({
      default: [
        actionAdd({
          action() {
            setIsSubjectCreated(true);
            return subjectModalApi.open({
              type: selectedSubjectType
            });
          }
        })
      ],
      single: [
        actionShowDetail({
          action(selected) {
            const subject = selected[0];
            setIsSubjectCreated(false);

            return subjectModalApi.open({
              type: subject.subjectType,
              person:
                subject.subjectType === SubjectType.Person
                  ? subject
                  : undefined,
              legalEntity:
                subject.subjectType === SubjectType.LegalEntity
                  ? subject
                  : undefined
            });
          },
          canBeDisplayed: () => selectedSubject !== undefined
        })
      ]
    }),
    [selectedSubject, selectedSubjectType, subjectModalApi]
  );

  const okButtonProps = useMemo(
    () => ({
      disabled: !selectedSubject
    }),
    [selectedSubject]
  );

  return (
    <>
      {subjectModal}
      {subjectContactDetailsModal}
      <Modal
        visible={true}
        title={t(translationPath(lang.dialog.searchSubjectDialog.title))}
        onCancel={props.onCancel}
        onOk={onOk}
        okButtonProps={okButtonProps}
        okText={t(translationPath(lang.modal.ok))}
        cancelText={t(translationPath(lang.modal.cancel))}
      >
        <FormStyled form={form}>
          <SubjectTypeInput name="subjectType" onChange={onSelectChange} />
          <Item name="search" label={"Vyhledat subjekt"}>
            <Search
              enterButton
              allowClear
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </Item>
        </FormStyled>

        {selectedSubjectType === SubjectType.Person ? (
          <SearchSubjectPersonTable
            actions={ACTIONS}
            remoteQueryParams={remoteQueryParams}
            onRowSelection={onRowSelection}
          />
        ) : (
          <SearchSubjectLegalEntityTable
            actions={ACTIONS}
            remoteQueryParams={remoteQueryParams}
            onRowSelection={onRowSelection}
          />
        )}
      </Modal>
    </>
  );
};

const FormStyled = styled(Form)`
  display: flex;
  .ant-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 3rem;
    margin-top: 2rem;
  }

  .ant-form-item-label {
    padding: 0;
    margin-right: 1rem;
  }
`;
