import { HttpClient } from "../../../../../../../../../infrastructure/service/HttpClient";
import { RegisterDataboxDigitalFormFieldTypes } from "../forms/RegisterDataboxDigitalFormFieldTypes";
import { CreateDataboxRegistration } from "../types";
import { mapDocumentToApi } from "./mappers";
import { AnalogCreateDocument } from "../../../../analog/types/types";

export const registerDocument = (id: string): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<void>(
    "/api/app/v2/document/{nodeId}/register",
    "POST",
    {
      urlWildCards: {
        nodeId: id
      }
    }
  );
};

export const updateUnfinishedDocument = <T>(id: string, input: T) => {
  const httpClient = new HttpClient();
  const documentToApi = mapDocumentToApi(input);
  return httpClient.fetchWithThrow<void>(
    "/api/app/v2/document/{nodeId}/update/unfinished",
    "POST",
    {
      bodyJSON: documentToApi,
      urlWildCards: {
        nodeId: id
      }
    }
  );
};

export const createDocumentFromDataBox = (
  id: string
): Promise<CreateDataboxRegistration> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<CreateDataboxRegistration>(
    "/api/app/v2/document/create/digital/{nodeId}/databox",
    "POST",
    {
      urlWildCards: {
        nodeId: id
      }
    }
  );
};

export const createAnalogDocument = (): Promise<AnalogCreateDocument> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<AnalogCreateDocument>(
    "/api/app/v2/document/create/analog",
    "POST"
  );
};
