import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";

import { HouseNumberRepositoryToken } from "../../../domain/di/token/codelist";

import { HouseNumberRepository } from "../../../domain/service/codelist/HouseNumberRepository";

@DInjectable()
export class FindHouseNumber {
  constructor(
    @DInject(HouseNumberRepositoryToken)
    protected repository: HouseNumberRepository
  ) {}

  findAll() {
    return this.repository.findAll();
  }
}
