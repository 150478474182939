import { Input } from "@isfg/react-components/build/Input/Input";
import {
  CardContent,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { Field } from "formik";
import { Checkbox } from "formik-material-ui";
import styled from "presentation/styles";
import { Link } from "react-router-dom";

export const StyledErrorContainer = styled("div")(() => ({
  height: 45
}));

export const StyledErrorIcon = styled(WarningIcon)(() => ({
  marginRight: 10
}));

export const StyledErrorMessage = styled("div")(() => ({
  boxOrient: "horizontal",
  color: "#f44336",
  display: "block",
  flexDirection: "row",
  fontSize: 13,
  justifyContent: "flex-start",
  marginBottom: 4,
  padding: 0,
  "svg,span": {
    verticalAlign: "middle"
  }
}));

export const StyledLoginControllPasswordsContainer = styled("div")(() => ({
  display: "inline-block",
  marginTop: 22
}));

export const StyledForgotInput = styled(Input)(() => ({
  background: "#F2F2F2",
  border: "1px solid #C6CDD3",
  borderRadius: 3,
  fontSize: "15px",
  height: "50px",
  marginTop: 7,
  marginBottom: 0,
  padding: " 6px 4px 6px"
}));

export const StyledForgotField = styled("div")(() => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  paddingBottom: 0
}));

export const StyledForgotPasswordDiv = styled("div")(() => ({
  display: "inline-block"
}));

export const StyledForgotPasswordDivider = styled("div")(() => ({
  display: "inline-block",
  height: "48px"
}));

export const StyledResetPasswordDivider = styled("div")(() => ({
  display: "inline-block",
  height: "10px"
}));

export const StyledForgotPasswordLink = styled(Link)(() => ({
  display: "block",
  textDecorationLine: "underline",
  textAlign: "center"
}));

export const StyledFullWidthField = styled(Field)(({ theme }) => ({
  ".MuiInput-underline input": {
    background: "#F2F2F2",
    border: "1px solid #C6CDD3",
    borderRadius: 3,
    fontSize: "15px",
    height: 30,
    padding: "6px 4px 6px"
  },
  ".MuiInput-underline input ::before": {
    borderBottom: "none"
  },
  ".MuiOutlinedInput-root:not(.Mui-error)": {
    "&.Mui-focused fieldset": {
      borderColor: "#ff9800"
    },
    "&:hover fieldset": {
      borderColor: "#ff9800"
    },
    fieldset: {
      borderColor: "#ff9800"
    }
  },

  caretColor: "#ff9800",

  width: "100%"
}));
export const StyledLoginLabel = styled("label")(() => ({
  color: "#424344",
  fontFamily: "Roboto",
  fontSize: 15,
  fontWeight: 700
}));
export const StyledImgLogo = styled("img")(() => ({
  display: "block",
  height: 67,
  marginLeft: "auto",
  marginRight: "auto",
  maxHeight: 80,
  width: 249
}));

export const StyledInteractiveLoginLabel = styled("div")(() => ({
  boxOrient: "horizontal",
  boxPack: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center"
}));

export const StyledLoginButton = styled("button")(() => ({
  "&.isWelcome": {
    color: "#ffffff"
  },
  "&.login-isChecking": {
    ".login-button-label": {
      color: "#00bcd4"
    },
    backgroundColor: "#e0f7fa"
  },
  "&[disabled]": {
    backgroundColor: "rgba(0,0,0,.12)",
    color: "rgba(0,0,0,.26)"
  },
  backgroundColor: "rgb(255, 152, 0)",
  border: "none",
  borderRadius: 4,
  boxShadow: "none",
  color: "#ffffff",
  cursor: "pointer",
  height: 54,
  lineHeight: "38px",
  marginTop: "1em",
  width: 312
}));

export const StyledLoginButtonLabel = styled("span")<{}>(({ theme }) => ({
  color: "rgb(255, 255, 255)",
  fontFamily: theme.fontFamily
}));

export const StyledLoginControls = styled(CardContent)(() => ({
  overflow: "visible",
  padding: "0 0 26px",
  width: "100%"
}));

export const StyledLoginCheckingSpinner = styled(CircularProgress)(() => ({
  marginLeft: 15,
  marginTop: 5
}));

export const StyledLoginField = styled("div")(() => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  paddingBottom: 18
}));

export const StyledLoginLogo = styled("div")(() => ({
  padding: "0px 16px"
}));

export const StyledLoginRememberMe = styled(Checkbox)(() => ({
  "&.Mui-checked": {
    "&.Mui-disabled .mat-checkbox-checked": {
      backgroundColor: "rgba(0,0,0,.12)",
      color: "rgba(0,0,0,.26)"
    },
    color: "#ff9800"
  },
  color: "#ff9800",
  lineHeight: 0
}));

export const StyledLoginRememberMeContainer = styled(FormControlLabel)<{}>(
  ({ theme }) => ({
    ".MuiFormControlLabel-label": {
      fontFamily: theme.fontFamily,
      opacity: 0.87
    },
    display: "inline-flex",
    justifyContent: "center",
    marginTop: 0,
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  })
);

export const StyledLoginRememberMeIcon = styled("span")(() => ({
  border: "2px solid #747474",
  boxSizing: "border-box",
  display: "block",
  height: 16,
  width: 16
}));

export const StyledLoginRememberMeIconChecked = styled("span")(() => ({
  ".mat-checkbox-checkmark-path": {
    stroke: "#fafafa!important",
    strokeDasharray: 22.91026,
    strokeDashoffset: 0,
    strokeWidth: 2.13333
  },
  backgroundColor: "#ff9800",
  boxSizing: "border-box",
  height: 16,
  transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  width: 16
}));

export const StyledVisibilityIconButton = styled(IconButton)(() => ({
  marginRight: 0
}));

export const StyledVisibilityIconButtonIeFix = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 0
}));
