import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { FileHistoryRepositoryToken } from "../../domain/di/token/file";
import { FileHistoryRepository } from "../../domain/service/file/FileHistoryRepository";
import { FileId } from "../../domain/struct/file/File";
import { History } from "domain/struct/history/History";
import { SortingConfig } from "lib/contract/Sorting";

@DInjectable()
export class FindFileHistory {
  constructor(
    @DInject(FileHistoryRepositoryToken)
    protected readonly repository: FileHistoryRepository
  ) {}

  findPaginated(
    id: FileId,
    paginationConfig: PaginationConfig,
    sortingConfig?: SortingConfig
  ): Promise<PaginatedResult<History>> {
    return this.repository.findPaginated(id, paginationConfig, sortingConfig);
  }
}
