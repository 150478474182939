import React from "react";
import styled from "@emotion/styled";

import { Item } from "../../../../designSystem/Form/v2/Form";
import { Input } from "../../../../designSystem/Input/Input";
import { ColOneFourth, Row } from "../../../../designSystem/Grid/Grid";
import { AddressTypeSelect } from "./Input/AddressTypeSelect";
import { AddressFormEnum } from "../../../../../domain/struct/nameRegistry/AddressFormEnums";
import { HouseNumberTypeSelect } from "./Input/HouseNumberTypeSelect";
import { CountryTypeSelect } from "./Input/CountryTypeSelect";
import {
  Address,
  DESCRIPTIVE_HOUSE_NUMBER_TYPE
} from "../../../../../domain/struct/nameRegistry/Address";
import { useSubjectModalContext } from "../../contexts/SubjectModalContextProvider";
import { useAddressFields } from "./hooks/useAddressFields";
import { useAddressValidations } from "./hooks/useAddressValidations";
import { useAddressesFilteredOptions } from "../../hooks/useAddressesFilteredOptions";

const validationStreetRules = [{ required: true }, { max: 50 }];
const validationCityRules = [{ required: true }, { max: 50 }];
const validationCityPartRules = [{ max: 50 }];
const validationDependencies = ["country"];

interface AddressFieldsProps {
  address?: Address;
}

export const AddressFields: React.FC<AddressFieldsProps> = ({ address }) => {
  const {
    houseNumberType,
    showOrientationHouseNumberAddendum,
    onChange,
    checkHouseNumber
  } = useAddressFields(address);
  const { persistence } = useSubjectModalContext();
  const { validationRules, onCountryChange } = useAddressValidations(
    address?.country
  );
  const {
    validationPostCodeRules,
    validationHouseNumberRules,
    validationOrientationHouseNumberAddendumRules,
    validationOrientationHouseNumberRules
  } = validationRules;

  const subject = persistence.getSubjectData();

  const { isFetching, options } = useAddressesFilteredOptions(
    subject?.subjectType
  );

  if (!subject?.subjectType) {
    return null;
  }

  return (
    <>
      <Row>
        <ColOneFourth spaceAround>
          <AddressTypeSelect
            options={options}
            isFetching={isFetching}
            type={subject.subjectType}
          />
        </ColOneFourth>
      </Row>
      <Row>
        <ColOneFourth spaceAround>
          <Item
            name="street"
            label={AddressFormEnum.Street}
            rules={validationStreetRules}
          >
            <Input />
          </Item>
        </ColOneFourth>
      </Row>
      <Row justify="space-between">
        <ColOneFourth spaceAround>
          <HouseNumberTypeSelect onChange={onChange} />
        </ColOneFourth>
        <ColOneFourth spaceAround>
          <Item
            name="houseNumber"
            label={AddressFormEnum.HouseNumber}
            rules={validationHouseNumberRules}
            dependencies={validationDependencies}
          >
            <Input />
          </Item>
        </ColOneFourth>
        <ColOneFourth spaceAround>
          {houseNumberType === DESCRIPTIVE_HOUSE_NUMBER_TYPE && (
            <Item
              name="orientationHouseNumber"
              label={AddressFormEnum.OrientationHouseNumber}
              getValueFromEvent={checkHouseNumber}
              rules={validationOrientationHouseNumberRules}
              dependencies={validationDependencies}
            >
              <Input />
            </Item>
          )}
        </ColOneFourth>

        <ColOneFourth spaceAround>
          {houseNumberType === DESCRIPTIVE_HOUSE_NUMBER_TYPE &&
            showOrientationHouseNumberAddendum && (
              <Item
                name="orientationHouseNumberAddendum"
                label={AddressFormEnum.OrientationHouseNumberAddendum}
                rules={validationOrientationHouseNumberAddendumRules}
                dependencies={validationDependencies}
              >
                <Input />
              </Item>
            )}
        </ColOneFourth>
      </Row>
      <Row justify="space-between">
        <ColOneFourth spaceAround>
          <Item
            name="city"
            label={AddressFormEnum.City}
            rules={validationCityRules}
          >
            <Input />
          </Item>
        </ColOneFourth>
        <ColOneFourth spaceAround>
          <Item
            name="cityPart"
            label={AddressFormEnum.CityPart}
            rules={validationCityPartRules}
          >
            <Input />
          </Item>
        </ColOneFourth>
        <ColOneFourth spaceAround>
          <Item
            name="postalCode"
            label={AddressFormEnum.PostCode}
            rules={validationPostCodeRules}
            dependencies={validationDependencies}
          >
            <Input />
          </Item>
        </ColOneFourth>
        <ColOneFourth spaceAround>
          <CountryTypeSelect onChange={onCountryChange} />
        </ColOneFourth>
      </Row>
    </>
  );
};
