import { CircularProgress } from "@material-ui/core";
import React from "react";
import { StyledWrapper } from "./LoadingIndicator.styles";

export const LoadingIndicator = () => {
  return (
    <StyledWrapper>
      <CircularProgress />
    </StyledWrapper>
  );
};
