import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { ConceptConcernedSubjectRepositoryToken } from "../../../domain/di/token/document";
import { ConceptConcernedSubjectRepository } from "../../../domain/service/document/ConceptConcernedSubjectRepository";
import { SubjectId } from "../../../domain/struct/nameRegistry/Subject";

@DInjectable()
export class AddConceptConcernedSubject {
  constructor(
    @DInject(ConceptConcernedSubjectRepositoryToken)
    protected repository: ConceptConcernedSubjectRepository
  ) {}

  addConcernedSubjects(conceptId: string, subjectIds: SubjectId[]) {
    return this.repository.addConcernedSubjects(conceptId, subjectIds);
  }
}
