import {
  DInject,
  DInjectable
} from "../../presentation/core/features/dependencyInjection";
import {
  CreatePayload,
  PersonRepository
} from "../../domain/service/nameRegistry/PersonRepository";
import { PersonRepositoryToken } from "../../domain/di/token/nameRegistry";
import { Person } from "../../domain/struct/nameRegistry/Person";
import { FindDataBox } from "./isds/FindDataBox";

@DInjectable()
export class SavePerson {
  constructor(
    @DInject(PersonRepositoryToken) protected repository: PersonRepository,
    protected findDataBox: FindDataBox
  ) {}

  async create(payload: CreatePayload) {
    return this.repository.create(payload);
  }

  async update(person: Person) {
    return this.repository.update(person);
  }
}
