import { useFindAddresses } from "./useFindAddresses";
import { useSubjectModalContext } from "../contexts/SubjectModalContextProvider";
import { useEffect, useMemo, useState } from "react";
import { Notification } from "presentation/designSystem/notification/Notification";
import { getErrorCodeTranslation } from "../../../share/utils/errorCodeTranslation";

export const useAddressesFilteredOptions = (type: string = "") => {
  const { data, isFetching } = useFindAddresses({ retry: false });
  const { persistence } = useSubjectModalContext();
  const [usedAddresses, setUsedAddresses] = useState({});

  useEffect(() => {
    const getItems = async () => {
      const { items } = await persistence.getAddresses({
        page: 1,
        itemsPerPage: 1000
      });
      return items;
    };

    getItems()
      .then((items) => {
        const result = items.reduce((acc, item) => {
          if (item && item.addressType) {
            if (!acc[item.addressType]) {
              acc[item.addressType] = 1;
              return acc;
            }
            acc[item.addressType] = acc[item.addressType] + 1;
            return acc;
          }
          return acc;
        }, {} as Record<string, number>);
        setUsedAddresses(result);
      })
      .catch((error) => {
        return Notification.error({
          message: getErrorCodeTranslation(error.code)
        });
      });
  }, []);

  const options = useMemo(
    () =>
      data
        ? data
            .filter(({ subjectType }) => {
              return (
                subjectType ===
                (persistence?.getSubjectData()?.subjectType || type)
              );
            })
            .filter((dataAddress) => {
              if (dataAddress && dataAddress.maxOccurrence) {
                const occurrences = usedAddresses[dataAddress.code];
                if (!occurrences) return true;
                return dataAddress.maxOccurrence > occurrences;
              }
              return false;
            })
            .map(({ code, czechName }) => ({
              label: czechName,
              value: code
            }))
        : [],
    [data, persistence, type, usedAddresses]
  );

  return {
    options,
    isFetching
  };
};
