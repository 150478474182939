import React, { useEffect } from "react";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Input } from "presentation/designSystem/Input/Input";
import styled from "@emotion/styled";
import { Row } from "presentation/designSystem/Grid/Grid";
import { t } from "../../../../translation/i18n";
import { translationPath } from "../../../../share/utils/getPath";
import lang from "../../../../translation/lang";
import { DateTimeFormat, formatDate } from "../../../../../lib/dateTime";
import { ErasedFile } from "../../../../../domain/struct/recordRetention/ErasedFile";
import { useFindFileErased } from "../../hooks/useFindFileErased";
import { useFilePlan } from "../../../../share/hook/filePlan/useFilePlan";
import { SpisumNodeTypes } from "../../../../enums";

interface ShowDetailsOfErasedFileFormProps {
  id: string;
}
export const ErasedFileForm = ({ id }: ShowDetailsOfErasedFileFormProps) => {
  const [form] = useForm<ErasedFile>();
  const { data } = useFindFileErased(id);
  const filePlanName = useFilePlan(data?.filePlan!)?.name;
  const isFileErasedArchived = data?.nodeType === SpisumNodeTypes.FileArchived;

  const mayBeFileMarkCode = data?.fileMark?.split("VS_");
  const fileMark =
    !mayBeFileMarkCode || mayBeFileMarkCode.length < 2
      ? undefined
      : mayBeFileMarkCode[1];

  // TODO: change metamodel and usecase as soon as erased document metadataform is implemented and add formatter for the erased date
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        createdDate: formatDate(data.createdDate, DateTimeFormat.FullDateTime),
        eraseDate: formatDate(data.eraseDate, DateTimeFormat.FullDateTime),
        deliveryDate: formatDate(
          data.deliveryDate,
          DateTimeFormat.FullDateTime
        ),
        filePlan: filePlanName,
        subject: data?.subject,
        fileMark: fileMark
      });
    }
  }, [data, form]);

  return (
    <Form form={form} name="ErasedFile">
      <StyledRow>
        <Item
          name="pid"
          label={t(
            translationPath(
              lang.dialog.form.ShowDetailsOfErasedDocumentForm.pid
            )
          )}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name="createdDate"
          label={t(
            translationPath(
              lang.dialog.form.ShowDetailsOfErasedDocumentForm.createdAt
            )
          )}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name="createdDate"
          label={t(translationPath(lang.general.evidenceDateTime))}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name="fileIdentifier"
          label={t(translationPath(lang.general.fileIdentifier))}
        >
          <Input disabled={true} />
        </Item>
        <Item name="state" label={t(translationPath(lang.general.fileState))}>
          <Input disabled={true} />
        </Item>
        <Item
          name="decisionDa"
          label={t(translationPath(lang.general.decisionDA))}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name="reviewer"
          label={t(translationPath(lang.general.reviewerFullName))}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name="eraseDate"
          label={t(
            translationPath(
              lang.dialog.form.ShowDetailsOfErasedDocumentForm
                .deletionDateAndTime
            )
          )}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name="shreddingOperationReason"
          label={t(
            translationPath(
              lang.dialog.performShreddingProcedureModal
                .reasonOfShreddingOrTransfer
            )
          )}
        >
          <Input disabled={true} />
        </Item>
        <Item name="filePlan" label={t(translationPath(lang.general.filePlan))}>
          <Input disabled={true} />
        </Item>
        <Item name="fileMark" label={t(translationPath(lang.general.fileMark))}>
          <Input disabled={true} />
        </Item>
        {isFileErasedArchived && (
          <Item name="idDa" label={t(translationPath(lang.general.idDA))}>
            <Input disabled={true} />
          </Item>
        )}
      </StyledRow>

      <Item name="subject" label={t(translationPath(lang.general.subject))}>
        <Input disabled={true} size={"large"} />
      </Item>
    </Form>
  );
};

const StyledRow = styled(Row)`
  display: flex;
  margin-bottom: 2rem;

  .ant-form-item {
    margin-right: 2.2rem;
    //max-width: 12.7rem;
    min-width: 14rem;

    :nth-last-of-type(1) {
      margin-right: 0;
    }
  }
`;
