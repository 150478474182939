import { SendModeValues, SpisumNodeTypes } from "presentation/enums";
import { partition } from "lodash";
import { File, FileMetaType } from "../../../../entities";
import { sumFileSizes } from "../../../../helpers/file";

export const isMainFile = (file: File) => file.metaType === FileMetaType.main;

const alphabeticalFilesSorter = (a: File, b: File) =>
  a.name?.localeCompare(b.name || "") || 0;

export const sortComponents = (files: File[]) => {
  const [[mainFile], otherFiles] = partition(files, isMainFile);

  otherFiles.sort(alphabeticalFilesSorter);

  if (mainFile) {
    otherFiles.unshift(mainFile);
  }

  return otherFiles;
};

export const findMainFile = (files: File[]) => files.find(isMainFile);

const dataBoxLimits = {
  maxCount: 300,
  maxSize: 50 * 1024 * 1024 // 50MB
};

const emailLimits = {
  maxCount: 300,
  maxSize: 10 * 1024 * 1024 // 10MB
};

const sendModeLimits = {
  [SendModeValues.Databox]: dataBoxLimits,
  [SpisumNodeTypes.ShipmentDatabox]: dataBoxLimits,
  [SendModeValues.Email]: emailLimits,
  [SpisumNodeTypes.ShipmentEmail]: emailLimits
};

const getComponentMaxSizeAndCount = (sendMode: string) => {
  return (
    sendModeLimits[sendMode] || {
      maxCount: Infinity,
      maxSize: Infinity
    }
  );
};

export interface ComponentValidationResult {
  isTooBig: boolean;
  isWrongCountOfFiles: boolean;
  selectedCount: number;
  sizeInBytes: number;
}
export const componentValidation = (
  components: File[] | undefined,
  sendMode: string
): ComponentValidationResult => {
  const { maxSize, maxCount } = getComponentMaxSizeAndCount(sendMode);
  const selectedSize = components ? sumFileSizes(components) : 0;
  const isTooBig = selectedSize > maxSize;
  const isWrongCountOfFiles =
    !!components && (components.length > maxCount || components.length === 0);

  return {
    isTooBig,
    isWrongCountOfFiles,
    selectedCount: components?.length || 0,
    sizeInBytes: selectedSize
  };
};
