import React from "react";
import { lang, t } from "../../translation/i18n";
import { translationPath } from "../../share/utils/getPath";

import styled from "@emotion/styled";
import { theme } from "../../styles";
import Breadcrumb from "antd/lib/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";

interface BreadcrumbProps {
  title: string;
}

const tranformToTranslatable = (item: string) => {
  const [first, ...rest] = item.split("-");
  if (rest && rest.length) {
    return (
      first +
      rest.reduce((acc, curr) => {
        return acc + curr.charAt(0).toUpperCase() + curr.slice(1);
      }, "")
    );
  }
  return first;
};
export const BreadcrumbForTable = ({ title }: BreadcrumbProps) => {
  const { pathname } = useLocation();
  const breadcrumbItems = pathname.split("/").filter(Boolean);
  return (
    <BreadcrumbStyled>
      <TitleStyled className="title">
        {t(translationPath(lang.breadcrumb[title]))}
      </TitleStyled>
      <PathStyled separator=">">
        {breadcrumbItems.map((item) => (
          <Breadcrumb.Item key={item}>
            {t(translationPath(lang.breadcrumb[tranformToTranslatable(item)]))}
          </Breadcrumb.Item>
        ))}
      </PathStyled>
    </BreadcrumbStyled>
  );
};

const BreadcrumbStyled = styled("div")`
  position: relative;
  height: 5%;
  padding: 20px;
`;

const TitleStyled = styled("h5")(() => ({
  color: theme.colors.orange,
  fontSize: 23,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: 700,
  lineHeight: 1.5,
  letterSpacing: "0.00938em"
}));
const PathStyled = styled(Breadcrumb)(() => ({
  "& span .ant-breadcrumb-link": {
    color: theme.colors.gray,
    fontSize: "1rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em"
  },
  "& span .ant-breadcrumb-separator": {
    color: theme.colors.gray,
    margin: 8
  },
  "& :last-child .ant-breadcrumb-separator": { display: "none" }
}));
