import { PaginationConfig, RemoteTableProps } from "../RemoteTable";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDelayedQuery } from "./query/useDelayedQuery";
import { DataColumn } from "presentation/core/components/dataTable/_types";
import {
  RemoteTableApiContext,
  RemoteTableApiState
} from "../contexts/RemoteTableApiContextProvider";
import { DataTableValues } from "../../../core/components/documentView/_types";
import {
  SortDirection,
  SortingConfig
} from "presentation/govDesignSystem/lib/contract/Sorting";

export const DEFAULT_TABLE_CONFIG = {
  pagination: {
    page: 1,
    itemsPerPage: 10
  }
};
const PAGINATION_CONFIG = DEFAULT_TABLE_CONFIG.pagination;
const REFETCH_DELAY = 1000;

interface RemoteDataState {
  pagination: PaginationConfig;
  sorting?: SortingConfig;
}
const dataTableValues: DataTableValues = {
  resetIcons: true
};
export const useRemoteData = <Row>({
  name,
  getRemoteData,
  columns,
  defaultSortAsc,
  defaultSortColumn,
  remoteQueryParams,
  queryConfig,
  pagination: paginationProps
}: RemoteTableProps<Row>) => {
  const { tableState, setTableState } = useContext(
    RemoteTableApiContext<Row>()
  );

  let { sortAsc, sortColumnIndex, sortKeys } = tableState;
  const [{ pagination, sorting }, setState] = useState<RemoteDataState>({
    pagination: paginationProps
      ? mapAntdPagination(paginationProps)
      : PAGINATION_CONFIG,
    sorting: columns && getDefaultSorting<Row>(columns)
  });

  const remoteQueryKey = useMemo(
    () =>
      getQueryKey({
        name,
        remoteQueryParams,
        pagination,
        sorting
      }),
    [name, pagination, remoteQueryParams, sorting]
  );

  const { data, isFetching, refetch } = useDelayedQuery(
    remoteQueryKey,
    REFETCH_DELAY,
    () => getRemoteData({ pagination, sorting, params: remoteQueryParams }),
    queryConfig
  );

  useEffect(() => {
    refetch();
  }, [refetch, remoteQueryKey]);
  const updateTableState = () => {
    if (defaultSortColumn && tableState.sortColumnIndex === null) {
      setTableState({
        ...tableState,
        sortColumnIndex: columns.indexOf(defaultSortColumn),
        sortKeys: defaultSortColumn.keys
      });
    }
    if (defaultSortAsc && tableState.sortAsc === null) {
      setTableState({
        ...tableState,
        sortAsc: defaultSortAsc
      });
    }
    if (
      defaultSortAsc &&
      tableState.sortAsc === null &&
      defaultSortColumn &&
      tableState.sortColumnIndex === null
    ) {
      setTableState({
        ...tableState,
        sortAsc: defaultSortAsc,
        sortColumnIndex: columns.indexOf(defaultSortColumn),
        sortKeys: defaultSortColumn.keys
      });
    }
  };
  useEffect(() => {
    updateTableState();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setTableState({
      ...tableState,
      pageNumber: newPage
    });
    setState({
      pagination: mapAntdPagination({
        page: newPage || tableState.pageNumber,
        itemsPerPage: tableState.itemsPerPage
      }),
      sorting: getSorting(tableState)
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTableState({
      ...tableState,
      itemsPerPage: +event.target.value
    });
    setState({
      pagination: mapAntdPagination({
        page: tableState.pageNumber,
        itemsPerPage: +event.target.value || tableState.itemsPerPage
      }),
      sorting: getSorting(tableState)
    });
  };

  const handleSortingChange = (index: number, keys: string[]) => () => {
    setTableState({
      ...tableState,
      sortAsc: !sortAsc,
      sortColumnIndex: index,
      sortKeys: keys
    });
    dataTableValues.resetIcons = true;
    setState({
      pagination: mapAntdPagination({
        page: tableState.pageNumber,
        itemsPerPage: tableState.itemsPerPage
      }),
      sorting: [
        {
          property: keys[0],
          direction:
            index === sortColumnIndex
              ? sortAsc === true
                ? SortDirection.Descending
                : SortDirection.Ascending
              : SortDirection.Descending
        }
      ]
    });
  };
  const setSelected = (rows: Row[]) => {
    setTableState({
      ...tableState,
      selected: rows
    });
  };

  return {
    data,
    isFetching,
    refetch,
    sortAsc,
    sortColumnIndex,
    handleChangePage,
    handleChangeRowsPerPage,
    dataTableValues,
    handleSortingChange,
    setSelected
  };
};

const getSorting = <Row>({
  sortAsc,
  sortKeys
}: RemoteTableApiState<Row>["tableState"]): SortingConfig | undefined => {
  return [
    {
      property: sortKeys![0],
      direction: sortAsc ? SortDirection.Ascending : SortDirection.Descending
    }
  ];
};

// maps antd pagination to our pagination contract
const mapAntdPagination = (pagination: PaginationConfig): PaginationConfig => {
  return {
    page: pagination.page || PAGINATION_CONFIG.page,
    itemsPerPage: pagination.itemsPerPage || PAGINATION_CONFIG.itemsPerPage
  };
};

// query helpers
interface GetQueryKeyConfig<Row> extends RemoteDataState {
  name: RemoteTableProps<Row>["name"];
  remoteQueryParams: RemoteTableProps<Row>["remoteQueryParams"];
}

const getQueryKey = <Row>({ name, ...config }: GetQueryKeyConfig<Row>) => [
  `remoteTable/${name}`,
  JSON.stringify(config)
];

// sorting helpers
const getDefaultSorting = <Row>(
  columns: DataColumn<Row>[]
): SortingConfig | undefined => {
  const defaultSortColumn = findDefaultSortingColumn(columns);

  if (!defaultSortColumn) {
    return undefined;
  }

  if (!isColumn(defaultSortColumn) || !defaultSortColumn.keys) {
    return undefined;
  }

  return [
    {
      property: defaultSortColumn.keys.toString(),
      direction:
        defaultSortColumn.defaultSortOrder === "ascend"
          ? SortDirection.Ascending
          : SortDirection.Descending
    }
  ];
};

const isColumn = <Row>(column: object): column is DataColumn<Row> => {
  return "keys" in column;
};

const findDefaultSortingColumn = <Row>(columns: DataColumn<Row>[]) =>
  columns.find(
    ({ sorter, defaultSortOrder }) => sorter === true && defaultSortOrder
  );
