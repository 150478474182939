import { DefaultsConfiguration } from "@isfg/react-components/build/defaults/DefaultsConfiguration";
import React from "react";
import { IconButton } from "../../presentation/designSystem/Button/IconButton";
import { CloseOutlined } from "../../presentation/designSystem/Icon/Icon";

const closeButton = <IconButton icon={<CloseOutlined />} tooltip={null} />;

export const isfgComponentsDefaults: DefaultsConfiguration = {
  modal: {
    closeIcon: closeButton
  }
};
