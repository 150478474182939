import { CheckCircleOutline, Save, Visibility } from "@material-ui/icons";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ControlsType, ReadonlyControlsType } from "./_types";
import { File } from "presentation/core/entities";

export const controls: ControlsType = ({
  handleCanShowPreview,
  handleDownloadComponent,
  handleShowPreview,
  handleActivateComponent,
  handleCanActivateComponent
}) => {
  return {
    multi: {
      items: [
        {
          action: handleActivateComponent,
          filter: (file: File) => handleCanActivateComponent(file),
          icon: <CheckCircleOutline data-test-id="tab-activate" />,
          title: t(translationPath(lang.general.activateComponent))
        },
        {
          action: handleDownloadComponent,
          icon: <Save data-test-id="tab-download" />,
          title: t(translationPath(lang.general.download))
        }
      ]
    },
    single: {
      items: [
        {
          action: handleActivateComponent,
          filter: (file: File) => handleCanActivateComponent(file),
          icon: <CheckCircleOutline data-test-id="tab-activate" />,
          title: t(translationPath(lang.general.activateComponent))
        },
        {
          action: handleDownloadComponent,
          icon: <Save data-test-id="tab-download" />,
          title: t(translationPath(lang.general.download))
        },
        {
          action: handleShowPreview,
          filter: handleCanShowPreview,
          icon: <Visibility data-test-id="tab-show-preview" />,
          title: t(translationPath(lang.general.showPreview))
        }
      ]
    }
  };
};

export const readOnlyControls: ReadonlyControlsType = ({
  handleCanShowPreview,
  handleShowPreview,
  handleDownloadComponent
}) => ({
  multi: {
    items: [
      {
        action: handleDownloadComponent,
        icon: <Save data-test-id="tab-download" />,
        title: t(translationPath(lang.general.download))
      }
    ]
  },
  single: {
    items: [
      {
        action: handleDownloadComponent,
        icon: <Save data-test-id="tab-download" />,
        title: t(translationPath(lang.general.download))
      },
      {
        action: handleShowPreview,
        filter: handleCanShowPreview,
        icon: <Visibility data-test-id="tab-show-preview" />,
        title: t(translationPath(lang.general.showPreview))
      }
    ]
  }
});
