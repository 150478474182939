import React, { useMemo, useState } from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ActionButtonStateType, ActionType, DialogDataProps } from "./_types";
import { Space } from "../../../designSystem/Layout/Space/Space";
import { Button } from "../../../designSystem/Button/Button";
import type { ButtonProps } from "../../../designSystem/Button/Button";

interface OwnProps {
  actions: (dialogProps: DialogDataProps) => ActionType[];
  dialogProps: DialogDataProps;
  onClose: VoidFunction;
  onActionClicked: (
    actionFn: ActionType["onClick"],
    buttonState: ActionButtonStateType
  ) => void;
}

const ActionButtons = React.memo(
  ({ actions, dialogProps, onClose, onActionClicked }: OwnProps) => {
    const [buttonsState, setButtonsState] = useState({});

    const disabled = !!Object.keys(buttonsState).find(
      (index) => buttonsState[index].isPending
    );

    const buttons = useMemo(() => {
      const setIsPending = (index: number) => (isPending: boolean) => {
        setButtonsState({
          ...buttonsState,
          [index]: {
            isPending
          }
        });
      };

      const actionButtons = actions(dialogProps);

      return (
        !!actionButtons.length &&
        actionButtons
          .reverse()
          .map(
            ({ name, onClick, type, color, colorThemeType }, actionIndex) => {
              const onClickHandler = () => {
                onActionClicked(onClick, {
                  setIsPending: setIsPending(actionIndex)
                });
              };

              const isPending =
                buttonsState[actionIndex] &&
                buttonsState[actionIndex].isPending;

              return (
                <Button
                  key={name}
                  loading={isPending}
                  disabled={disabled}
                  data-test-id={`actions-input-${name.toLowerCase()}`}
                  onClick={onClickHandler}
                  {...muiToAntdProps(color, type, colorThemeType)}
                >
                  {name}
                </Button>
              );
            }
          )
      );
    }, [actions, buttonsState, dialogProps, disabled, onActionClicked]);

    return (
      <Space>
        <Button data-test-id="actions-input-cancel" onClick={onClose}>
          {dialogProps?.cancelButtonText
            ? dialogProps?.cancelButtonText
            : t(translationPath(lang.modal.cancel))}
        </Button>
        {buttons}
      </Space>
    );
  }
);

// backwards compatibility with mui
const muiToAntdProps = (
  color: ActionType["color"],
  type?: ActionType["type"],
  colorThemeType?: ActionType["colorThemeType"]
): Partial<ButtonProps> => {
  if (colorThemeType === "error") {
    return {
      danger: true
    };
  }

  return {
    // in mui is the primary color black and secondary is orange
    type:
      color === "secondary" || colorThemeType === "success"
        ? "primary"
        : "default"
  };
};

export default ActionButtons;
