import { GlobalLayout } from "presentation/core/components/layout/GlobalLayout";
import {
  LoginCardWideStyled,
  LoginContentStyled,
  LoginStyled,
  StyledIe11FixerChild,
  StyledIe11FixerParent
} from "presentation/core/features/login/Login.styles";
import React, { useState } from "react";
import { t, withTranslation } from "presentation/translation/i18n";
import { translationPath } from "../../../share/utils/getPath";
import lang from "../../../translation/lang";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { CardHeader } from "@material-ui/core";
import {
  StyledForgotField,
  StyledForgotInput,
  StyledForgotPasswordDivider,
  StyledImgLogo,
  StyledLoginButton,
  StyledLoginControls,
  StyledLoginLabel,
  StyledLoginLogo
} from "../login/FormRenderer.form.styles";
import logoImage from "../../../../assets/images/loginlogo.png";
import { useForgotPassword } from "../../../modules/authentication/hooks/useForgotPassword";
import { Redirect } from "react-router-dom";
import { CoreRoutes } from "../../routes";

const ForgottenPassword = () => {
  const rulesUserId = [
    {
      required: true
    }
  ];

  const [form] = useForm();

  const { mutate, isError, isSuccess } = useForgotPassword();

  const submitUserId = async (values: any) => {
    setFormOnSubmitted(false);
    await mutate(values.userId);
  };

  const [formOnSubmitted, setFormOnSubmitted] = useState<boolean>(true);

  return (
    <GlobalLayout>
      <LoginStyled>
        <LoginContentStyled>
          <StyledIe11FixerParent>
            <StyledIe11FixerChild>
              <LoginCardWideStyled>
                {(isError || formOnSubmitted) && (
                  <Form
                    form={form}
                    name="forgottenPasswordForm"
                    onFinish={submitUserId}
                  >
                    <CardHeader
                      title={
                        <StyledLoginLogo>
                          <StyledImgLogo
                            src={logoImage}
                            alt={t(translationPath(lang.login.logoAlt))}
                          />
                        </StyledLoginLogo>
                      }
                    />
                    <StyledLoginControls>
                      <StyledForgotPasswordDivider />
                      <StyledForgotField>
                        <StyledLoginLabel
                          htmlFor={t(translationPath(lang.login.username))}
                        >
                          {t(translationPath(lang.login.username))}
                        </StyledLoginLabel>
                        <Item rules={rulesUserId} name="userId">
                          <StyledForgotInput />
                        </Item>
                      </StyledForgotField>
                      <Item>
                        <StyledLoginButton
                          type={"submit"}
                          data-test-id="reset-password-button"
                        >
                          {t(translationPath(lang.dialog.title.resetPassword))}
                        </StyledLoginButton>
                      </Item>
                    </StyledLoginControls>
                  </Form>
                )}
                {!formOnSubmitted && isSuccess && (
                  <Redirect to={CoreRoutes.LOGIN} />
                )}
              </LoginCardWideStyled>
            </StyledIe11FixerChild>
          </StyledIe11FixerParent>
        </LoginContentStyled>
      </LoginStyled>
    </GlobalLayout>
  );
};

export default withTranslation()(ForgottenPassword);
