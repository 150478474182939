import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { RouteComponentProps, withRouter } from "react-router-dom";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ColumnType } from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "../../../designSystem/Table/column/column";
import { FilePlanColumnValue } from "../../../share/components/table/column/document/FilePlan/FilePlanColumnValue";
import { FileMarkColumnValue } from "../../../share/components/table/column/document/FilePlan/FileMarkColumnValue";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../designSystem/Table/RemoteTable";
import { SearchResult } from "../../../../domain/struct/search/SearchResult";
import { useFindSearch } from "../../hooks/useFindSearch";
import { actionShowDetail } from "../../../share/components/table/actionFactory";
import { styled } from "../../../styles";
import { getDetailActionForDocumentNewDataModel } from "../../../share/utils/action/dialog/documentDetail";
import { DateTimeFormat } from "../../../../lib/dateTime";
import { useQueryClient } from "react-query";

const COLUMNS: ColumnType<SearchResult>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.table.searchTable.pid)),
    defaultSortOrder: "ascend",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "fileIdent",
    title: t(translationPath(lang.table.searchTable.fileIdent)),
    render: (text, row) => {
      const { fileIdent, ssid, nodeType } = row;

      if (nodeType === "ssl:document") {
        return ssid;
      }
      if (nodeType === "ssl:file") {
        return fileIdent;
      }
      return "";
    }
  }),
  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.table.searchTable.subject))
  }),
  createDateTimeColumn(
    {
      dataIndex: "evidenceDate",
      title: t(translationPath(lang.table.searchTable.evidenceDate))
    },
    DateTimeFormat.FullDateTime
  ),
  createDateTimeColumn(
    {
      dataIndex: "settleToDate",
      title: t(translationPath(lang.table.searchTable.settleToDate))
    },
    DateTimeFormat.FullDateTime
  ),

  createColumn({
    dataIndex: "filePlan",
    title: t(translationPath(lang.table.searchTable.filePlan)),
    render: (text, row) => {
      const { filePlan } = row;

      if (!filePlan) {
        return;
      }

      return <FilePlanColumnValue filePlanId={filePlan} />;
    }
  }),
  createColumn({
    dataIndex: "fileMark",
    title: t(translationPath(lang.table.searchTable.fileMark)),
    render: (text, row) => {
      const { fileMark } = row;

      if (!fileMark) {
        return;
      }

      return <FileMarkColumnValue fileMarkId={fileMark} />;
    }
  }),
  createColumn({
    dataIndex: "retentionMode",
    title: t(translationPath(lang.table.searchTable.retentionMode))
  }),
  createColumn({
    dataIndex: "sender",
    title: t(translationPath(lang.table.searchTable.sender))
  }),
  createColumn({
    dataIndex: "owner",
    title: t(translationPath(lang.table.searchTable.owner))
  })
];

const Results = () => {
  const [totalCount, setTotalCount] = useState("");
  const [recordCount, setRecordCount] = useState("");

  const queryClient = useQueryClient();

  useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe((listener) => {
      setRecordCount(localStorage.getItem("allowedRecord") ?? "0");
      setTotalCount(localStorage.getItem("totalCount") ?? "0");
    });

    return () => unsubscribe();
  }, [localStorage]);

  return (
    <StyledParagraph>
      Nalezeno <strong>{totalCount}</strong> záznamů, z toho{" "}
      <strong>{recordCount}</strong> dostupných
    </StyledParagraph>
  );
};

const SearchComponent = ({
  location
}: RouteComponentProps<{}, {}, { searchTerm: string }>) => {
  const term = location?.state ? location.state.searchTerm : "";

  const dispatch = useDispatch();

  const findSearched = useFindSearch();

  const ACTIONS = useMemo(
    () => ({
      single: [
        actionShowDetail({
          action(selected: SearchResult[]) {
            const nodeType = selected[0].nodeType || "";
            const action = getDetailActionForDocumentNewDataModel(nodeType);
            action &&
              dispatch(
                action({
                  canUploadComponents: false,
                  data: {
                    id: selected[0].nodeId,
                    nodeType
                  },
                  hideManageShipmentsIcon: true,
                  initiator: nodeType,
                  isReadonly: true
                })
              );
          }
        })
      ]
    }),
    [dispatch]
  );

  const getRemoteData: GetRemoteDataClb<SearchResult> = useCallback(
    ({ pagination, sorting }) => findSearched(term, pagination, sorting),
    [findSearched, term]
  );
  return (
    <>
      <MenuLayout>
        <StyledSection>
          <StyledH1>{t(translationPath(lang.general.search))}</StyledH1>

          <RemoteTable<SearchResult>
            actions={ACTIONS}
            name={`search/searchTable`}
            columns={COLUMNS}
            getRemoteData={getRemoteData}
            rowKey="nodeId"
            remoteQueryParams={{
              term: term
            }}
            onChange={() => {
              console.log("Change");
            }}
          />
          <Results />
        </StyledSection>
      </MenuLayout>
    </>
  );
};

export default withRouter(SearchComponent);

const StyledSection = styled.section`
  position: relative;
`;
const StyledH1 = styled.h1`
  color: #ff9600;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0;
  margin-bottom: 0;
  padding: 30px 6px 10px 20px;
`;

const StyledParagraph = styled.p`
  position: absolute;
  bottom: 0.4rem;
  left: 2.3rem;
`;
