import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { ContactRepositoryToken } from "../../../domain/di/token/nameRegistry";
import { ContactRepository } from "../../../domain/service/nameRegistry/ContactRepository";
import { Contact } from "../../../domain/struct/nameRegistry/Contact";
import { FindDataBox } from "../isds/FindDataBox";

@DInjectable()
export class SaveContact {
  constructor(
    @DInject(ContactRepositoryToken) protected repository: ContactRepository,
    protected findDataBox: FindDataBox
  ) {}

  async create(subjectId: string, contact: Contact) {
    return this.repository.create(subjectId, contact);
  }
  async update(subjectId: string, contact: Contact) {
    return this.repository.update(subjectId, contact);
  }
  delete(subjectId: string, contact: Contact) {
    return this.repository.delete(subjectId, contact);
  }
}
