import React from "react";
import { ConfigProvider } from "@isfg/react-components/build/config/ConfigProvider";
import {
  csCZLocale,
  theme,
  formValidationMessages,
  isfgComponentsDefaults
} from "../../../config/isfgComponents";
import { queryClient } from "./ReactQueryProvider";

export const ISFGComponentsConfigProvider: React.FC = ({ children }) => {
  return (
    <ConfigProvider
      locale={csCZLocale}
      form={{ validateMessages: formValidationMessages }}
      theme={theme}
      defaults={isfgComponentsDefaults}
      queryClient={queryClient}
    >
      {children}
    </ConfigProvider>
  );
};
