import AllInboxIcon from "@material-ui/icons/AllInbox";
import { MenuItemType } from "presentation/core/components/menu/_types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import DocumentsForProcessing from "./features/documents/forProcessing";
import OverviewsForSignature from "./features/overviews/forSignature";
import OverviewsReturnedFromDispatch from "./features/overviews/returnedFromDispatch";
import ToTakeOver from "./features/toTakeOver";
import WaitingForTakeover from "./features/waitingForTakeover";
import { EvidenceRoutes } from "./routes";
import RentedOverviews from "./features/overviews/rented";
import LostDestroyedOverviews from "./features/overviews/lostDestroyed/LostDestroyedOverviews";
import CancelledOverviews from "./features/overviews/cancelled/CancelledOverviews";
import ReturnedForReworkOverviews from "./features/overviews/returnedForRework";
import RejectedByTakeoverOverviews from "./features/overviews/rejectedByTakeover";
import ClosedFiles from "./features/files/closed/ClosedFiles";
import OpenedFiles from "./features/files/open/OpenedFiles";
import EvidenceConcepts from "./features/concepts/EvidenceConcepts";
import ProcessedDocuments from "./features/documents/processed/ProcessedDocuments";
import OverviewsForSeal from "./features/overviews/forSeal/OverviewsForSeal";
import { OpenTypeFilesTable } from "./features/typeFiles/open/OpenTypeFiles";
import { ClosedTypeFilesTable } from "./features/typeFiles/closed/ClosedTypeFiles";

export const evidenceMenuItems: MenuItemType[] = [
  {
    __testId: "menu-main-evidence",
    icon: <AllInboxIcon />,
    submenu: [
      {
        __testId: "menu-main-evidence-totakeover",
        content: <ToTakeOver />,
        text: t(translationPath(lang.menu.items.toTakeOver)),
        url: EvidenceRoutes.EVIDENCE_TOTAKEOVER
      },
      {
        __testId: "menu-main-evidence-waitingfortakeover",
        content: <WaitingForTakeover />,
        text: t(translationPath(lang.menu.items.waitingForTakeover)),
        url: EvidenceRoutes.EVIDENCE_WAITINGTOTAKEOVER
      },
      {
        __testId: "menu-main-evidence-documents",
        submenu: [
          {
            __testId: "menu-main-evidence-documents-forprocessing",
            content: <DocumentsForProcessing />,
            text: t(translationPath(lang.menu.items.forProcessing)),
            url: EvidenceRoutes.EVIDENCE_DOCUMENTS_FORPROCESSING
          },
          {
            __testId: "menu-main-evidence-documents-processed",
            content: <ProcessedDocuments />,
            text: t(translationPath(lang.menu.items.processed)),
            url: EvidenceRoutes.EVIDENCE_DOCUMENTS_PROCESSED
          }
        ],
        text: t(translationPath(lang.menu.items.documents))
      },
      {
        __testId: "menu-main-evidence-concepts",
        content: <EvidenceConcepts />,
        text: t(translationPath(lang.menu.items.concepts)),
        url: EvidenceRoutes.EVIDENCE_CONCEPTS
      },
      {
        __testId: "menu-main-evidence-files",
        submenu: [
          {
            __testId: "menu-main-evidence-files-open",
            content: <OpenedFiles />,
            text: t(translationPath(lang.menu.items.open)),
            url: EvidenceRoutes.EVIDENCE_FILES_OPEN
          },
          {
            __testId: "menu-main-evidence-files-closed",
            content: <ClosedFiles />,
            text: t(translationPath(lang.menu.items.closed)),
            url: EvidenceRoutes.EVIDENCE_FILES_CLOSED
          }
        ],
        text: t(translationPath(lang.menu.items.files))
      },
      {
        __testId: "menu-main-evidence-type-files",
        submenu: [
          {
            __testId: "menu-main-evidence-type-files-open",
            content: <OpenTypeFilesTable />,
            text: t(translationPath(lang.menu.items.open)),
            url: EvidenceRoutes.EVIDENCE_TYPE_FILES_OPEN
          },
          {
            __testId: "menu-main-evidence-type-files-closed",
            content: <ClosedTypeFilesTable />,
            text: t(translationPath(lang.menu.items.closed)),
            url: EvidenceRoutes.EVIDENCE_TYPE_FILES_CLOSED
          }
        ],
        text: t(translationPath(lang.menu.items.typeFiles))
      },
      {
        __testId: "menu-main-evidence-overviews",
        submenu: [
          {
            __testId: "menu-main-evidence-overviews-rejectedbytakeover",
            content: <RejectedByTakeoverOverviews />,
            text: t(translationPath(lang.menu.items.rejectedByTakeover)),
            url: EvidenceRoutes.EVIDENCE_OVERVIEWS_REJECTEDBYTAKEOVER
          },
          {
            __testId: "menu-main-evidence-overviews-forsignature",
            content: <OverviewsForSignature />,
            text: t(translationPath(lang.menu.items.forSignature)),
            url: EvidenceRoutes.EVIDENCE_OVERVIEWS_FORSIGNATURE
          },
          {
            __testId: "menu-main-evidence-overviews-forseal",
            content: <OverviewsForSeal />,
            text: t(translationPath(lang.menu.items.forSeal)),
            url: EvidenceRoutes.EVIDENCE_OVERVIEWS_FORSEAL
          },
          {
            __testId: "menu-main-evidence-overviews-returnedforrework",
            content: <ReturnedForReworkOverviews />,
            text: t(translationPath(lang.menu.items.returnedForRework)),
            url: EvidenceRoutes.EVIDENCE_OVERVIEWS_RETURNEDFORREWORK
          },
          {
            __testId: "menu-main-evidence-overviews-returnedfromdispatch",
            content: <OverviewsReturnedFromDispatch />,
            text: t(translationPath(lang.menu.items.returnedFromDispatch)),
            url: EvidenceRoutes.EVIDENCE_OVERVIEWS_RETURNEDFROMDISPATCH
          },
          {
            __testId: "menu-main-evidence-overviews-cancel",
            content: <CancelledOverviews />,
            text: t(translationPath(lang.menu.items.cancel)),
            url: EvidenceRoutes.EVIDENCE_OVERVIEWS_CANCEL
          },
          {
            __testId: "menu-main-evidence-overviews-lostdestroyed",
            content: <LostDestroyedOverviews />,
            text: t(translationPath(lang.menu.items.lostDestroyed)),
            url: EvidenceRoutes.EVIDENCE_OVERVIEWS_LOSTDESTROYED
          },
          {
            __testId: "menu-main-evidence-overviews-rented",
            content: <RentedOverviews />,
            text: t(translationPath(lang.menu.items.borrowed)),
            url: EvidenceRoutes.EVIDENCE_OVERVIEWS_RENTED
          }
        ],
        text: t(translationPath(lang.menu.items.overviews))
      }
    ],
    text: t(translationPath(lang.menu.items.evidence))
  }
];
