import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "presentation/designSystem/Form/v2/Form";
import CommentsTabContainer from "presentation/core/components/dialog/tabs/comments";
import { Tabs } from "presentation/designSystem/Tabs/Tabs";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import { useComponentsV2TabContainer } from "presentation/core/components/dialog/tabs/components/hooks/useComponentsV2TabContainer";
import { SpisumNodeTypes } from "presentation/enums";
import { Button } from "antd";
import { useModal } from "@isfg/react-components/build/Modal/useModal";
import { handoverDocument } from "presentation/share/components/dialog/documentHandoverDialog/_actions";
import { RootStateType } from "presentation/reducers";
import { DialogDataType } from "presentation/core/components/dialog/_types";
import { DocumentType } from "presentation/enums";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import {
  TechnicalDataCarrierDocument,
  UpdateTechnDataCarrierFormFieldTypes
} from "../types/types";
import UnsavedChangesModal from "../../dataBox/unprocessed/modals/UnsavedChangesModal";
import { ModalWithPreview } from "presentation/designSystem/Modal/ModalWithPreview";
import { TechnicalDataCarrierForm } from "../form/TechnicalDataCarrierForm";
import { useUpdateDocument } from "../../dataBox/unprocessed/hooks/useUpdateDocument";
import { useInactiveComponentsV2TabContainer } from "../../../../../../../core/components/dialog/tabs/InactivateComponents/hooks/useInactiveComponentsV2TabContainer";
import { useDocumentConcernedSubjectTable } from "presentation/share/tables/document/ConcernedSubjectTable/useDocumentConcernedSubjectTable";
import { SearchSubjectResult } from "presentation/modules/nameRegister/modal/SearchSubjectModal/SearchSubjectModal";
import { RemoteTableApiContextProvider } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import { useEncryptedComponentsModal } from "../../hooks/useEncryptedComponentsModal";
import { Component } from "presentation/core/types";
import { EncryptedComponentsPasswordModal } from "../../modals/EncryptedComponentsPasswordModal";
import { ComponentType } from "presentation/core/api/components/_types";

export interface TechnicalDataCarrieProps extends BaseModalProps {
  nodeId?: string;
  readonly?: boolean;
  documentId?: string;
  nodeType?: string;
  document: TechnicalDataCarrierDocument;
}

enum RegisterTechDataCarrierModalTabs {
  Metadata = "0",
  Components = "1",
  InActiveComponents = "2",
  ConcernedSubjects = "3",
  Comments = "4"
}

export const TechnicalDataCarrierModal = (props: TechnicalDataCarrieProps) => {
  return (
    <RemoteTableApiContextProvider>
      <TechnicalDataCarrierModalInner {...props} />
    </RemoteTableApiContextProvider>
  );
};

export const TechnicalDataCarrierModalInner = ({
  documentId,
  nodeId,
  document,
  onCancel,
  onOk
}: TechnicalDataCarrieProps) => {
  const [activeTab, setActiveTab] = useState("0");
  const [form] = useForm<UpdateTechnDataCarrierFormFieldTypes>();
  const dispatch = useDispatch();
  let [
    isTechnicalDataCarrierRegistered,
    setIsTechnicalDataCarrierRegistered
  ] = useState<boolean>(false);

  const formData = useSelector(
    (state: RootStateType) => state.metaFormReducer.actual.formValues
  );

  const changeActiveTab = (activeTabIndex: string) => {
    setActiveTab(activeTabIndex);
  };

  const {
    updateAndRegister: updateAndRegisterDocument,
    updateAndRegisterLoading,
    update: updateDocument
  } = useUpdateDocument<UpdateTechnDataCarrierFormFieldTypes>();

  const [unsavedChangesModal, unsavedChangesModalApi] = useModal(
    UnsavedChangesModal
  );

  const saveChanges = async () =>
    updateDocument(
      {
        id: nodeId!,
        input: form.getFieldsValue(true)
      },
      {
        onSuccess: () => {
          dispatch(documentViewAction__Refresh(true));
          onCancel?.();
        }
      }
    );

  const registerTechnicalCarrierDocumentAndHandOver = async () => {
    try {
      if (isTechnicalDataCarrierRegistered) {
        await modalForHandover(nodeId!, form.getFieldsValue());
        return;
      }
      await form.validateFields();
      updateAndRegisterDocument(
        {
          id: nodeId!,
          input: form.getFieldsValue()
        },
        {
          onSuccess: () => {
            setIsTechnicalDataCarrierRegistered(true);
            modalForHandover(nodeId!, form.getFieldsValue());
          }
        }
      );
    } catch {}
  };

  const modalForHandover = async (nodeId: string, formData: any) => {
    dispatch(
      handoverDocument({
        data: { id: nodeId, ...(formData as DialogDataType) },
        onClose: () => {},
        onSuccess() {
          onCancel?.();
        }
      })
    );
  };

  const closeTechnicalCarrierModal = async () => {
    dispatch(documentViewAction__Refresh(true));
    onCancel?.();
  };

  const registerTechnicalCarrierDocument = async () => {
    try {
      if (isTechnicalDataCarrierRegistered) {
        await closeTechnicalCarrierModal();
        return;
      }
      await form.validateFields();
      updateAndRegisterDocument(
        {
          id: nodeId!,
          input: form.getFieldsValue()
        },
        {
          onSuccess: () => {
            setIsTechnicalDataCarrierRegistered(true);
            closeTechnicalCarrierModal();
          }
        }
      );
    } catch {}
  };

  const handleCancel = () => {
    form.isFieldsTouched()
      ? unsavedChangesModalApi.open({
          saveChanges,
          closeModal: onCancel
        })
      : onCancel?.();
  };
  const [showInactiveComponents, setShowInactiveComponents] = useState(false);
  const handleShowDeactivateComponent = () => {
    setShowInactiveComponents(true);
    setActiveTab("2");
  };

  const [showPreview, setShowPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState({
    id: "",
    name: "",
    nodeType: SpisumNodeTypes.Document,
    entityId: "",
    fileIsEncrypted: false
  });

  const onUploadFinished = (components: Component[]) => {
    const encryptedFiles = components.filter(
      (component: Component) => component.properties?.ssl?.fileIsEncrypted
    );

    if (encryptedFiles.length === 1) {
      openEncryptedComponentsModal({
        documentType: DocumentType.TechnicalDataCarries,
        encryptedComponents: [encryptedFiles[0].id]
      });
    }
  };

  const [
    componentsV2TabContainer,
    { fetchComponents }
  ] = useComponentsV2TabContainer({
    nodeId: nodeId!,
    isActive: activeTab === RegisterTechDataCarrierModalTabs.Components,
    dialogProps: {
      canUploadComponents: true,
      data: {
        nodeType: SpisumNodeTypes.Document,
        properties: {
          ssl: {
            form: document.form,
            documentType: DocumentType.TechnicalDataCarries
          }
        }
      }
    },
    documentId,
    showPreview,
    previewItem,
    setPreviewItem,
    setShowPreview,
    handleShowDeactivateComponent,
    onUploadFinished
  });

  const [
    encryptedComponentsPasswordModal,
    encryptedComponentsPasswordModalApi
  ] = useModal(EncryptedComponentsPasswordModal, { onOk: fetchComponents });

  const {
    encryptedComponentsModal,
    openEncryptedComponentsModal
  } = useEncryptedComponentsModal({
    onOk: (encryptedComponents) => {
      if (encryptedComponents && encryptedComponents.length === 1) {
        encryptedComponentsPasswordModalApi.open({
          componentType: ComponentType.Document,
          nodeId: nodeId!,
          componentId: encryptedComponents[0]
        });
      }
    }
  });

  const [
    inactiveComponentsV2TabContainer,
    { inActiveFetchComponent }
  ] = useInactiveComponentsV2TabContainer({
    nodeId: nodeId!,
    isActive: activeTab === RegisterTechDataCarrierModalTabs.InActiveComponents,
    dialogProps: {
      canUploadComponents: false,
      data: { nodeType: SpisumNodeTypes.Document }
    },
    documentId,
    showPreview,
    previewItem,
    setPreviewItem,
    setShowPreview
  });

  const [
    documentConcernedSubjectTable,
    { setSenderAsConcernedSubject, refreshDocConcernedSubjs }
  ] = useDocumentConcernedSubjectTable({
    documentId: document.nodeId!,
    readonly: false
  });

  const onSenderChange = (sender: SearchSubjectResult) => {
    setSenderAsConcernedSubject(sender.subject);
  };

  const tabsWithRefresh = {
    [RegisterTechDataCarrierModalTabs.Components]: fetchComponents,
    [RegisterTechDataCarrierModalTabs.ConcernedSubjects]: refreshDocConcernedSubjs,
    [RegisterTechDataCarrierModalTabs.InActiveComponents]: inActiveFetchComponent
  };

  return (
    <>
      {unsavedChangesModal}
      {encryptedComponentsModal}
      {encryptedComponentsPasswordModal}
      <ModalWithPreview
        visible={true}
        hasTabs
        title={t(translationPath(lang.dialog.title.digitalDocumentIncome))}
        onCancel={handleCancel}
        okText={t(translationPath(lang.modal.ok))}
        cancelText={t(translationPath(lang.modal.cancel))}
        onRefresh={tabsWithRefresh[activeTab]}
        confirmLoading={false}
        handleShowPreviewChange={() => setShowPreview(false)}
        showPreview={showPreview}
        previewItem={previewItem}
        setPreviewItem={setPreviewItem}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            data-test-id="cancel-technical-carrier"
          >
            {t(translationPath(lang.modal.cancel))}
          </Button>,
          <Button
            key="registerAndHandover"
            type="primary"
            loading={false}
            onClick={registerTechnicalCarrierDocumentAndHandOver}
            data-test-id="register-and-handover-technical-carrier"
          >
            {t(translationPath(lang.dialog.form.toRegisterAndRefer))}
          </Button>,
          <Button
            key="register"
            loading={updateAndRegisterLoading}
            onClick={registerTechnicalCarrierDocument}
            data-test-id="register-technical-carrier"
          >
            {t(translationPath(lang.dialog.form.toRegister))}
          </Button>
        ]}
      >
        <Tabs onChange={changeActiveTab}>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.subjectDialog.tabs.metadata))}
            key={RegisterTechDataCarrierModalTabs.Metadata}
            data-test-id="metadata-technical-carrier-name"
          >
            <TechnicalDataCarrierForm
              form={form}
              formData={document}
              data-test-id="metadata-technical-carrier"
              onSenderChange={onSenderChange}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.components))}
            key={RegisterTechDataCarrierModalTabs.Components}
            data-test-id="components-technical-carrier"
          >
            {componentsV2TabContainer}
          </Tabs.TabPane>
          {showInactiveComponents && (
            <Tabs.TabPane
              tab={t(translationPath(lang.dialog.tabs.InactiveComponents))}
              key={RegisterTechDataCarrierModalTabs.InActiveComponents}
            >
              {inactiveComponentsV2TabContainer}
            </Tabs.TabPane>
          )}
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.concernedSubjects))}
            key={RegisterTechDataCarrierModalTabs.ConcernedSubjects}
            data-test-id="conserned-subjects-technical-carrier-name"
          >
            <div className="body-fullsize">{documentConcernedSubjectTable}</div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.notes))}
            key={RegisterTechDataCarrierModalTabs.Comments}
            data-test-id="comments-technical-carrier-name"
          >
            <CommentsTabContainer
              nodeId={nodeId!}
              isActive={activeTab === RegisterTechDataCarrierModalTabs.Comments}
              dialogProps={{ data: { nodeType: SpisumNodeTypes.Document } }}
              data-test-id="comments-technical-carrier"
            />
          </Tabs.TabPane>
        </Tabs>
      </ModalWithPreview>
    </>
  );
};
