import CreateIcon from "@material-ui/icons/Create";
import { Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import {
  StyledFieldWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t, WithTranslation } from "presentation/translation/i18n";
import React, { useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useModal } from "../../../../../../designSystem/Modal/useModal";
import { SendModeValues, ShipmentPersonally } from "../../../../../../enums";
import { SearchSubjectModal } from "../../../../../../modules/nameRegister/modal/SearchSubjectModal/SearchSubjectModal";
import { SearchSubjectResult } from "../../../createShipmentDialog/CreateShipmentForm";
import { MuiFormikShipmentHelper } from "../../../createShipmentDialog/MuiFormikShipmentHelper";
import { CreateShipmentFormValuesProxy } from "../../../createShipmentDialog/_types";
import { MetaFormProps } from "../../../_types";
import {
  ShipmentFormValues,
  shipmentPersonalFormValuesProxy
} from "../../_types";
import { BaseBottomForm, BaseUpperForm } from "../baseForm/ShipmentBaseForm";
import { validate } from "./_validations";
import AmountToSend from "../../../createShipmentDialog/forms/common/AmountToSend";

const Component = ({
  initialValues,
  formRef,
  readonly
}: MetaFormProps<ShipmentFormValues> & WithTranslation) => {
  const dialogClasses = useStyles();
  const handle = (): void => void 0;
  const disabled = initialValues.internalState === ShipmentPersonally.Delivered;
  const [searchResult, setSearchResult] = useState<SearchSubjectResult>();
  const [modal, modalApi] = useModal(SearchSubjectModal, {
    onOk: (result?: SearchSubjectResult) => {
      setSearchResult(result);
    }
  });

  const onSearchSubject = () => {
    modalApi.open({});
  };

  const editFormValues = useMemo(
    () => ({
      ...initialValues,
      addressStreet: `${initialValues.recipientAddressStreet} ${initialValues.recipientAddressHouseNumber}`,
      addressCity: initialValues.recipientAddressCity,
      addressZip: initialValues.recipientAddressZipCode
    }),
    [initialValues]
  );

  return (
    <>
      {modal}
      <Formik<ShipmentFormValues>
        initialValues={editFormValues}
        validate={validate}
        innerRef={formRef || handle}
        onSubmit={handle}
      >
        {() => {
          return (
            <Form className={dialogClasses.form}>
              {searchResult && (
                <MuiFormikShipmentHelper
                  sendMode={SendModeValues.Personally}
                  searchSubjectResult={searchResult}
                />
              )}
              <BaseUpperForm initialValues={initialValues} readonly={true} />
              <div
                style={{ display: "flex", position: "relative", width: "100%" }}
              >
                <StyledFieldWide
                  component={TextField}
                  data-test-id="create-shipment-post-address1"
                  required={true}
                  disabled={disabled}
                  name={
                    lastPathMember(CreateShipmentFormValuesProxy.address1).path
                  }
                  type="text"
                  label={t(translationPath(lang.general.addressee))}
                />
                {!disabled && (
                  <CreateIcon
                    style={{
                      margin: "9px",
                      position: "absolute",
                      right: 0,
                      top: "1.25rem",
                      cursor: "pointer"
                    }}
                    onClick={onSearchSubject}
                  />
                )}
              </div>
              <StyledFieldWide
                component={TextField}
                data-test-id="detail-shipment-personally-address2"
                disabled={disabled}
                name={
                  lastPathMember(shipmentPersonalFormValuesProxy.address2).path
                }
                type="text"
              />
              <StyledFieldWide
                component={TextField}
                data-test-id="detail-shipment-personally-address3"
                disabled={disabled}
                name={
                  lastPathMember(shipmentPersonalFormValuesProxy.address3).path
                }
                type="text"
              />
              <StyledFieldWide
                component={TextField}
                data-test-id="detail-shipment-personally-address4"
                disabled={disabled}
                name={
                  lastPathMember(shipmentPersonalFormValuesProxy.address4).path
                }
                type="text"
              />
              <StyledFieldWide
                component={TextField}
                data-test-id="detail-shipment-personally-address-street"
                disabled={true}
                required={true}
                name={
                  lastPathMember(shipmentPersonalFormValuesProxy.addressStreet)
                    .path
                }
                type="text"
                label={t(translationPath(lang.general.streetAndStreetNumber))}
              />
              <StyledFieldWide
                component={TextField}
                data-test-id="detail-shipment-personally-address-city"
                disabled={true}
                required={true}
                name={
                  lastPathMember(shipmentPersonalFormValuesProxy.addressCity)
                    .path
                }
                type="text"
                label={t(translationPath(lang.general.municipality))}
              />
              <StyledFieldWide
                component={TextField}
                data-test-id="detail-shipment-personally-address-zip"
                disabled={true}
                required={true}
                name={
                  lastPathMember(shipmentPersonalFormValuesProxy.addressZip)
                    .path
                }
                type="text"
                label={t(translationPath(lang.general.zipCode))}
              />
              <StyledFieldWide
                component={TextField}
                data-test-id="detail-shipment-personally-address-state"
                disabled={true}
                name={
                  lastPathMember(shipmentPersonalFormValuesProxy.addressState)
                    .path
                }
                type="text"
                label={t(translationPath(lang.general.addressState))}
              />
              <BaseBottomForm />
              <AmountToSend readonly={!!readonly} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export const ShipmentPersonalForm = withTranslation()(React.memo(Component));
