import { CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import { ErrorBoundary } from "presentation/core/components/errorBoundary";
import { useShowLayoutLoading } from "presentation/core/hooks/useShowLayoutLoading";
import React, { useEffect, useMemo, useState } from "react";
import { DialogTabError } from "../../errorBoundary/errorTypes/DialogErrors";
import { useStyles } from "../Dialog.styles";
import {
  ChannelsType,
  DialogDataProps,
  TabAndDialogChannelType
} from "../_types";
import { TabPropsType } from "./_types";
import { Tabs } from "../../../../designSystem/Tabs/Tabs";

interface OwnProps {
  tabs: TabPropsType[];
  channels: ChannelsType;
  dialogProps: DialogDataProps;
  showPreview: boolean;
  onClose?: VoidFunction;
  setActiveChannel: (channel: TabAndDialogChannelType) => void;
}

export const DialogTabs = ({
  tabs,
  channels,
  dialogProps,
  onClose,
  setActiveChannel
}: OwnProps) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("0");
  const [refreshTab, setRefreshTab] = useState(0);
  const tabsHiddenByDefault = useMemo(
    () =>
      tabs.reduce(
        (acc, tab, index) =>
          tab.hiddenByDefault ? acc.concat(String(index)) : acc,
        [] as string[]
      ),
    [tabs]
  );
  const [hiddenTabs, setHiddenTabs] = useState(tabsHiddenByDefault);
  const loading = useShowLayoutLoading();
  const filteredTabs = useMemo(
    () => filterTabs(tabs, channels, dialogProps, hiddenTabs),
    [tabs, dialogProps, channels, hiddenTabs]
  );

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    const activeTabChannel = filteredTabs.find(
      (tab) => tab.tab.key === activeTab
    )?.channel;
    setActiveChannel(activeTabChannel!);
  }, [setActiveChannel, filteredTabs, activeTab]);

  const reloadTab = () => {
    setRefreshTab(refreshTab + 1);
  };

  const showTab = (key: string, setAsActiveTab: boolean = false) => {
    if (hiddenTabs.includes(key)) {
      setHiddenTabs(hiddenTabs.filter((item) => item !== key));
    }
    if (setAsActiveTab) {
      setActiveTab(key);
    }
  };

  const hideTab = (key: string) => {
    if (!hiddenTabs.includes(key)) {
      setHiddenTabs(hiddenTabs.concat(key));
      setPreviousTabActive(key);
    }
  };

  const setPreviousTabActive = (key: string) => {
    const index = filteredTabs.findIndex(({ tab }) => tab.key === key);
    const previousTabKey = filteredTabs[index - 1].tab.key;

    setActiveTab(previousTabKey);
  };

  return (
    <Tabs activeKey={activeTab} onChange={handleTabChange} size="large">
      {filteredTabs.map(
        ({ tab: { content: Tab, label, key, renderImmediately }, channel }) => {
          const isActive = key === activeTab;

          return (
            <Tabs.TabPane key={key} tab={label} forceRender={renderImmediately}>
              {channel.isLoading && (
                <div className={clsx("body-fullsize", classes.contentCentered)}>
                  {!loading && <CircularProgress />}
                </div>
              )}
              <ErrorBoundary Component={DialogTabError} reloadTab={reloadTab}>
                <Tab
                  dialogProps={dialogProps}
                  channel={channel}
                  isActive={isActive}
                  onClose={onClose}
                  showTab={showTab}
                  hideTab={hideTab}
                />
              </ErrorBoundary>
            </Tabs.TabPane>
          );
        }
      )}
    </Tabs>
  );
};

const filterTabs = (
  tabs: TabPropsType[],
  channels: ChannelsType,
  dialogProps: DialogDataProps,
  hiddenTabs: string[]
) => {
  return (
    tabs
      .map((tab, index) => {
        return { ...tab, key: String(index) };
      })
      // remove tabs which should not be displayed
      .filter(
        (tab) =>
          !hiddenTabs.includes(tab.key) &&
          (tab.filter
            ? tab.filter({ dialogProps, channel: channels[tab.label] })
            : true)
      )
      // associate tab with its channel for faster active channel lookup
      .map((tab) => ({
        tab,
        channel: channels[tab.label]
      }))
  );
};

export default DialogTabs;
