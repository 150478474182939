import { SslAnalog } from "presentation/core/api/models";
import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import MetaFormContainer from "presentation/core/components/MetaForm";
import { GenericDocument } from "presentation/core/types";
import { DocumentType, SenderType } from "presentation/enums";
import React from "react";
import { DigitalDeliveredMetadataForm } from "./DigitalDeliveredMetadataForm";
import { DigitalOwnMetadataForm } from "./DigitalOwnMetadataForm";

const FORMS = {
  [DocumentType.Digital]: {
    [SenderType.Individual]: DigitalDeliveredMetadataForm,
    [SenderType.Legal]: DigitalDeliveredMetadataForm,
    [SenderType.Own]: DigitalOwnMetadataForm
  },
  [DocumentType.DA]: {
    [SenderType.Individual]: DigitalDeliveredMetadataForm,
    [SenderType.Legal]: DigitalDeliveredMetadataForm,
    [SenderType.Own]: DigitalOwnMetadataForm
  },
  [DocumentType.AD]: {
    [SenderType.Individual]: DigitalDeliveredMetadataForm,
    [SenderType.Legal]: DigitalDeliveredMetadataForm,
    [SenderType.Own]: DigitalOwnMetadataForm
  }
};

const MetaDataFormTab = ({
  channel,
  dialogProps,
  onClose
}: DialogContentPropsType) => {
  const sslProps = (dialogProps.data as GenericDocument)?.properties?.ssl;
  const form = sslProps?.form;
  const senderType = sslProps?.senderType;
  const selectedForm =
    (form && senderType && FORMS[form][senderType]) ||
    DigitalDeliveredMetadataForm;

  return (
    <MetaFormContainer<SslAnalog>
      channel={channel}
      dialogProps={dialogProps}
      MetaForm={selectedForm}
      onClose={onClose}
    />
  );
};

export default MetaDataFormTab;
