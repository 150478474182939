import { History as NameRegisterHistroy } from "domain/struct/nameRegistry/History";
import { HistoryRepositoryToken } from "domain/di/token/nameRegistry";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { HistoryRepository } from "../../../domain/service/nameRegistry/HistoryRepository";
import { SortingConfig } from "lib/contract/Sorting";

@DInjectable()
export class FindHistory {
  constructor(
    @DInject(HistoryRepositoryToken)
    protected historyRepository: HistoryRepository
  ) {}

  findAllPaginated(
    subjectId: string,
    config: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<NameRegisterHistroy>> {
    return this.historyRepository.findBySubjectId(subjectId, config, sorting);
  }
}
