import React, { useState } from "react";
import { useForm } from "@isfg/react-components/build/Form/Form";
import { BaseModalProps } from "@isfg/react-components/build/Modal/useModal";
import { Tabs } from "@isfg/react-components/build/Tabs/Tabs";
import { Button } from "presentation/designSystem/Button/Button";
import { SslEmail } from "presentation/core/api/models";
import { useComponentsV2TabContainer } from "presentation/core/components/dialog/tabs/components/hooks/useComponentsV2TabContainer";
import { EmailDocument } from "presentation/core/types";
import { RemoteTableApiContextProvider } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { EmailDetailsForm } from "../../unprocessed/forms/EmailDetailsForm";
import { UseCreateEmailRegistrationReturn } from "../../unprocessed/hooks/useCreateEmailRegistration";
import { SpisumNodeTypes } from "../../../../../../../../enums";
import { ModalWithPreview } from "../../../../../../../../designSystem/Modal/ModalWithPreview";

interface EmailNotRegisteredDetailsModalProps {
  onCancel?: BaseModalProps["onCancel"];
  onOk?: BaseModalProps["onOk"];
  data: EmailDocument;
  register: UseCreateEmailRegistrationReturn["register"];
}

enum EmailNotRegisteredDetailsModalTabs {
  Metadata = "0",
  Components = "1"
}

export const EmailNotRegisteredDetailsModal = (
  props: EmailNotRegisteredDetailsModalProps
) => {
  return (
    <RemoteTableApiContextProvider>
      <EmailNotRegisteredDetailsModalInner {...props} />
    </RemoteTableApiContextProvider>
  );
};

export const EmailNotRegisteredDetailsModalInner = ({
  onCancel,
  data,
  register
}: EmailNotRegisteredDetailsModalProps) => {
  const [activeTab, setActiveTab] = useState("0");
  const [form] = useForm<SslEmail>();
  const formData = data.properties!.ssl!;

  const changeActiveTab = (activeTabIndex: string) => {
    setActiveTab(activeTabIndex);
  };

  const onRegister = () => {
    register(data.id);
    onCancel?.();
  };

  const [showPreview, setShowPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState({
    id: "",
    name: "",
    nodeType: SpisumNodeTypes.Email,
    entityId: "",
    fileIsEncrypted: false
  });

  const [
    componentsV2TabContainer,
    { fetchComponents }
  ] = useComponentsV2TabContainer({
    nodeId: data.id,
    isActive: activeTab === EmailNotRegisteredDetailsModalTabs.Components,
    dialogProps: {
      canUploadComponents: false,
      data
    },
    isReadonly: true,
    showPreview,
    previewItem,
    setPreviewItem,
    setShowPreview
  });

  const tabsWithRefresh = {
    [EmailNotRegisteredDetailsModalTabs.Components]: fetchComponents
  };

  return (
    <ModalWithPreview
      visible={true}
      hasTabs
      onRefresh={tabsWithRefresh[activeTab]}
      title={t(translationPath(lang.dialog.title.emailDetails))}
      onCancel={onCancel}
      confirmLoading={false}
      handleShowPreviewChange={() => setShowPreview(false)}
      showPreview={showPreview}
      previewItem={previewItem}
      setPreviewItem={setPreviewItem}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t(translationPath(lang.modal.cancel))}
        </Button>,
        <Button key="register" type="primary" onClick={onRegister}>
          {t(translationPath(lang.dialog.form.register))}
        </Button>
      ]}
    >
      <Tabs onChange={changeActiveTab}>
        <Tabs.TabPane
          tab={t(translationPath(lang.dialog.subjectDialog.tabs.metadata))}
          key={EmailNotRegisteredDetailsModalTabs.Metadata}
        >
          <EmailDetailsForm formData={formData} form={form} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t(translationPath(lang.dialog.tabs.components))}
          key={EmailNotRegisteredDetailsModalTabs.Components}
        >
          {componentsV2TabContainer}
        </Tabs.TabPane>
      </Tabs>
    </ModalWithPreview>
  );
};
