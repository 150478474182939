import React, { useMemo } from "react";
import styled from "@emotion/styled";

import { Select } from "../../../../../designSystem/Select/Select";
import { useFindCountries } from "../../../hooks/useFindCountries";
import { AddressFormEnum } from "../../../../../../domain/struct/nameRegistry/AddressFormEnums";
import { Item } from "../../../../../designSystem/Form/v2/Form";

interface CountryTypeSelectProps {
  onChange?: (value: string) => void;
}

const validationCountryRules = [{ required: true }];

export const CountryTypeSelect = ({ onChange }: CountryTypeSelectProps) => {
  const { data, isFetching } = useFindCountries({ retry: false });

  const options = useMemo(
    () =>
      data
        ? data.map(({ code, czechName }) => ({
            label: czechName,
            value: code
          }))
        : [],
    [data]
  );

  return (
    <StyledSelectItem
      rules={validationCountryRules}
      name="country"
      label={AddressFormEnum.Country}
    >
      <Select
        onChange={onChange}
        loading={isFetching}
        showSearch
        optionFilterProp="label"
        options={options}
      />
    </StyledSelectItem>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;
