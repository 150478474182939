import { Person } from "domain/struct/nameRegistry/Person";
import { SearchSubjectPersonRepositoryToken } from "domain/di/token/nameRegistry";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { SearchedSubjectPersonRepository } from "../../../domain/service/nameRegistry/SearchedSubjectRepository";

@DInjectable()
export class FindSearchedSubjectPerson {
  constructor(
    @DInject(SearchSubjectPersonRepositoryToken)
    protected searchedSubjectRepository: SearchedSubjectPersonRepository
  ) {}

  findAllPaginated(
    config: PaginationConfig,
    params?: Record<string, string>
  ): Promise<PaginatedResult<Person>> {
    return this.searchedSubjectRepository.findAllPaginated(config, params);
  }
}
