import { push } from "connected-react-router";
import { databoxAccountsAction } from "presentation/core/api/databox/_actions";
import { emailAccountsAction } from "presentation/core/api/email/_actions";
import { Notification } from "presentation/designSystem/notification/Notification";
import { CoreRoutes } from "presentation/core/routes";
import intersectionWith from "lodash/intersectionWith";
import { all, call, put, select, take, takeLatest } from "redux-saga/effects";
import { secretEncrypt } from "presentation/share/utils/byteOperations";
import { fetchSaga } from "presentation/share/utils/fetch";
import { translationPath } from "presentation/share/utils/getPath";
import { getDomainFromUsername } from "presentation/share/utils/utils";
import { lang, t } from "presentation/translation/i18n";
import { ActionType, getType } from "typesafe-actions";
import { RootStateType } from "../../../reducers";
import { ApiURL } from "../../apiURL";
import {
  AdminFilePlanAction,
  allGroupsAction,
  FilePlanAction,
  loginAction,
  loginKeepAction,
  loginSetDomainAction,
  loginSetSessionTokenAction,
  userGroupAction,
  userInfoAction
} from "./_actions";
import { UserGroupType } from "./_types";
import { getService } from "../dependencyInjection";
import { GetConfiguration } from "../../../../useCase/configuration/GetConfiguration";
import { CONFIG } from "../../../../config";
import { GetUserInfo } from "../../../../useCase/user/GetUserInfo";

export function* watchAllGroupsAction() {
  yield takeLatest(getType(allGroupsAction.request), function* () {
    const { errorResponse, response, success } = yield call(
      fetchSaga,
      ApiURL.GROUPS_ALL,
      "GET"
    );

    if (!success) {
      yield put(allGroupsAction.failure(errorResponse));
      return;
    }

    yield put(allGroupsAction.success(response));
  });
}

export function* watchLoginAction() {
  yield takeLatest(
    getType(loginAction.request),
    function* ({ payload }: ActionType<typeof loginAction.request>) {
      const { password, remember, username } = payload;
      yield put(loginSetDomainAction(getDomainFromUsername(username)));
      const { errorResponse, response, success } = yield call(
        fetchSaga,
        ApiURL.LOGIN,
        "POST",
        {
          bodyJSON: {
            password,
            username
          }
        }
      );

      if (!success) {
        yield put(loginAction.failure(errorResponse));
        return;
      }

      if (!response.authorizationToken) {
        yield put(loginAction.failure(errorResponse));
        Notification.error({
          message: t(translationPath(lang.login.error))
        });
        return;
      }

      yield put(
        loginSetSessionTokenAction({
          domain: getDomainFromUsername(username),
          isAdmin: response.isAdmin,
          token: response.authorizationToken
        })
      );

      const configuration = yield getConfiguration();

      if (!response.isAdmin) {
        yield put(allGroupsAction.request()); // all groups
        yield put(FilePlanAction.request());
        yield put(databoxAccountsAction.request());
        yield put(emailAccountsAction.request());
        yield put(userInfoAction.request());

        yield all([
          take(getType(allGroupsAction.success)),
          take(getType(FilePlanAction.success)),
          take(getType(userInfoAction.success))
        ]);

        yield put(userGroupAction.request()); // user groups
        yield take(getType(userGroupAction.success));
      }

      if (response.isAdmin) {
        yield put(AdminFilePlanAction.request());

        yield all([take(getType(AdminFilePlanAction.success))]);
      }

      yield put(
        loginAction.success({
          ...response,
          remember:
            (remember && {
              password: secretEncrypt(password),
              username: secretEncrypt(username)
            }) ||
            null,
          configuration
        })
      );

      yield put(push(CoreRoutes.DASHBOARD));
    }
  );
}

export function* watchCodeListsAction() {
  yield takeLatest(getType(FilePlanAction.request), function* () {
    const { errorResponse, response, success } = yield call(
      fetchSaga,
      ApiURL.FILE_PLANS,
      "GET"
    );

    if (!success) {
      yield put(FilePlanAction.failure(errorResponse));
      return;
    }

    yield put(FilePlanAction.success(response.reverse()));
  });

  yield takeLatest(getType(AdminFilePlanAction.request), function* () {
    const { errorResponse, response, success } = yield call(
      fetchSaga,
      ApiURL.A_FILE_PLANS,
      "GET"
    );

    if (!success) {
      yield put(AdminFilePlanAction.failure(errorResponse));
      return;
    }

    yield put(AdminFilePlanAction.success(response));
  });
}

export function* watchLoginKeepAction() {
  yield takeLatest(getType(loginKeepAction.request), function* () {
    const { errorResponse, status, success } = yield call(
      fetchSaga,
      ApiURL.VALIDATE,
      "GET"
    );

    if (!success && status !== 401) {
      yield put(loginKeepAction.failure(errorResponse));
      return;
    }

    yield put(loginKeepAction.success());
  });
}

export function* watchUserInfoAction() {
  yield takeLatest(getType(userInfoAction.request), function* () {
    try {
      const response = yield getUserInfo();
      yield put(userInfoAction.success(response));
    } catch {
      yield put(
        userInfoAction.failure({
          code: null,
          message: null
        })
      );
    }
  });
}

export function* watchUserGroupAction() {
  yield takeLatest(getType(userGroupAction.request), function* () {
    const { errorResponse, response, success } = yield call(
      fetchSaga,
      ApiURL.USER_GROUPS,
      "GET",
      {
        urlWildCards: {
          personId: "-me-"
        }
      }
    );

    if (!success) {
      yield put(userGroupAction.failure(errorResponse));
      return;
    }

    const allGroups = yield select(
      (state: RootStateType) => state.loginReducer?.global?.groups?.main
    );

    const userGroups = response?.list?.entries?.map(
      (entity: { entry: UserGroupType }) => entity.entry
    );

    const filteredGroups = intersectionWith(
      userGroups,
      allGroups,
      (actionEntity: UserGroupType, allGroupsEntity: UserGroupType) => {
        return allGroupsEntity.id === actionEntity.id;
      }
    );

    yield put(
      userGroupAction.success({ groups: filteredGroups, myGroups: userGroups })
    );
  });
}

function getConfiguration() {
  const configuration = getService(GetConfiguration);
  return call(() => configuration.getConfiguration().catch(() => CONFIG.app));
}

function getUserInfo() {
  const userInfo = getService(GetUserInfo);
  return call(() => userInfo.getCurrentUserInfo());
}
