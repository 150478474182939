import {
  StyledFieldWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import { Form } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { lang, t } from "presentation/translation/i18n";
import { lastPathMember, translationPath } from "../../../utils/getPath";
import { retentionProposalProxy, RetentionProposalValues } from "./_types";
import { validate } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const CreateRetentionProposalDialog: DialogContentType["content"] = ({
  channel
}) => {
  const classes = useStyles();

  return (
    <div className="body-midsize" style={{ minHeight: 100, maxWidth: 500 }}>
      <ChannelForm<RetentionProposalValues>
        initialValues={{
          name: ""
        }}
        validate={validate}
        channel={channel}
      >
        <Form className={classes.form}>
          <StyledFieldWide
            component={TextField}
            data-test-id="meta-input-subject"
            disabled={false}
            required={true}
            name={lastPathMember(retentionProposalProxy.name).path}
            type="string"
            label={t(translationPath(lang.general.nameOfRetentionProposal))}
          />
        </Form>
      </ChannelForm>
    </div>
  );
};
