import React, { useMemo } from "react";
import styled from "@emotion/styled";

import { Select } from "../../../../../../designSystem/Select/Select";
import { Item } from "../../../../../../designSystem/Form/v2/Form";
import { useFindUsersForSeal } from "../hooks/useUsersForSeal";
import { ForSealForm } from "./ForSealFormEnums";

const validationGroupUserTypeRules = [{ required: true }];
export interface UserFieldsProps {
  groupId: string;
}
export const ForSealUsersFieldSelects = ({ groupId }: UserFieldsProps) => {
  const { data } = useFindUsersForSeal(groupId);

  const options = useMemo(
    () =>
      data
        ? data.map(({ id, displayName }) => ({
            label: displayName,
            value: id
          }))
        : [],
    [data, groupId]
  );

  return (
    <StyledSelectItem
      name="user"
      label={ForSealForm.users}
      rules={validationGroupUserTypeRules}
    >
      <Select
        style={{ width: "15rem" }}
        options={options}
        disabled={groupId ? false : true}
      />
    </StyledSelectItem>
  );
};

const StyledSelectItem = styled(Item)`
  margin-left: 10px;
  .ant-select {
    max-width: 100%;
  }
`;
