import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import { Notification } from "presentation/designSystem/notification/Notification";

import { RootStateType } from "presentation/reducers";
import { put, select, takeLatest } from "redux-saga/effects";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ActionType, getType } from "typesafe-actions";
import { submitToRepositoryDialogOpen } from "./_actions";

const getRepository = (state: RootStateType) =>
  state?.loginReducer?.global?.groups?.repository;

export function* watchSubmitToRepositoryDialogAction() {
  yield takeLatest(
    getType(submitToRepositoryDialogOpen),
    function* ({ payload }: ActionType<typeof submitToRepositoryDialogOpen>) {
      if (!payload) {
        return;
      }
      const repository = yield select(getRepository);
      const { data } = payload;
      if (repository.length === 0) {
        Notification.error({
          message: t(
            translationPath(lang.dialog.notifications.notFoundAnyRepositories)
          )
        });
        return;
      }

      yield put(
        dialogOpenAction({
          dialogProps: {
            data: {
              ...data,
              groupList: repository
            }
          },
          dialogType: DialogType.SubmitTo
        })
      );
    }
  );
}
