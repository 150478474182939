import { debounce } from "lodash";
import React, { useCallback, useMemo, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { SslProperties } from "presentation/core/api/models";
import { ErrorBoundary } from "../errorBoundary";
import { DialogError } from "../errorBoundary/errorTypes/DialogErrors";
import ActionButtons from "./ActionButtons";
import { TabAndDialogChannel } from "./lib/TabAndDialogChannel";
import Tabs from "./tabs";
import { dialogOpenAction, onSetShowPreview } from "./_actions";
import { initialState, reducer } from "./_componentReducer";
import { createChannelsForTabs, triggerChannelsValidation } from "./_methods";
import {
  ActionButtonStateType,
  ActionOnCloseType,
  ActionType,
  ChannelsType,
  DialogContentType,
  DialogDataGenericData,
  DialogDataProps,
  DialogState,
  DialogType,
  TabAndDialogChannelType
} from "./_types";
import {
  Modal,
  ModalSize,
  ModalWithTabs
} from "../../../designSystem/Modal/Modal";
import { IconButton } from "../../../designSystem/Button/IconButton";
import { ReloadOutlined } from "../../../designSystem/Icon/Icon";
import { LoadingIndicator } from "../loadingIndicator/LoadingIndicator";
import { styled } from "../../../styles";
import { Space } from "../../../designSystem/Layout/Space/Space";
import PreviewButton from "./PreviewButton";
import { tableActionViewAction__Refresh } from "../dataTable/components/_action";
import { RemoteTableApiContextProvider } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";

export interface OwnProps extends DialogContentType {
  onClose: (props: ActionOnCloseType) => void;
  dialogProps: DialogDataProps;
  reloadDialog: VoidFunction;
}

// tab
// - oznamit, ze jsou neulozene zmeny
// - oznamit, ze jsou nevalidni data
// - ma novou volitelnou property - renderPreview
// akce
// - dostanou dispatch a dialogProps
// dialog
// - po kliknuti na akci musi zjistit, jestli jsou data validni
// - po kliknuti na zrusit musi zjistit, jestl jsou neulozena data
// - mel by se zavrit az kdyz akce uspesne probehla

// used when the tabs property is missing, so we use only the content
const CONTENT_TAB = "contentTab";
const emptyActions = () => [];

const Dialog = ({
  actions,
  content: Content,
  dialogProps,
  onClose,
  renderPreview,
  tabs,
  title: Title,
  size
}: OwnProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(true);
  const [activeChannel, setActiveChannel] = useState<
    TabAndDialogChannelType | undefined
  >();
  const [
    { fileIsEncrypted, previewItem, showPreview },
    componentDispatch
  ] = useReducer(reducer, initialState);

  const channels: ChannelsType = useMemo(() => {
    if (!tabs) {
      return {
        [CONTENT_TAB]: new TabAndDialogChannel(CONTENT_TAB, componentDispatch)
      };
    }

    return createChannelsForTabs(tabs, componentDispatch);
  }, [tabs]);
  const channelInstances = useMemo(() => Object.values(channels), [channels]);

  const setShowPreview = useCallback(
    (openPreview: boolean) =>
      componentDispatch(
        onSetShowPreview({
          showPreview: openPreview
        })
      ),
    []
  );

  const doClose = useCallback(
    (refreshTableData = true) => {
      const parentMetaData = dialogProps.parentDialogChannels?.Metadata;
      setOpen(false);
      onClose({
        channels,
        dialogProps,
        dispatch,
        dataMutated: refreshTableData
      });
      parentMetaData?.setState({
        ...parentMetaData?.state,
        unsavedChangesDialogOpen: false
      });
      //because this is the only solution/shit for now
      refreshTableData && dispatch(tableActionViewAction__Refresh(true));
    },
    [channels, dialogProps, dispatch, onClose]
  );

  const handleClose = useCallback(
    (refreshTableData = true) => {
      const allChannelsSaved = channelInstances.every(
        (channel) => channel.isSaved
      );

      if (allChannelsSaved || dialogProps.dontUseDataModifiedDialog) {
        doClose(refreshTableData);
        return;
      }

      channels?.Metadata?.setState({
        ...channels?.Metadata?.state,
        unsavedChangesDialogOpen: true
      });

      dispatch(
        dialogOpenAction({
          dialogProps: {
            data: {
              formValues: channels?.Metadata?.state
                ?.formValues as SslProperties,
              id: channels?.Metadata?.state?.id as string,
              nodeType:
                channels?.Metadata?.state?.nodeType ||
                (dialogProps?.data as DialogDataGenericData)?.nodeType
            },
            onSuccess: () => {
              onClose({
                channels,
                dialogProps,
                dispatch,
                dataMutated: refreshTableData
              });
              setOpen(false);
            },
            parentDialogChannels: channels
          },
          dialogType: DialogType.DataModified
        })
      );
    },
    [channels] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleCancel = useCallback(() => handleClose(false), [handleClose]);

  const handleActionClicked = useCallback(
    (action: ActionType["onClick"], buttonState: ActionButtonStateType) => {
      triggerChannelsValidation(channelInstances).then(() => {
        const allChannelsAreValid = channelInstances.every(
          (channel) => channel.isValid
        );

        if (!allChannelsAreValid) {
          return;
        }

        // set all channels to is saving
        invokeOnAllChannels(channelInstances, (channel) =>
          channel.setIsSaving(true)
        );

        const buttonStateEnhanced = {
          buttonState,
          setIsPending: (isPending: boolean) => {
            buttonState.setIsPending(isPending);
            invokeOnAllChannels(channelInstances, (channel) =>
              channel.triggerDialogStateChanged(
                isPending
                  ? DialogState.ActionRunning
                  : DialogState.ActionFinished
              )
            );
          }
        };

        action({
          buttonState: buttonStateEnhanced,
          channels,
          dialogProps,
          dispatch,
          onClose: doClose
        });
      });
    },
    [channelInstances, channels, dialogProps, dispatch, doClose]
  );

  const preview = useMemo(() => {
    if (!showPreview || !renderPreview) {
      return null;
    }

    return (
      !!previewItem && renderPreview(fileIsEncrypted, dialogProps, previewItem)
    );
  }, [fileIsEncrypted, showPreview, renderPreview, previewItem, dialogProps]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshTabDebounced = useMemo(() => {
    return activeChannel?.refreshData
      ? debounce(activeChannel?.refreshData, 500, { leading: true })
      : () => {};
  }, [activeChannel]);

  const title = useMemo(
    () =>
      Title && (
        <StyledTitleWrapper>
          <Title dialogProps={dialogProps} channel={channels[CONTENT_TAB]} />
          <StyledTitleActionsWrapper>
            <Space size={4}>
              {activeChannel?.refreshData && (
                <IconButton
                  icon={<ReloadOutlined />}
                  tooltip={t(translationPath(lang.dialog.form.refresh))}
                  onClick={refreshTabDebounced}
                />
              )}
              {!dialogProps.hidePreviewButton && preview && (
                <PreviewButton
                  handlePreviewChange={setShowPreview}
                  showPreview={true}
                />
              )}
            </Space>
          </StyledTitleActionsWrapper>
        </StyledTitleWrapper>
      ),
    [
      Title,
      dialogProps,
      channels,
      activeChannel?.refreshData,
      refreshTabDebounced,
      preview,
      setShowPreview
    ]
  );

  const footer = useMemo(
    () => (
      <ActionButtons
        actions={!dialogProps.isReadonly && actions ? actions : emptyActions}
        dialogProps={dialogProps}
        onClose={handleCancel}
        onActionClicked={handleActionClicked}
      />
    ),
    [actions, dialogProps, handleActionClicked, handleCancel]
  );

  const modalSize = useMemo(() => {
    if (showPreview) {
      return ModalSize.FullScreen;
    }

    return size;
  }, [size, showPreview]);

  const ModalComponent = tabs ? ModalWithTabs : Modal;

  return (
    <ModalComponent
      visible={open}
      size={modalSize}
      onCancel={handleCancel}
      title={title}
      footer={footer}
      centered={true}
    >
      <ErrorBoundary Component={DialogError} handleClose={handleClose}>
        <StyledModalContent>
          {preview && <StyledPreviewWrapper>{preview}</StyledPreviewWrapper>}
          <StyledContentWrapper>
            {tabs?.length && (
              <RemoteTableApiContextProvider>
                <Tabs
                  tabs={tabs}
                  dialogProps={dialogProps}
                  setActiveChannel={setActiveChannel}
                  onClose={handleClose}
                  channels={channels}
                  showPreview={showPreview}
                />
              </RemoteTableApiContextProvider>
            )}
            {Content && (
              <div>
                {channels[CONTENT_TAB]?.isLoading && <LoadingIndicator />}
                <Content
                  dialogProps={dialogProps}
                  channel={channels[CONTENT_TAB]}
                  onClose={handleClose}
                />
              </div>
            )}
          </StyledContentWrapper>
        </StyledModalContent>
      </ErrorBoundary>
    </ModalComponent>
  );
};

const invokeOnAllChannels = (
  channels: TabAndDialogChannelType[],
  fn: (channel: TabAndDialogChannelType) => void
) => {
  channels.forEach(fn);
};

const StyledModalContent = styled("div")({
  display: "flex",
  flexDirection: "row",
  height: "100%",
  "> * ": {
    flex: "1 1 0"
  },

  // MUI hacks
  // to make the inputs all the same height
  ".MuiInputBase-input": {
    height: "inherit"
  },
  // to make less space between tab names and content in table
  ".action-bar__container": {
    paddingTop: 0,
    height: "30px"
  },
  // to make the data table height to grow with content
  ".dialog__table-layout": {
    height: "unset"
  },
  // to display the loading indicator inside data table
  ".MuiTableContainer-root": {
    minHeight: "50vh"
  }
});

const StyledContentWrapper = styled("div")({
  // overflowY: "auto"
});

const StyledPreviewWrapper = styled("div")(({ theme }) => ({
  borderRight: "1px solid rgba(0, 0, 0, 0.4)",
  marginRight: theme.margin.sm,
  maxWidth: "50%"
}));

const StyledTitleWrapper = styled("div")({
  display: "flex",
  position: "relative"
});

const StyledTitleActionsWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  right: theme.margin.lg,
  top: -theme.margin.xss,
  // buttons are close to the "close" modal button, sometimes when an user
  // clicks the nearest button to the "close" modal button, it closes the dialog.
  // So we need to provide bigger z-index to avoid this situation
  zIndex: 11
}));

export default React.memo(Dialog);
