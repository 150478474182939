import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, Formik } from "formik";
import { Select, TextField } from "formik-material-ui";
import { SslAnalogWithOwner } from "presentation/core/api/models";
import Datepicker from "presentation/core/components/datepicker";
import { StyledKeyboardDatePickerFifth } from "presentation/core/components/datepicker/DatePicker.styles";
import DateTimePicker from "presentation/core/components/datetimepicker";
import { StyledDateTimePickerFifth } from "presentation/core/components/datetimepicker/DateTimePicker.styles";
import {
  StyledFakeFieldFifth,
  StyledFieldFifth,
  StyledFieldWide,
  StyledFormControlFifth,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { BaseField } from "presentation/core/components/form/fields/BaseField";
import { MetaFormProps } from "presentation/core/components/MetaForm/_types";
import { FormState } from "presentation/core/components/reactiveFormik/_types";
import { SenderForm } from "presentation/core/components/senderForm/SenderForm";
import { sslPropsProxy } from "presentation/core/types";
import { DeliveryMode, DocumentType } from "presentation/enums";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import {
  lang,
  t,
  WithTranslation,
  withTranslation
} from "presentation/translation/i18n";
import React from "react";
import FilePlanField from "../../form/fields/FilePlanField";
import { SslDeliveryMode } from "../../form/fields/SSLDeliveryMode";
import { SslDocumentState } from "../../form/fields/SSLDocumentState";
import { validate } from "./_validations";
import { useIsValidOrExpireFilePlan } from "../../../hook/filePlan/useIsValidOrExpireFilePlan";
import { GeneralFileMarkField } from "../../form/fields/GeneralFileMarkField";

const Component = ({
  initialValues,
  formRef,
  readonly
}: MetaFormProps<SslAnalogWithOwner> & WithTranslation) => {
  const filePlans = useIsValidOrExpireFilePlan(initialValues.filePlan);

  const isReadonly = !!readonly;
  const classes = useStyles();
  const handle = (): void => void 0;

  return (
    <Formik<SslAnalogWithOwner>
      initialValues={initialValues}
      validate={validate}
      innerRef={formRef || handle}
      onSubmit={handle}
    >
      {({ setFieldValue, values }: FormState<SslAnalogWithOwner>) => {
        let filePlan = filePlans.find(
          (plan) => plan.nodeId === values.filePlan
        );
        let fileMarks = filePlan?.filePlanGroups || [];

        const handlePlanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.value || filePlans.length > 1) {
            setFieldValue(lastPathMember(sslPropsProxy.fileMark).path, "");
            setFieldValue(lastPathMember(sslPropsProxy.retentionMode).path, "");
          }

          filePlan = filePlans.find((plan) => plan.nodeId === e.target.value);
          fileMarks = filePlan?.filePlanGroups || [];
        };

        return (
          <Form className={classes.form}>
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-pid"
              disabled={true}
              name={lastPathMember(sslPropsProxy.pid).path}
              type="text"
              label={t(translationPath(lang.general.pid))}
            />
            <StyledFormControlFifth>
              <InputLabel htmlFor="form">
                {t(translationPath(lang.general.form))}
              </InputLabel>
              <BaseField
                component={Select}
                data-test-id="meta-input-form"
                disabled={true}
                name={lastPathMember(sslPropsProxy.form).path}
                inputProps={{
                  id: lastPathMember(sslPropsProxy.form).path
                }}
              >
                <MenuItem value={DocumentType.Analog}>
                  {t(translationPath(lang.general.analog))}
                </MenuItem>
                <MenuItem value={DocumentType.Digital}>
                  {t(translationPath(lang.general.digital))}
                </MenuItem>
              </BaseField>
            </StyledFormControlFifth>
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-ssid"
              disabled={true}
              name={lastPathMember(sslPropsProxy.ssid).path}
              type="text"
              label={t(translationPath(lang.general.ssid))}
            />
            <DateTimePicker
              component={StyledDateTimePickerFifth}
              data-test-id="meta-input-deliveryDateAndTime"
              disabled={true}
              name={lastPathMember(sslPropsProxy.deliveryDate).path}
              label={t(translationPath(lang.general.deliveryDateAndTime))}
            />
            <SslDeliveryMode
              component={StyledFormControlFifth}
              disabled={true}
              allowedModes={[
                DeliveryMode.Currier,
                DeliveryMode.Personally,
                DeliveryMode.Post
              ]}
            />

            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-attachmentsCount"
              disabled={isReadonly}
              name={lastPathMember(sslPropsProxy.attachmentsCount).path}
              type="number"
              label={t(translationPath(lang.general.attachmentsCount))}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-attachmentsType"
              disabled={isReadonly}
              name={lastPathMember(sslPropsProxy.attachmentsType).path}
              type="string"
              label={t(translationPath(lang.general.attachmentsType))}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-listCountAttachments"
              disabled={isReadonly}
              name={lastPathMember(sslPropsProxy.listCountAttachments).path}
              type="number"
              label={t(translationPath(lang.general.listCountAttachments))}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-senderIdent"
              disabled={isReadonly}
              InputLabelProps={{ className: "MuiFormLabel-root--long-text" }}
              name={lastPathMember(sslPropsProxy.senderIdent).path}
              type="text"
              label={t(translationPath(lang.general.senderIdent))}
            />
            <Datepicker
              component={StyledKeyboardDatePickerFifth}
              data-test-id="meta-input-settleTo"
              disabled={isReadonly}
              disablePast={true}
              name={lastPathMember(sslPropsProxy.settleToDate).path}
              label={t(translationPath(lang.general.settleToDate))}
            />
            <FilePlanField
              validFilePlans={filePlans}
              isReadonly={isReadonly}
              handlePlanChange={handlePlanChange}
            />
            <GeneralFileMarkField
              isReadonly={isReadonly}
              values={values}
              fileMarks={fileMarks}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-senderSSID"
              disabled={isReadonly}
              name={lastPathMember(sslPropsProxy.senderSSID).path}
              type="string"
              label={t(translationPath(lang.general.senderSSID))}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-listCount"
              disabled={isReadonly}
              name={lastPathMember(sslPropsProxy.listCount).path}
              type="number"
              label={t(translationPath(lang.general.listCount))}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-owner"
              disabled={true}
              name={"owner"}
              type="string"
              label={t(translationPath(lang.general.owner))}
            />

            <SslDocumentState />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-retentionMode"
              disabled={true}
              name={lastPathMember(sslPropsProxy.retentionMode).path}
              type="string"
              label={t(translationPath(lang.general.retentionMode))}
            />
            <StyledFakeFieldFifth />
            <StyledFakeFieldFifth />
            <StyledFakeFieldFifth />

            <StyledFieldWide
              component={TextField}
              data-test-id="meta-input-subject"
              disabled={isReadonly}
              name={lastPathMember(sslPropsProxy.subject).path}
              type="string"
              label={t(translationPath(lang.general.subject))}
            />

            <SenderForm
              initialValues={initialValues}
              readonly={isReadonly}
              hidden={{ own: true }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export const AnalogDeliveredMetadataForm = withTranslation()(
  React.memo(Component)
);
