import React from "react";
import { Modal, ModalSize } from "../../../../designSystem/Modal/Modal";
import { useMutation } from "../../../../share/hook/query/useQM";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "../../../../share/utils/getPath";
import styled from "@emotion/styled";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { useSubjectModalContext } from "../../contexts/SubjectModalContextProvider";
import { getErrorCodeTranslation } from "../../../../share/utils/errorCodeTranslation";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";

export interface DeleteContactModalProps extends BaseModalProps {
  address?: Address;
}

export const AddressDeleteModal = ({
  address,
  onCancel,
  onOk
}: DeleteContactModalProps) => {
  const { persistence } = useSubjectModalContext();

  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });

  const { isLoading, mutate: deleteAddress } = useMutation(
    (address: Address) => persistence.removeAddress(address),
    {
      onSuccess() {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(translationPath(lang.dialog.notifications.addressWasDeleted))
      },
      onErrorNotification: getNotificationForError || {
        message: t(
          translationPath(lang.dialog.notifications.addressWasNotDeleted)
        )
      }
    }
  );

  const handleOk = () => deleteAddress(address!);

  return (
    <Modal
      visible={true}
      title={t(translationPath(lang.dialog.addressDeleteDialog.title))}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={isLoading}
      className={""}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <StyledDiv>
        {t(
          translationPath(
            lang.dialog.addressDeleteDialog.doYouReallyWantToDeleteAddress
          )
        )}
      </StyledDiv>
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
