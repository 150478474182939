import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";

import { ContactRepositoryToken } from "../../../domain/di/token/codelist";

import { ContactRepository } from "../../../domain/service/codelist/ContactRepository";

@DInjectable()
export class FindContact {
  constructor(
    @DInject(ContactRepositoryToken)
    protected repository: ContactRepository
  ) {}

  findAll() {
    return this.repository.findAll();
  }
}
