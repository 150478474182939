import clsx from "clsx";
import { SslDatabox } from "presentation/core/api/models";
import Datepicker from "presentation/core/components/datepicker/DatePicker";
import {
  StyledField,
  StyledFieldWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import Timepicker from "presentation/core/components/timepicker/TimePicker";
import { sslDataboxPropsProxy } from "presentation/core/types";
import { DateTimeFormats } from "presentation/enums";
import { Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t, WithTranslation } from "presentation/translation/i18n";
import { MetaFormProps } from "../_types";
import styled from "@emotion/styled";

const ReadonlyDataboxMetadataForm = ({
  initialValues,
  formRef
}: MetaFormProps<SslDatabox> & WithTranslation) => {
  const classes = useStyles();
  const handle = (): void => void 0;

  const mappedValues = {
    ...initialValues
  };

  return (
    <Formik<SslDatabox>
      initialValues={mappedValues}
      innerRef={formRef}
      onSubmit={handle}
    >
      <Form className={classes.form}>
        <div className={clsx(classes.fullWidth, classes.mtGap)}>
          <Datepicker
            className={classes.gapRight}
            data-test-id="meta-input-deliveryDate"
            name={
              lastPathMember(sslDataboxPropsProxy.digitalDeliveryDeliveryDate)
                .path
            }
            disabled={true}
            label={t(translationPath(lang.general.deliveryDate))}
          />
          <Timepicker
            className={classes.gapRight}
            data-test-id="meta-input-deliveryTime"
            format={DateTimeFormats.HoursMinutesSeconds}
            name="databoxDeliveryTime"
            disabled={true}
            label={t(translationPath(lang.general.deliveryTime))}
          />
          <ReStyledField
            component={TextField}
            data-test-id="meta-input-attachmentsCount"
            name={
              lastPathMember(
                sslDataboxPropsProxy.digitalDeliveryAttachmentsCount
              ).path
            }
            type="number"
            disabled={true}
            label={t(translationPath(lang.general.attachmentsCount))}
          />
          <StyledField
            component={TextField}
            disabled={true}
            required={true}
            data-test-id="meta-input-pid"
            name={lastPathMember(sslDataboxPropsProxy.pid).path}
            type="text"
            label={t(translationPath(lang.documentMetaForm.pid))}
          />
        </div>
        <StyledFieldWide
          component={TextField}
          data-test-id="meta-input-sender"
          name={lastPathMember(sslDataboxPropsProxy.databoxSender).path}
          type="text"
          disabled={true}
          label={t(translationPath(lang.general.sender))}
        />
        <StyledFieldWide
          component={TextField}
          data-test-id="meta-input-subject"
          name={
            lastPathMember(sslDataboxPropsProxy.digitalDeliverySubject).path
          }
          type="text"
          disabled={true}
          label={t(translationPath(lang.general.subject))}
        />
      </Form>
    </Formik>
  );
};

export const DataboxMetadataForm = withTranslation()(
  React.memo(ReadonlyDataboxMetadataForm)
);
const ReStyledField = styled(StyledField)`
  margin-right: 5%;
`;
