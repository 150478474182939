import React, { createContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootStateType } from "../../reducers";
import { AppAbility } from "../authorization/abilityType";
import { buildAbilityForUser } from "../authorization/ability";

export const AuthorizatioContext = createContext<AppAbility>(
  buildAbilityForUser({
    isAdmin: false,
    userGroups: [],
    allGroups: {
      dispatch: [],
      main: [],
      repository: [],
      fileNodes: []
    }
  })
);

export const AuthorizationProvider: React.FC = ({ children }) => {
  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );

  const groups = useSelector(
    (state: RootStateType) => state.loginReducer.global.groups
  );

  const abilities = useMemo(
    () =>
      buildAbilityForUser({
        user: session.user,
        userGroups: session.myGroups,
        isAdmin: session.isAdmin || false,
        activeGroup: session.activeGroup,
        allGroups: groups
      }),
    [
      groups,
      session.activeGroup,
      session.isAdmin,
      session.myGroups,
      session.user
    ]
  );

  return (
    <AuthorizatioContext.Provider value={abilities}>
      {children}
    </AuthorizatioContext.Provider>
  );
};
