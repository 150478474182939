import React, { useState } from "react";
import styled from "@emotion/styled";
import moment from "moment";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Input } from "presentation/designSystem/Input/Input";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { useMutation } from "presentation/share/hook/query/useQM";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { createTypeFile } from "../api";
import { useIsValidOrExpireFilePlan } from "presentation/share/hook/filePlan/useIsValidOrExpireFilePlan";
import { FilePlanGroups } from "presentation/core/features/login/_types";
import { FilePlanField } from "presentation/modules/evidence/features/typeFiles/components/FilePlanField";
import { FileMarkField } from "presentation/modules/evidence/features/typeFiles/components/FileMarkField";
import { ErrorType } from "presentation/types";
import { CreateTypeFileParams } from "../types";

export interface CreateTypeFileModalInterface extends BaseModalProps {
  nodeId?: string;
  onOk?: BaseModalProps["onOk"];
  required?: boolean;
}

export interface CreateTypeFileProps
  extends Omit<CreateTypeFileParams, "nodeId" | "createdAt"> {
  createdAt: moment.Moment;
}

export interface TreeData {
  title: string;
  value: string;
  children?: TreeData[];
}

const validationDocumentTypeRules = [{ required: true }];

export const CreateTypeFile = ({
  onCancel,
  onOk
}: CreateTypeFileModalInterface) => {
  const getNotificationForError = (error: ErrorType) => {
    return {
      message: error.code
        ? getErrorCodeTranslation(error.code)
        : t(
            translationPath(lang.dialog.notifications.documentForSealFormFailed)
          )
    };
  };
  const [form] = useForm<CreateTypeFileProps>();
  const filePlans = useIsValidOrExpireFilePlan("");
  const [fileMarks, setFileMarks] = useState<FilePlanGroups[]>(
    [] as FilePlanGroups[]
  );

  const onConfirm = async () => {
    try {
      await form.validateFields();
      const { subject, filePlanId, fileMark } = form.getFieldsValue();
      mutate({
        subject,
        filePlanId,
        fileMark
      });
    } catch {}
  };

  const { isLoading, mutate } = useMutation(
    (params: CreateTypeFileParams) => createTypeFile(params),
    {
      onSuccess() {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(lang.table.openTypeFilesTable.successCreatedFilePlan)
        )
      },
      onErrorNotification: getNotificationForError
    }
  );

  const handlePlanChange = (value: string) => {
    const selectedFilePlan = filePlans.find((plan) => plan.nodeId === value);

    setFileMarks(selectedFilePlan?.filePlanGroups || []);

    form.setFieldsValue({
      filePlanId: selectedFilePlan?.nodeId
    });
  };

  const handleMarkChange = (value: string) => {
    form.setFieldsValue({
      fileMark: value
    });
  };

  return (
    <Modal
      visible={true}
      title={t(
        translationPath(lang.table.openTypeFilesTable.createNewTypeFile)
      )}
      onCancel={onCancel}
      onOk={onConfirm}
      confirmLoading={isLoading}
      className={""}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <Form form={form} initialValues={{ createdAt: moment() }}>
        <Item
          name="subject"
          label={t(translationPath(lang.table.openTypeFilesTable.typeFileName))}
          rules={[{ required: true }, { max: 255 }, { min: 4 }]}
          required={true}
        >
          <Input style={{ width: "100%" }} />
        </Item>
        <StyledSelectItem
          name="filePlanId"
          label={t(translationPath(lang.general.filePlan))}
          rules={validationDocumentTypeRules}
          required={true}
        >
          <FilePlanField
            validFilePlans={filePlans}
            handlePlanChange={handlePlanChange}
          />
        </StyledSelectItem>
        <StyledSelectItem
          name="fileMark"
          label={t(translationPath(lang.general.fileMark))}
          rules={validationDocumentTypeRules}
          required={true}
        >
          <FileMarkField fileMarks={fileMarks} onChange={handleMarkChange} />
        </StyledSelectItem>
      </Form>
    </Modal>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;
