import { GlobalLayout } from "presentation/core/components/layout/GlobalLayout";
import {
  LoginCardWideStyled,
  LoginContentStyled,
  LoginStyled,
  StyledIe11FixerChild,
  StyledIe11FixerParent
} from "presentation/core/features/login/Login.styles";
import React, { useState } from "react";
import { t, withTranslation } from "presentation/translation/i18n";
import { translationPath } from "../../../share/utils/getPath";
import lang from "../../../translation/lang";
import { Form, useForm, Item } from "presentation/designSystem/Form/v2/Form";
import {
  StyledImgLogo,
  StyledLoginButton,
  StyledLoginControls,
  StyledForgotField,
  StyledLoginLogo,
  StyledResetPasswordDivider,
  StyledLoginControllPasswordsContainer,
  StyledForgotPasswordDiv,
  StyledForgotPasswordLink
} from "../login/FormRenderer.form.styles";
import logoImage from "../../../../assets/images/loginlogo.png";
import { useVerifyTokenPassword } from "../../../modules/authentication/hooks/useVerifyTokenPassword";
import { Redirect, useParams } from "react-router-dom";
import { CoreRoutes } from "../../routes";
import { useChangePassword } from "../../../modules/authentication/hooks/useChangePassword";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { StyledChangedInput } from "./FormRenderer.form.styles";
import { ErrorCodeList } from "../../../enums";
import { Title } from "presentation/designSystem/Typography/Typography";

const ResetPassword = () => {
  //constants
  const getTranslation = (path: string) => t(`_validations:form.${path}`);
  const passwordValidation = {
    pattern: /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\]\^\_\`\{\|\}\~])|(?=.*[A-Z])(?=.*\d)(?=.*[\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\]\^\_\`\{\|\}\~])|(?=.*[A-Z])(?=.*[a-z])(?=.*[\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\]\^\_\`\{\|\}\~])).{8,}$/,
    message: getTranslation("password")
  };
  const rulesNewPassword = [
    {
      required: true,
      message: t(translationPath(lang._validations.inputPassword))
    },
    passwordValidation,
    { min: 8 }
  ];

  //1.) verify token
  const { token } = useParams<{ token: string }>();
  React.useEffect(() => {
    mutate(token);
  }, [token]);
  const { mutate, isError, isSuccess } = useVerifyTokenPassword();

  //2.) submit changed password
  const [form] = useForm();
  const submitUserId = async (values: any) => {
    setFormOnSubmitted(false);
    await changePassword({ token: token, newPassword: values.newPassword });
  };

  const checkResultDuplicatedPassword = (errorCode: string) => {
    if (errorCode === ErrorCodeList.PasswordDuplicity) {
      return true;
    }
    return false;
  };

  const {
    mutate: changePassword,
    isError: isErrorChanged,
    isSuccess: isSuccessChanged,
    error: errorCode
  } = useChangePassword(form);

  const isDuplicityPassword = checkResultDuplicatedPassword(errorCode?.code);

  const [formOnSubmitted, setFormOnSubmitted] = useState<boolean>(true);

  return (
    <GlobalLayout>
      <LoginStyled>
        <LoginContentStyled>
          <StyledIe11FixerParent>
            <StyledIe11FixerChild>
              <LoginCardWideStyled>
                {(isError || (isErrorChanged && !isDuplicityPassword)) && (
                  <>
                    <StyledLoginLogo>
                      <StyledImgLogo
                        src={logoImage}
                        alt={t(translationPath(lang.login.logoAlt))}
                      />
                    </StyledLoginLogo>
                    <StyledLoginControllPasswordsContainer>
                      <StyledForgotPasswordDiv>
                        <Title level={5}>
                          {t(
                            translationPath(
                              lang.dialog.notifications
                                .passwordResetTokenHasExpired
                            )
                          )}
                        </Title>
                        <br />
                        <StyledForgotPasswordLink
                          to={CoreRoutes.FORGOTTEN_PASSWORD}
                        >
                          {t(translationPath(lang.login.forgotPassword))}
                        </StyledForgotPasswordLink>
                      </StyledForgotPasswordDiv>
                    </StyledLoginControllPasswordsContainer>
                  </>
                )}
                {isSuccess && (
                  <Form
                    form={form}
                    name="forgottenPasswordForm"
                    onFinish={submitUserId}
                  >
                    <StyledLoginLogo>
                      <StyledImgLogo
                        src={logoImage}
                        alt={t(translationPath(lang.login.logoAlt))}
                      />
                    </StyledLoginLogo>
                    <StyledLoginControls>
                      <StyledResetPasswordDivider></StyledResetPasswordDivider>
                      <StyledForgotField>
                        <Item
                          label={t(translationPath(lang.general.newPassword))}
                          style={{ fontWeight: "bold" }}
                          name="newPassword"
                          rules={rulesNewPassword}
                          hasFeedback
                        >
                          <StyledChangedInput
                            type="Password"
                            data-test-id="new-password"
                          />
                        </Item>
                      </StyledForgotField>
                      <StyledForgotField>
                        <Item
                          label={t(translationPath(lang.general.newPassword2))}
                          style={{ fontWeight: "bold" }}
                          name="newPassword2"
                          dependencies={["newPassword"]}
                          hasFeedback
                          rules={[
                            ...rulesNewPassword,
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("newPassword") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    t(
                                      translationPath(
                                        lang.dialog.errors
                                          .newPasswordsAreNotEqual
                                      )
                                    )
                                  )
                                );
                              }
                            })
                          ]}
                        >
                          <StyledChangedInput
                            min={8}
                            type="Password"
                            data-test-id="new-password-repeat"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined data-test-id="show-new-password-repeat" />
                              )
                            }
                          />
                        </Item>
                      </StyledForgotField>
                      <Item>
                        <StyledLoginButton
                          type={"submit"}
                          data-test-id="change-password-button"
                        >
                          {t(translationPath(lang.dialog.title.changePassword))}
                        </StyledLoginButton>
                      </Item>
                    </StyledLoginControls>
                  </Form>
                )}
                {!formOnSubmitted && isSuccessChanged && (
                  <Redirect to={CoreRoutes.LOGIN} />
                )}
              </LoginCardWideStyled>
            </StyledIe11FixerChild>
          </StyledIe11FixerParent>
        </LoginContentStyled>
      </LoginStyled>
    </GlobalLayout>
  );
};

export default withTranslation()(ResetPassword);
