import clsx from "clsx";
import { GroupMember } from "presentation/core/api/models";
import Datepicker from "presentation/core/components/datepicker/DatePicker";
import {
  StyledAutocomplete,
  StyledField,
  StyledFieldWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import FormikAutocomplete from "presentation/core/components/formikAutocomplete/FormikAutocomplete";
import { TextField } from "formik-material-ui";
import { FormState } from "presentation/core/components/reactiveFormik/_types";
import { Form } from "formik";
import React from "react";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import {
  PromoteConceptToDocumentFormValues,
  PromoteConceptToDocumentFormValuesProxy
} from "./_types";
import { BaseField } from "../../../../core/components/form/fields/BaseField";
import { useGroupMembers } from "../../../../core/hooks/useGroupMembers";
import {
  AutocompleteOptionsType,
  PossibleAutocompleteOptions
} from "../../../../core/components/formikAutocomplete/_types";
import { FilterOptionsState } from "@material-ui/lab";
import { styled } from "../../../../styles";
import { useGroupsToHandover } from "../documentHandoverDialog/useGroupsToHandover";

const NUMBER_OF_ENTERED_CHARS = 3;

export const PromoteConceptToDocumentForm = React.memo(
  ({ values }: FormState<PromoteConceptToDocumentFormValues>) => {
    const dialogClasses = useStyles();
    const { data: groups } = useGroupsToHandover();
    const getGroupMembersOptionLabel = (option: GroupMember) => {
      return option.displayName;
    };

    const {
      entities: groupMembers,
      loading: isGroupMembersLoading
    } = useGroupMembers(values.authorGroup);
    const isGroupSelected = values && !!values.authorGroup;

    const filterGroupMembersOptions = (
      opts: AutocompleteOptionsType,
      state: FilterOptionsState<PossibleAutocompleteOptions>
    ) => {
      const { inputValue } = state;
      if (!inputValue || inputValue.length < NUMBER_OF_ENTERED_CHARS)
        return opts;
      return opts.filter((opt) =>
        opt.displayName.toLowerCase().includes(inputValue.toLocaleLowerCase())
      );
    };
    const dataAttributeChangeActiveGroup = {
      "data-test-id": "change-active-group-in-concept"
    };
    const dataAttributeChangeAuthor = {
      "data-test-id": "change-author-in-concept"
    };

    return (
      <Form className={dialogClasses.form}>
        <p className={clsx(dialogClasses.fullWidth, dialogClasses.textCenter)}>
          {t(translationPath(lang.dialog.content.conceptToDocument))}
        </p>
        <StyledFieldWide
          component={TextField}
          data-test-id="promote-concept-to-doc-subject"
          name={
            lastPathMember(PromoteConceptToDocumentFormValuesProxy.subject).path
          }
          required={true}
          multiline={true}
          rows={1}
          rowsMax={Infinity}
          type="text"
          label={t(translationPath(lang.general.subject))}
        />
        <StyledDiv>
          <BaseField
            autocompleteComponent={StyledAutocomplete}
            component={FormikAutocomplete}
            getOptionLabel={getGroupMembersOptionLabel}
            label={t(translationPath(lang.general.organizationUnit))}
            name={
              lastPathMember(
                PromoteConceptToDocumentFormValuesProxy.authorGroup
              ).path
            }
            options={groups}
            optionValueProperty="id"
            required={true}
            inputProps={{ ...dataAttributeChangeActiveGroup }}
          />
          <BaseField
            autocompleteComponent={StyledAutocomplete}
            component={FormikAutocomplete}
            disabled={!isGroupSelected}
            disableOnLoad={true}
            getOptionLabel={getGroupMembersOptionLabel}
            label={t(translationPath(lang.general.documentAuthor))}
            loading={isGroupMembersLoading}
            name={
              lastPathMember(PromoteConceptToDocumentFormValuesProxy.author)
                .path
            }
            options={groupMembers}
            optionValueProperty="id"
            required={true}
            filterOptions={filterGroupMembersOptions}
            inputProps={{ ...dataAttributeChangeAuthor }}
          />
        </StyledDiv>
        <StyledDiv>
          <StyledField
            className={dialogClasses.fieldErrorPositioned}
            component={TextField}
            data-test-id="promote-concept-to-doc-subject-attachmentsCount"
            name={
              lastPathMember(
                PromoteConceptToDocumentFormValuesProxy.attachmentsCount
              ).path
            }
            required={true}
            type="number"
            label={t(translationPath(lang.general.attachmentsCount))}
          />
          <Datepicker
            data-test-id="promote-concept-to-doc-subject-settleTo"
            disablePast={true}
            name={
              lastPathMember(PromoteConceptToDocumentFormValuesProxy.settleTo)
                .path
            }
            label={t(translationPath(lang.documentMetaForm.settleDate))}
          />
        </StyledDiv>
      </Form>
    );
  }
);

const StyledDiv = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  > div {
    width: 45%;
  }
`;
