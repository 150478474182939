import { AddressTypeRepository } from "domain/service/codelist/AddressTypeRepository";
import { AddressTypeRepositoryToken } from "domain/di/token/codelist";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { AddressType } from "../../../domain/struct/codelist/AddressType";

// 2. step - define use case, apply to data from wherever
@DInjectable()
export class FindAddressType {
  constructor(
    @DInject(AddressTypeRepositoryToken)
    protected addressTypeRepository: AddressTypeRepository
  ) {}

  findAll(): Promise<AddressType[]> {
    // Business logic
    // Apply business rules defined by analytic
    return this.addressTypeRepository.findAll();
  }
}
