import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react";
import { Subject } from "../../../../domain/struct/nameRegistry/Subject";
import { getService } from "../../../core/features/dependencyInjection";
import { UpdateSubjectLogic } from "../modal/SubjectModal/lib/UpdateSubjectLogic";
import { CreateSubjectLogic } from "../modal/SubjectModal/lib/CreateSubjectLogic";
import { SubjectPersistenceLogic } from "../modal/SubjectModal/lib/SubjectPersistenceLogic";
import { InitialPersistenceSubjectLogic } from "../modal/SubjectModal/lib/InitialPersistenceSubjectLogic";

export enum NameRegistryTabs {
  SUBJECT_TAB = "1",
  ADDRESS_TAB = "2",
  CONTACT_TAB = "3"
}

interface DefaultSubjectState {
  activeTab?: string;
  activeModal?: string;
  persistence: SubjectPersistenceLogic;
  // storing only to force the provider to provide new value for its state
  // after the replaceSubject function is called
  _originalSubject?: Subject;
}

const defaultSubjectState: DefaultSubjectState = {
  activeTab: NameRegistryTabs.SUBJECT_TAB,
  persistence: new InitialPersistenceSubjectLogic()
};

export interface SubjectModalContextState {
  state: DefaultSubjectState;
  persistence: SubjectPersistenceLogic;
  setActiveTab(index: string): void;
  setActiveModal(modalId: string): void;
  replaceSubject(subject?: Subject): void;
  reset(): void;
}

export const SubjectModalContext = React.createContext<SubjectModalContextState>(
  {
    state: defaultSubjectState,
    persistence: defaultSubjectState.persistence,
    setActiveTab: (index: string) => {},
    setActiveModal: (modalId: string) => {},
    replaceSubject: (subject?: Subject) => {},
    reset: () => {}
  }
);

type SubjectAction = {
  type: string;
  payload?: any;
};

export const ACTIVATE_TAB = "ACTIVATE_TAB";
export const ACTIVATE_MODAL = "ACTIVATE_MODAL";
export const REPLACE_SUBJECT = "REPLACE_SUBJECT";
export const RESET = "RESET";

const reducer = (state = defaultSubjectState, action: SubjectAction) => {
  switch (action.type) {
    case ACTIVATE_TAB:
      return {
        ...state,
        activeTab: action.payload
      };
    case ACTIVATE_MODAL:
      return {
        ...state,
        activeModal: action.payload
      };
    case REPLACE_SUBJECT: {
      return {
        ...state,
        _originalSubject: action.payload,
        persistence: getLogicForSubject(action.payload)
      };
    }
    case RESET: {
      return defaultSubjectState;
    }
    default:
      return state;
  }
};

const getLogicForSubject = (subject?: Subject) => {
  const updateLogic = getService(UpdateSubjectLogic);
  const createLogic = getService(CreateSubjectLogic);

  const logic_ = subject?.id ? updateLogic : createLogic;

  // logic_.resetState();

  if (subject) {
    logic_.setSubjectData(subject);
  }

  return logic_;
};

export interface SubjectModalContextProviderProps {
  subject?: Subject;
}

export const SubjectModalContextProvider: React.FC<SubjectModalContextProviderProps> = ({
  children,
  subject
}) => {
  const [state, dispatch] = useReducer(reducer, defaultSubjectState);
  const { persistence } = state;

  const setActiveTab = useCallback((index: string) => {
    dispatch({
      type: ACTIVATE_TAB,
      payload: index
    });
  }, []);

  const setActiveModal = useCallback((modalId: string) => {
    dispatch({
      type: ACTIVATE_MODAL,
      payload: modalId
    });
  }, []);

  const replaceSubject = useCallback((subject?: Subject) => {
    dispatch({ type: REPLACE_SUBJECT, payload: subject });
  }, []);

  const reset = useCallback(() => dispatch({ type: RESET }), []);

  const memoizedValues = useMemo(
    () => ({
      state,
      persistence,
      setActiveTab,
      setActiveModal,
      replaceSubject,
      reset
    }),
    [persistence, replaceSubject, reset, setActiveModal, setActiveTab, state]
  );

  useEffect(() => {
    replaceSubject(subject);
  }, [replaceSubject, subject]);

  return (
    <SubjectModalContext.Provider value={memoizedValues}>
      {children}
    </SubjectModalContext.Provider>
  );
};

export const useSubjectModalContext = () => {
  const context = useContext(SubjectModalContext);
  if (!context) {
    throw new Error("Check if provider set up properly");
  }
  return context;
};
