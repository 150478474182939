import React, { useCallback, useState } from "react";
import { TableAction } from "./contract";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { isActionResult } from "presentation/govDesignSystem/lib/contract/Action";
import { isPromise } from "presentation/govDesignSystem/lib/promise/typeChecker";

export interface TableActionButtonProps<Row> {
  action: TableAction<Row>;
  selectedRows: Row[];
  dataSource: Row[];
  onActionFinished?: (
    action: TableAction<Row>,
    dataWasMutated: boolean
  ) => void;
}

export function TableActionButton<Row>({
  action,
  selectedRows,
  dataSource,
  onActionFinished
}: TableActionButtonProps<Row>) {
  const [, setActionRunning] = useState(false);

  const onClick = useCallback(() => {
    const result = action.action(selectedRows, dataSource);

    if (!result) {
      return onActionFinished?.(action, false);
    }

    if (isActionResult(result)) {
      return onActionFinished?.(action, result.dataWasMutated);
    }

    if (isPromise(result)) {
      setActionRunning(true);
      result
        .then((data) => {
          onActionFinished?.(
            action,
            isActionResult(data) ? data.dataWasMutated : false
          );
        })
        .catch(() => onActionFinished?.(action, false))
        .finally(() => {
          setActionRunning(false);
        });
    }

    onActionFinished?.(action, false);
  }, [action, dataSource, onActionFinished, selectedRows]);

  return (
    <Tooltip title={action.tooltip} placement="top">
      <IconButton style={{ color: "#131D1E" }} onClick={onClick}>
        {action.icon}
      </IconButton>
    </Tooltip>
  );
}
