import { MenuItemType } from "presentation/core/components/menu/_types";
import Dashboard from "presentation/core/features/dashboard";
import { CoreRoutes } from "presentation/core/routes";
import { DispatchRoutes } from "presentation/modules/dispatch/routes";
import { EvidenceRoutes } from "presentation/modules/evidence/routes";
import { RecordRetentionProcessRoutes } from "presentation/modules/recordRetentionProcess/routes";
import { RepositoryRoutes } from "presentation/modules/repository/routes";
import { SignatureBookRoutes } from "presentation/modules/signatureBook/routes";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "presentation/share/components/ProtectedRoute";
import ResolveDefaultRoute from "presentation/share/components/ResolveDefaultRoute";
import { redirectFromMenu } from "presentation/share/utils/routerRedirect";
import SearchComponent from "./core/components/search/SearchComponent";
import Login from "./core/features/login";
import { useMenuItemsWithPermissions } from "./core/hooks/useMenuItemsWithPermissons";
import { AdministrationRoutes } from "./modules/administration/routes";
import { MailroomRoutes } from "./modules/mailroom/routes";
import ForgottenPassword from "./core/features/forgottenPassword/ForgottenPassword";
import ResetPassword from "./core/features/resetPassword/ResetPassword";

interface RouterPropsType {
  itemsOfMenu?: MenuItemType[];
}

const Router = ({
  itemsOfMenu,
  location
}: RouteComponentProps & RouterPropsType) => {
  const itemsOfMenuWithPerms = useMenuItemsWithPermissions();
  const itemsOfMenuForRouter = itemsOfMenu || itemsOfMenuWithPerms;

  return (
    <Switch>
      {redirectFromMenu(location.pathname, itemsOfMenuForRouter)}
      <ProtectedRoute
        exact={true}
        children={<Dashboard />}
        path={CoreRoutes.DASHBOARD}
      />
      <ProtectedRoute
        exact={true}
        children={<SearchComponent />}
        path={CoreRoutes.SEARCH}
      />
      <Route exact={true} path={CoreRoutes.LOGIN}>
        <ResolveDefaultRoute
          defaultChildren={<Login />}
          itemsOfMenu={itemsOfMenuForRouter}
        />
      </Route>
      <Route exact={true} path={CoreRoutes.FORGOTTEN_PASSWORD}>
        <ForgottenPassword />
      </Route>
      <Route exact={true} path={CoreRoutes.RESET_PASSWORD}>
        <ResetPassword />
      </Route>
      <ResolveDefaultRoute itemsOfMenu={itemsOfMenuForRouter} />
    </Switch>
  );
};

export type RootRouterType =
  | CoreRoutes
  | DispatchRoutes
  | EvidenceRoutes
  | MailroomRoutes
  | RecordRetentionProcessRoutes
  | RepositoryRoutes
  | SignatureBookRoutes
  | AdministrationRoutes
  | string;

export default withRouter(Router);
