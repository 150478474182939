import { CardHeader, Toolbar } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckboxChecked from "assets/icons-tsx/CheckboxChecked";
import logoImage from "assets/images/loginlogo.png";
import {
  StyledErrorContainer,
  StyledErrorIcon,
  StyledErrorMessage,
  StyledForgotPasswordDiv,
  StyledForgotPasswordLink,
  StyledFullWidthField,
  StyledImgLogo,
  StyledInteractiveLoginLabel,
  StyledLoginButton,
  StyledLoginButtonLabel,
  StyledLoginCheckingSpinner,
  StyledLoginControls,
  StyledLoginField,
  StyledLoginLabel,
  StyledLoginLogo,
  StyledLoginControllPasswordsContainer,
  StyledLoginRememberMe,
  StyledLoginRememberMeContainer,
  StyledLoginRememberMeIcon,
  StyledLoginRememberMeIconChecked,
  StyledVisibilityIconButton,
  StyledVisibilityIconButtonIeFix
} from "presentation/core/features/login/FormRenderer.form.styles";
import { FieldProps, Form, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { ErrorType } from "presentation/types";
import { LoginFormValuesType, LoginFormValuesTypeProxy } from "./_types";
import { BaseField } from "../../components/form/fields/BaseField";
import { Link } from "react-router-dom";
import { CoreRoutes } from "../../routes";
import headerLogoImg from "../../../../assets/images/headerLogo.png";

interface OwnProps {
  error: ErrorType | null;
  pending: boolean;
}

const renderForm = ({ error, pending }: OwnProps) => (
  formProps: FormikProps<LoginFormValuesType>
) => {
  const [values, setValues] = React.useState({
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({
      showPassword: !values.showPassword
    });
  };

  const isPending = formProps.isSubmitting || pending;

  const getErrorMessage = () => {
    if (!error) return;

    switch (error.code) {
      case "401":
      case "403":
      case "AUTH":
        return t(translationPath(lang._common.codes.AUTH));
      default:
        return t(translationPath(lang.login.loginFailed));
    }
  };

  if (formProps.isSubmitting && values.showPassword) {
    handleClickShowPassword();
  }

  return (
    <Form>
      <CardHeader
        title={
          <StyledLoginLogo>
            <StyledImgLogo
              src={logoImage}
              alt={t(translationPath(lang.login.logoAlt))}
            />
          </StyledLoginLogo>
        }
      />
      <StyledLoginControls>
        <StyledErrorContainer>
          {error && (
            <StyledErrorMessage>
              <StyledErrorIcon />
              <span>{getErrorMessage()}</span>
            </StyledErrorMessage>
          )}
        </StyledErrorContainer>
        <div>
          <StyledLoginField>
            <StyledLoginLabel
              htmlFor={classPath(LoginFormValuesTypeProxy.username).path}
            >
              {t(translationPath(lang.login.username))}
            </StyledLoginLabel>

            <StyledFullWidthField
              component={TextField}
              data-test-id="login-input-username"
              disabled={isPending}
              name={classPath(LoginFormValuesTypeProxy.username).path}
              type="text"
            />
          </StyledLoginField>
          <StyledLoginField>
            <StyledLoginLabel htmlFor={t(translationPath(lang.login.password))}>
              {t(translationPath(lang.login.password))}
            </StyledLoginLabel>

            <StyledFullWidthField
              component={TextField}
              data-test-id="login-input-password"
              disabled={isPending}
              InputProps={{
                autoComplete: "",
                endAdornment: (
                  <StyledVisibilityIconButtonIeFix position="end">
                    <StyledVisibilityIconButton
                      aria-label="toggle password visibility"
                      disabled={isPending}
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </StyledVisibilityIconButton>
                  </StyledVisibilityIconButtonIeFix>
                )
              }}
              name={classPath(LoginFormValuesTypeProxy.password).path}
              type={values.showPassword ? "text" : "password"}
            />
            <StyledLoginButton
              className={isPending ? "login-isChecking" : "isWelcome"}
              data-test-id="button-submit-login"
              disabled={isPending}
              type="submit"
            >
              {(isPending && (
                <StyledInteractiveLoginLabel>
                  <StyledLoginButtonLabel className="login-button-label">
                    {t(translationPath(lang.login.checking))}
                  </StyledLoginButtonLabel>
                  <StyledLoginCheckingSpinner size={25} />
                </StyledInteractiveLoginLabel>
              )) || (
                <StyledLoginButtonLabel className="login-button-label">
                  {t(translationPath(lang.login.signUp))}
                </StyledLoginButtonLabel>
              )}
            </StyledLoginButton>
            <StyledLoginControllPasswordsContainer>
              <StyledLoginRememberMeContainer
                control={
                  <BaseField
                    component={CheckboxField}
                    data-test-id="button-remember-login"
                    disabled={isPending}
                    name={classPath(LoginFormValuesTypeProxy.remember).path}
                    type="checkbox"
                  />
                }
                label={t(translationPath(lang.login.rememberMe))}
              />
              <StyledForgotPasswordDiv>
                <StyledForgotPasswordLink
                  to={CoreRoutes.FORGOTTEN_PASSWORD}
                  data-test-id="forgotten-password-button"
                >
                  {t(translationPath(lang.login.forgotPassword))}
                </StyledForgotPasswordLink>
              </StyledForgotPasswordDiv>
            </StyledLoginControllPasswordsContainer>
          </StyledLoginField>
        </div>
      </StyledLoginControls>
    </Form>
  );
};

const CheckboxField = <T,>(props: FieldProps<T>) => {
  return (
    <StyledLoginRememberMe
      {...props}
      checkedIcon={
        <StyledLoginRememberMeIconChecked className="mat-checkbox-checked">
          <CheckboxChecked />
        </StyledLoginRememberMeIconChecked>
      }
      icon={<StyledLoginRememberMeIcon />}
    />
  );
};

export default renderForm;
