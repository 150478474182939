import React, { useMemo } from "react";
import styled from "@emotion/styled";

import { Select } from "../../../../../designSystem/Select/Select";
import {
  AddressFormEnum,
  HouseNumberEnum
} from "../../../../../../domain/struct/nameRegistry/AddressFormEnums";
import { useFindHouseNumbers } from "../../../hooks/useFindHouseNumbers";
import { Item } from "../../../../../designSystem/Form/v2/Form";

export interface HouseNumberTypeInputProps {
  onChange?: (value: HouseNumberEnum) => void;
}

const validationHouseNumberTypeRules = [{ required: true }];

export const HouseNumberTypeSelect = ({
  onChange
}: HouseNumberTypeInputProps) => {
  const { data, isFetching } = useFindHouseNumbers({ retry: false });

  const options = useMemo(
    () =>
      data
        ? data.map(({ code, czechName }) => ({
            label: czechName,
            value: code
          }))
        : [],
    [data]
  );

  return (
    <StyledSelectItem
      name="houseNumberType"
      label={AddressFormEnum.HouseNumberType}
      rules={validationHouseNumberTypeRules}
    >
      <Select<HouseNumberEnum>
        onChange={onChange}
        style={{ width: "15rem" }}
        options={options}
        loading={isFetching}
      />
    </StyledSelectItem>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;
