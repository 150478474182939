import {
  CreateNewFolder,
  Description,
  Mail,
  Send,
  Storage,
  Whatshot,
  WorkOff
} from "@material-ui/icons";
import { openDocumentWithSaveButtonsAction } from "presentation/core/api/document/_actions";
import { Node, SslProperties } from "presentation/core/api/models";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import DocumentView from "presentation/core/components/documentView";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { SessionType } from "presentation/core/features/login/_types";
import { GenericDocument, genericDocumentProxy } from "presentation/core/types";
import {
  DocumentType,
  SitePaths,
  SpisumNamesWithoutPrefix,
  SpisumNodeTypes,
  SubmitToRepositoryDialog
} from "presentation/enums";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { handoverDocument } from "presentation/share/components/dialog/documentHandoverDialog/_actions";
import { lostDestroyedDialogOpen } from "presentation/share/components/dialog/lostDestroyedDialog/_actions";
import { submitToRepositoryDialogOpen } from "presentation/share/components/dialog/submitToRepository/_actions";
import {
  classPath,
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { isUserInLeadership } from "presentation/share/utils/user";
import { isEmptyString } from "presentation/share/utils/utils";
import { validateItems } from "presentation/share/utils/validation";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import * as yup from "yup";
import { filePlanColumn } from "presentation/share/components/table/column/document/FilePlan/filePlanColumn";
import { fileMarkColumn } from "presentation/share/components/table/column/document/FilePlan/fileMarkColumn";
import { DisplaySenderNameByType } from "../../../../../share/components/table/column/DisplaySenderNameByType";
import { useModal } from "@isfg/react-components/build/Modal/useModal";
import {
  ConvertDigitalToAnalogModal,
  isDocumentAvailableForDigitalToAnalogConversion
} from "../../../../../share/components/modals/conversions/ConvertDigitalToAnalogModal";
import { FileSyncOutlined } from "@ant-design/icons";
import { CreateNewDocumentFileModal } from "../forProcessing/modals/CreateNewDocumentFileModal";

const defaultColumn: DataColumn<GenericDocument> = {
  getValue: (x) =>
    x?.properties?.ssl?.senderType === "own"
      ? x?.createdAt
      : x?.properties?.ssl?.deliveryDate,
  isDateTime: true,
  keys: [
    classPath(genericDocumentProxy.properties!.ssl!.deliveryDate).path,
    classPath(genericDocumentProxy.createdAt).path
  ],
  label: t(translationPath(lang.general.dateOfEvidence))
};

const getColumns = (session: SessionType): DataColumn<GenericDocument>[] => {
  const columns: DataColumn<GenericDocument>[] = [
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.pid).path],
      label: t(translationPath(lang.general.identifier))
    },
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.ssid).path],
      label: t(translationPath(lang.general.referenceNumber))
    },
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.subject).path],
      label: t(translationPath(lang.general.subject))
    },
    {
      getValue: (row) => <DisplaySenderNameByType ssl={row?.properties?.ssl} />,
      keys: [
        classPath(genericDocumentProxy.properties!.ssl!.senderCompanyName).path,
        classPath(genericDocumentProxy.properties!.ssl!.senderFullName).path
      ],
      label: t(translationPath(lang.general.sender))
    },
    defaultColumn,
    filePlanColumn,
    fileMarkColumn,
    {
      keys: [
        classPath(genericDocumentProxy.properties!.ssl!.retentionMode).path
      ],
      label: t(translationPath(lang.general.retentionMode))
    },
    {
      isDateTime: true,
      keys: [classPath(genericDocumentProxy.properties!.ssl!.settleDate).path],
      label: t(translationPath(lang.general.settleDate))
    }
  ];

  if (isUserInLeadership(session)) {
    columns.push({
      keys: [
        classPath(genericDocumentProxy.properties!.cm!.owner?.displayName).path
      ],
      label: t(translationPath(lang.general.owner))
    });
  }

  return columns;
};

const ProcessedDocuments = () => {
  const dispatch = useDispatch();
  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );
  const [
    convertDigitalToAnalogModal,
    convertDigitalToAnalogModalApi
  ] = useModal(ConvertDigitalToAnalogModal);
  const relativePath = useSelector((state: RootStateType) =>
    getRelativePath(
      state.loginReducer.global.paths,
      state.loginReducer.session.activeGroup,
      SitePaths.Evidence,
      SitePaths.Documents,
      SitePaths.Processed
    )
  );
  const [createNewDocumentFileModal, createNewDocumentFileModalApi] = useModal(
    CreateNewDocumentFileModal
  );
  const toRegistryValidation = (items: Node<SslProperties>[]) =>
    validateItems(items, {
      [classPath(genericDocumentProxy.properties!.ssl!.pid)
        .path]: yup
        .string()
        .required(t(translationPath(lang._validations.requiredPid))),
      [classPath(genericDocumentProxy.properties!.ssl!.ssid)
        .path]: yup
        .string()
        .required(t(translationPath(lang._validations.requiredSsid))),
      [classPath(genericDocumentProxy.properties!.ssl!.subject)
        .path]: yup
        .string()
        .required(t(translationPath(lang._validations.requiredSubject))),
      [classPath(genericDocumentProxy.properties!.ssl!.senderFullName)
        .path]: yup
        .string()
        .test(
          "oneOfRequired",
          t(translationPath(lang._validations.requiredSender)),
          function () {
            return (
              !isEmptyString(
                this.parent[
                  lastPathMember(
                    genericDocumentProxy.properties!.ssl!.senderCompanyName
                  ).path
                ]
              ) ||
              !isEmptyString(
                this.parent[
                  lastPathMember(
                    genericDocumentProxy.properties!.ssl!.senderFullName
                  ).path
                ]
              ) ||
              !isEmptyString(
                this.parent[
                  lastPathMember(
                    genericDocumentProxy.properties!.ssl!.senderType
                  ).path
                ]
              )
            );
          }
        ),
      ...(items.filter((x) => x?.properties?.ssl?.form === DocumentType.Analog)
        .length === items.length && {
        [classPath(genericDocumentProxy.properties!.ssl!.listCount)
          .path]: yup
          .string()
          .required(t(translationPath(lang._validations.requiredListCount)))
      }),
      ...(items.filter((x) => x?.properties?.ssl?.form === DocumentType.Analog)
        .length === items.length && {
        [classPath(genericDocumentProxy.properties!.ssl!.listCountAttachments)
          .path]: yup
          .string()
          .required(
            t(translationPath(lang._validations.requiredListCountAttachments))
          )
      }),
      [classPath(genericDocumentProxy.properties!.ssl!.attachmentsCount)
        .path]: yup
        .string()
        .required(
          t(translationPath(lang._validations.requiredAttachmentsCount))
        ),
      [classPath(genericDocumentProxy.properties!.cm!.owner!.id)
        .path]: yup
        .string()
        .required(t(translationPath(lang._validations.requiredOwner))),
      [classPath(genericDocumentProxy.properties!.ssl!.fileMark)
        .path]: yup
        .string()
        .required(t(translationPath(lang._validations.requiredFileMark))),
      [classPath(genericDocumentProxy.properties!.ssl!.filePlan)
        .path]: yup
        .string()
        .required(t(translationPath(lang._validations.requiredFilePlan))),
      [classPath(genericDocumentProxy.properties!.ssl!.retentionMode)
        .path]: yup
        .string()
        .required(t(translationPath(lang._validations.requiredRetentionMode))),
      [classPath(genericDocumentProxy.properties!.ssl!.form)
        .path]: yup
        .string()
        .required(t(translationPath(lang._validations.requiredFormDocument))),
      [classPath(genericDocumentProxy.properties!.ssl!.form)
        .path]: yup
        .string()
        .required(t(translationPath(lang._validations.requiredFormDocument))),
      [classPath(genericDocumentProxy.properties!.ssl!.settleMethod)
        .path]: yup
        .string()
        .required(t(translationPath(lang._validations.requiredSettleMethod)))
    });

  const dispatchOpenDialog: (row: GenericDocument) => void = (row) => {
    dispatch(
      openDocumentWithSaveButtonsAction({
        canUploadComponents: false,
        data: row,
        isReadonly: true
      })
    );
  };

  const controls: ControlsBarType<GenericDocument> = {
    multi: {
      items: [
        /*
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Add,
                items: selected,
                nodeType: SpisumNodeTypes.Document
              })
            );
          },
          filter: (x) => !x.isFavorite,
          icon: <Star />,
          title: t(translationPath(lang.general.bookmark))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Remove,
                items: selected,
                nodeType: SpisumNodeTypes.Document
              })
            );
          },
          filter: (x) => x.isFavorite === true,
          icon: <StarBorderOutlined />,
          title: t(translationPath(lang.general.bookmarkRemove))
        },
        */
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              submitToRepositoryDialogOpen({
                data: {
                  entityType: SubmitToRepositoryDialog.Documents,
                  selected
                }
              })
            );
          },
          icon: <Storage />,
          title: t(translationPath(lang.general.toRegistry)),
          validation: toRegistryValidation
        }
      ]
    },
    single: {
      items: [
        {
          action: (selected: GenericDocument[]) =>
            dispatchOpenDialog(selected[0]),
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: GenericDocument[]) => {
            return createNewDocumentFileModalApi.open({
              document: selected[0],
              nodeId: selected[0].id
            });
          },
          icon: <CreateNewFolder />,
          title: t(translationPath(lang.general.pasteToFile))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(handoverDocument({ data: selected[0] }));
          },
          icon: <Send />,
          title: t(translationPath(lang.general.handOVer))
        },
        /*
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Add,
                items: selected,
                nodeType: SpisumNodeTypes.Document
              })
            );
          },
          filter: (x) => !x.isFavorite,
          icon: <Star />,
          title: t(translationPath(lang.general.bookmark))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Remove,
                items: selected,
                nodeType: SpisumNodeTypes.Document
              })
            );
          },
          filter: (x) => x.isFavorite === true,
          icon: <StarBorderOutlined />,
          title: t(translationPath(lang.general.bookmarkRemove))
        },
        */
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.SendShipment
              })
            );
          },
          icon: <Mail />,
          title: t(translationPath(lang.general.manageShipments))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.CancelProcessing
              })
            );
          },
          icon: <WorkOff />,
          title: t(translationPath(lang.general.cancelProcessing))
        },
        {
          action: (selected: GenericDocument[]) => {
            convertDigitalToAnalogModalApi.open({
              nodeId: selected[0].id
            });
          },
          filter: (document: GenericDocument) => {
            return isDocumentAvailableForDigitalToAnalogConversion(document);
          },
          validation: (document: GenericDocument[]) => {
            if (document[0]?.properties?.ssl?.fileIsEncrypted) {
              return t(
                translationPath(lang._validations.documentHasEncryptedComponent)
              );
            }
            if (!document[0]?.properties?.ssl?.fileMark) {
              return t(
                translationPath(
                  lang.dialog.convertions.digitalToAnalog.missingFilePlan
                )
              );
            }
            return null;
          },
          icon: <FileSyncOutlined data-test-id="digital-to-analog" />,
          title: t(translationPath(lang.general.convertDA))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              submitToRepositoryDialogOpen({
                data: {
                  entityType: SubmitToRepositoryDialog.Documents,
                  selected
                }
              })
            );
          },
          icon: <Storage />,
          title: t(translationPath(lang.general.toRegistry)),
          validation: toRegistryValidation
        }
      ],
      more: [
        /* V2
      {
        action: () => {},
        icon: <Print />,
        title: t(translationPath(lang.general.print))
      },
      */
        {
          action: (selected: GenericDocument[]) => {
            dispatch(lostDestroyedDialogOpen({ data: selected[0] }));
          },
          filter: (document) =>
            document.properties?.ssl?.form === DocumentType.Analog ||
            document?.properties?.ssl?.form === DocumentType.AD,
          icon: <Whatshot />,
          title: t(translationPath(lang.general.damaged))
        }
      ]
    }
  };

  return (
    <>
      {convertDigitalToAnalogModal}
      {createNewDocumentFileModal}
      <MenuLayout>
        <DocumentView
          children={{
            include: [SpisumNamesWithoutPrefix.IsFavorite],
            relativePath,
            where: `(nodeType='${SpisumNodeTypes.Document}')`
          }}
          columns={getColumns(session)}
          controls={controls}
          customTitle={t(translationPath(lang.table.processedDocuments))}
          defaultSortAsc={true}
          defaultSortColumn={defaultColumn}
          handleDoubleClick={dispatchOpenDialog}
        />
      </MenuLayout>
    </>
  );
};

export default withTranslation()(ProcessedDocuments);
