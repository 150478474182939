import React from "react";
import { useLocation } from "react-router-dom";
import { translationPath } from "presentation/share/utils/getPath";
import lang from "presentation/translation/lang";
import { t } from "presentation/translation/i18n";
import Typography from "@material-ui/core/Typography";
import {
  BreadCrumbsSectionStyled,
  PathStyled,
  TitleStyled
} from "../../DataTable.styles";

interface BreadcrumbProps {
  title: string;
}

const tranformToTranslatable = (item: string) => {
  const [first, ...rest] = item.split("-");
  if (rest && rest.length) {
    return (
      first +
      rest.reduce((acc, curr) => {
        return acc + curr.charAt(0).toUpperCase() + curr.slice(1);
      }, "")
    );
  }
  return first;
};
export const BreadcrumbForTable = ({ title }: BreadcrumbProps) => {
  const { pathname } = useLocation();
  const breadcrumbItems = pathname.split("/").filter(Boolean);
  return (
    <BreadCrumbsSectionStyled xs={7} item={true}>
      <TitleStyled className="title" variant="h5" gutterBottom={true}>
        {t(translationPath(lang.breadcrumb[title]))}
      </TitleStyled>
      <PathStyled separator=">">
        {breadcrumbItems.map((item) => (
          <Typography color="textPrimary">
            {t(translationPath(lang.breadcrumb[tranformToTranslatable(item)]))}
          </Typography>
        ))}
      </PathStyled>
    </BreadCrumbsSectionStyled>
  );
};
