import React, { useCallback, useMemo } from "react";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { createColumn } from "presentation/designSystem/Table/column/column";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { ColumnType } from "presentation/designSystem/Table/Table";
import { actionShowDetail } from "presentation/share/components/table/actionFactory";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import { styled } from "../../../../styles";
import { useFindFileErasedDocuments } from "../../hooks/useFindFileErasedDocuments";
import { ErasedDocument } from "../../../../../domain/struct/recordRetention/ErasedDocument";
import { DetailsOfErasedDocumentModal } from "../../modal/DetailsOfErasedDocumentModal";

interface ErasedDocumentsContentTableProps {
  id: string;
}

export const ErasedDocumentsContentTable = ({
  id
}: ErasedDocumentsContentTableProps) => {
  const COLUMNS: ColumnType<ErasedDocument>[] = [
    createColumn({
      dataIndex: "pid",
      title: t(
        translationPath(lang.table.retentionProposalOrProtocolContentTable.pid)
      )
    }),
    createColumn({
      dataIndex: "ssid",
      title: t(translationPath(lang.table.concernedContentTable.documentSsid))
    }),
    createColumn({
      dataIndex: "subject",
      title: t(
        translationPath(
          lang.table.retentionProposalOrProtocolContentTable.subject
        )
      )
    })
  ];

  const findFileErasedDocuments = useFindFileErasedDocuments(id);

  const [documentModal, documentModalApi] = useModal(
    DetailsOfErasedDocumentModal
  );

  const ACTIONS = useMemo(
    () => ({
      single: [
        actionShowDetail({
          action(selected: ErasedDocument[]) {
            return documentModalApi.open({
              nodeId: selected[0].nodeId!
            });
          }
        })
      ]
    }),
    [documentModalApi]
  );

  const getRemoteData: GetRemoteDataClb<ErasedDocument> = useCallback(
    ({ pagination }) => findFileErasedDocuments(pagination),
    [findFileErasedDocuments]
  );

  return (
    <>
      {documentModal}
      <RemoteTableStyled
        name={`nameRegistry/erasedDocumentslContentTable/${id}`}
        rowKey="pid"
        actions={ACTIONS}
        columns={COLUMNS}
        getRemoteData={getRemoteData}
      />
    </>
  );
};

const RemoteTableStyled = styled(RemoteTable)`
  overflow-x: scroll;
`;
